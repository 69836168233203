















import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { isEmpty } from "lodash";

import { CustomInput, Dropdown, SvgIcon, Loader } from "@/components";
import { PaymentMode } from "@/types";
import { Bank } from "@/proto/banks/all_pb";

const configModule = namespace("config");

@Component({
  components: {
    Loader,
    Dropdown,
    CustomInput,
    SvgIcon,
  },
})
export default class BanksDropdown extends Vue {
  @Prop({ type: Boolean, default: false }) error!: boolean;
  @Prop({ type: String }) settlementRail!: PaymentMode;
  @Prop({ type: String }) bankCode!: string;

  @configModule.Getter("pesonetBanks") pesonetBanks!: Array<Bank.AsObject>;
  @configModule.Getter("instapayBanks") instapayBanks!: Array<Bank.AsObject>;
  @configModule.Getter("allBanks") allBanks!: Array<[string, Bank.AsObject]>;

  get bankList() {
    if (!this.pesonetBanks.length && !this.instapayBanks.length) return [];

    if (this.settlementRail === PaymentMode.Pesonet) {
      return this.pesonetBanks.map(this._generateBankList);
    } else if (this.settlementRail === PaymentMode.Instapay) {
      return this.instapayBanks.map(this._generateBankList);
    } else {
      return this._generateAllBanks();
    }
  }

  get isBanksLoaded() {
    return !isEmpty(this.pesonetBanks);
  }

  _generateAllBanks() {
    return this.allBanks.map((bank) => {
      return {
        label: bank[1].name,
        value: bank[0],
      };
    });
  }

  _generateBankList(bank: Bank.AsObject) {
    let code;
    if (this.settlementRail === PaymentMode.Pesonet) {
      code = bank.rails?.pesonet;
    } else if (this.settlementRail === PaymentMode.Instapay) {
      code = bank.rails?.instapay;
    } else {
      code = "";
    }

    return {
      label: bank.name,
      value: code,
    };
  }

  _setBankCode(code: string) {
    this.$emit("set-bank-code", code);
  }
}
