










export default {
  name: "LogOut",
};
