export const isTokenExpired = (expiryTime: number) => {
  return new Date().getTime() - expiryTime > 0;
};

export const getMetadata = (token: string) => {
  return {
    authorization: "Bearer " + token,
  };
};

export const clearData = () => {
  localStorage.removeItem("client.cebcorp.dashboard");
};

export default {
  getMetadata,
  isTokenExpired,
  clearData,
};
