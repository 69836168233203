<template>
  <div class="w-100">
    <!---------header part------------>
    <div
      class="
        px-8
        pb-3
        text-xs text-gray-500
        border-b border-gray-300
        font-normal
      "
    >
      FILE MANAGEMENT
    </div>
    <!-------------body parts/rows --------------------->
    <div class="">
      <custom-file-row
        v-for="file in files"
        :key="file.name"
        :category="file.category"
        :number="file.file"
        :date="file.date"
        :actions="file.actions"
      ></custom-file-row>
    </div>
    <!--------------------footer part------------------------------------->
    <div class="text-right px-8 pt-3 text-xs text-orange">
      <div><router-link to="/files">View All</router-link></div>
    </div>
  </div>
</template>

<script>
import CustomFileRow from "./CustomFileRow.vue";
export default {
  components: { CustomFileRow },
  name: "CustomPopover",
  data() {
    return {
      files: [
        {
          name: "File Name Record 01",
          file: "1230-000-00005-00",
          date: new Date(),
          category: "Payroll",
          actions: "Download",
        },
        {
          name: "File Name Record 02",
          file: "1230-000-00005-00",
          date: new Date(),
          category: "Payroll",
          actions: "Download",
        },
        {
          name: "File Name Record 03",
          file: "1230-000-00005-00",
          date: new Date(),
          category: "Payroll",
          actions: "Download",
        },
        {
          name: "File Name Record 04",
          file: "1230-000-00005-00",
          date: new Date(),
          category: "Payroll",
          actions: "Download",
        },
      ],
    };
  },
};
</script>
