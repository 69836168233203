import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { ProtobufMessage } from "@improbable-eng/grpc-web/dist/typings/message";

import { InviteService as Service } from "@/proto/invite/all_pb_service";
import * as InviteServiceProto from "@/proto/invite/all_pb";

import { ProfileService } from "@/proto/profile/all_pb_service";
import * as ProfileServiceProto from "@/proto/profile/all_pb";

import AuthUtils from "@/utils/auth-utils";
// import { UserInfo, UserRole, InvitedUser } from "@/types";

export default class InviteService {
  constructor(private baseURL: string, private token: string) {}

  private genericErrorMessage =
    "Connection to the network failed. Please contact our support team support@cebuanalhullier.com.";

  public async resendInvite(id: string) {
    const request = new InviteServiceProto.ResendRequest();
    request.setId(id);

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.Resend, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      console.error(
        `Error when resending user invite: unexpected status code=${status} msg=${statusMessage}`
      );

      switch (status) {
        case grpc.Code.InvalidArgument:
          throw new Error(statusMessage);
        case grpc.Code.Internal:
        default:
          throw new Error(this.genericErrorMessage);
      }
    }

    const decoded = message as InviteServiceProto.ResendResponse;
    const response = decoded.toObject();
    console.log(response);
  }

  public async sendInvite(invitationID: string) {
    const request = new ProfileServiceProto.SendInviteRequest();
    request.setInvitationid(invitationID);

    const {
      status,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(ProfileService.SendInvite, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      console.error(
        `Error when inviting user: unexpected status code=${status} msg=${statusMessage}`
      );

      switch (status) {
        case grpc.Code.InvalidArgument:
          throw new Error(statusMessage);
        case grpc.Code.Internal:
        default:
          throw new Error(this.genericErrorMessage);
      }
    }
  }
}
