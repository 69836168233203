


















export default {
  name: "DeleteBin",
};
