















































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Vuelidate from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { isEmpty } from "lodash";
import VModal from "vue-js-modal";

import { CustomInput, Dropdown, SvgIcon, Loader } from "@/components";
import { OrgAccount, UserInfo, Batch, Currencies, PaymentMode } from "@/types";
import { formatAmount, formatCurrency } from "@/utils/common";
import { Bank } from "@/proto/banks/all_pb";

const orgModule = namespace("org");
const userModule = namespace("user");
const transactionsModule = namespace("transactions");
const configModule = namespace("config");

Vue.use(VModal);
Vue.use(Vuelidate);

@Component({
  components: {
    Loader,
    Dropdown,
    CustomInput,
    SvgIcon,
    BanksDropdown: () => import("@components/banks-dropdown/BanksDropdown.vue"),
    AccountsDropdown: () =>
      import("@components/accounts-dropdown/AccountsDropdown.vue"),
  },
  validations: {
    sourceAccount: {
      hasChosen: (value) => !isEmpty(value),
    },
    destinationAccount: {
      required,
    },
    destinationAccountName: {
      required,
    },
    amount: {
      required,
    },
  },
})
export default class TransferFundModal extends Vue {
  @orgModule.State("accounts") accounts!: OrgAccount[];
  @userModule.Getter("currentUserInfo") userInfo!: UserInfo;
  @configModule.Getter("allBanks") allBanks!: Array<[string, Bank.AsObject]>;

  @transactionsModule.Action("createBatch") createBatch!: (
    payload: Batch
  ) => void;
  @transactionsModule.Action("getSingleTransactions")
  getSingleTransactions!: () => void;

  @Prop({ type: Boolean, default: false })
  private readonly withButton!: boolean;

  @Prop({ default: null })
  private readonly paymentMode!: PaymentMode | null;

  @Prop({ default: "" })
  private readonly accountName!: string;

  @Prop({ default: "" })
  private readonly accountNumber!: string;

  @Prop({ default: "" })
  private readonly payeeBankCode!: string;

  sourceAccount = {} as OrgAccount;
  destinationAccount = "";
  destinationAccountName = "";
  bankCode = "";
  amount = "";
  internalError = "";
  loading = false;
  transferSuccess = false;
  settlementRail: PaymentMode = PaymentMode.EFT;
  hasPesonetAndInstapay = false;

  get recipientAccountLabel() {
    return this.settlementRail === PaymentMode.EFT
      ? "Cebuana Bank Account No"
      : "Transfer To";
  }

  get balance() {
    return isEmpty(this.sourceAccount)
      ? 0
      : this.sourceAccount.availableBalance;
  }

  get _paymentMode() {
    switch (this.settlementRail) {
      case PaymentMode.Pesonet:
        return {
          logo: "pesonet",
          label: "Pesonet",
        };
      case PaymentMode.Instapay:
        return {
          logo: "instapay",
          label: "InstaPay",
        };
      case PaymentMode.EFT:
      default:
        return {
          logo: "clrb",
          label: "EFT",
        };
    }
  }

  get isEFT() {
    return this.settlementRail === PaymentMode.EFT;
  }

  get isAccountsLoaded() {
    return !isEmpty(this.accounts);
  }

  get availableBalance() {
    if (this.sourceAccount.availableBalance) {
      return formatCurrency(this.sourceAccount.availableBalance);
    }

    return false;
  }

  async onSubmit() {
    this.internalError = "";

    this.$v.$touch();
    if (this.$v.$invalid) return;

    this.loading = true;

    try {
      const batch: Batch = {
        source: {
          accountNumber: this.sourceAccount.accountNumber,
          firstName: this.userInfo.firstName as string,
          lastName: this.userInfo.lastName as string,
        },
        settlementRail: this.settlementRail as PaymentMode,
        remarks: "",
        txnSource: "single",
        transactions: [
          {
            destination: {
              accountNumber: this.destinationAccount,
              accountName: this.destinationAccountName,
              bankCode: this.bankCode,
              firstName: "User",
              lastName: "User",
            },
            currency: Currencies.PHP,
            amount: formatAmount(this.amount),
          },
        ],
      };

      await this.createBatch(batch);
      await this.getSingleTransactions();

      this.transferSuccess = true;
    } catch (err) {
      const error = err as Error;
      this.internalError = error.message;
    } finally {
      this.loading = false;
    }
  }

  _onModalClose() {
    this.sourceAccount = {} as OrgAccount;
    this.destinationAccount = "";
    this.destinationAccountName = "";
    this.bankCode = "";
    this.amount = "";
    this.internalError = "";
    this.transferSuccess = false;
    this.hasPesonetAndInstapay = false;
    this.$v.$reset();
  }

  _onModalOpen() {
    // else, it is coming from payees page
    if (this.payeeBankCode) {
      this._setRail();
    }

    // assign default account name
    if (this.accountName) {
      this.destinationAccountName = this.accountName;
    }

    // assign default account number
    if (this.accountNumber) {
      this.destinationAccount = this.accountNumber;
    }
  }

  _setRail() {
    if (this.payeeBankCode === "CLRB") {
      this._setBankCode("CLRB");
      this.settlementRail = PaymentMode.EFT;
      return;
    }

    const code = this.payeeBankCode;
    const bank = this.allBanks.find((bank) => {
      return bank[0] === code;
    }) as [string, Bank.AsObject];

    if (bank[1].rails?.instapay) {
      this.settlementRail = PaymentMode.Instapay;
      this._setBankCode(bank[1].rails?.instapay);
    } else {
      this.settlementRail = PaymentMode.Pesonet;
      this._setBankCode(bank[1].rails?.pesonet as string);
    }

    if (bank[1].rails?.instapay && bank[1].rails?.pesonet) {
      this.hasPesonetAndInstapay = true;
    }
  }

  _setSourceAccount(payload: OrgAccount) {
    this.sourceAccount = payload;
  }

  _setBankCode(payload: string) {
    this.bankCode = payload;
  }

  _showTransferFundModal() {
    this.$modal.show("transfer-modal");
  }

  _hideTransferFundModal() {
    this.$modal.hide("transfer-modal");
  }

  @Watch("paymentMode", { immediate: true })
  watchUsers(paymentMode: PaymentMode) {
    // If paymentMode prop is not null, this means that the transfer is coming from fund-transfer page
    if (paymentMode !== null) {
      if (paymentMode === PaymentMode.EFT) {
        this._setBankCode("CLRB");
      }
      this.settlementRail = paymentMode;
    }
  }

  @Watch("settlementRail", { immediate: true })
  watchSettlementRail(settlementRail: PaymentMode) {
    if (this.hasPesonetAndInstapay) {
      const code = this.payeeBankCode;
      const bank = this.allBanks.find((bank) => {
        return bank[0] === code;
      }) as [string, Bank.AsObject];

      if (settlementRail === PaymentMode.Instapay) {
        this._setBankCode(bank[1].rails?.instapay as string);
      } else {
        this._setBankCode(bank[1].rails?.pesonet as string);
      }
    }
  }
}
