// package: cebcorp.v1.organization
// file: brank.as/cebuana-corporate/gunk/v1/organization/all.proto

var brank_as_cebuana_corporate_gunk_v1_organization_all_pb = require("./all_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var OrganizationService = (function () {
  function OrganizationService() {}
  OrganizationService.serviceName = "cebcorp.v1.organization.OrganizationService";
  return OrganizationService;
}());

OrganizationService.CreateOrganization = {
  methodName: "CreateOrganization",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_v1_organization_all_pb.CreateOrganizationRequest,
  responseType: brank_as_cebuana_corporate_gunk_v1_organization_all_pb.CreateOrganizationResponse
};

OrganizationService.UpdateOrganization = {
  methodName: "UpdateOrganization",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_v1_organization_all_pb.UpdateOrganizationRequest,
  responseType: brank_as_cebuana_corporate_gunk_v1_organization_all_pb.UpdateOrganizationResponse
};

OrganizationService.GetOrganization = {
  methodName: "GetOrganization",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_v1_organization_all_pb.GetOrganizationRequest,
  responseType: brank_as_cebuana_corporate_gunk_v1_organization_all_pb.GetOrganizationResponse
};

OrganizationService.DeleteOrganization = {
  methodName: "DeleteOrganization",
  service: OrganizationService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_v1_organization_all_pb.DeleteOrganizationRequest,
  responseType: brank_as_cebuana_corporate_gunk_v1_organization_all_pb.DeleteOrganizationResponse
};

exports.OrganizationService = OrganizationService;

function OrganizationServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

OrganizationServiceClient.prototype.createOrganization = function createOrganization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.CreateOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.updateOrganization = function updateOrganization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.UpdateOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.getOrganization = function getOrganization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.GetOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OrganizationServiceClient.prototype.deleteOrganization = function deleteOrganization(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OrganizationService.DeleteOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.OrganizationServiceClient = OrganizationServiceClient;

