




























































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import CustomTopNavPopover from "./custom-topnav-popover/CustomTopNavPopover.vue";
import { SvgIcon } from "@components";
import { VTooltip, VPopover, VClosePopover } from "v-tooltip";

Vue.directive("tooltip", VTooltip);
Vue.directive("close-popover", VClosePopover);
Vue.component("v-popover", VPopover);

const userModule = createNamespacedHelpers("user");

export default Vue.extend({
  name: "TopNav",

  components: {
    SvgIcon,
    CustomTopNavPopover,
  },
  data() {
    return {
      showFileManagement: false,
    };
  },

  computed: {
    ...userModule.mapGetters(["userFullName"]),
  },

  methods: {
    onLogoutClick(): void {
      this.$router.push("/sign-out");
    },

    onShow(): void {
      this.$emit("on-show");
    },
  },
});
