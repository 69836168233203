














































import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class CustomTextArea extends Vue {
  @Prop({ default: "" })
  private readonly label!: string;

  @Prop({ default: "" })
  private readonly placeholder!: string;

  @Prop({ default: "" })
  private readonly value!: string | number;

  @Prop({ default: false })
  private readonly error!: boolean;

  @Prop({ default: false })
  private readonly id!: string;

  @Prop({ default: "" })
  private readonly color!: string;

  @Prop({ default: false })
  private readonly pageWidth!: boolean;

  get inputID(): string {
    return this.id
      ? this.id
      : `custom-input-${this.label.replace(/[^\w-]+/g, "-").toLowerCase()}`;
  }

  get BGColor(): string {
    return this.color ? `bg-${this.color}` : "bg-grey-400";
  }
}
