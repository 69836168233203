













export default {
  name: "Collection",
};
