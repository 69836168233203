// source: all.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.cebcorp.v1.organization.Address', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.Amount', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.AuthorizedEmailRecipient', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.AuthorizedRepresentative', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.Charge', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.CompanyInformation', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.CreateOrganizationRequest', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.CreateOrganizationResponse', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.DeleteOrganizationRequest', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.DeleteOrganizationResponse', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.DeliveryInformation', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.GetOrganizationRequest', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.GetOrganizationResponse', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.Merchant', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.MerchantInfo', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.OrgInfo', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.OrgType', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.Organization', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.OwnershipStyle', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.PIC', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.SupportingDocument', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.UpdateOrganizationRequest', null, global);
goog.exportSymbol('proto.cebcorp.v1.organization.UpdateOrganizationResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.Amount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.Amount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.Amount.displayName = 'proto.cebcorp.v1.organization.Amount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.CreateOrganizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.CreateOrganizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.CreateOrganizationRequest.displayName = 'proto.cebcorp.v1.organization.CreateOrganizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.CreateOrganizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.CreateOrganizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.CreateOrganizationResponse.displayName = 'proto.cebcorp.v1.organization.CreateOrganizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.UpdateOrganizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.UpdateOrganizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.UpdateOrganizationRequest.displayName = 'proto.cebcorp.v1.organization.UpdateOrganizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.UpdateOrganizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.UpdateOrganizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.UpdateOrganizationResponse.displayName = 'proto.cebcorp.v1.organization.UpdateOrganizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.GetOrganizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.GetOrganizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.GetOrganizationRequest.displayName = 'proto.cebcorp.v1.organization.GetOrganizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.GetOrganizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.GetOrganizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.GetOrganizationResponse.displayName = 'proto.cebcorp.v1.organization.GetOrganizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.DeleteOrganizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.DeleteOrganizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.DeleteOrganizationRequest.displayName = 'proto.cebcorp.v1.organization.DeleteOrganizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.DeleteOrganizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.DeleteOrganizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.DeleteOrganizationResponse.displayName = 'proto.cebcorp.v1.organization.DeleteOrganizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.Organization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.Organization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.Organization.displayName = 'proto.cebcorp.v1.organization.Organization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.OrgInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.OrgInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.OrgInfo.displayName = 'proto.cebcorp.v1.organization.OrgInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.MerchantInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.MerchantInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.MerchantInfo.displayName = 'proto.cebcorp.v1.organization.MerchantInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.Address.displayName = 'proto.cebcorp.v1.organization.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.PIC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.PIC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.PIC.displayName = 'proto.cebcorp.v1.organization.PIC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.Merchant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cebcorp.v1.organization.Merchant.repeatedFields_, null);
};
goog.inherits(proto.cebcorp.v1.organization.Merchant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.Merchant.displayName = 'proto.cebcorp.v1.organization.Merchant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.CompanyInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.CompanyInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.CompanyInformation.displayName = 'proto.cebcorp.v1.organization.CompanyInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.AuthorizedRepresentative = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.AuthorizedRepresentative, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.AuthorizedRepresentative.displayName = 'proto.cebcorp.v1.organization.AuthorizedRepresentative';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.AuthorizedEmailRecipient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.AuthorizedEmailRecipient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.AuthorizedEmailRecipient.displayName = 'proto.cebcorp.v1.organization.AuthorizedEmailRecipient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.DeliveryInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.DeliveryInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.DeliveryInformation.displayName = 'proto.cebcorp.v1.organization.DeliveryInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.Charge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.Charge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.Charge.displayName = 'proto.cebcorp.v1.organization.Charge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cebcorp.v1.organization.SupportingDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cebcorp.v1.organization.SupportingDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cebcorp.v1.organization.SupportingDocument.displayName = 'proto.cebcorp.v1.organization.SupportingDocument';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.Amount.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.Amount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.Amount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.Amount.toObject = function(includeInstance, msg) {
  var f, obj = {
    amt: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cur: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.Amount}
 */
proto.cebcorp.v1.organization.Amount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.Amount;
  return proto.cebcorp.v1.organization.Amount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.Amount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.Amount}
 */
proto.cebcorp.v1.organization.Amount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCur(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.Amount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.Amount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.Amount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.Amount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCur();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Amt = 1;
 * @return {string}
 */
proto.cebcorp.v1.organization.Amount.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.Amount} returns this
 */
proto.cebcorp.v1.organization.Amount.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Cur = 2;
 * @return {string}
 */
proto.cebcorp.v1.organization.Amount.prototype.getCur = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.Amount} returns this
 */
proto.cebcorp.v1.organization.Amount.prototype.setCur = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.CreateOrganizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.CreateOrganizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.CreateOrganizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.CreateOrganizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && proto.cebcorp.v1.organization.Organization.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.CreateOrganizationRequest}
 */
proto.cebcorp.v1.organization.CreateOrganizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.CreateOrganizationRequest;
  return proto.cebcorp.v1.organization.CreateOrganizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.CreateOrganizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.CreateOrganizationRequest}
 */
proto.cebcorp.v1.organization.CreateOrganizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cebcorp.v1.organization.Organization;
      reader.readMessage(value,proto.cebcorp.v1.organization.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.CreateOrganizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.CreateOrganizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.CreateOrganizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.CreateOrganizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cebcorp.v1.organization.Organization.serializeBinaryToWriter
    );
  }
};


/**
 * optional Organization Organization = 1;
 * @return {?proto.cebcorp.v1.organization.Organization}
 */
proto.cebcorp.v1.organization.CreateOrganizationRequest.prototype.getOrganization = function() {
  return /** @type{?proto.cebcorp.v1.organization.Organization} */ (
    jspb.Message.getWrapperField(this, proto.cebcorp.v1.organization.Organization, 1));
};


/**
 * @param {?proto.cebcorp.v1.organization.Organization|undefined} value
 * @return {!proto.cebcorp.v1.organization.CreateOrganizationRequest} returns this
*/
proto.cebcorp.v1.organization.CreateOrganizationRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.CreateOrganizationRequest} returns this
 */
proto.cebcorp.v1.organization.CreateOrganizationRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.CreateOrganizationRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.CreateOrganizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.CreateOrganizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.CreateOrganizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.CreateOrganizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.CreateOrganizationResponse}
 */
proto.cebcorp.v1.organization.CreateOrganizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.CreateOrganizationResponse;
  return proto.cebcorp.v1.organization.CreateOrganizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.CreateOrganizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.CreateOrganizationResponse}
 */
proto.cebcorp.v1.organization.CreateOrganizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.CreateOrganizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.CreateOrganizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.CreateOrganizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.CreateOrganizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.cebcorp.v1.organization.CreateOrganizationResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.CreateOrganizationResponse} returns this
 */
proto.cebcorp.v1.organization.CreateOrganizationResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.UpdateOrganizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.UpdateOrganizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.UpdateOrganizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.UpdateOrganizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && proto.cebcorp.v1.organization.Organization.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.UpdateOrganizationRequest}
 */
proto.cebcorp.v1.organization.UpdateOrganizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.UpdateOrganizationRequest;
  return proto.cebcorp.v1.organization.UpdateOrganizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.UpdateOrganizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.UpdateOrganizationRequest}
 */
proto.cebcorp.v1.organization.UpdateOrganizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cebcorp.v1.organization.Organization;
      reader.readMessage(value,proto.cebcorp.v1.organization.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.UpdateOrganizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.UpdateOrganizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.UpdateOrganizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.UpdateOrganizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cebcorp.v1.organization.Organization.serializeBinaryToWriter
    );
  }
};


/**
 * optional Organization Organization = 1;
 * @return {?proto.cebcorp.v1.organization.Organization}
 */
proto.cebcorp.v1.organization.UpdateOrganizationRequest.prototype.getOrganization = function() {
  return /** @type{?proto.cebcorp.v1.organization.Organization} */ (
    jspb.Message.getWrapperField(this, proto.cebcorp.v1.organization.Organization, 1));
};


/**
 * @param {?proto.cebcorp.v1.organization.Organization|undefined} value
 * @return {!proto.cebcorp.v1.organization.UpdateOrganizationRequest} returns this
*/
proto.cebcorp.v1.organization.UpdateOrganizationRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.UpdateOrganizationRequest} returns this
 */
proto.cebcorp.v1.organization.UpdateOrganizationRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.UpdateOrganizationRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.UpdateOrganizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.UpdateOrganizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.UpdateOrganizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.UpdateOrganizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.UpdateOrganizationResponse}
 */
proto.cebcorp.v1.organization.UpdateOrganizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.UpdateOrganizationResponse;
  return proto.cebcorp.v1.organization.UpdateOrganizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.UpdateOrganizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.UpdateOrganizationResponse}
 */
proto.cebcorp.v1.organization.UpdateOrganizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.UpdateOrganizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.UpdateOrganizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.UpdateOrganizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.UpdateOrganizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.cebcorp.v1.organization.UpdateOrganizationResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.UpdateOrganizationResponse} returns this
 */
proto.cebcorp.v1.organization.UpdateOrganizationResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.GetOrganizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.GetOrganizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.GetOrganizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.GetOrganizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.GetOrganizationRequest}
 */
proto.cebcorp.v1.organization.GetOrganizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.GetOrganizationRequest;
  return proto.cebcorp.v1.organization.GetOrganizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.GetOrganizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.GetOrganizationRequest}
 */
proto.cebcorp.v1.organization.GetOrganizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.GetOrganizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.GetOrganizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.GetOrganizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.GetOrganizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.cebcorp.v1.organization.GetOrganizationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.GetOrganizationRequest} returns this
 */
proto.cebcorp.v1.organization.GetOrganizationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.GetOrganizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.GetOrganizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.GetOrganizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.GetOrganizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && proto.cebcorp.v1.organization.Organization.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.GetOrganizationResponse}
 */
proto.cebcorp.v1.organization.GetOrganizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.GetOrganizationResponse;
  return proto.cebcorp.v1.organization.GetOrganizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.GetOrganizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.GetOrganizationResponse}
 */
proto.cebcorp.v1.organization.GetOrganizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cebcorp.v1.organization.Organization;
      reader.readMessage(value,proto.cebcorp.v1.organization.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.GetOrganizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.GetOrganizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.GetOrganizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.GetOrganizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cebcorp.v1.organization.Organization.serializeBinaryToWriter
    );
  }
};


/**
 * optional Organization Organization = 1;
 * @return {?proto.cebcorp.v1.organization.Organization}
 */
proto.cebcorp.v1.organization.GetOrganizationResponse.prototype.getOrganization = function() {
  return /** @type{?proto.cebcorp.v1.organization.Organization} */ (
    jspb.Message.getWrapperField(this, proto.cebcorp.v1.organization.Organization, 1));
};


/**
 * @param {?proto.cebcorp.v1.organization.Organization|undefined} value
 * @return {!proto.cebcorp.v1.organization.GetOrganizationResponse} returns this
*/
proto.cebcorp.v1.organization.GetOrganizationResponse.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.GetOrganizationResponse} returns this
 */
proto.cebcorp.v1.organization.GetOrganizationResponse.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.GetOrganizationResponse.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.DeleteOrganizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.DeleteOrganizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.DeleteOrganizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.DeleteOrganizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.DeleteOrganizationRequest}
 */
proto.cebcorp.v1.organization.DeleteOrganizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.DeleteOrganizationRequest;
  return proto.cebcorp.v1.organization.DeleteOrganizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.DeleteOrganizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.DeleteOrganizationRequest}
 */
proto.cebcorp.v1.organization.DeleteOrganizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.DeleteOrganizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.DeleteOrganizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.DeleteOrganizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.DeleteOrganizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.cebcorp.v1.organization.DeleteOrganizationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.DeleteOrganizationRequest} returns this
 */
proto.cebcorp.v1.organization.DeleteOrganizationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.DeleteOrganizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.DeleteOrganizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.DeleteOrganizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.DeleteOrganizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && proto.cebcorp.v1.organization.Organization.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.DeleteOrganizationResponse}
 */
proto.cebcorp.v1.organization.DeleteOrganizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.DeleteOrganizationResponse;
  return proto.cebcorp.v1.organization.DeleteOrganizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.DeleteOrganizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.DeleteOrganizationResponse}
 */
proto.cebcorp.v1.organization.DeleteOrganizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cebcorp.v1.organization.Organization;
      reader.readMessage(value,proto.cebcorp.v1.organization.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.DeleteOrganizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.DeleteOrganizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.DeleteOrganizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.DeleteOrganizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cebcorp.v1.organization.Organization.serializeBinaryToWriter
    );
  }
};


/**
 * optional Organization Organization = 1;
 * @return {?proto.cebcorp.v1.organization.Organization}
 */
proto.cebcorp.v1.organization.DeleteOrganizationResponse.prototype.getOrganization = function() {
  return /** @type{?proto.cebcorp.v1.organization.Organization} */ (
    jspb.Message.getWrapperField(this, proto.cebcorp.v1.organization.Organization, 1));
};


/**
 * @param {?proto.cebcorp.v1.organization.Organization|undefined} value
 * @return {!proto.cebcorp.v1.organization.DeleteOrganizationResponse} returns this
*/
proto.cebcorp.v1.organization.DeleteOrganizationResponse.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.DeleteOrganizationResponse} returns this
 */
proto.cebcorp.v1.organization.DeleteOrganizationResponse.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.DeleteOrganizationResponse.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.Organization.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.Organization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.Organization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.Organization.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    corporateid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orginfo: (f = msg.getOrginfo()) && proto.cebcorp.v1.organization.OrgInfo.toObject(includeInstance, f),
    merchant: (f = msg.getMerchant()) && proto.cebcorp.v1.organization.Merchant.toObject(includeInstance, f),
    orgtype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    cifnumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updated: (f = msg.getUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deleted: (f = msg.getDeleted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.Organization}
 */
proto.cebcorp.v1.organization.Organization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.Organization;
  return proto.cebcorp.v1.organization.Organization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.Organization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.Organization}
 */
proto.cebcorp.v1.organization.Organization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorporateid(value);
      break;
    case 3:
      var value = new proto.cebcorp.v1.organization.OrgInfo;
      reader.readMessage(value,proto.cebcorp.v1.organization.OrgInfo.deserializeBinaryFromReader);
      msg.setOrginfo(value);
      break;
    case 4:
      var value = new proto.cebcorp.v1.organization.Merchant;
      reader.readMessage(value,proto.cebcorp.v1.organization.Merchant.deserializeBinaryFromReader);
      msg.setMerchant(value);
      break;
    case 5:
      var value = /** @type {!proto.cebcorp.v1.organization.OrgType} */ (reader.readEnum());
      msg.setOrgtype(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCifnumber(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdated(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.Organization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.Organization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.Organization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.Organization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorporateid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrginfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cebcorp.v1.organization.OrgInfo.serializeBinaryToWriter
    );
  }
  f = message.getMerchant();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.cebcorp.v1.organization.Merchant.serializeBinaryToWriter
    );
  }
  f = message.getOrgtype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCifnumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdated();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.cebcorp.v1.organization.Organization.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.Organization} returns this
 */
proto.cebcorp.v1.organization.Organization.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string CorporateID = 2;
 * @return {string}
 */
proto.cebcorp.v1.organization.Organization.prototype.getCorporateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.Organization} returns this
 */
proto.cebcorp.v1.organization.Organization.prototype.setCorporateid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional OrgInfo OrgInfo = 3;
 * @return {?proto.cebcorp.v1.organization.OrgInfo}
 */
proto.cebcorp.v1.organization.Organization.prototype.getOrginfo = function() {
  return /** @type{?proto.cebcorp.v1.organization.OrgInfo} */ (
    jspb.Message.getWrapperField(this, proto.cebcorp.v1.organization.OrgInfo, 3));
};


/**
 * @param {?proto.cebcorp.v1.organization.OrgInfo|undefined} value
 * @return {!proto.cebcorp.v1.organization.Organization} returns this
*/
proto.cebcorp.v1.organization.Organization.prototype.setOrginfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.Organization} returns this
 */
proto.cebcorp.v1.organization.Organization.prototype.clearOrginfo = function() {
  return this.setOrginfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.Organization.prototype.hasOrginfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Merchant Merchant = 4;
 * @return {?proto.cebcorp.v1.organization.Merchant}
 */
proto.cebcorp.v1.organization.Organization.prototype.getMerchant = function() {
  return /** @type{?proto.cebcorp.v1.organization.Merchant} */ (
    jspb.Message.getWrapperField(this, proto.cebcorp.v1.organization.Merchant, 4));
};


/**
 * @param {?proto.cebcorp.v1.organization.Merchant|undefined} value
 * @return {!proto.cebcorp.v1.organization.Organization} returns this
*/
proto.cebcorp.v1.organization.Organization.prototype.setMerchant = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.Organization} returns this
 */
proto.cebcorp.v1.organization.Organization.prototype.clearMerchant = function() {
  return this.setMerchant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.Organization.prototype.hasMerchant = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional OrgType OrgType = 5;
 * @return {!proto.cebcorp.v1.organization.OrgType}
 */
proto.cebcorp.v1.organization.Organization.prototype.getOrgtype = function() {
  return /** @type {!proto.cebcorp.v1.organization.OrgType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.cebcorp.v1.organization.OrgType} value
 * @return {!proto.cebcorp.v1.organization.Organization} returns this
 */
proto.cebcorp.v1.organization.Organization.prototype.setOrgtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string CifNumber = 6;
 * @return {string}
 */
proto.cebcorp.v1.organization.Organization.prototype.getCifnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.Organization} returns this
 */
proto.cebcorp.v1.organization.Organization.prototype.setCifnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp Created = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.cebcorp.v1.organization.Organization.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.cebcorp.v1.organization.Organization} returns this
*/
proto.cebcorp.v1.organization.Organization.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.Organization} returns this
 */
proto.cebcorp.v1.organization.Organization.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.Organization.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp Updated = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.cebcorp.v1.organization.Organization.prototype.getUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.cebcorp.v1.organization.Organization} returns this
*/
proto.cebcorp.v1.organization.Organization.prototype.setUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.Organization} returns this
 */
proto.cebcorp.v1.organization.Organization.prototype.clearUpdated = function() {
  return this.setUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.Organization.prototype.hasUpdated = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp Deleted = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.cebcorp.v1.organization.Organization.prototype.getDeleted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.cebcorp.v1.organization.Organization} returns this
*/
proto.cebcorp.v1.organization.Organization.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.Organization} returns this
 */
proto.cebcorp.v1.organization.Organization.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.Organization.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.OrgInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.OrgInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.OrgInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    industry: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: (f = msg.getAddress()) && proto.cebcorp.v1.organization.Address.toObject(includeInstance, f),
    phonenumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pic: (f = msg.getPic()) && proto.cebcorp.v1.organization.PIC.toObject(includeInstance, f),
    merchantinfo: (f = msg.getMerchantinfo()) && proto.cebcorp.v1.organization.MerchantInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.OrgInfo}
 */
proto.cebcorp.v1.organization.OrgInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.OrgInfo;
  return proto.cebcorp.v1.organization.OrgInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.OrgInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.OrgInfo}
 */
proto.cebcorp.v1.organization.OrgInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndustry(value);
      break;
    case 3:
      var value = new proto.cebcorp.v1.organization.Address;
      reader.readMessage(value,proto.cebcorp.v1.organization.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = new proto.cebcorp.v1.organization.PIC;
      reader.readMessage(value,proto.cebcorp.v1.organization.PIC.deserializeBinaryFromReader);
      msg.setPic(value);
      break;
    case 7:
      var value = new proto.cebcorp.v1.organization.MerchantInfo;
      reader.readMessage(value,proto.cebcorp.v1.organization.MerchantInfo.deserializeBinaryFromReader);
      msg.setMerchantinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.OrgInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.OrgInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.OrgInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIndustry();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cebcorp.v1.organization.Address.serializeBinaryToWriter
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPic();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.cebcorp.v1.organization.PIC.serializeBinaryToWriter
    );
  }
  f = message.getMerchantinfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.cebcorp.v1.organization.MerchantInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.OrgInfo} returns this
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Industry = 2;
 * @return {string}
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.getIndustry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.OrgInfo} returns this
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.setIndustry = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Address Address = 3;
 * @return {?proto.cebcorp.v1.organization.Address}
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.getAddress = function() {
  return /** @type{?proto.cebcorp.v1.organization.Address} */ (
    jspb.Message.getWrapperField(this, proto.cebcorp.v1.organization.Address, 3));
};


/**
 * @param {?proto.cebcorp.v1.organization.Address|undefined} value
 * @return {!proto.cebcorp.v1.organization.OrgInfo} returns this
*/
proto.cebcorp.v1.organization.OrgInfo.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.OrgInfo} returns this
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string PhoneNumber = 4;
 * @return {string}
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.OrgInfo} returns this
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.setPhonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Email = 5;
 * @return {string}
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.OrgInfo} returns this
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional PIC PIC = 6;
 * @return {?proto.cebcorp.v1.organization.PIC}
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.getPic = function() {
  return /** @type{?proto.cebcorp.v1.organization.PIC} */ (
    jspb.Message.getWrapperField(this, proto.cebcorp.v1.organization.PIC, 6));
};


/**
 * @param {?proto.cebcorp.v1.organization.PIC|undefined} value
 * @return {!proto.cebcorp.v1.organization.OrgInfo} returns this
*/
proto.cebcorp.v1.organization.OrgInfo.prototype.setPic = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.OrgInfo} returns this
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.clearPic = function() {
  return this.setPic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.hasPic = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional MerchantInfo MerchantInfo = 7;
 * @return {?proto.cebcorp.v1.organization.MerchantInfo}
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.getMerchantinfo = function() {
  return /** @type{?proto.cebcorp.v1.organization.MerchantInfo} */ (
    jspb.Message.getWrapperField(this, proto.cebcorp.v1.organization.MerchantInfo, 7));
};


/**
 * @param {?proto.cebcorp.v1.organization.MerchantInfo|undefined} value
 * @return {!proto.cebcorp.v1.organization.OrgInfo} returns this
*/
proto.cebcorp.v1.organization.OrgInfo.prototype.setMerchantinfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.OrgInfo} returns this
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.clearMerchantinfo = function() {
  return this.setMerchantinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.OrgInfo.prototype.hasMerchantinfo = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.MerchantInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.MerchantInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.MerchantInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.MerchantInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    applicationservice: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.MerchantInfo}
 */
proto.cebcorp.v1.organization.MerchantInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.MerchantInfo;
  return proto.cebcorp.v1.organization.MerchantInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.MerchantInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.MerchantInfo}
 */
proto.cebcorp.v1.organization.MerchantInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplicationservice(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.MerchantInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.MerchantInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.MerchantInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.MerchantInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApplicationservice();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ApplicationService = 1;
 * @return {string}
 */
proto.cebcorp.v1.organization.MerchantInfo.prototype.getApplicationservice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.MerchantInfo} returns this
 */
proto.cebcorp.v1.organization.MerchantInfo.prototype.setApplicationservice = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.cebcorp.v1.organization.MerchantInfo.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.MerchantInfo} returns this
 */
proto.cebcorp.v1.organization.MerchantInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    line1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    line2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    district: jspb.Message.getFieldWithDefault(msg, 5, ""),
    zipcode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    province: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.Address}
 */
proto.cebcorp.v1.organization.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.Address;
  return proto.cebcorp.v1.organization.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.Address}
 */
proto.cebcorp.v1.organization.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistrict(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipcode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvince(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLine1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLine2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDistrict();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getZipcode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProvince();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string Line1 = 1;
 * @return {string}
 */
proto.cebcorp.v1.organization.Address.prototype.getLine1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.Address} returns this
 */
proto.cebcorp.v1.organization.Address.prototype.setLine1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Line2 = 2;
 * @return {string}
 */
proto.cebcorp.v1.organization.Address.prototype.getLine2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.Address} returns this
 */
proto.cebcorp.v1.organization.Address.prototype.setLine2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string City = 3;
 * @return {string}
 */
proto.cebcorp.v1.organization.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.Address} returns this
 */
proto.cebcorp.v1.organization.Address.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string State = 4;
 * @return {string}
 */
proto.cebcorp.v1.organization.Address.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.Address} returns this
 */
proto.cebcorp.v1.organization.Address.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string District = 5;
 * @return {string}
 */
proto.cebcorp.v1.organization.Address.prototype.getDistrict = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.Address} returns this
 */
proto.cebcorp.v1.organization.Address.prototype.setDistrict = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ZipCode = 6;
 * @return {string}
 */
proto.cebcorp.v1.organization.Address.prototype.getZipcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.Address} returns this
 */
proto.cebcorp.v1.organization.Address.prototype.setZipcode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Province = 7;
 * @return {string}
 */
proto.cebcorp.v1.organization.Address.prototype.getProvince = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.Address} returns this
 */
proto.cebcorp.v1.organization.Address.prototype.setProvince = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.PIC.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.PIC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.PIC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.PIC.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phonenumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.PIC}
 */
proto.cebcorp.v1.organization.PIC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.PIC;
  return proto.cebcorp.v1.organization.PIC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.PIC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.PIC}
 */
proto.cebcorp.v1.organization.PIC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.PIC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.PIC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.PIC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.PIC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.cebcorp.v1.organization.PIC.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.PIC} returns this
 */
proto.cebcorp.v1.organization.PIC.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string PhoneNumber = 2;
 * @return {string}
 */
proto.cebcorp.v1.organization.PIC.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.PIC} returns this
 */
proto.cebcorp.v1.organization.PIC.prototype.setPhonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Email = 3;
 * @return {string}
 */
proto.cebcorp.v1.organization.PIC.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.PIC} returns this
 */
proto.cebcorp.v1.organization.PIC.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cebcorp.v1.organization.Merchant.repeatedFields_ = [2,3,4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.Merchant.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.Merchant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.Merchant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.Merchant.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyinformation: (f = msg.getCompanyinformation()) && proto.cebcorp.v1.organization.CompanyInformation.toObject(includeInstance, f),
    authorizedrepresentativesList: jspb.Message.toObjectList(msg.getAuthorizedrepresentativesList(),
    proto.cebcorp.v1.organization.AuthorizedRepresentative.toObject, includeInstance),
    authorizedemailrecipientsList: jspb.Message.toObjectList(msg.getAuthorizedemailrecipientsList(),
    proto.cebcorp.v1.organization.AuthorizedEmailRecipient.toObject, includeInstance),
    deliveryinformationsList: jspb.Message.toObjectList(msg.getDeliveryinformationsList(),
    proto.cebcorp.v1.organization.DeliveryInformation.toObject, includeInstance),
    charge: (f = msg.getCharge()) && proto.cebcorp.v1.organization.Charge.toObject(includeInstance, f),
    supportingdocumentsList: jspb.Message.toObjectList(msg.getSupportingdocumentsList(),
    proto.cebcorp.v1.organization.SupportingDocument.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.Merchant}
 */
proto.cebcorp.v1.organization.Merchant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.Merchant;
  return proto.cebcorp.v1.organization.Merchant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.Merchant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.Merchant}
 */
proto.cebcorp.v1.organization.Merchant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cebcorp.v1.organization.CompanyInformation;
      reader.readMessage(value,proto.cebcorp.v1.organization.CompanyInformation.deserializeBinaryFromReader);
      msg.setCompanyinformation(value);
      break;
    case 2:
      var value = new proto.cebcorp.v1.organization.AuthorizedRepresentative;
      reader.readMessage(value,proto.cebcorp.v1.organization.AuthorizedRepresentative.deserializeBinaryFromReader);
      msg.addAuthorizedrepresentatives(value);
      break;
    case 3:
      var value = new proto.cebcorp.v1.organization.AuthorizedEmailRecipient;
      reader.readMessage(value,proto.cebcorp.v1.organization.AuthorizedEmailRecipient.deserializeBinaryFromReader);
      msg.addAuthorizedemailrecipients(value);
      break;
    case 4:
      var value = new proto.cebcorp.v1.organization.DeliveryInformation;
      reader.readMessage(value,proto.cebcorp.v1.organization.DeliveryInformation.deserializeBinaryFromReader);
      msg.addDeliveryinformations(value);
      break;
    case 5:
      var value = new proto.cebcorp.v1.organization.Charge;
      reader.readMessage(value,proto.cebcorp.v1.organization.Charge.deserializeBinaryFromReader);
      msg.setCharge(value);
      break;
    case 6:
      var value = new proto.cebcorp.v1.organization.SupportingDocument;
      reader.readMessage(value,proto.cebcorp.v1.organization.SupportingDocument.deserializeBinaryFromReader);
      msg.addSupportingdocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.Merchant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.Merchant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.Merchant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.Merchant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyinformation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cebcorp.v1.organization.CompanyInformation.serializeBinaryToWriter
    );
  }
  f = message.getAuthorizedrepresentativesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cebcorp.v1.organization.AuthorizedRepresentative.serializeBinaryToWriter
    );
  }
  f = message.getAuthorizedemailrecipientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.cebcorp.v1.organization.AuthorizedEmailRecipient.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryinformationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cebcorp.v1.organization.DeliveryInformation.serializeBinaryToWriter
    );
  }
  f = message.getCharge();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.cebcorp.v1.organization.Charge.serializeBinaryToWriter
    );
  }
  f = message.getSupportingdocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.cebcorp.v1.organization.SupportingDocument.serializeBinaryToWriter
    );
  }
};


/**
 * optional CompanyInformation CompanyInformation = 1;
 * @return {?proto.cebcorp.v1.organization.CompanyInformation}
 */
proto.cebcorp.v1.organization.Merchant.prototype.getCompanyinformation = function() {
  return /** @type{?proto.cebcorp.v1.organization.CompanyInformation} */ (
    jspb.Message.getWrapperField(this, proto.cebcorp.v1.organization.CompanyInformation, 1));
};


/**
 * @param {?proto.cebcorp.v1.organization.CompanyInformation|undefined} value
 * @return {!proto.cebcorp.v1.organization.Merchant} returns this
*/
proto.cebcorp.v1.organization.Merchant.prototype.setCompanyinformation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.Merchant} returns this
 */
proto.cebcorp.v1.organization.Merchant.prototype.clearCompanyinformation = function() {
  return this.setCompanyinformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.Merchant.prototype.hasCompanyinformation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated AuthorizedRepresentative AuthorizedRepresentatives = 2;
 * @return {!Array<!proto.cebcorp.v1.organization.AuthorizedRepresentative>}
 */
proto.cebcorp.v1.organization.Merchant.prototype.getAuthorizedrepresentativesList = function() {
  return /** @type{!Array<!proto.cebcorp.v1.organization.AuthorizedRepresentative>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cebcorp.v1.organization.AuthorizedRepresentative, 2));
};


/**
 * @param {!Array<!proto.cebcorp.v1.organization.AuthorizedRepresentative>} value
 * @return {!proto.cebcorp.v1.organization.Merchant} returns this
*/
proto.cebcorp.v1.organization.Merchant.prototype.setAuthorizedrepresentativesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cebcorp.v1.organization.AuthorizedRepresentative=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cebcorp.v1.organization.AuthorizedRepresentative}
 */
proto.cebcorp.v1.organization.Merchant.prototype.addAuthorizedrepresentatives = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cebcorp.v1.organization.AuthorizedRepresentative, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cebcorp.v1.organization.Merchant} returns this
 */
proto.cebcorp.v1.organization.Merchant.prototype.clearAuthorizedrepresentativesList = function() {
  return this.setAuthorizedrepresentativesList([]);
};


/**
 * repeated AuthorizedEmailRecipient AuthorizedEmailRecipients = 3;
 * @return {!Array<!proto.cebcorp.v1.organization.AuthorizedEmailRecipient>}
 */
proto.cebcorp.v1.organization.Merchant.prototype.getAuthorizedemailrecipientsList = function() {
  return /** @type{!Array<!proto.cebcorp.v1.organization.AuthorizedEmailRecipient>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cebcorp.v1.organization.AuthorizedEmailRecipient, 3));
};


/**
 * @param {!Array<!proto.cebcorp.v1.organization.AuthorizedEmailRecipient>} value
 * @return {!proto.cebcorp.v1.organization.Merchant} returns this
*/
proto.cebcorp.v1.organization.Merchant.prototype.setAuthorizedemailrecipientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.cebcorp.v1.organization.AuthorizedEmailRecipient=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cebcorp.v1.organization.AuthorizedEmailRecipient}
 */
proto.cebcorp.v1.organization.Merchant.prototype.addAuthorizedemailrecipients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.cebcorp.v1.organization.AuthorizedEmailRecipient, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cebcorp.v1.organization.Merchant} returns this
 */
proto.cebcorp.v1.organization.Merchant.prototype.clearAuthorizedemailrecipientsList = function() {
  return this.setAuthorizedemailrecipientsList([]);
};


/**
 * repeated DeliveryInformation DeliveryInformations = 4;
 * @return {!Array<!proto.cebcorp.v1.organization.DeliveryInformation>}
 */
proto.cebcorp.v1.organization.Merchant.prototype.getDeliveryinformationsList = function() {
  return /** @type{!Array<!proto.cebcorp.v1.organization.DeliveryInformation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cebcorp.v1.organization.DeliveryInformation, 4));
};


/**
 * @param {!Array<!proto.cebcorp.v1.organization.DeliveryInformation>} value
 * @return {!proto.cebcorp.v1.organization.Merchant} returns this
*/
proto.cebcorp.v1.organization.Merchant.prototype.setDeliveryinformationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cebcorp.v1.organization.DeliveryInformation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cebcorp.v1.organization.DeliveryInformation}
 */
proto.cebcorp.v1.organization.Merchant.prototype.addDeliveryinformations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cebcorp.v1.organization.DeliveryInformation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cebcorp.v1.organization.Merchant} returns this
 */
proto.cebcorp.v1.organization.Merchant.prototype.clearDeliveryinformationsList = function() {
  return this.setDeliveryinformationsList([]);
};


/**
 * optional Charge Charge = 5;
 * @return {?proto.cebcorp.v1.organization.Charge}
 */
proto.cebcorp.v1.organization.Merchant.prototype.getCharge = function() {
  return /** @type{?proto.cebcorp.v1.organization.Charge} */ (
    jspb.Message.getWrapperField(this, proto.cebcorp.v1.organization.Charge, 5));
};


/**
 * @param {?proto.cebcorp.v1.organization.Charge|undefined} value
 * @return {!proto.cebcorp.v1.organization.Merchant} returns this
*/
proto.cebcorp.v1.organization.Merchant.prototype.setCharge = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.Merchant} returns this
 */
proto.cebcorp.v1.organization.Merchant.prototype.clearCharge = function() {
  return this.setCharge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.Merchant.prototype.hasCharge = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated SupportingDocument SupportingDocuments = 6;
 * @return {!Array<!proto.cebcorp.v1.organization.SupportingDocument>}
 */
proto.cebcorp.v1.organization.Merchant.prototype.getSupportingdocumentsList = function() {
  return /** @type{!Array<!proto.cebcorp.v1.organization.SupportingDocument>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cebcorp.v1.organization.SupportingDocument, 6));
};


/**
 * @param {!Array<!proto.cebcorp.v1.organization.SupportingDocument>} value
 * @return {!proto.cebcorp.v1.organization.Merchant} returns this
*/
proto.cebcorp.v1.organization.Merchant.prototype.setSupportingdocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.cebcorp.v1.organization.SupportingDocument=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cebcorp.v1.organization.SupportingDocument}
 */
proto.cebcorp.v1.organization.Merchant.prototype.addSupportingdocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.cebcorp.v1.organization.SupportingDocument, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cebcorp.v1.organization.Merchant} returns this
 */
proto.cebcorp.v1.organization.Merchant.prototype.clearSupportingdocumentsList = function() {
  return this.setSupportingdocumentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.CompanyInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.CompanyInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.CompanyInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    officephonenumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountnumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subdivision: jspb.Message.getFieldWithDefault(msg, 4, ""),
    municipality: jspb.Message.getFieldWithDefault(msg, 5, ""),
    province: jspb.Message.getFieldWithDefault(msg, 6, ""),
    region: jspb.Message.getFieldWithDefault(msg, 7, ""),
    country: jspb.Message.getFieldWithDefault(msg, 8, ""),
    natureofbusiness: jspb.Message.getFieldWithDefault(msg, 9, ""),
    yearsinbusiness: jspb.Message.getFieldWithDefault(msg, 10, ""),
    monthlygrosssales: (f = msg.getMonthlygrosssales()) && proto.cebcorp.v1.organization.Amount.toObject(includeInstance, f),
    noofcustomers: jspb.Message.getFieldWithDefault(msg, 12, 0),
    companyregistrationnumber: jspb.Message.getFieldWithDefault(msg, 13, ""),
    taxidentificationnumber: jspb.Message.getFieldWithDefault(msg, 14, ""),
    ownershipstyle: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.CompanyInformation}
 */
proto.cebcorp.v1.organization.CompanyInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.CompanyInformation;
  return proto.cebcorp.v1.organization.CompanyInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.CompanyInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.CompanyInformation}
 */
proto.cebcorp.v1.organization.CompanyInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficephonenumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountnumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubdivision(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMunicipality(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvince(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNatureofbusiness(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setYearsinbusiness(value);
      break;
    case 11:
      var value = new proto.cebcorp.v1.organization.Amount;
      reader.readMessage(value,proto.cebcorp.v1.organization.Amount.deserializeBinaryFromReader);
      msg.setMonthlygrosssales(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNoofcustomers(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyregistrationnumber(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxidentificationnumber(value);
      break;
    case 15:
      var value = /** @type {!proto.cebcorp.v1.organization.OwnershipStyle} */ (reader.readEnum());
      msg.setOwnershipstyle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.CompanyInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.CompanyInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.CompanyInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOfficephonenumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountnumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubdivision();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMunicipality();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProvince();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNatureofbusiness();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getYearsinbusiness();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMonthlygrosssales();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.cebcorp.v1.organization.Amount.serializeBinaryToWriter
    );
  }
  f = message.getNoofcustomers();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getCompanyregistrationnumber();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTaxidentificationnumber();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getOwnershipstyle();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
};


/**
 * optional string BusinessName = 1;
 * @return {string}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.getBusinessname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.CompanyInformation} returns this
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.setBusinessname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string OfficePhoneNumber = 2;
 * @return {string}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.getOfficephonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.CompanyInformation} returns this
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.setOfficephonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string AccountNumber = 3;
 * @return {string}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.getAccountnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.CompanyInformation} returns this
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.setAccountnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Subdivision = 4;
 * @return {string}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.getSubdivision = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.CompanyInformation} returns this
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.setSubdivision = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Municipality = 5;
 * @return {string}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.getMunicipality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.CompanyInformation} returns this
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.setMunicipality = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Province = 6;
 * @return {string}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.getProvince = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.CompanyInformation} returns this
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.setProvince = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Region = 7;
 * @return {string}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.CompanyInformation} returns this
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string Country = 8;
 * @return {string}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.CompanyInformation} returns this
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string NatureOfBusiness = 9;
 * @return {string}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.getNatureofbusiness = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.CompanyInformation} returns this
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.setNatureofbusiness = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string YearsInBusiness = 10;
 * @return {string}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.getYearsinbusiness = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.CompanyInformation} returns this
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.setYearsinbusiness = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional Amount MonthlyGrossSales = 11;
 * @return {?proto.cebcorp.v1.organization.Amount}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.getMonthlygrosssales = function() {
  return /** @type{?proto.cebcorp.v1.organization.Amount} */ (
    jspb.Message.getWrapperField(this, proto.cebcorp.v1.organization.Amount, 11));
};


/**
 * @param {?proto.cebcorp.v1.organization.Amount|undefined} value
 * @return {!proto.cebcorp.v1.organization.CompanyInformation} returns this
*/
proto.cebcorp.v1.organization.CompanyInformation.prototype.setMonthlygrosssales = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.CompanyInformation} returns this
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.clearMonthlygrosssales = function() {
  return this.setMonthlygrosssales(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.hasMonthlygrosssales = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int64 NoOfCustomers = 12;
 * @return {number}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.getNoofcustomers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.cebcorp.v1.organization.CompanyInformation} returns this
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.setNoofcustomers = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string CompanyRegistrationNumber = 13;
 * @return {string}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.getCompanyregistrationnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.CompanyInformation} returns this
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.setCompanyregistrationnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string TaxIdentificationNumber = 14;
 * @return {string}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.getTaxidentificationnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.CompanyInformation} returns this
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.setTaxidentificationnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional OwnershipStyle OwnershipStyle = 15;
 * @return {!proto.cebcorp.v1.organization.OwnershipStyle}
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.getOwnershipstyle = function() {
  return /** @type {!proto.cebcorp.v1.organization.OwnershipStyle} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.cebcorp.v1.organization.OwnershipStyle} value
 * @return {!proto.cebcorp.v1.organization.CompanyInformation} returns this
 */
proto.cebcorp.v1.organization.CompanyInformation.prototype.setOwnershipstyle = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.AuthorizedRepresentative.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.AuthorizedRepresentative.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.AuthorizedRepresentative} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.AuthorizedRepresentative.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contactno: jspb.Message.getFieldWithDefault(msg, 2, ""),
    emailaddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    designation: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.AuthorizedRepresentative}
 */
proto.cebcorp.v1.organization.AuthorizedRepresentative.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.AuthorizedRepresentative;
  return proto.cebcorp.v1.organization.AuthorizedRepresentative.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.AuthorizedRepresentative} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.AuthorizedRepresentative}
 */
proto.cebcorp.v1.organization.AuthorizedRepresentative.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactno(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailaddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesignation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.AuthorizedRepresentative.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.AuthorizedRepresentative.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.AuthorizedRepresentative} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.AuthorizedRepresentative.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContactno();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmailaddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDesignation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.cebcorp.v1.organization.AuthorizedRepresentative.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.AuthorizedRepresentative} returns this
 */
proto.cebcorp.v1.organization.AuthorizedRepresentative.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ContactNo = 2;
 * @return {string}
 */
proto.cebcorp.v1.organization.AuthorizedRepresentative.prototype.getContactno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.AuthorizedRepresentative} returns this
 */
proto.cebcorp.v1.organization.AuthorizedRepresentative.prototype.setContactno = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string EmailAddress = 3;
 * @return {string}
 */
proto.cebcorp.v1.organization.AuthorizedRepresentative.prototype.getEmailaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.AuthorizedRepresentative} returns this
 */
proto.cebcorp.v1.organization.AuthorizedRepresentative.prototype.setEmailaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Designation = 4;
 * @return {string}
 */
proto.cebcorp.v1.organization.AuthorizedRepresentative.prototype.getDesignation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.AuthorizedRepresentative} returns this
 */
proto.cebcorp.v1.organization.AuthorizedRepresentative.prototype.setDesignation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.AuthorizedEmailRecipient.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.AuthorizedEmailRecipient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.AuthorizedEmailRecipient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.AuthorizedEmailRecipient.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contactnumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    emailaddress: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.AuthorizedEmailRecipient}
 */
proto.cebcorp.v1.organization.AuthorizedEmailRecipient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.AuthorizedEmailRecipient;
  return proto.cebcorp.v1.organization.AuthorizedEmailRecipient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.AuthorizedEmailRecipient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.AuthorizedEmailRecipient}
 */
proto.cebcorp.v1.organization.AuthorizedEmailRecipient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPosition(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactnumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailaddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.AuthorizedEmailRecipient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.AuthorizedEmailRecipient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.AuthorizedEmailRecipient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.AuthorizedEmailRecipient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContactnumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmailaddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.cebcorp.v1.organization.AuthorizedEmailRecipient.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.AuthorizedEmailRecipient} returns this
 */
proto.cebcorp.v1.organization.AuthorizedEmailRecipient.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Position = 2;
 * @return {string}
 */
proto.cebcorp.v1.organization.AuthorizedEmailRecipient.prototype.getPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.AuthorizedEmailRecipient} returns this
 */
proto.cebcorp.v1.organization.AuthorizedEmailRecipient.prototype.setPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ContactNumber = 3;
 * @return {string}
 */
proto.cebcorp.v1.organization.AuthorizedEmailRecipient.prototype.getContactnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.AuthorizedEmailRecipient} returns this
 */
proto.cebcorp.v1.organization.AuthorizedEmailRecipient.prototype.setContactnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string EmailAddress = 4;
 * @return {string}
 */
proto.cebcorp.v1.organization.AuthorizedEmailRecipient.prototype.getEmailaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.AuthorizedEmailRecipient} returns this
 */
proto.cebcorp.v1.organization.AuthorizedEmailRecipient.prototype.setEmailaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.DeliveryInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.DeliveryInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.DeliveryInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.DeliveryInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    branchoutlet: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contactnumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    specialinstruction: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.DeliveryInformation}
 */
proto.cebcorp.v1.organization.DeliveryInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.DeliveryInformation;
  return proto.cebcorp.v1.organization.DeliveryInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.DeliveryInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.DeliveryInformation}
 */
proto.cebcorp.v1.organization.DeliveryInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchoutlet(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactnumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialinstruction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.DeliveryInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.DeliveryInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.DeliveryInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.DeliveryInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranchoutlet();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContactnumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSpecialinstruction();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string BranchOutlet = 1;
 * @return {string}
 */
proto.cebcorp.v1.organization.DeliveryInformation.prototype.getBranchoutlet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.DeliveryInformation} returns this
 */
proto.cebcorp.v1.organization.DeliveryInformation.prototype.setBranchoutlet = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Address = 2;
 * @return {string}
 */
proto.cebcorp.v1.organization.DeliveryInformation.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.DeliveryInformation} returns this
 */
proto.cebcorp.v1.organization.DeliveryInformation.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ContactNumber = 3;
 * @return {string}
 */
proto.cebcorp.v1.organization.DeliveryInformation.prototype.getContactnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.DeliveryInformation} returns this
 */
proto.cebcorp.v1.organization.DeliveryInformation.prototype.setContactnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string SpecialInstruction = 4;
 * @return {string}
 */
proto.cebcorp.v1.organization.DeliveryInformation.prototype.getSpecialinstruction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.DeliveryInformation} returns this
 */
proto.cebcorp.v1.organization.DeliveryInformation.prototype.setSpecialinstruction = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.Charge.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.Charge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.Charge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.Charge.toObject = function(includeInstance, msg) {
  var f, obj = {
    poscashout: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountnumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fees: (f = msg.getFees()) && proto.cebcorp.v1.organization.Amount.toObject(includeInstance, f),
    averagedailybalance: (f = msg.getAveragedailybalance()) && proto.cebcorp.v1.organization.Amount.toObject(includeInstance, f),
    averagedailytransactioncount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    averagetransactionamount: (f = msg.getAveragetransactionamount()) && proto.cebcorp.v1.organization.Amount.toObject(includeInstance, f),
    monthlysales: (f = msg.getMonthlysales()) && proto.cebcorp.v1.organization.Amount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.Charge}
 */
proto.cebcorp.v1.organization.Charge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.Charge;
  return proto.cebcorp.v1.organization.Charge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.Charge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.Charge}
 */
proto.cebcorp.v1.organization.Charge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPoscashout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountnumber(value);
      break;
    case 3:
      var value = new proto.cebcorp.v1.organization.Amount;
      reader.readMessage(value,proto.cebcorp.v1.organization.Amount.deserializeBinaryFromReader);
      msg.setFees(value);
      break;
    case 4:
      var value = new proto.cebcorp.v1.organization.Amount;
      reader.readMessage(value,proto.cebcorp.v1.organization.Amount.deserializeBinaryFromReader);
      msg.setAveragedailybalance(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAveragedailytransactioncount(value);
      break;
    case 6:
      var value = new proto.cebcorp.v1.organization.Amount;
      reader.readMessage(value,proto.cebcorp.v1.organization.Amount.deserializeBinaryFromReader);
      msg.setAveragetransactionamount(value);
      break;
    case 7:
      var value = new proto.cebcorp.v1.organization.Amount;
      reader.readMessage(value,proto.cebcorp.v1.organization.Amount.deserializeBinaryFromReader);
      msg.setMonthlysales(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.Charge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.Charge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.Charge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.Charge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoscashout();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountnumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFees();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cebcorp.v1.organization.Amount.serializeBinaryToWriter
    );
  }
  f = message.getAveragedailybalance();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.cebcorp.v1.organization.Amount.serializeBinaryToWriter
    );
  }
  f = message.getAveragedailytransactioncount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getAveragetransactionamount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.cebcorp.v1.organization.Amount.serializeBinaryToWriter
    );
  }
  f = message.getMonthlysales();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.cebcorp.v1.organization.Amount.serializeBinaryToWriter
    );
  }
};


/**
 * optional string POSCashout = 1;
 * @return {string}
 */
proto.cebcorp.v1.organization.Charge.prototype.getPoscashout = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.Charge} returns this
 */
proto.cebcorp.v1.organization.Charge.prototype.setPoscashout = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string AccountNumber = 2;
 * @return {string}
 */
proto.cebcorp.v1.organization.Charge.prototype.getAccountnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.Charge} returns this
 */
proto.cebcorp.v1.organization.Charge.prototype.setAccountnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Amount Fees = 3;
 * @return {?proto.cebcorp.v1.organization.Amount}
 */
proto.cebcorp.v1.organization.Charge.prototype.getFees = function() {
  return /** @type{?proto.cebcorp.v1.organization.Amount} */ (
    jspb.Message.getWrapperField(this, proto.cebcorp.v1.organization.Amount, 3));
};


/**
 * @param {?proto.cebcorp.v1.organization.Amount|undefined} value
 * @return {!proto.cebcorp.v1.organization.Charge} returns this
*/
proto.cebcorp.v1.organization.Charge.prototype.setFees = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.Charge} returns this
 */
proto.cebcorp.v1.organization.Charge.prototype.clearFees = function() {
  return this.setFees(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.Charge.prototype.hasFees = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Amount AverageDailyBalance = 4;
 * @return {?proto.cebcorp.v1.organization.Amount}
 */
proto.cebcorp.v1.organization.Charge.prototype.getAveragedailybalance = function() {
  return /** @type{?proto.cebcorp.v1.organization.Amount} */ (
    jspb.Message.getWrapperField(this, proto.cebcorp.v1.organization.Amount, 4));
};


/**
 * @param {?proto.cebcorp.v1.organization.Amount|undefined} value
 * @return {!proto.cebcorp.v1.organization.Charge} returns this
*/
proto.cebcorp.v1.organization.Charge.prototype.setAveragedailybalance = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.Charge} returns this
 */
proto.cebcorp.v1.organization.Charge.prototype.clearAveragedailybalance = function() {
  return this.setAveragedailybalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.Charge.prototype.hasAveragedailybalance = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 AverageDailyTransactionCount = 5;
 * @return {number}
 */
proto.cebcorp.v1.organization.Charge.prototype.getAveragedailytransactioncount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.cebcorp.v1.organization.Charge} returns this
 */
proto.cebcorp.v1.organization.Charge.prototype.setAveragedailytransactioncount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional Amount AverageTransactionAmount = 6;
 * @return {?proto.cebcorp.v1.organization.Amount}
 */
proto.cebcorp.v1.organization.Charge.prototype.getAveragetransactionamount = function() {
  return /** @type{?proto.cebcorp.v1.organization.Amount} */ (
    jspb.Message.getWrapperField(this, proto.cebcorp.v1.organization.Amount, 6));
};


/**
 * @param {?proto.cebcorp.v1.organization.Amount|undefined} value
 * @return {!proto.cebcorp.v1.organization.Charge} returns this
*/
proto.cebcorp.v1.organization.Charge.prototype.setAveragetransactionamount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.Charge} returns this
 */
proto.cebcorp.v1.organization.Charge.prototype.clearAveragetransactionamount = function() {
  return this.setAveragetransactionamount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.Charge.prototype.hasAveragetransactionamount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Amount MonthlySales = 7;
 * @return {?proto.cebcorp.v1.organization.Amount}
 */
proto.cebcorp.v1.organization.Charge.prototype.getMonthlysales = function() {
  return /** @type{?proto.cebcorp.v1.organization.Amount} */ (
    jspb.Message.getWrapperField(this, proto.cebcorp.v1.organization.Amount, 7));
};


/**
 * @param {?proto.cebcorp.v1.organization.Amount|undefined} value
 * @return {!proto.cebcorp.v1.organization.Charge} returns this
*/
proto.cebcorp.v1.organization.Charge.prototype.setMonthlysales = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cebcorp.v1.organization.Charge} returns this
 */
proto.cebcorp.v1.organization.Charge.prototype.clearMonthlysales = function() {
  return this.setMonthlysales(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cebcorp.v1.organization.Charge.prototype.hasMonthlysales = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cebcorp.v1.organization.SupportingDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.cebcorp.v1.organization.SupportingDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cebcorp.v1.organization.SupportingDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.SupportingDocument.toObject = function(includeInstance, msg) {
  var f, obj = {
    certificate: msg.getCertificate_asB64(),
    fileurl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cebcorp.v1.organization.SupportingDocument}
 */
proto.cebcorp.v1.organization.SupportingDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cebcorp.v1.organization.SupportingDocument;
  return proto.cebcorp.v1.organization.SupportingDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cebcorp.v1.organization.SupportingDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cebcorp.v1.organization.SupportingDocument}
 */
proto.cebcorp.v1.organization.SupportingDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCertificate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.SupportingDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cebcorp.v1.organization.SupportingDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cebcorp.v1.organization.SupportingDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cebcorp.v1.organization.SupportingDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCertificate_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getFileurl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bytes Certificate = 1;
 * @return {!(string|Uint8Array)}
 */
proto.cebcorp.v1.organization.SupportingDocument.prototype.getCertificate = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes Certificate = 1;
 * This is a type-conversion wrapper around `getCertificate()`
 * @return {string}
 */
proto.cebcorp.v1.organization.SupportingDocument.prototype.getCertificate_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCertificate()));
};


/**
 * optional bytes Certificate = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCertificate()`
 * @return {!Uint8Array}
 */
proto.cebcorp.v1.organization.SupportingDocument.prototype.getCertificate_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCertificate()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.cebcorp.v1.organization.SupportingDocument} returns this
 */
proto.cebcorp.v1.organization.SupportingDocument.prototype.setCertificate = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string FileUrl = 2;
 * @return {string}
 */
proto.cebcorp.v1.organization.SupportingDocument.prototype.getFileurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cebcorp.v1.organization.SupportingDocument} returns this
 */
proto.cebcorp.v1.organization.SupportingDocument.prototype.setFileurl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.cebcorp.v1.organization.OrgType = {
  UNKNOWNORGTYPE: 0,
  CEBCORP: 1,
  CORPORATE: 2
};

/**
 * @enum {number}
 */
proto.cebcorp.v1.organization.OwnershipStyle = {
  UNKNOWNOWNERSHIPSTYLE: 0,
  SOLEPROPRIETORSHIP: 1,
  PARTNERSHIP: 2,
  COPERATION: 3,
  COOPERATIVE: 4,
  ASSOCIATIONS: 5
};

goog.object.extend(exports, proto.cebcorp.v1.organization);
