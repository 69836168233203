/**
 * TODO: create proper enum and interfaces for destinationBankCode, currency and settlementRail
 */
export interface Txn {
  destination: {
    accountNumber: string;
    accountName: string;
    firstName: string;
    lastName: string;
    address?: string;
    bankCode: string;
  };
  currency: string;
  amount: string;
}

export interface Source {
  accountNumber: string;
  firstName: string;
  lastName: string;
}

export interface Batch {
  source: Source;
  settlementRail: string;
  remarks: string;
  transactions: Array<Txn>;
  txnSource: TxnSource;
}

export type TxnSource = keyof typeof TxnSourceMap;
export enum TxnSourceMap {
  batch = "batch",
  single = "single",
}

export enum TransactionType {
  PayrollExecutive = "Payroll - Executive",
  PayrollEmployee = "Payroll - Employee",
  Collections = "Collections",
  LoanDisbursement = "Loan Disbursement",
  Others = "Other Payout",
}

export enum Currencies {
  PHP = "PHP",
}

export enum PaymentMode {
  EFT = "EFT",
  Pesonet = "PESONET",
  Instapay = "RTA",
}
