import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { ProtobufMessage } from "@improbable-eng/grpc-web/dist/typings/message";

import { ProfileService as Service } from "@/proto/profile/all_pb_service";
import * as ServiceProto from "@/proto/profile/all_pb";

import { UserService as UService } from "@/proto/user/all_pb_service";
import * as UServiceProto from "@/proto/user/all_pb";

import AuthUtils from "@/utils/auth-utils";
import { UserInfo, UserRole, InvitedUser } from "@/types";

export default class UserService {
  constructor(private baseURL: string, private token: string) {}

  private genericErrorMessage =
    "Connection to the network failed. Please contact our support team support@cebuanalhullier.com.";

  public async createUser(payload: InvitedUser) {
    const request = new ServiceProto.CreateProfileRequest();
    request.setFirstname(payload.firstName);
    request.setLastname(payload.lastName);
    request.setEmail(payload.email);
    request.setRoletype(payload.role);
    request.setPhone(payload.phone);

    if (payload.cifNumber) {
      request.setCifnumber(payload.cifNumber as string);
      request.setCompanyname(payload.companyName as string);
    }

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.CreateProfile, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      console.error(
        `Error when inviting user: unexpected status code=${status} msg=${statusMessage}`
      );

      switch (status) {
        case grpc.Code.InvalidArgument:
          throw new Error(statusMessage);
        case grpc.Code.Internal:
          throw new Error("E-mail address is already invited.");
        default:
          throw new Error(this.genericErrorMessage);
      }
    }

    const decoded = message as ServiceProto.CreateProfileResponse;
    const response = decoded.toObject();
    return response;
  }

  private constuctUserInfo(profile: ServiceProto.Profile) {
    const personalInfo = profile?.getPersonalinfo();
    const profileInfo = profile?.getProfileinfo();

    const userInfo: UserInfo = {
      id: profile?.getId(),
      orgId: profile?.getOrgid() as string,
      firstName: personalInfo?.getFirstname() as string,
      lastName: personalInfo?.getLastname() as string,
      role: profile?.getRoletype() as UserRole,
      email: personalInfo?.getEmail() as string,
      phone: personalInfo?.getPhone() as string,
      maritalStatus: profileInfo?.getMaritalstatus(),
      education: profileInfo?.getEducation(),
      industry: profileInfo?.getIndustry(),
      position: profileInfo?.getPosition(),
      employerName: profileInfo?.getEmployer(),
      officeNumber: profileInfo?.getOfficenumber(),
      homeNumber: profileInfo?.getHomenumber(),
      address: {
        line1: profileInfo?.getAddress()?.getLine1(),
        line2: profileInfo?.getAddress()?.getLine2(),
        province: profileInfo?.getAddress()?.getProvince(),
        city: profileInfo?.getAddress()?.getCity(),
        district: profileInfo?.getAddress()?.getDistrict(),
        zipCode: profileInfo?.getAddress()?.getZipcode(),
      },
    };

    return userInfo;
  }

  public async getUserInfo() {
    const request = new ServiceProto.GetProfileRequest();

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.GetProfile, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      throw new Error(
        `unexpected status code: code=${status} msg=${statusMessage}`
      );
    } else if (message === null) {
      throw new Error("received no data");
    } else {
      const decoded = message as ServiceProto.GetProfileResponse;
      return this.constuctUserInfo(
        decoded.getProfile() as ServiceProto.Profile
      );
    }
  }

  public async updateUserInfo(userInfo: UserInfo) {
    const profile = new ServiceProto.Profile();
    const personalInfo = new ServiceProto.PersonalInfo();
    const profileInfo = new ServiceProto.ProfileInfo();
    const address = new ServiceProto.Address();

    personalInfo.setFirstname(userInfo.firstName as string);
    personalInfo.setLastname(userInfo.lastName as string);
    personalInfo.setEmail(userInfo.email as string);
    personalInfo.setPhone(userInfo.phone as string);

    address.setLine1(userInfo.address?.line1 as string);
    address.setLine2(userInfo.address?.line2 as string);
    address.setProvince(userInfo.address?.province as string);
    address.setCity(userInfo.address?.city as string);
    address.setDistrict(userInfo.address?.district as string);
    address.setZipcode(userInfo.address?.zipCode as string);

    profileInfo.setMaritalstatus(userInfo.maritalStatus as string);
    profileInfo.setEducation(userInfo.education as string);
    profileInfo.setIndustry(userInfo.industry as string);
    profileInfo.setPosition(userInfo.position as string);
    profileInfo.setEmployer(userInfo.employerName as string);
    profileInfo.setOfficenumber(userInfo.officeNumber as string);
    profileInfo.setHomenumber(userInfo.homeNumber as string);
    profileInfo.setAddress(address);

    profile.setId(userInfo.id as string);
    profile.setPersonalinfo(personalInfo);
    profile.setProfileinfo(profileInfo);

    return await this.updateUser(profile);
  }

  public async approveUser(invitationID: string, mustReject: boolean) {
    const request = new ServiceProto.ApproveRequest();
    request.setInvitationid(invitationID);
    request.setRejected(mustReject);

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.Approve, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      console.error(
        `Error when inviting user: unexpected status code=${status} msg=${statusMessage}`
      );

      switch (status) {
        case grpc.Code.InvalidArgument:
          throw new Error(statusMessage);
        case grpc.Code.Internal:
        default:
          throw new Error(this.genericErrorMessage);
      }
    }

    const decoded = message as ServiceProto.ApproveResponse;
    const response = decoded.toObject();
    return response;
  }

  public async editUserInfo(payload: any) {
    const profile = new ServiceProto.Profile();
    const personalInfo = new ServiceProto.PersonalInfo();

    personalInfo.setFirstname(payload.firstName as string);
    personalInfo.setLastname(payload.lastName as string);
    personalInfo.setEmail(payload.email as string);

    profile.setId(payload.id);
    profile.setPersonalinfo(personalInfo);

    if (payload.role) {
      profile.setRoletype(payload.role);
    }

    await this.updateUser(profile);
  }

  private async updateUser(payload: ServiceProto.Profile) {
    const request = new ServiceProto.UpdateProfileRequest();
    request.setProfile(payload);

    const {
      status,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.UpdateProfile, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      console.error(
        `Error when updating user profile: unexpected status code=${status} msg=${statusMessage}`
      );

      if (status === grpc.Code.Internal) {
        throw new Error(this.genericErrorMessage);
      } else {
        throw new Error(statusMessage);
      }
    } else {
      return this.constuctUserInfo(payload);
    }
  }

  public async blockUser(id: string) {
    const request = new ServiceProto.BlockProfileRequest();
    request.setId(id);

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.BlockProfile, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      console.error(
        `Error when inviting user: unexpected status code=${status} msg=${statusMessage}`
      );

      switch (status) {
        case grpc.Code.InvalidArgument:
          throw new Error(statusMessage);
        case grpc.Code.Internal:
        default:
          throw new Error(this.genericErrorMessage);
      }
    }

    const decoded = message as ServiceProto.BlockProfileResponse;
    const response = decoded.toObject();
    return response;
  }

  public async deleteUser(id: string) {
    const request = new ServiceProto.DeleteProfileRequest();
    request.setId(id);

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.DeleteProfile, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      console.error(
        `Error when inviting user: unexpected status code=${status} msg=${statusMessage}`
      );

      switch (status) {
        case grpc.Code.InvalidArgument:
          throw new Error(statusMessage);
        case grpc.Code.Internal:
        default:
          throw new Error(this.genericErrorMessage);
      }
    }

    const decoded = message as ServiceProto.DeleteProfileResponse;
    const response = decoded.toObject();
    return response;
  }

  public async changePassword(
    payload: UServiceProto.ChangePasswordRequest.AsObject
  ) {
    const request = new UServiceProto.ChangePasswordRequest();
    request.setNewpassword(payload.newpassword);
    request.setOldpassword(payload.oldpassword);
    request.setUserid(payload.userid);
    request.setEventid(payload.eventid);

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(UService.ChangePassword, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      console.error(
        `Error when changing user password: unexpected status code=${status} msg=${statusMessage}`
      );

      if (status === grpc.Code.Internal) {
        throw new Error(this.genericErrorMessage);
      } else {
        throw new Error(statusMessage);
      }
    }

    const decoded = message as UServiceProto.ChangePasswordResponse;
    const response = decoded.toObject();
    return response;
  }

  public async confirmChangePassword(
    payload: UServiceProto.ConfirmUpdateRequest.AsObject
  ) {
    const request = new UServiceProto.ConfirmUpdateRequest();
    request.setUserid(payload.userid);
    request.setMfaeventid(payload.mfaeventid);
    request.setMfatype(payload.mfatype);
    request.setMfatoken(payload.mfatoken);

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(UService.ConfirmUpdate, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      console.error(
        `Error when confirming change password: unexpected status code=${status} msg=${statusMessage}`
      );

      if (status === grpc.Code.Internal) {
        throw new Error(this.genericErrorMessage);
      } else {
        throw new Error(statusMessage);
      }
    }

    const decoded = message as UServiceProto.ConfirmUpdateResponse;
    const response = decoded.toObject();
    return response;
  }
}
