
















export default {
  name: "IconBulb",
};
