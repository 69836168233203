







































































import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";

import { FilterDropdown, FilterValues, FilterItem } from "../filter";
import { Dropdown } from "../../components";
import CustomInput from "../../components/custom-input/CustomInput.vue";

interface State {
  search: string;
  typingTimer: any;
}

@Component({
  components: {
    Dropdown,
    FilterDropdown,
    CustomInput,
  },
})
export default class TableHeader extends Vue {
  @Prop()
  private readonly title!: string;

  @Prop()
  private readonly headerActions!: boolean;

  @Prop({ default: false })
  private readonly enableSort!: boolean;

  @Prop()
  private readonly sortType!: "ASC" | "DESC";

  @Prop({ default: "" })
  private readonly sortValue!: string;

  @Prop({ default: () => [] })
  private readonly sortingOptions!: Array<any>;

  @Prop()
  private readonly searchPlaceholder!: string;

  @Prop()
  private readonly filterButtonText!: string;

  @Prop()
  private readonly filterOptions!: Array<FilterItem>;

  @Prop()
  private readonly filterValues!: FilterValues;

  @Prop({ default: false })
  private readonly showDownloadBtn!: boolean;

  @Prop({ default: () => [] })
  private readonly downloadOptions!: Array<{ label: string; value: any }>;

  private state: State = {
    search: "",
    typingTimer: null,
  };

  get _hasCustomHeaderActions() {
    return !!this.$slots["header-actions"];
  }

  get hasDownloadListener() {
    return this.$listeners && this.$listeners.download;
  }

  get hasActiveFilter() {
    if (!this.filterValues) {
      return false;
    }

    return Object.entries(this.filterValues).reduce(
      (acc: boolean, curr: any) => {
        if (acc) {
          return acc;
        }

        // Get the first index for the object value
        const obj = curr[1];

        // Check for date range
        if (typeof obj === "object" && obj !== null) {
          acc = !!obj.start || !!obj.end;
        } else {
          acc = !!obj;
        }

        return acc;
      },
      false
    );
  }

  onFilterInput(data: any) {
    this.$emit("filter-input", data);
  }

  onDropdownSelect(value: string) {
    this.$emit("download", value);
  }

  get mainLabel() {
    return this.sortValue ? `Sort By (${this.sortType})` : "Sort By";
  }

  onSortSelect(value: string) {
    this.$emit("sort-select", value);
  }

  onClickFilter() {
    if (this.hasActiveFilter) {
      this.$emit("clear-filter");
      return;
    }
  }

  _filterBySearch() {
    const query = this.state.search.split(":");
    let field = null;
    let value = query[0];

    if (query.length === 2) {
      field = query[0];
      value = query[1];
    }

    this.$emit("on-search", {
      value,
      field,
    });
  }

  onSearchStart() {
    clearTimeout(this.state.typingTimer);
    this.state.typingTimer = setTimeout(this._filterBySearch, 200);
  }

  onSearchStop() {
    clearTimeout(this.state.typingTimer);
  }

  // @Watch("state.search", { immediate: true })
  // watchSearch() {
  //   this._filterBySearch();
  // }
}
