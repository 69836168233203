
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import { mapActions, mapMutations, mapState } from "vuex";

@Component({
  computed: {
    ...mapState("auth", ["redirectUrl"]),
  },
  methods: {
    ...mapActions("auth", ["exchangeCode"]),
    ...mapMutations("auth", ["setRedirectUrl"]),
  },
})
export default class OidcCallback extends Vue {
  private readonly redirectUrl!: string;
  private readonly exchangeCode!: () => void;
  private readonly setRedirectUrl!: (url: string) => void;

  async mounted() {
    try {
      await this.exchangeCode();
      const url = this.redirectUrl;

      if (!url) {
        this.$router.push({ path: "/" });
        return;
      }

      this.setRedirectUrl("");
      this.$router.push({ path: url });
    } catch (err) {
      this.$router.push({ name: "sign-in" });
    }
  }
}
