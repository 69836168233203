




































import Vue from "vue";

interface TabItem {
  label: string;
  to: string;
  isActive: boolean;
}

interface Tab {
  name: string;
  label?: string;
  list: Array<TabItem>;
}

export default Vue.extend({
  name: "TabLayout",

  props: {
    activeTab: {
      type: String,
    },
    tabName: {
      type: String,
    },
    showTitle: {
      default: true,
      type: Boolean,
    },
  },

  computed: {
    selectedTab(): Tab {
      const tabList = [
        {
          name: "Account",
          list: [
            {
              label: "My Profile",
              to: "/account/profile",
              isActive: false,
            },
            {
              label: "Organization",
              to: "/account/organization",
              isActive: false,
            },
            {
              label: "Notifications",
              to: "/account/notifications",
              isActive: false,
            },
            {
              label: "Security",
              to: "/account/security",
              isActive: false,
            },
          ],
        },
        {
          name: "Payees",
          label: "Favorites - Payees",
          list: [
            {
              label: "CLRB ACCOUNTS",
              to: "/favorites/payees/clrb",
              isActive: false,
            },
            {
              label: "OTHER BANKS",
              to: "/favorites/payees/other-banks",
              isActive: false,
            },
          ],
        },
      ];

      return tabList.find((tab) => tab.name === this.tabName) as Tab;
    },

    getTabs(): Array<any> {
      const selectedTab: Tab = this.selectedTab;

      return selectedTab.list.map((tab: any) => {
        if (tab.label === this.activeTab) {
          tab.isActive = true;
        }

        return tab;
      });
    },

    selectedTabName() {
      const selectedTab: Tab = this.selectedTab;

      return selectedTab.label ? selectedTab.label : selectedTab.name;
    },
  },
});
