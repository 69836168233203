







export default {
  name: "Search",
};
