
import { pick } from "lodash";
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import { namespace } from "vuex-class";

const authModule = namespace("auth");

@Component({})
export default class Login extends Vue {
  @authModule.Action("login") login!: () => void;
  @authModule.Mutation("setRedirectUrl") setRedirectUrl!: (url: string) => void;
  @authModule.Mutation("setLoginParams") setLoginParams!: (params: any) => void;

  mounted() {
    const url = (this.$route.query.redirect as string) || "";
    const loginParams = pick(this.$route.query, [
      "logout_success",
      "confirmation_success",
    ]);

    this.setRedirectUrl(url);
    this.setLoginParams(loginParams);

    this.login();
  }
}
