





































































































export default {
  name: "CIBLogo",
};
