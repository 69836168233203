import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "@/styles/_tailwind.css";
import APP_CONFIG from "@/config";

Vue.config.productionTip = false;

const init = async () => {
  // This is a hack for handeling dynamic configuration
  // in the frontend. APP_CONFIG is populated at build
  // time (it is a webpack module). We should avoid modifiying
  // it as we are doing here. This will be removed when we find
  // a better way to do it.
  try {
    /**
     * - Get base origin as baseURL to fetch config
     * - This is for resolve the issue with nested routes
     */

    const baseUrl = window.location.origin;
    const response = await fetch(`${baseUrl}/config`);
    const dynamicConfig = await response.json();
    APP_CONFIG.authUrl = dynamicConfig.authUrl;
    APP_CONFIG.scope = dynamicConfig.scope;

    // Set config
    store.commit("config/setConfig", dynamicConfig);
  } catch (error) {
    // Log error
    console.log("Error: ", error);
  }

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
};

init();
