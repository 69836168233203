






























export default {
  name: "PDFFileIcon",
};
