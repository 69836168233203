




import { Vue, Component, Prop } from "vue-property-decorator";
import Toasted from "vue-toasted";
import "vue-toasted/dist/vue-toasted.min.css";

Vue.use(Toasted);

@Component
export default class NotificationModal extends Vue {
  @Prop({ required: true }) textDisplay!: string;
  @Prop({ default: "success" }) notifType!: string;
  @Prop({ default: 3000 }) customDuration!: number;

  mounted() {
    this.$toasted.show(this.textDisplay, {
      className: [`toasted-primary-${this.notifType}`, "toasted-custom"],
      position: "top-center",
      fullWidth: true,
      duration: this.customDuration,
    });

    // to set showNotificationModal back to false
    this.$emit("close-notification-modal");
  }
}
