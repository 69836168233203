




































import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class TabLayoutV2 extends Vue {
  tabs: any[] = [];

  created() {
    this.tabs = this.$children;
  }

  _selectTab(selectedTab: any) {
    this.tabs.forEach((tab) => {
      tab.isActive = tab.name == selectedTab.name;
    });
  }
}
