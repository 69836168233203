












export default {
  name: "IconSpinner",
};
