import { ActionContext } from "vuex";
import { RootState } from "@/store";
import { ConfigState } from "@/store/modules/config";
import { Metadata } from "@/services";
import { Country } from "@/proto/metadata/all_pb";
import { Fee } from "@/proto/fee/all_pb";

export async function getMetadata({
  commit,
  rootState,
}: ActionContext<ConfigState, RootState>) {
  const { auth, config } = rootState;

  try {
    const result = await new Metadata(
      config.apiURL,
      auth.user.accessToken
    ).getMetadata();

    const PH_COUNTRY_CODE = result.location?.countriesMap.find((country) => {
      return country[1].name.toLowerCase() === "philippines";
    }) as [number, Country.AsObject];

    let cities = result.location?.citiesMap as Array<any>;
    cities = cities?.map((city) => {
      return {
        provinceId: city[0],
        list: city[1].citiesList,
      };
    });

    let provinces = result.location?.provincesMap as Array<any>;
    provinces = provinces?.find(
      (province) => province[0] === PH_COUNTRY_CODE[0]
    );
    provinces = [...provinces[1].provincesList];

    commit("setCities", cities);
    commit("setProvinces", provinces);
  } catch (err) {
    const error = err as Error;
    console.error("Error when getting locations: ", error.message);
  }
}

export async function getFeeList({
  rootState,
}: ActionContext<ConfigState, RootState>) {
  const { auth, config } = rootState;

  try {
    const result = await new Metadata(
      config.apiURL,
      auth.user.accessToken
    ).getFeeList();

    return result;
  } catch (err) {
    const error = err as Error;
    console.error("Error when getting fee list: ", error.message);
  }
}

export async function updateFee(
  { rootState }: ActionContext<ConfigState, RootState>,
  fee: Fee.AsObject
) {
  const { auth, config } = rootState;

  try {
    await new Metadata(config.apiURL, auth.user.accessToken).updateFee(fee);
  } catch (err) {
    const error = err as Error;
    console.error("Error when getting fee list: ", error.message);
  }
}
