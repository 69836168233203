

























































import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import {
  FilterInputOption,
  FilterType,
  FilterData,
  FilterInputValue,
  DateRange,
} from "../../types";

import SvgIcon from "../../../../components/svg-icon/SvgIcon.vue";
import FilterInput from "../filter-input/FilterInput.vue";

interface State {
  expanded: boolean;
}

@Component({
  components: {
    FilterInput,
    SvgIcon,
  },
})
export default class FilterOptionsItem extends Vue {
  @Prop()
  private readonly name!: string;

  @Prop()
  private readonly label!: string;

  @Prop()
  private readonly icon!: string;

  @Prop()
  private readonly type!: FilterType;

  @Prop({ default: null })
  private readonly value!: FilterInputValue;

  @Prop({ default: () => [] })
  private readonly options!: Array<FilterInputOption>;

  @Prop({ default: null })
  private readonly min?: number | null;

  @Prop({ default: null })
  private readonly max?: number | null;

  private state: State = {
    expanded: false,
  };

  onClear() {
    this.$emit("clear-item", this.name);
  }

  onInput(value: FilterInputValue) {
    const data: FilterData = {
      name: this.name,
      type: this.type,
      value: value,
    };

    this.$emit("input", data);
  }

  onToggle() {
    this.state.expanded = !this.state.expanded;
  }

  get active() {
    if (this.type === "date-range" && this.value) {
      return (
        (this.value as DateRange).start !== null ||
        (this.value as DateRange).end !== null
      );
    }

    return !!this.value;
  }
}
