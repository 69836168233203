// package: accounts
// file: github.com/openbank/openbank/v1/account/all.proto

var github_com_openbank_openbank_v1_account_all_pb = require("./all_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var AccountService = (function () {
  function AccountService() {}
  AccountService.serviceName = "accounts.AccountService";
  return AccountService;
}());

AccountService.GetAccount = {
  methodName: "GetAccount",
  service: AccountService,
  requestStream: false,
  responseStream: false,
  requestType: github_com_openbank_openbank_v1_account_all_pb.GetAccountRequest,
  responseType: github_com_openbank_openbank_v1_account_all_pb.Account
};

AccountService.GetAccounts = {
  methodName: "GetAccounts",
  service: AccountService,
  requestStream: false,
  responseStream: false,
  requestType: github_com_openbank_openbank_v1_account_all_pb.GetAccountsRequest,
  responseType: github_com_openbank_openbank_v1_account_all_pb.GetAccountsResponse
};

AccountService.CreateAccount = {
  methodName: "CreateAccount",
  service: AccountService,
  requestStream: false,
  responseStream: false,
  requestType: github_com_openbank_openbank_v1_account_all_pb.CreateAccountRequest,
  responseType: github_com_openbank_openbank_v1_account_all_pb.CreateAccountResponse
};

AccountService.UpdateAccount = {
  methodName: "UpdateAccount",
  service: AccountService,
  requestStream: false,
  responseStream: false,
  requestType: github_com_openbank_openbank_v1_account_all_pb.UpdateAccountRequest,
  responseType: google_protobuf_empty_pb.Empty
};

AccountService.DeleteAccount = {
  methodName: "DeleteAccount",
  service: AccountService,
  requestStream: false,
  responseStream: false,
  requestType: github_com_openbank_openbank_v1_account_all_pb.DeleteAccountRequest,
  responseType: google_protobuf_empty_pb.Empty
};

AccountService.GetAccountStatus = {
  methodName: "GetAccountStatus",
  service: AccountService,
  requestStream: false,
  responseStream: false,
  requestType: github_com_openbank_openbank_v1_account_all_pb.GetAccountStatusRequest,
  responseType: github_com_openbank_openbank_v1_account_all_pb.GetAccountStatusResponse
};

AccountService.UpdateAccountStatus = {
  methodName: "UpdateAccountStatus",
  service: AccountService,
  requestStream: false,
  responseStream: false,
  requestType: github_com_openbank_openbank_v1_account_all_pb.UpdateAccountStatusRequest,
  responseType: google_protobuf_empty_pb.Empty
};

AccountService.CheckAccount = {
  methodName: "CheckAccount",
  service: AccountService,
  requestStream: false,
  responseStream: false,
  requestType: github_com_openbank_openbank_v1_account_all_pb.CheckAccountRequest,
  responseType: github_com_openbank_openbank_v1_account_all_pb.CheckAccountResponse
};

exports.AccountService = AccountService;

function AccountServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

AccountServiceClient.prototype.getAccount = function getAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AccountService.GetAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AccountServiceClient.prototype.getAccounts = function getAccounts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AccountService.GetAccounts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AccountServiceClient.prototype.createAccount = function createAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AccountService.CreateAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AccountServiceClient.prototype.updateAccount = function updateAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AccountService.UpdateAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AccountServiceClient.prototype.deleteAccount = function deleteAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AccountService.DeleteAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AccountServiceClient.prototype.getAccountStatus = function getAccountStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AccountService.GetAccountStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AccountServiceClient.prototype.updateAccountStatus = function updateAccountStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AccountService.UpdateAccountStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AccountServiceClient.prototype.checkAccount = function checkAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AccountService.CheckAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.AccountServiceClient = AccountServiceClient;

