

















































































import { Prop, Vue, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import Datepicker from "vuejs-datepicker";
import {
  FilterRules,
  FilterInputValue,
  FilterInputOption,
  FilterType,
  FilterData,
  DateRange,
} from "../../types";

import SvgIcon from "../../../../components/svg-icon/SvgIcon.vue";
import Dropdown from "../../../dropdown/Dropdown.vue";

interface State {
  rules: FilterRules;
}

@Component({
  components: {
    Datepicker,
    Dropdown,
    SvgIcon,
  },
})
export default class FilterBarInput extends Vue {
  @Prop()
  private readonly name!: string;

  @Prop()
  private readonly label!: string;

  @Prop()
  private readonly type!: FilterType;

  @Prop({ default: () => [] })
  private readonly options!: Array<FilterInputOption>;

  @Prop({ default: null })
  private readonly value!: FilterInputValue;

  @Prop({ default: null })
  private readonly min?: number | null;

  @Prop({ default: null })
  private readonly max?: number | null;

  @Prop({ default: "vertical" })
  private readonly dateRangePosition!: "vertical" | "horizontal";

  private state: State = {
    rules: {
      dateRange: {
        disabled: {
          start: {
            from: null,
            to: null,
          },
          end: {
            from: null,
            to: null,
          },
        },
      },
    },
  };

  onChange(value: any) {
    const val =
      this.type === "checkbox" ? this._getCheckedValues(value) : value;

    this._emitValue(val);
  }

  onChangeDateRange(type: "start" | "end", value: Date) {
    const { start, end } = this.filterValue as DateRange;

    const range = {
      start,
      end,
    };

    range[type] = value;

    this._emitValue(range);
  }

  _getCheckedValues(value: any) {
    if (!Array.isArray(this.value)) {
      return [value];
    }

    if (this.value.includes(value)) {
      return this.value.filter((x) => x !== value);
    } else {
      return this.value.concat(value);
    }
  }

  _getInitialValue(type: FilterType): FilterInputValue {
    switch (type) {
      case "text":
      case "radio":
        return "";
      case "checkbox":
        return [];
      case "number":
        return null;
      case "date":
        return null;
      case "date-range":
        return { start: null, end: null };
      default:
        return null;
    }
  }

  _emitValue(value: any) {
    const data: FilterData = {
      name: this.name,
      type: this.type,
      value: value,
    };

    this.$emit("input", data);
  }

  /**
   * Used to determine if checkbox is checked
   * based on the provided array of values
   */
  _isCheckboxChecked(value: string) {
    if (!this.value || !Array.isArray(this.value)) {
      return false;
    }

    return (this.value as Array<string>).includes(value);
  }

  /**
   * Used to determine if radio button is selected.
   * This also handles issue with numerical values since
   * radio button converts values to string when selected
   */
  _isRadioSelected(value: any) {
    /**
     * Interpolate to handle potential
     * null, undefined, and numerical values
     */
    return this.value === `${value}`;
  }

  /**
   * Since the value of the filter is dynamic, we need
   * to ensure that there is a default fallback value
   * based on the provided type
   */
  get filterValue() {
    return this.value || this._getInitialValue(this.type);
  }

  /**
   * Watch selected Start and End date to ensure
   * that the user will only be able to select valid
   * dates
   */

  @Watch("value.start")
  onStartDateChange(value: Date | null) {
    if (value) {
      this.state.rules.dateRange.disabled.end.to = value;
    } else {
      this.state.rules.dateRange.disabled.end = {
        from: new Date(),
        to: null,
      };
    }
  }

  @Watch("value.end")
  onEndDateChange(value: Date | null) {
    if (value) {
      this.state.rules.dateRange.disabled.start.from = value;
    } else {
      this.state.rules.dateRange.disabled.start = {
        from: new Date(),
        to: null,
      };
    }
  }
}
