






























import { Vue, Component, Prop } from "vue-property-decorator";
import { SvgIcon } from "@components";

import { unixToDateString, formatCurrency } from "@/utils/common";

@Component({
  components: {
    SvgIcon,
  },
})
export default class AccountDetailsRow extends Vue {
  @Prop()
  private readonly field!: string;
  @Prop()
  private readonly row!: Record<string, any>;

  get txnDate() {
    return unixToDateString(this.row.date);
  }

  get txnAmount() {
    const amount = parseFloat(this.row.amount as string);
    return formatCurrency(amount / 100);
  }

  get balance() {
    return formatCurrency(this.row.balance);
  }
}
