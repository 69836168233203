// package: fee
// file: brank.as/cebuana-corporate/gunk/bank/v1/fee/all.proto

var brank_as_cebuana_corporate_gunk_bank_v1_fee_all_pb = require("./all_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var FeeService = (function () {
  function FeeService() {}
  FeeService.serviceName = "fee.FeeService";
  return FeeService;
}());

FeeService.GetFees = {
  methodName: "GetFees",
  service: FeeService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_bank_v1_fee_all_pb.GetFeesRequest,
  responseType: brank_as_cebuana_corporate_gunk_bank_v1_fee_all_pb.GetFeesResponse
};

FeeService.UpdateFee = {
  methodName: "UpdateFee",
  service: FeeService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_bank_v1_fee_all_pb.UpdateFeeRequest,
  responseType: brank_as_cebuana_corporate_gunk_bank_v1_fee_all_pb.UpdateFeeResponse
};

exports.FeeService = FeeService;

function FeeServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

FeeServiceClient.prototype.getFees = function getFees(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(FeeService.GetFees, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

FeeServiceClient.prototype.updateFee = function updateFee(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(FeeService.UpdateFee, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.FeeServiceClient = FeeServiceClient;

