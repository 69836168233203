


















import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import { FilterItem, FilterValues, FilterData } from "../types";

import FilterBarInput from "../components/filter-bar-input/FilterBarInput.vue";

@Component({
  components: {
    FilterBarInput,
  },
})
export default class FilterBar extends Vue {
  @Prop({ default: () => [] })
  private readonly options!: Array<FilterItem>;

  @Prop()
  private readonly value!: FilterValues;

  @Prop()
  private readonly show?: boolean;

  onInput(data: FilterData) {
    const updated = {
      ...this.value,
      [data.name]: data.value,
    };

    this.$emit("input", updated);
  }

  _getValue(key: string) {
    return !this.value ? null : this.value[key];
  }
}
