import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { ProtobufMessage } from "@improbable-eng/grpc-web/dist/typings/message";

import { OrganizationService as Service } from "@/proto/org/all_pb_service";
import { ProfileService } from "@/proto/profile/all_pb_service";
import * as ServiceProto from "@/proto/org/all_pb";
import * as ProfileServiceProto from "@/proto/profile/all_pb";

import { OrgUser, UserRole, OrgDetails } from "@/types";
import AuthUtils from "@/utils/auth-utils";

export default class OrgService {
  constructor(private baseURL: string, private token: string) {}

  private genericErrorMessage =
    "Connection to the network failed. Please contact our support team support@cebuanalhullier.com.";

  public async getOrgUsers() {
    const request = new ProfileServiceProto.GetProfilesRequest();

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(ProfileService.GetProfiles, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      throw new Error(`unexpected status code=${status} msg=${statusMessage}`);
    } else if (message === null) {
      throw new Error("received no data");
    } else {
      const decoded = message as ProfileServiceProto.GetProfilesResponse;
      const userList: OrgUser[] = decoded.getProfileList().map((profile) => {
        const personalInfo = profile.getPersonalinfo();

        const orgUser: OrgUser = {
          id: profile.getId(),
          firstName: personalInfo?.getFirstname() as string,
          lastName: personalInfo?.getLastname() as string,
          email: personalInfo?.getEmail() as string,
          dateInvited: profile.getInviteddate()?.getSeconds(),
          role: profile.getRoletype() as UserRole,
          status: profile.getStatus() as string,
          corporateID: profile.getCorporateid() as string,
        };

        return orgUser;
      });

      return userList;
    }
  }

  public async getOrgDetails(): Promise<OrgDetails> {
    const request = new ServiceProto.GetOrganizationRequest();

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.GetOrganization, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      throw new Error(`unexpected status code=${status} msg=${statusMessage}`);
    } else if (message === null) {
      throw new Error("received no data");
    } else {
      const decoded = message as ServiceProto.GetOrganizationResponse;
      const org = decoded.getOrganization();
      const orgInfo = org?.getOrginfo();
      const orgAddress = orgInfo?.getAddress();
      const orgPIC = orgInfo?.getPic();

      const orgDetails: OrgDetails = {
        id: org?.getId() as string,
        name: orgInfo?.getName(),
        corporateID: org?.getCorporateid(),
        industry: orgInfo?.getIndustry(),
        address: {
          line1: orgAddress?.getLine1(),
          line2: orgAddress?.getLine2(),
          province: orgAddress?.getProvince(),
          city: orgAddress?.getCity(),
          district: orgAddress?.getDistrict(),
          zipCode: orgAddress?.getZipcode(),
        },
        phoneNumber: orgInfo?.getPhonenumber(),
        email: orgInfo?.getEmail(),
        pic: {
          name: orgPIC?.getName(),
          email: orgPIC?.getEmail(),
          phoneNumber: orgPIC?.getPhonenumber(),
        },
      };

      return orgDetails;
    }
  }

  public async updateOrgInfo(orgDetails: OrgDetails) {
    const request = new ServiceProto.UpdateOrganizationRequest();
    const org = new ServiceProto.Organization();
    const orgInfo = new ServiceProto.OrgInfo();
    const orgAddress = new ServiceProto.Address();
    const orgPIC = new ServiceProto.PIC();

    const { address, pic } = orgDetails;
    orgAddress.setLine1(address?.line1 as string);
    orgAddress.setLine2(address?.line2 as string);
    orgAddress.setProvince(address?.province as string);
    orgAddress.setCity(address?.city as string);
    orgAddress.setDistrict(address?.district as string);
    orgAddress.setZipcode(address?.zipCode as string);

    orgPIC.setName(pic?.name as string);
    orgPIC.setEmail(pic?.email as string);
    orgPIC.setPhonenumber(pic?.phoneNumber as string);

    orgInfo.setName(orgDetails.name as string);
    orgInfo.setIndustry(orgDetails.industry as string);
    orgInfo.setAddress(orgAddress);
    orgInfo.setPhonenumber(orgDetails.phoneNumber as string);
    orgInfo.setEmail(orgDetails.email as string);
    orgInfo.setPic(orgPIC);

    org.setOrginfo(orgInfo);
    org.setId(orgDetails.id as string);
    request.setOrganization(org);

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.UpdateOrganization, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      throw new Error(`unexpected status code=${status} msg=${statusMessage}`);
    } else if (message === null) {
      throw new Error("received no data");
    }
  }
}
