
















































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Vuelidate from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import VModal from "vue-js-modal";

import { CustomInput, SvgIcon, Loader } from "@/components";
import { Favorite } from "@/types";
import { AddFavoriteResponse, FavoriteType } from "@/proto/favorites/all_pb";

const favoritesModule = namespace("favorites");

Vue.use(Vuelidate);
Vue.use(VModal);

@Component({
  components: {
    CustomInput,
    SvgIcon,
    Loader,
    NotificationModal: () =>
      import("@components/notification-modal/NotificationModal.vue"),
    BanksDropdown: () => import("@components/banks-dropdown/BanksDropdown.vue"),
  },
  validations: {
    accountName: {
      required,
    },
    accountNumber: {
      required,
    },
    bankCode: {
      required: requiredIf(function (vm: AddPayeeModal) {
        return vm.settlementRail !== "CLRB";
      }),
    },
  },
})
export default class AddPayeeModal extends Vue {
  @favoritesModule.Action("addFavorite") addFavorite!: (
    payload: Favorite
  ) => AddFavoriteResponse.AsObject;
  @favoritesModule.Action("getFavoritesList") getFavoritesList!: () => void;

  settlementRail = "CLRB";
  accountName = "";
  accountNumber = "";
  bankCode = "";
  loading = false;
  error = "";
  mustShowNotif = false;

  get saveButtonText(): string {
    return this.loading ? "Adding Favorite Payee" : "Add Payee";
  }

  _closeNotif() {
    this.mustShowNotif = false;
  }

  _showNotif() {
    this.mustShowNotif = true;
  }

  _setBankCode(payload: string) {
    this.bankCode = payload;
  }

  _showAddPayeeModal() {
    this.$modal.show("add-payee-modal");
  }

  _hideAddPayeeModal() {
    this.$modal.hide("add-payee-modal");
    this._resetForm();
  }

  _resetForm() {
    this.accountName = "";
    this.accountNumber = "";
    this.bankCode = "";
    this.settlementRail = "CLRB";
  }

  async onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) return;
    this.loading = true;

    try {
      const bankCode = this.settlementRail === "CLRB" ? "CLRB" : this.bankCode;
      const accountInfo = {
        accountName: this.accountName,
        accountNumber: this.accountNumber,
      };
      const favorite: Favorite = {
        billingCode: bankCode,
        refCode: JSON.stringify(accountInfo),
        type: FavoriteType.PAYEE,
        category: "",
      };

      await this.addFavorite(favorite);
      this.getFavoritesList();
      this._showNotif();
      this._hideAddPayeeModal();
    } catch (error) {
      const err = error as Error;
      this.error = err.message;
    } finally {
      this.loading = false;
    }
  }

  @Watch("settlementRail", { immediate: true })
  watchRows(settlementRail: string) {
    if (settlementRail) {
      this.bankCode = "";
    }
  }
}
