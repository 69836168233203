


















































import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { SvgIcon } from "@components";
import { SIDEBAR_LINKS } from "@/constants";

import { UserInfo } from "@/types";

interface Link {
  label: string;
  to: string;
  allowedUsers?: Array<any>;
}

const userModule = namespace("user");

@Component({
  components: {
    SvgIcon,
  },
})
export default class Sidebar extends Vue {
  @Prop()
  private readonly toggle!: boolean;

  @userModule.State("userInfo") userInfo!: UserInfo;

  get sidebarLinks() {
    return SIDEBAR_LINKS;
  }

  _isUserAllowed(linkConfig: Link) {
    if (linkConfig.allowedUsers) {
      return linkConfig.allowedUsers.find(
        (user) => user === this.userInfo.role
      );
    }

    return true;
  }

  _mustShowGroup(links: Array<Link>) {
    let mustShow = false;

    links.forEach((link) => {
      if (this._isUserAllowed(link)) {
        mustShow = true;
        return;
      }
    });

    return mustShow;
  }

  subIsActive(links: Array<Link>): boolean {
    const paths = Array.isArray(links) ? links : [links];
    return paths.some((path): boolean => {
      return this.$route.path.indexOf(path.to) === 0; // current path starts with this path string
    });
  }
}
