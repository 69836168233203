











export default {
  name: "IconClose",
};
