












import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class CustomCheckbox extends Vue {
  @Prop({ default: "" })
  label!: string;
  @Prop({})
  value!: Array<string | number>;
  @Prop({ default: "" })
  inputValue!: string;
  @Prop({})
  id!: string;

  get getId(): string {
    return this.id
      ? this.id
      : `custom-input-${this.label.replace(/[^\w-]+/g, "-").toLowerCase()}`;
  }
  get model() {
    return this.value;
  }
  set model(value) {
    this.$emit("input", value);
  }
}
