import { ActionContext } from "vuex";
import { RootState } from "@/store";
import { OrgState } from "./index";
import { Org, Accounts } from "@/services";
import { OrgDetails } from "@/types";

export async function getOrgDetails({
  commit,
  rootState,
}: ActionContext<OrgState, RootState>) {
  const { auth, config } = rootState;

  try {
    const result = await new Org(
      config.profileURL,
      auth.user.accessToken
    ).getOrgDetails();

    commit("setOrgDetails", result);
  } catch (err) {
    const error = err as Error;
    console.error("Error when getting org details: ", error.message);
  }
}

export async function updateOrgInfo(
  { commit, rootState }: ActionContext<OrgState, RootState>,
  orgInfo: OrgDetails
) {
  const { auth, config } = rootState;

  try {
    await new Org(config.profileURL, auth.user.accessToken).updateOrgInfo(
      orgInfo
    );

    commit("setOrgDetails", orgInfo);
  } catch (err) {
    const error = err as Error;
    console.error("Error when updating org details: ", error.message);
  }
}

export async function getAccounts({
  commit,
  rootState,
}: ActionContext<OrgState, RootState>) {
  const { auth, config } = rootState;

  commit("setLoading", true);
  commit("setError", "");

  try {
    const result = await new Accounts(
      config.apiURL,
      auth.user.accessToken
    ).getAccounts();

    commit("setOrgAccounts", result);
  } catch (err) {
    commit("setError", err.message);
    console.error("Error when getting accounts: ", err.message);
  } finally {
    commit("setLoading", false);
  }
}

export async function getOrgUsers({
  commit,
  rootState,
}: ActionContext<OrgState, RootState>) {
  const { auth, config } = rootState;

  commit("setLoading", true);
  commit("setError", "");

  const result = await new Org(
    config.profileURL,
    auth.user.accessToken
  ).getOrgUsers();

  return result;
}
