





















































































































import Vue, { PropType } from "vue";
import { startCase, toLower } from "lodash";
import { mixin as clickaway } from "vue-clickaway";
import { SvgIcon } from "@components";

export interface DropdownOption {
  value: string | number;
  label: string;
}

const isStringArray = (arr: any[]): boolean =>
  arr.every((i) => typeof i === "string");

export default Vue.extend({
  name: "Dropdown",

  components: {
    SvgIcon,
  },

  mixins: [clickaway],

  props: {
    mainLabel: {
      type: String,
      default: "",
    },
    options: {
      type: Array as PropType<DropdownOption[]> | PropType<string[]>,
      default: function () {
        return [];
      },
    },
    error: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: "Choose an option",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    closeOnOutsideClick: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "",
    },
    pageWidth: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
    uppercaseValues: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showMenu: false,
      menuOptions: [] as DropdownOption[],
    };
  },

  computed: {
    selectedOption(): DropdownOption | undefined {
      return this.menuOptions.find((item: any) => item.value === this.value);
    },

    currentLabel(): string {
      return this.selectedOption ? this.selectedOption.label : this.placeholder;
    },

    focusBgColor(): string {
      return this.color
        ? `focus-within:bg-${this.color}`
        : "focus-within:bg-grey-400";
    },

    bgColor(): string {
      return this.color ? `bg-${this.color}` : "bg-grey-400";
    },

    borderColor(): string {
      return this.color ? `border-${this.color}` : "border-grey-400";
    },
  },

  mounted() {
    this.menuOptions = this.initMenuOptions();
  },

  watch: {
    options: {
      handler() {
        this.menuOptions = this.initMenuOptions();
      },
      immediate: true,
    },
  },

  methods: {
    initMenuOptions(): DropdownOption[] {
      if (isStringArray(this.options)) {
        const optionsArr = this.options as string[];
        return optionsArr.map<DropdownOption>((option: string) => ({
          value: option,
          label: option,
        }));
      }

      return this.options as DropdownOption[];
    },

    properCase(str: string): string {
      return startCase(toLower(str));
    },

    onSelectOption(option: DropdownOption) {
      this.showMenu = false;
      this.$emit("input", option.value);
    },

    toggleMenu() {
      this.showMenu = !this.showMenu;
    },

    onClickAway() {
      if (this.showMenu && this.closeOnOutsideClick) {
        this.showMenu = false;
      }
    },
  },
});
