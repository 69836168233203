<template>
  <div
    class="
      px-8
      py-2.5
      text-xs
      border-b border-gray-300
      flex
      justify-between
      items-center
    "
  >
    <div class="flex items-center">
      <!----------------icon----------------------->
      <div class="mr-8">
        <svg-icon name="PDFFileIcon"></svg-icon>
      </div>
      <div class="font-medium">
        <p class="text-blue-450 text-xs">{{ category }}</p>
        <p class="text-sm leading-loose">{{ number }}</p>
        <p class="text-xs text-gray-500">{{ givenDate }}</p>
      </div>
    </div>
    <!-------------------button---------------------->
    <div>
      <button class="btn btn-orange">{{ actions }}</button>
    </div>
  </div>
</template>

<script>
import { SvgIcon } from "@components";
export default {
  name: "CustomFileRow",
  components: {
    SvgIcon,
  },
  props: {
    category: String,
    number: String,
    date: Date,
    actions: String,
  },
  computed: {
    givenDate() {
      return this.date.toDateString();
    },
  },
};
</script>
