








export default {
  name: "Notif",
};
