import { GetterTree, Module, MutationTree } from "vuex";

import { RootState } from "@/store";
import { OrgDetails, OrgAccount } from "@/types";
import * as actions from "./action";

export interface OrgState {
  loading: boolean;
  error: string;
  orgDetails: OrgDetails;
  accounts: OrgAccount[];
}

const getInitialState = (): OrgState => {
  const orgDetails: OrgDetails = {
    id: "",
    name: "",
    corporateID: "",
    industry: "",
    address: {
      line1: "",
      line2: "",
      province: "",
      city: "",
      district: "",
      zipCode: "",
    },
    phoneNumber: "",
    email: "",
    pic: {
      name: "",
      email: "",
      phoneNumber: "",
    },
  };

  return {
    loading: true,
    error: "",
    orgDetails,
    accounts: [] as OrgAccount[],
  };
};

const initialState: OrgState = getInitialState();

const getters: GetterTree<OrgState, RootState> = {
  orgDetails: (state: OrgState): OrgDetails => {
    return state.orgDetails;
  },
};

const mutations: MutationTree<OrgState> = {
  setOrgDetails(state: OrgState, payload: OrgDetails) {
    state.orgDetails = payload;
  },
  setOrgAccounts(state: OrgState, payload: Array<OrgAccount>) {
    state.accounts = payload;
  },
  setLoading(state: OrgState, loading: boolean) {
    state.loading = loading;
  },
  setError(state: OrgState, error: string) {
    state.error = error;
  },
};

const org: Module<OrgState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default org;
