// source: all.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.brankas.rbac.v1.invite.ApproveRequest', null, global);
goog.exportSymbol('proto.brankas.rbac.v1.invite.ApproveResponse', null, global);
goog.exportSymbol('proto.brankas.rbac.v1.invite.CancelInviteRequest', null, global);
goog.exportSymbol('proto.brankas.rbac.v1.invite.CancelInviteResponse', null, global);
goog.exportSymbol('proto.brankas.rbac.v1.invite.Invite', null, global);
goog.exportSymbol('proto.brankas.rbac.v1.invite.InviteUserRequest', null, global);
goog.exportSymbol('proto.brankas.rbac.v1.invite.InviteUserResponse', null, global);
goog.exportSymbol('proto.brankas.rbac.v1.invite.ListInviteRequest', null, global);
goog.exportSymbol('proto.brankas.rbac.v1.invite.ListInviteResponse', null, global);
goog.exportSymbol('proto.brankas.rbac.v1.invite.ResendRequest', null, global);
goog.exportSymbol('proto.brankas.rbac.v1.invite.ResendResponse', null, global);
goog.exportSymbol('proto.brankas.rbac.v1.invite.RetrieveInviteRequest', null, global);
goog.exportSymbol('proto.brankas.rbac.v1.invite.RetrieveInviteResponse', null, global);
goog.exportSymbol('proto.brankas.rbac.v1.invite.RevokeRequest', null, global);
goog.exportSymbol('proto.brankas.rbac.v1.invite.RevokeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brankas.rbac.v1.invite.InviteUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brankas.rbac.v1.invite.InviteUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brankas.rbac.v1.invite.InviteUserRequest.displayName = 'proto.brankas.rbac.v1.invite.InviteUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brankas.rbac.v1.invite.InviteUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brankas.rbac.v1.invite.InviteUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brankas.rbac.v1.invite.InviteUserResponse.displayName = 'proto.brankas.rbac.v1.invite.InviteUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brankas.rbac.v1.invite.RetrieveInviteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brankas.rbac.v1.invite.RetrieveInviteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brankas.rbac.v1.invite.RetrieveInviteRequest.displayName = 'proto.brankas.rbac.v1.invite.RetrieveInviteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brankas.rbac.v1.invite.RetrieveInviteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brankas.rbac.v1.invite.RetrieveInviteResponse.displayName = 'proto.brankas.rbac.v1.invite.RetrieveInviteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brankas.rbac.v1.invite.ResendRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brankas.rbac.v1.invite.ResendRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brankas.rbac.v1.invite.ResendRequest.displayName = 'proto.brankas.rbac.v1.invite.ResendRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brankas.rbac.v1.invite.ResendResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brankas.rbac.v1.invite.ResendResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brankas.rbac.v1.invite.ResendResponse.displayName = 'proto.brankas.rbac.v1.invite.ResendResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brankas.rbac.v1.invite.ListInviteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brankas.rbac.v1.invite.ListInviteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brankas.rbac.v1.invite.ListInviteRequest.displayName = 'proto.brankas.rbac.v1.invite.ListInviteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brankas.rbac.v1.invite.Invite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brankas.rbac.v1.invite.Invite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brankas.rbac.v1.invite.Invite.displayName = 'proto.brankas.rbac.v1.invite.Invite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brankas.rbac.v1.invite.ListInviteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.brankas.rbac.v1.invite.ListInviteResponse.repeatedFields_, null);
};
goog.inherits(proto.brankas.rbac.v1.invite.ListInviteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brankas.rbac.v1.invite.ListInviteResponse.displayName = 'proto.brankas.rbac.v1.invite.ListInviteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brankas.rbac.v1.invite.CancelInviteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brankas.rbac.v1.invite.CancelInviteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brankas.rbac.v1.invite.CancelInviteRequest.displayName = 'proto.brankas.rbac.v1.invite.CancelInviteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brankas.rbac.v1.invite.CancelInviteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brankas.rbac.v1.invite.CancelInviteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brankas.rbac.v1.invite.CancelInviteResponse.displayName = 'proto.brankas.rbac.v1.invite.CancelInviteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brankas.rbac.v1.invite.ApproveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brankas.rbac.v1.invite.ApproveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brankas.rbac.v1.invite.ApproveRequest.displayName = 'proto.brankas.rbac.v1.invite.ApproveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brankas.rbac.v1.invite.ApproveResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brankas.rbac.v1.invite.ApproveResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brankas.rbac.v1.invite.ApproveResponse.displayName = 'proto.brankas.rbac.v1.invite.ApproveResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brankas.rbac.v1.invite.RevokeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brankas.rbac.v1.invite.RevokeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brankas.rbac.v1.invite.RevokeRequest.displayName = 'proto.brankas.rbac.v1.invite.RevokeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.brankas.rbac.v1.invite.RevokeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.brankas.rbac.v1.invite.RevokeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.brankas.rbac.v1.invite.RevokeResponse.displayName = 'proto.brankas.rbac.v1.invite.RevokeResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.brankas.rbac.v1.invite.InviteUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brankas.rbac.v1.invite.InviteUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orgname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    role: jspb.Message.getFieldWithDefault(msg, 7, ""),
    customemaildataMap: (f = msg.getCustomemaildataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brankas.rbac.v1.invite.InviteUserRequest}
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brankas.rbac.v1.invite.InviteUserRequest;
  return proto.brankas.rbac.v1.invite.InviteUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brankas.rbac.v1.invite.InviteUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brankas.rbac.v1.invite.InviteUserRequest}
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    case 8:
      var value = msg.getCustomemaildataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brankas.rbac.v1.invite.InviteUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brankas.rbac.v1.invite.InviteUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrgname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCustomemaildataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string OrgID = 1;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.getOrgid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.InviteUserRequest} returns this
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string OrgName = 2;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.getOrgname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.InviteUserRequest} returns this
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.setOrgname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string FirstName = 3;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.InviteUserRequest} returns this
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string LastName = 4;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.InviteUserRequest} returns this
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Email = 5;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.InviteUserRequest} returns this
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Phone = 6;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.InviteUserRequest} returns this
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Role = 7;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.InviteUserRequest} returns this
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * map<string, string> CustomEmailData = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.getCustomemaildataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.brankas.rbac.v1.invite.InviteUserRequest} returns this
 */
proto.brankas.rbac.v1.invite.InviteUserRequest.prototype.clearCustomemaildataMap = function() {
  this.getCustomemaildataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brankas.rbac.v1.invite.InviteUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.brankas.rbac.v1.invite.InviteUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brankas.rbac.v1.invite.InviteUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.InviteUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orgid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    invitationcode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brankas.rbac.v1.invite.InviteUserResponse}
 */
proto.brankas.rbac.v1.invite.InviteUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brankas.rbac.v1.invite.InviteUserResponse;
  return proto.brankas.rbac.v1.invite.InviteUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brankas.rbac.v1.invite.InviteUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brankas.rbac.v1.invite.InviteUserResponse}
 */
proto.brankas.rbac.v1.invite.InviteUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvitationcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brankas.rbac.v1.invite.InviteUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brankas.rbac.v1.invite.InviteUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brankas.rbac.v1.invite.InviteUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.InviteUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrgid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInvitationcode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.InviteUserResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.InviteUserResponse} returns this
 */
proto.brankas.rbac.v1.invite.InviteUserResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string OrgID = 2;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.InviteUserResponse.prototype.getOrgid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.InviteUserResponse} returns this
 */
proto.brankas.rbac.v1.invite.InviteUserResponse.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string InvitationCode = 3;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.InviteUserResponse.prototype.getInvitationcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.InviteUserResponse} returns this
 */
proto.brankas.rbac.v1.invite.InviteUserResponse.prototype.setInvitationcode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.brankas.rbac.v1.invite.RetrieveInviteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brankas.rbac.v1.invite.RetrieveInviteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.RetrieveInviteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteRequest}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brankas.rbac.v1.invite.RetrieveInviteRequest;
  return proto.brankas.rbac.v1.invite.RetrieveInviteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brankas.rbac.v1.invite.RetrieveInviteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteRequest}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brankas.rbac.v1.invite.RetrieveInviteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brankas.rbac.v1.invite.RetrieveInviteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.RetrieveInviteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteRequest} returns this
 */
proto.brankas.rbac.v1.invite.RetrieveInviteRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Code = 2;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteRequest} returns this
 */
proto.brankas.rbac.v1.invite.RetrieveInviteRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.brankas.rbac.v1.invite.RetrieveInviteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orgid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    countrycode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    companyname: jspb.Message.getFieldWithDefault(msg, 6, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    inviteemail: jspb.Message.getFieldWithDefault(msg, 8, ""),
    invitestatus: jspb.Message.getFieldWithDefault(msg, 9, ""),
    invited: (f = msg.getInvited()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    firstname: jspb.Message.getFieldWithDefault(msg, 11, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brankas.rbac.v1.invite.RetrieveInviteResponse;
  return proto.brankas.rbac.v1.invite.RetrieveInviteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountrycode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyname(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInviteemail(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvitestatus(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setInvited(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brankas.rbac.v1.invite.RetrieveInviteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrgid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountrycode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getInviteemail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getInvitestatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getInvited();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse} returns this
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string OrgID = 2;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.getOrgid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse} returns this
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Email = 3;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse} returns this
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string CountryCode = 4;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.getCountrycode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse} returns this
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.setCountrycode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Phone = 5;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse} returns this
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string CompanyName = 6;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse} returns this
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool Active = 7;
 * @return {boolean}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse} returns this
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string InviteEmail = 8;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.getInviteemail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse} returns this
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.setInviteemail = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string InviteStatus = 9;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.getInvitestatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse} returns this
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.setInvitestatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp Invited = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.getInvited = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse} returns this
*/
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.setInvited = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse} returns this
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.clearInvited = function() {
  return this.setInvited(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.hasInvited = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string FirstName = 11;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse} returns this
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string LastName = 12;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.RetrieveInviteResponse} returns this
 */
proto.brankas.rbac.v1.invite.RetrieveInviteResponse.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brankas.rbac.v1.invite.ResendRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.brankas.rbac.v1.invite.ResendRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brankas.rbac.v1.invite.ResendRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.ResendRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brankas.rbac.v1.invite.ResendRequest}
 */
proto.brankas.rbac.v1.invite.ResendRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brankas.rbac.v1.invite.ResendRequest;
  return proto.brankas.rbac.v1.invite.ResendRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brankas.rbac.v1.invite.ResendRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brankas.rbac.v1.invite.ResendRequest}
 */
proto.brankas.rbac.v1.invite.ResendRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brankas.rbac.v1.invite.ResendRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brankas.rbac.v1.invite.ResendRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brankas.rbac.v1.invite.ResendRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.ResendRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.ResendRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.ResendRequest} returns this
 */
proto.brankas.rbac.v1.invite.ResendRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brankas.rbac.v1.invite.ResendResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.brankas.rbac.v1.invite.ResendResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brankas.rbac.v1.invite.ResendResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.ResendResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brankas.rbac.v1.invite.ResendResponse}
 */
proto.brankas.rbac.v1.invite.ResendResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brankas.rbac.v1.invite.ResendResponse;
  return proto.brankas.rbac.v1.invite.ResendResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brankas.rbac.v1.invite.ResendResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brankas.rbac.v1.invite.ResendResponse}
 */
proto.brankas.rbac.v1.invite.ResendResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brankas.rbac.v1.invite.ResendResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brankas.rbac.v1.invite.ResendResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brankas.rbac.v1.invite.ResendResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.ResendResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brankas.rbac.v1.invite.ListInviteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.brankas.rbac.v1.invite.ListInviteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brankas.rbac.v1.invite.ListInviteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.ListInviteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brankas.rbac.v1.invite.ListInviteRequest}
 */
proto.brankas.rbac.v1.invite.ListInviteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brankas.rbac.v1.invite.ListInviteRequest;
  return proto.brankas.rbac.v1.invite.ListInviteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brankas.rbac.v1.invite.ListInviteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brankas.rbac.v1.invite.ListInviteRequest}
 */
proto.brankas.rbac.v1.invite.ListInviteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brankas.rbac.v1.invite.ListInviteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brankas.rbac.v1.invite.ListInviteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brankas.rbac.v1.invite.ListInviteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.ListInviteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brankas.rbac.v1.invite.Invite.prototype.toObject = function(opt_includeInstance) {
  return proto.brankas.rbac.v1.invite.Invite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brankas.rbac.v1.invite.Invite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.Invite.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orgid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contactemail: jspb.Message.getFieldWithDefault(msg, 3, ""),
    invited: (f = msg.getInvited()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    role: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brankas.rbac.v1.invite.Invite}
 */
proto.brankas.rbac.v1.invite.Invite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brankas.rbac.v1.invite.Invite;
  return proto.brankas.rbac.v1.invite.Invite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brankas.rbac.v1.invite.Invite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brankas.rbac.v1.invite.Invite}
 */
proto.brankas.rbac.v1.invite.Invite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactemail(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setInvited(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brankas.rbac.v1.invite.Invite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brankas.rbac.v1.invite.Invite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brankas.rbac.v1.invite.Invite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.Invite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrgid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContactemail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInvited();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.Invite.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.Invite} returns this
 */
proto.brankas.rbac.v1.invite.Invite.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string OrgID = 2;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.Invite.prototype.getOrgid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.Invite} returns this
 */
proto.brankas.rbac.v1.invite.Invite.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ContactEmail = 3;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.Invite.prototype.getContactemail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.Invite} returns this
 */
proto.brankas.rbac.v1.invite.Invite.prototype.setContactemail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp Invited = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.brankas.rbac.v1.invite.Invite.prototype.getInvited = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.brankas.rbac.v1.invite.Invite} returns this
*/
proto.brankas.rbac.v1.invite.Invite.prototype.setInvited = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.brankas.rbac.v1.invite.Invite} returns this
 */
proto.brankas.rbac.v1.invite.Invite.prototype.clearInvited = function() {
  return this.setInvited(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.brankas.rbac.v1.invite.Invite.prototype.hasInvited = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string Role = 5;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.Invite.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.Invite} returns this
 */
proto.brankas.rbac.v1.invite.Invite.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Status = 6;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.Invite.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.Invite} returns this
 */
proto.brankas.rbac.v1.invite.Invite.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.brankas.rbac.v1.invite.ListInviteResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brankas.rbac.v1.invite.ListInviteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.brankas.rbac.v1.invite.ListInviteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brankas.rbac.v1.invite.ListInviteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.ListInviteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invitesList: jspb.Message.toObjectList(msg.getInvitesList(),
    proto.brankas.rbac.v1.invite.Invite.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brankas.rbac.v1.invite.ListInviteResponse}
 */
proto.brankas.rbac.v1.invite.ListInviteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brankas.rbac.v1.invite.ListInviteResponse;
  return proto.brankas.rbac.v1.invite.ListInviteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brankas.rbac.v1.invite.ListInviteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brankas.rbac.v1.invite.ListInviteResponse}
 */
proto.brankas.rbac.v1.invite.ListInviteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.brankas.rbac.v1.invite.Invite;
      reader.readMessage(value,proto.brankas.rbac.v1.invite.Invite.deserializeBinaryFromReader);
      msg.addInvites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brankas.rbac.v1.invite.ListInviteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brankas.rbac.v1.invite.ListInviteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brankas.rbac.v1.invite.ListInviteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.ListInviteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.brankas.rbac.v1.invite.Invite.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Invite Invites = 1;
 * @return {!Array<!proto.brankas.rbac.v1.invite.Invite>}
 */
proto.brankas.rbac.v1.invite.ListInviteResponse.prototype.getInvitesList = function() {
  return /** @type{!Array<!proto.brankas.rbac.v1.invite.Invite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.brankas.rbac.v1.invite.Invite, 1));
};


/**
 * @param {!Array<!proto.brankas.rbac.v1.invite.Invite>} value
 * @return {!proto.brankas.rbac.v1.invite.ListInviteResponse} returns this
*/
proto.brankas.rbac.v1.invite.ListInviteResponse.prototype.setInvitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.brankas.rbac.v1.invite.Invite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.brankas.rbac.v1.invite.Invite}
 */
proto.brankas.rbac.v1.invite.ListInviteResponse.prototype.addInvites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.brankas.rbac.v1.invite.Invite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.brankas.rbac.v1.invite.ListInviteResponse} returns this
 */
proto.brankas.rbac.v1.invite.ListInviteResponse.prototype.clearInvitesList = function() {
  return this.setInvitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brankas.rbac.v1.invite.CancelInviteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.brankas.rbac.v1.invite.CancelInviteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brankas.rbac.v1.invite.CancelInviteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.CancelInviteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brankas.rbac.v1.invite.CancelInviteRequest}
 */
proto.brankas.rbac.v1.invite.CancelInviteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brankas.rbac.v1.invite.CancelInviteRequest;
  return proto.brankas.rbac.v1.invite.CancelInviteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brankas.rbac.v1.invite.CancelInviteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brankas.rbac.v1.invite.CancelInviteRequest}
 */
proto.brankas.rbac.v1.invite.CancelInviteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brankas.rbac.v1.invite.CancelInviteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brankas.rbac.v1.invite.CancelInviteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brankas.rbac.v1.invite.CancelInviteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.CancelInviteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.CancelInviteRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.CancelInviteRequest} returns this
 */
proto.brankas.rbac.v1.invite.CancelInviteRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brankas.rbac.v1.invite.CancelInviteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.brankas.rbac.v1.invite.CancelInviteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brankas.rbac.v1.invite.CancelInviteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.CancelInviteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brankas.rbac.v1.invite.CancelInviteResponse}
 */
proto.brankas.rbac.v1.invite.CancelInviteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brankas.rbac.v1.invite.CancelInviteResponse;
  return proto.brankas.rbac.v1.invite.CancelInviteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brankas.rbac.v1.invite.CancelInviteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brankas.rbac.v1.invite.CancelInviteResponse}
 */
proto.brankas.rbac.v1.invite.CancelInviteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brankas.rbac.v1.invite.CancelInviteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brankas.rbac.v1.invite.CancelInviteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brankas.rbac.v1.invite.CancelInviteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.CancelInviteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brankas.rbac.v1.invite.ApproveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.brankas.rbac.v1.invite.ApproveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brankas.rbac.v1.invite.ApproveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.ApproveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brankas.rbac.v1.invite.ApproveRequest}
 */
proto.brankas.rbac.v1.invite.ApproveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brankas.rbac.v1.invite.ApproveRequest;
  return proto.brankas.rbac.v1.invite.ApproveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brankas.rbac.v1.invite.ApproveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brankas.rbac.v1.invite.ApproveRequest}
 */
proto.brankas.rbac.v1.invite.ApproveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brankas.rbac.v1.invite.ApproveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brankas.rbac.v1.invite.ApproveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brankas.rbac.v1.invite.ApproveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.ApproveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.ApproveRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.ApproveRequest} returns this
 */
proto.brankas.rbac.v1.invite.ApproveRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brankas.rbac.v1.invite.ApproveResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.brankas.rbac.v1.invite.ApproveResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brankas.rbac.v1.invite.ApproveResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.ApproveResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brankas.rbac.v1.invite.ApproveResponse}
 */
proto.brankas.rbac.v1.invite.ApproveResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brankas.rbac.v1.invite.ApproveResponse;
  return proto.brankas.rbac.v1.invite.ApproveResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brankas.rbac.v1.invite.ApproveResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brankas.rbac.v1.invite.ApproveResponse}
 */
proto.brankas.rbac.v1.invite.ApproveResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brankas.rbac.v1.invite.ApproveResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brankas.rbac.v1.invite.ApproveResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brankas.rbac.v1.invite.ApproveResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.ApproveResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brankas.rbac.v1.invite.RevokeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.brankas.rbac.v1.invite.RevokeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brankas.rbac.v1.invite.RevokeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.RevokeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brankas.rbac.v1.invite.RevokeRequest}
 */
proto.brankas.rbac.v1.invite.RevokeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brankas.rbac.v1.invite.RevokeRequest;
  return proto.brankas.rbac.v1.invite.RevokeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brankas.rbac.v1.invite.RevokeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brankas.rbac.v1.invite.RevokeRequest}
 */
proto.brankas.rbac.v1.invite.RevokeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brankas.rbac.v1.invite.RevokeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brankas.rbac.v1.invite.RevokeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brankas.rbac.v1.invite.RevokeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.RevokeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.brankas.rbac.v1.invite.RevokeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.brankas.rbac.v1.invite.RevokeRequest} returns this
 */
proto.brankas.rbac.v1.invite.RevokeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.brankas.rbac.v1.invite.RevokeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.brankas.rbac.v1.invite.RevokeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.brankas.rbac.v1.invite.RevokeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.RevokeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.brankas.rbac.v1.invite.RevokeResponse}
 */
proto.brankas.rbac.v1.invite.RevokeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.brankas.rbac.v1.invite.RevokeResponse;
  return proto.brankas.rbac.v1.invite.RevokeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.brankas.rbac.v1.invite.RevokeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.brankas.rbac.v1.invite.RevokeResponse}
 */
proto.brankas.rbac.v1.invite.RevokeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.brankas.rbac.v1.invite.RevokeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.brankas.rbac.v1.invite.RevokeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.brankas.rbac.v1.invite.RevokeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.brankas.rbac.v1.invite.RevokeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.brankas.rbac.v1.invite);
