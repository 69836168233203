import { RoleType } from "@/proto/profile/all_pb";
import { Status } from "@/proto/transactions/all_pb";
import { Roles } from "@/types";
import moment from "moment";

export const getRoles = (): Roles => {
  return {
    "Bank Administrator": RoleType.BANKADMINISTRATOR,
    "Content Administrator": RoleType.CONTENTADMIN,
    "Account Administrator": RoleType.ACCOUNTADMINISTRATOR,
    "Checker": RoleType.CHECKER,
    "Maker": RoleType.MAKER,
  };
};

export const getRoleLabel = (role: any): string => {
  let label = "";

  for (const [key, value] of Object.entries(getRoles())) {
    if (value === role) {
      label = key;
    }
  }

  return label;
};

export const unixToDateString = (date: number) => {
  return moment.unix(date).format("DD MMM YYYY[\n] kk:mm [GMT]");
};

export const getTxnStatusLabel = (status: number) => {
  switch (status) {
    case Status.SUCCESS:
      return "success";
    case Status.PENDING:
      return "pending";
    case Status.REJECTED:
      return "rejected";
    case Status.UNKNOWNSTATUS:
    default:
      return "unknown";
  }
};

export const getBatchStatusLabel = (status: string) => {
  switch (status) {
    case "Created":
      return "Pending";
    case "Approved":
      return "Approved";
    case "Cancelled":
      return "Rejected";
    case "Processed":
      return "Processed";
    case "Processing":
      return "Processing";
    default:
      return "Unknown";
  }
};

export const stringToNumberUtil = (
  value: string,
  callback: (trimmed: string) => string
): string => {
  if (value.length) {
    const trimmed = value.replace(/[^0-9]|\s/g, "");
    return callback ? callback(trimmed) : trimmed;
  }

  return "";
};

export const formatPhoneNumberUtil = (value: string): string => {
  return stringToNumberUtil(value, (formatted) => {
    if (formatted.length) {
      const first6Chars =
        formatted
          .slice(0, 6)
          .match(/.{1,3}/g)
          ?.join(" ") || "";
      const lastChars =
        formatted
          .slice(6)
          .match(/.{1,4}/g)
          ?.join(" ") || "";

      return `${first6Chars} ${lastChars}`;
    }

    return "";
  });
};

export const formatAmount = (amount: string): string => {
  return (parseInt(amount) * 100).toString();
};

export const formatCurrency = (amount: string | number): string => {
  const amt = typeof amount === "string" ? parseFloat(amount) : amount;

  return amt
    .toLocaleString("en-PH", {
      style: "currency",
      currency: "PHP",
    })
    .slice(1);
};

export const filterData = (
  data: Array<Record<string, any>>,
  filters: Record<string, any>,
  filterOptions: Record<string, any>
) => {
  let filterKeys = Object.keys(filters);
  filterKeys = filterKeys.filter((x) => filters[x] !== null);

  const _getFilterConfig = (key: string): Record<any, string> => {
    return filterOptions.find((filter: any) => filter.name === key);
  };

  const filtered = data.filter((item: any) => {
    let passed = true;
    filterKeys.forEach((key) => {
      const config = _getFilterConfig(key);

      if (config.type === "date") {
        const dateConfig = filters[key];

        if (dateConfig.type === "last") {
          const now = moment(new Date());
          const itemDate = moment(moment.unix(item[key]).format("DD MMM YYYY"));

          const duration = moment.duration(now.diff(itemDate));

          if (dateConfig.interval === "days") {
            const days = Math.round(duration.asDays());
            if (dateConfig.value < days) {
              passed = false;
              return false;
            }
          } else if (dateConfig.interval === "months") {
            const months = Math.round(duration.asMonths());
            if (dateConfig.value < months) {
              passed = false;
              return false;
            }
          }
        }

        if (dateConfig.type === "equal") {
          const chosenDate = moment(
            moment(dateConfig.value).format("MM-DD-YYYY")
          );
          const itemDate = moment(moment.unix(item[key]).format("MM-DD-YYYY"));

          if (!chosenDate.isSame(itemDate)) {
            passed = false;
            return false;
          }
        }
      }

      if (config.type === "checkbox") {
        const valueExists = filters[key].find(
          (option: string) => item[key] === option
        );

        if (!valueExists) {
          passed = false;
          return false;
        }
      }

      if (config.type === "select") {
        if (item[key] !== filters[key]) {
          passed = false;
          return false;
        }
      }
    });

    return passed;
  });

  return filtered;
};
