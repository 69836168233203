















import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { isEmpty } from "lodash";

import { CustomInput, Dropdown, SvgIcon, Loader } from "@/components";
import { OrgAccount, UserInfo } from "@/types";

const orgModule = namespace("org");
const userModule = namespace("user");

@Component({
  components: {
    Loader,
    Dropdown,
    CustomInput,
    SvgIcon,
  },
})
export default class AccountsDropdown extends Vue {
  @Prop({ type: Boolean, default: false }) error!: boolean;
  @Prop({ type: Object, default: {} }) sourceAccount!: OrgAccount;

  @orgModule.State("accounts") accounts!: OrgAccount[];
  @userModule.Getter("currentUserInfo") userInfo!: UserInfo;

  get accountList() {
    if (!this.accounts.length) return [];

    return this.accounts.map(({ accountNumber }) => ({
      label: "Account " + accountNumber,
      value: accountNumber,
    }));
  }

  get isAccountsLoaded() {
    return !isEmpty(this.accounts);
  }

  _setSourceAccount(accountNumber: string) {
    const sourceAccount: OrgAccount = this.accounts.find(
      (acc) => acc.accountNumber === accountNumber
    ) as OrgAccount;

    this.$emit("set-source-account", sourceAccount);
  }
}
