// package: batch
// file: brank.as/cebuana-corporate/gunk/bank/v1/batch/all.proto

var brank_as_cebuana_corporate_gunk_bank_v1_batch_all_pb = require("./all_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var BatchTransactionService = (function () {
  function BatchTransactionService() {}
  BatchTransactionService.serviceName = "batch.BatchTransactionService";
  return BatchTransactionService;
}());

BatchTransactionService.CreateBatch = {
  methodName: "CreateBatch",
  service: BatchTransactionService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_bank_v1_batch_all_pb.CreateBatchRequest,
  responseType: brank_as_cebuana_corporate_gunk_bank_v1_batch_all_pb.CreateBatchResponse
};

BatchTransactionService.MoveTransaction = {
  methodName: "MoveTransaction",
  service: BatchTransactionService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_bank_v1_batch_all_pb.MoveTransactionRequest,
  responseType: brank_as_cebuana_corporate_gunk_bank_v1_batch_all_pb.MoveTransactionResponse
};

BatchTransactionService.ProcessBatch = {
  methodName: "ProcessBatch",
  service: BatchTransactionService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_bank_v1_batch_all_pb.ProcessBatchRequest,
  responseType: brank_as_cebuana_corporate_gunk_bank_v1_batch_all_pb.ProcessBatchResponse
};

BatchTransactionService.ListBatch = {
  methodName: "ListBatch",
  service: BatchTransactionService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_bank_v1_batch_all_pb.ListBatchRequest,
  responseType: brank_as_cebuana_corporate_gunk_bank_v1_batch_all_pb.ListBatchResponse
};

exports.BatchTransactionService = BatchTransactionService;

function BatchTransactionServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

BatchTransactionServiceClient.prototype.createBatch = function createBatch(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BatchTransactionService.CreateBatch, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BatchTransactionServiceClient.prototype.moveTransaction = function moveTransaction(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BatchTransactionService.MoveTransaction, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BatchTransactionServiceClient.prototype.processBatch = function processBatch(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BatchTransactionService.ProcessBatch, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BatchTransactionServiceClient.prototype.listBatch = function listBatch(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BatchTransactionService.ListBatch, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.BatchTransactionServiceClient = BatchTransactionServiceClient;

