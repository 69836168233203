
















export default {
  name: "Remove",
};
