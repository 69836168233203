
































































import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { OrgAccount } from "@/types";
import {
  Transaction,
  GetTransactionsByAccountResponse,
} from "@/proto/transactions/all_pb";
import {
  SvgIcon,
  CustomTable,
  Dropdown,
  CustomInput,
  AddPayeeModal,
} from "@components";
import AccountDetailsRow from "./components/AccountDetailsRow.vue";
import AccountCard from "./components/AccountCard.vue";
import TransactionDetailsModal from "./components/TransactionDetailsModal.vue";

const userModule = namespace("user");
const transactionsModule = namespace("transactions");
const orgModule = namespace("org");

type Txn = Transaction.AsObject | Record<string, any> | undefined;

interface State {
  sortBy: string;
  searchTerm: string;
  transactions: Array<Transaction.AsObject>;
  selectedTransaction: Txn;
}

@Component({
  components: {
    SvgIcon,
    CustomTable,
    AccountDetailsRow,
    Dropdown,
    CustomInput,
    AccountCard,
    AddPayeeModal,
    TransactionDetailsModal,
  },
})
export default class AccountDetails extends Vue {
  @userModule.Getter("userInfoExists") userInfoExists!: boolean;
  @orgModule.State("accounts") accounts!: Array<OrgAccount>;
  @transactionsModule.Action("getAccountTransactions")
  getAccountTransactions!: (
    accountNumber: string
  ) => GetTransactionsByAccountResponse.AsObject;

  @Prop()
  private readonly account!: OrgAccount;

  state: State = {
    sortBy: "date",
    searchTerm: "",
    transactions: [],
    selectedTransaction: {},
  };

  get COLUMNS(): Array<any> {
    return [
      {
        field: "transaction",
        label: "Transaction",
        sortable: false,
      },
      {
        field: "date",
        label: "Date",
        sortable: true,
        filter: true,
        dataType: "date",
      },
      {
        field: "amount",
        label: "Amount",
        sortable: true,
      },
      {
        field: "balance",
        label: "Balance",
        sortable: true,
      },
      {
        field: "actions",
        label: "Actions",
        sortable: false,
      },
    ];
  }

  get transactionList(): Array<Record<string, any>> {
    if (this.state.transactions.length) {
      return this.state.transactions.map(
        (transaction: Transaction.AsObject) => {
          return {
            date: transaction.date.seconds,
            amount: transaction?.amount?.num,
            balance: 30000,
            id: transaction.transactionid,
          };
        }
      );
    }

    return [];
  }

  _showDetails(row: Record<string, any>) {
    this.state.selectedTransaction = this.state.transactions.find(
      (txn: Txn) => {
        return txn?.transactionid === row.id;
      }
    );
    this.$modal.show("transaction-details-modal");
  }

  onDownloadHistory() {
    console.log("defined");
  }

  async mounted() {
    const txns = await this.getAccountTransactions(this.account.accountNumber);
    this.state.transactions = txns.resultList;
  }
}
