







export default {
  name: "ChevronLeft",
};
