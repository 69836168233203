











































































import { Vue, Prop, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import { VueGoodTable } from "vue-good-table";
import moment from "moment";

import TableHeader from "./TableHeader.vue";
import { filterData } from "@/utils/common";
import {
  FilterValues,
  FilterItem,
  FilterType,
  FilterInputOption,
} from "../filter";
import TablePagination from "./TablePagination.vue";

import "vue-good-table/dist/vue-good-table.css";

export interface Column {
  label: string;
  field: string;
  dataType?: FilterType;
  sortable?: boolean;
  defaultSort?: boolean;
  options?: Array<FilterInputOption>;
  filter?: boolean;
  width?: string;
}

interface SearchConfig {
  field: string | null;
  value: string;
}

interface State {
  filterValues: FilterValues;
  search: SearchConfig;
  sortBy: Column;
  sortType: "ASC" | "DESC";
  rows: Array<any>;
  hasSortedDefault: boolean;
}

@Component({
  components: {
    TableHeader,
    VueGoodTable,
    TablePagination,
  },
})
export default class CustomTable extends Vue {
  @Prop({ type: String, default: "No records found" })
  noRecordedMessage!: string;

  @Prop()
  private readonly headerActions!: boolean;

  @Prop({ default: false })
  private readonly enableSort!: boolean;

  @Prop({ default: "Search" })
  private readonly searchPlaceholder!: string;

  @Prop({ default: () => [] })
  private readonly columns!: Array<Column>;

  @Prop({ default: () => [] })
  private readonly rows!: Array<any>;

  @Prop()
  private readonly filterButtonText!: string;

  @Prop({ default: () => [] })
  private readonly downloadOptions!: Array<{ label: string; value: any }>;

  @Prop({ default: 10 })
  private readonly perPage!: number;

  @Prop({ default: true })
  private readonly enablePagination!: boolean;

  private state: State = {
    filterValues: {},
    search: {
      field: null,
      value: "",
    },
    sortBy: {
      label: "",
      field: "",
    },
    sortType: "ASC",
    rows: [],
    hasSortedDefault: false,
  };

  get _hasHeader() {
    return this.headerActions;
  }

  get _hasDownloadListener() {
    return this.$listeners && !!this.$listeners.download;
  }

  get filterOptions(): Array<FilterItem> {
    return this.filterableColumns.map(({ field, label, dataType, options }) => {
      const item: FilterItem = {
        label,
        type: dataType as FilterType,
        name: field,
      };

      if (options) {
        item.options = options;
      }

      return item;
    });
  }

  get sortingOptions() {
    return this.sortableColumns.map((column) => {
      return {
        label: column.label,
        value: column.field,
      };
    });
  }

  get filterableColumns() {
    return this.columns.filter((column) => column.filter);
  }

  get sortableColumns() {
    return this.columns.filter((column) => column.sortable);
  }

  get defaultColumnSort() {
    return this.columns.find((column) => !!column.defaultSort);
  }

  message = this.noRecordedMessage;

  onDownload(value: string) {
    console.log(value);
    // this.$emit("download", value);
  }

  onFilterInput(data: any) {
    this.state.filterValues = data;
  }

  onClearFilter() {
    const filterValues = this.state.filterValues;

    Object.keys(filterValues).forEach((key) => {
      filterValues[key] = null;
    });

    this.state.filterValues = filterValues;
  }

  onClearFilterItem(name: any) {
    this.state.filterValues[name] = null;
    this.onSubmitFilter();
  }

  onCancelFilter() {
    console.log("onCancelFilter");
  }

  _getColumn(field: string) {
    return this.columns.find((column) => column.field === field);
  }

  onSortSelect(value: string, isDefaultSort = false) {
    if (value !== this.state.sortBy.field) {
      this.state.sortBy = this._getColumn(value) as Column;
      this.state.sortType = isDefaultSort
        ? this.getColumnSortType(this.state.sortBy)
        : "ASC";
    } else {
      if (isDefaultSort) {
        this.state.sortType = this.getColumnSortType(this.state.sortBy);
      } else {
        this.state.sortType = this.state.sortType === "ASC" ? "DESC" : "ASC";
      }
    }

    this._processRows();
  }

  getColumnSortType(sortBy: Column) {
    if (sortBy.dataType === "date") {
      return "DESC";
    }

    return "ASC";
  }

  onSubmitFilter() {
    // this.state.page = 1;
    this._processRows();
  }

  onSubmitSearch(config: SearchConfig) {
    this.state.search = config;
    this._processRows();
  }

  _processRows() {
    // apply search value
    this._applySearch();

    // apply filter values
    this._applyFilters();

    // apply filter values
    this._applySort();
  }

  _applyFilters() {
    const rows = !this.state.search.value ? this.rows : this.state.rows;

    const filteredData = filterData(
      rows,
      this.state.filterValues,
      this.filterOptions
    ) as Array<any>;

    this.state.rows = filteredData;
  }

  _applySearch() {
    const { field, value } = this.state.search;

    if (!value) {
      return;
    }

    const val = value.toLowerCase();

    if (field !== null) {
      this.state.rows = this.rows.filter((row) => {
        const item = row[field] as string;
        return item.toLowerCase().includes(val);
      });
    } else {
      this.state.rows = this.rows.filter((row: Record<string, any>) => {
        return JSON.stringify(row).toLowerCase().includes(val);
      });
    }
  }

  _applySort() {
    if (!this.state.sortBy) {
      return;
    }
    const value = this.state.sortBy?.field;
    const { sortType } = this.state;

    this.state.rows.sort((a: any, b: any) => {
      if (this.state.sortBy?.dataType === "date") {
        const dateA = moment(moment.unix(a[value]).format("MM-DD-YYYY kk:mm"));
        const dateB = moment(moment.unix(b[value]).format("MM-DD-YYYY kk:mm"));
        return sortType === "DESC" ? dateB.diff(dateA) : dateA.diff(dateB);
      } else {
        const valA = a[value].toUpperCase(); // ignore upper and lowercase
        const valB = b[value].toUpperCase(); // ignore upper and lowercase

        if (sortType === "ASC") {
          return valA < valB ? -1 : valA > valB ? 1 : 0;
        } else {
          return valB < valA ? -1 : valB > valA ? 1 : 0;
        }
      }
    });
  }

  _generateInitialFilterValues() {
    this.filterableColumns.forEach((column) => {
      this.state.filterValues[column.field] = null;
    });
  }

  @Watch("filterableColumns", { immediate: true })
  watchFilterableColumns(columns: Array<any>) {
    if (columns.length) {
      this._generateInitialFilterValues();
    }
  }

  @Watch("rows", { immediate: true })
  watchRows(rows: Array<any>) {
    if (rows.length > 0) {
      this.state.rows = this.rows;

      // Sort by default
      if (this.defaultColumnSort) {
        this.onSortSelect(this.defaultColumnSort?.field, true);
      }
    }
  }
}
