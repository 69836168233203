import { GetterTree, Module, MutationTree } from "vuex";
import { isEmpty } from "lodash";

import { RootState } from "@/store";
import { UserInfo, UserRole } from "@/types";
import { RoleType } from "@/proto/profile/all_pb";
import * as actions from "./action";

export interface UserState {
  loading: boolean;
  error: string;
  userInfo: UserInfo;
}

const getInitialState = (): UserState => {
  return {
    loading: true,
    error: "",
    userInfo: {
      id: "",
      orgId: "",
      firstName: "",
      lastName: "",
      role: 0 as UserRole,
      email: "",
      phone: "",
      maritalStatus: "",
      education: "",
      industry: "",
      position: "",
      employerName: "",
      officeNumber: "",
      homeNumber: "",
      address: {
        line1: "",
        line2: "",
        province: "",
        city: "",
        district: "",
        zipCode: null,
      },
    },
  };
};

const initialState: UserState = getInitialState();

const getters: GetterTree<UserState, RootState> = {
  userInfoExists: (state: UserState): boolean => {
    const { userInfo } = state;
    return !isEmpty(userInfo?.firstName) || !isEmpty(userInfo?.email);
  },
  currentUserInfo: (state: UserState): UserInfo => {
    return state.userInfo;
  },
  userFullName: (state: UserState): string => {
    return state.userInfo.firstName + " " + state.userInfo.lastName;
  },
  isAdmin: (state: UserState): boolean => {
    return (
      state.userInfo.role === RoleType.BANKADMINISTRATOR ||
      state.userInfo.role === RoleType.ACCOUNTADMINISTRATOR
    );
  },
  isBankAdmin: (state: UserState): boolean => {
    return state.userInfo.role === RoleType.BANKADMINISTRATOR;
  },
  isAccountAdmin: (state: UserState): boolean => {
    return state.userInfo.role === RoleType.ACCOUNTADMINISTRATOR;
  },
  isChecker: (state: UserState): boolean => {
    return state.userInfo.role === RoleType.CHECKER;
  },
  isMaker: (state: UserState): boolean => {
    return state.userInfo.role === RoleType.MAKER;
  },
  isContentAdmin: (state: UserState): boolean => {
    return state.userInfo.role === RoleType.CONTENTADMIN;
  },
};

const mutations: MutationTree<UserState> = {
  setLoading(state: UserState, loading: boolean) {
    state.loading = loading;
  },
  setError(state: UserState, error: string) {
    state.error = error;
  },
  setUserInfo(state: UserState, payload: UserInfo) {
    state.userInfo = payload;
  },
};

const user: Module<UserState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default user;
