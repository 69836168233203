// package: billspayment
// file: brank.as/ecebuana/gunk/billing/all.proto

var brank_as_ecebuana_gunk_billing_all_pb = require("./all_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var BillsPayment = (function () {
  function BillsPayment() {}
  BillsPayment.serviceName = "billspayment.BillsPayment";
  return BillsPayment;
}());

BillsPayment.GetBillers = {
  methodName: "GetBillers",
  service: BillsPayment,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_ecebuana_gunk_billing_all_pb.GetBillersRequest,
  responseType: brank_as_ecebuana_gunk_billing_all_pb.GetBillersResponse
};

BillsPayment.GetBillInputs = {
  methodName: "GetBillInputs",
  service: BillsPayment,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_ecebuana_gunk_billing_all_pb.GetBillInputsRequest,
  responseType: brank_as_ecebuana_gunk_billing_all_pb.GetBillInputsResponse
};

BillsPayment.GetProducts = {
  methodName: "GetProducts",
  service: BillsPayment,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_ecebuana_gunk_billing_all_pb.GetProductsRequest,
  responseType: brank_as_ecebuana_gunk_billing_all_pb.GetProductsResponse
};

BillsPayment.PayBill = {
  methodName: "PayBill",
  service: BillsPayment,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_ecebuana_gunk_billing_all_pb.PayBillRequest,
  responseType: brank_as_ecebuana_gunk_billing_all_pb.PayBillResponse
};

BillsPayment.PaymentStatus = {
  methodName: "PaymentStatus",
  service: BillsPayment,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_ecebuana_gunk_billing_all_pb.PaymentStatusRequest,
  responseType: brank_as_ecebuana_gunk_billing_all_pb.PaymentStatusResponse
};

exports.BillsPayment = BillsPayment;

function BillsPaymentClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

BillsPaymentClient.prototype.getBillers = function getBillers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillsPayment.GetBillers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillsPaymentClient.prototype.getBillInputs = function getBillInputs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillsPayment.GetBillInputs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillsPaymentClient.prototype.getProducts = function getProducts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillsPayment.GetProducts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillsPaymentClient.prototype.payBill = function payBill(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillsPayment.PayBill, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillsPaymentClient.prototype.paymentStatus = function paymentStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillsPayment.PaymentStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.BillsPaymentClient = BillsPaymentClient;

