import { ActionContext } from "vuex";
import { RootState } from "@/store";
import { UserState } from "./index";
import { User, Invite } from "@/services";
import { UserInfo, InvitedUser } from "@/types";
import {
  ChangePasswordRequest,
  ConfirmUpdateRequest,
} from "@/proto/user/all_pb";

export async function createUser(
  { rootState }: ActionContext<UserState, RootState>,
  payload: InvitedUser
) {
  const { auth, config } = rootState;
  await new User(config.profileURL, auth.user.accessToken).createUser(payload);
}

export async function resendInvite(
  { rootState }: ActionContext<UserState, RootState>,
  id: string
) {
  const { auth, config } = rootState;
  await new Invite(config.profileURL, auth.user.accessToken).resendInvite(id);
}

export async function getUserInfo({
  commit,
  rootState,
}: ActionContext<UserState, RootState>) {
  const { auth, config } = rootState;

  commit("setLoading", true);
  commit("setError", "");

  try {
    const result = await new User(
      config.profileURL,
      auth.user.accessToken
    ).getUserInfo();

    commit("setUserInfo", result);
    commit("auth/setOrgId", result.orgId, { root: true });
  } catch (err) {
    commit("setError", err);
    console.error("Error when getting user profile", err.message);
  } finally {
    commit("setLoading", false);
  }
}

export async function updateUserInfo(
  { commit, rootState, state }: ActionContext<UserState, RootState>,
  payload: UserInfo
) {
  const { auth, config } = rootState;

  commit("setLoading", true);
  commit("setError", "");

  try {
    payload.id = state.userInfo.id;

    await new User(config.profileURL, auth.user.accessToken).updateUserInfo(
      payload
    );
  } catch (err) {
    commit("setError", err);
    throw new Error(err.message);
  } finally {
    commit("setLoading", false);
  }
}

export async function editUserInfo(
  { rootState }: ActionContext<UserState, RootState>,
  payload: UserInfo
) {
  const { auth, config } = rootState;

  await new User(config.profileURL, auth.user.accessToken).editUserInfo(
    payload
  );
}

export async function approveUser(
  { rootState }: ActionContext<UserState, RootState>,
  invitationID: string
) {
  const { auth, config } = rootState;

  const result = await new User(
    config.profileURL,
    auth.user.accessToken
  ).approveUser(invitationID, false);

  return result;
}

export async function rejectUser(
  { rootState }: ActionContext<UserState, RootState>,
  invitationID: string
) {
  const { auth, config } = rootState;

  const result = await new User(
    config.profileURL,
    auth.user.accessToken
  ).approveUser(invitationID, true);

  return result;
}

export async function deleteUser(
  { rootState }: ActionContext<UserState, RootState>,
  id: string
) {
  const { auth, config } = rootState;

  const result = await new User(
    config.profileURL,
    auth.user.accessToken
  ).deleteUser(id);

  return result;
}

export async function sendInvite(
  { rootState }: ActionContext<UserState, RootState>,
  invitationID: string
) {
  const { auth, config } = rootState;

  await new Invite(config.profileURL, auth.user.accessToken).sendInvite(
    invitationID
  );
}

export async function changePassword(
  { rootState }: ActionContext<UserState, RootState>,
  payload: ChangePasswordRequest.AsObject
) {
  const { auth, config } = rootState;

  const result = await new User(
    config.profileURL,
    auth.user.accessToken
  ).changePassword(payload);

  return result;
}

export async function confirmChangePassword(
  { rootState }: ActionContext<UserState, RootState>,
  payload: ConfirmUpdateRequest.AsObject
) {
  const { auth, config } = rootState;

  await new User(
    config.profileURL,
    auth.user.accessToken
  ).confirmChangePassword(payload);
}
