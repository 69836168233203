











export default {
  name: "Options",
};
