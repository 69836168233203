













import { Vue, Component } from "vue-property-decorator";
import { Sidebar, TopNav } from "@components";

@Component({
  components: {
    TopNav,
    Sidebar,
  },
})
export default class BaseLayout extends Vue {
  toggleMobile = true;

  onShow() {
    this.toggleMobile = !this.toggleMobile;
    console.log(this.toggleMobile);
  }
}
