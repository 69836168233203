/* eslint-disable */
import Vue from "vue";
import VueRouter, { RouteConfig, NavigationGuardNext } from "vue-router";

import { Login, Logout, OidcCallback } from "@/pages";
import store from "@/store";
import { BaseLayout } from "@/layouts";

Vue.use(VueRouter);

const checkRole = (userAllowed: boolean, next: NavigationGuardNext) => {
  if (userAllowed) {
    next();
  } else {
    next({ path: `/dashboard` });
  }
};

const routes: Array<RouteConfig> = [
  {
    path: "/sign-in",
    name: "sign-in",
    component: Login,
    meta: { public: true, layout: "div" },
  },
  {
    path: "/sign-out",
    name: "sign-out",
    component: Logout,
    meta: { public: true, layout: "div" },
  },
  {
    path: "/oidc-callback",
    name: "oidc-callback",
    component: OidcCallback,
    meta: { public: true, layout: "div" },
  },
  {
    path: "/merchant-registration",
    name: "merchant-registration",
    redirect: "/merchant-registration/company-information",
    component: () =>
      import("@pages/merchant-registration/MerchantRegistration.vue"),
    children: [
      {
        path: "company-information",
        name: "company-information",
        component: () =>
          import(
            "@/pages/merchant-registration/components/company-information/CompanyInformation.vue"
          ),
      },
      {
        path: "authorized-email",
        name: "authorized-email",
        component: () =>
          import(
            "@/pages/merchant-registration/components/authorized-email/AuthorizedEmail.vue"
          ),
      },
      {
        path: "delivery-information",
        name: "delivery-information",
        component: () =>
          import(
            "@/pages/merchant-registration/components/delivery-information/DeliveryInformation.vue"
          ),
      },
      {
        path: "fees-charges",
        name: "fees-charges",
        component: () =>
          import(
            "@/pages/merchant-registration/components/fees-charges/FeesCharges.vue"
          ),
      },
      {
        path: "supporting-documents",
        name: "supporting-documents",
        component: () =>
          import(
            "@/pages/merchant-registration/components/supporting-documents/SupportingDocuments.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    name: "main",
    component: BaseLayout,
    redirect: "dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@pages/dashboard/Dashboard.vue"),
      },
      {
        path: "accounts",
        name: "accounts",
        component: () => import("@pages/accounts/Accounts.vue"),
      },
      {
        path: "announcement",
        name: "announcement",
        component: () => import("@pages/announcement/list/AnnoucementList.vue"),
      },
      {
        path: "/batches",
        name: "batches",
        component: () => import("@pages/batch-management/BatchManagement.vue"),
        beforeEnter(to, from, next) {
          checkRole(store.getters["user/isChecker"], next);
        },
      },
      {
        path: "/batches/:batchID",
        name: "batch-transaction-list",
        component: () => import("@pages/batch-management/TransactionsList.vue"),
        beforeEnter(to, from, next) {
          checkRole(store.getters["user/isChecker"], next);
        },
      },
      {
        path: "account/profile",
        name: "account-profile",
        component: () => import("@pages/account/profile/AccountProfile.vue"),
      },
      {
        path: "account/security",
        name: "account-security",
        component: () => import("@pages/account/security/AccountSecurity.vue"),
      },
      {
        path: "account/organization",
        name: "account-organization",
        component: () =>
          import("@pages/account/organization/AccountOrganization.vue"),
      },
      {
        path: "account/admin",
        name: "account-admin",
        component: () =>
          import("@pages/account/admin/MarchentRegistration.vue"),
      },
      {
        path: "statement",
        name: "statement",
        component: () => import("@pages/statement/Statement.vue"),
        beforeEnter(to, from, next) {
          checkRole(store.getters["user/isChecker"], next);
        },
      },
      {
        path: "fund-transfer",
        name: "fund-transfer",
        component: () => import("@pages/fund-transfer/FundTransfer.vue"),
        beforeEnter(to, from, next) {
          checkRole(store.getters["user/isMaker"], next);
        },
      },
      {
        path: "bills-payment",
        name: "bills-payment",
        component: () => import("@pages/bills-payment/BillsPayment.vue"),
        beforeEnter(to, from, next) {
          checkRole(store.getters["user/isMaker"], next);
        },
      },
      {
        path: "bills-payment/list",
        name: "bills-payment-list",
        component: () => import("@pages/bills-payment/BillsPaymentList.vue"),
        beforeEnter(to, from, next) {
          checkRole(store.getters["user/isMaker"], next);
        },
      },
      {
        path: "collection-disburse",
        name: "collection-disburse",
        component: () =>
          import("@pages/collection-disburse/CollectionDisburse.vue"),
        beforeEnter(to, from, next) {
          checkRole(store.getters["user/isMaker"], next);
        },
      },
      {
        path: "corporate-payroll",
        name: "corporate-payroll",
        component: () =>
          import("@pages/corporate-payroll/CorporatePayroll.vue"),
        beforeEnter(to, from, next) {
          checkRole(store.getters["user/isMaker"], next);
        },
      },
      {
        path: "batch-debit",
        name: "batch-debit",
        component: () => import("@pages/batch-debit/BatchDebit.vue"),
        beforeEnter(to, from, next) {
          checkRole(store.getters["user/isMaker"], next);
        },
      },
      {
        path: "batch-credit",
        name: "batch-credit",
        component: () => import("@pages/batch-credit/BatchCredit.vue"),
        beforeEnter(to, from, next) {
          checkRole(store.getters["user/isMaker"], next);
        },
      },
      {
        path: "batch-credit/upload",
        name: "batch-credit-upload",
        component: () => import("@pages/batch-credit/UploadFileForm.vue"),
        beforeEnter(to, from, next) {
          checkRole(store.getters["user/isMaker"], next);
        },
      },
      {
        path: "favorites/payees",
        name: "payees",
        component: () => import("@pages/payees/Payees.vue"),
        beforeEnter(to, from, next) {
          checkRole(store.getters["user/isMaker"], next);
        },
      },
      {
        path: "billers",
        name: "billers",
        component: () => import("@pages/billers/Billers.vue"),
        beforeEnter(to, from, next) {
          checkRole(store.getters["user/isMaker"], next);
        },
      },
      {
        path: "merchant-registration",
        name: "merchant-registration",
        component: () =>
          import("@pages/merchant-registration/MerchantRegistration.vue"),
        beforeEnter(to, from, next) {
          checkRole(store.getters["user/isAccountAdmin"], next);
        },
      },
      {
        path: "users",
        name: "users",
        component: () => import("@pages/users/Users.vue"),
        beforeEnter(to, from, next) {
          checkRole(store.getters["user/isAdmin"], next);
        },
      },
      {
        path: "users/invite-user",
        name: "invite-user",
        component: () => import("@pages/users/InviteUser.vue"),
        beforeEnter(to, from, next) {
          checkRole(store.getters["user/isAdmin"], next);
        },
      },
      {
        path: "payroll",
        name: "payroll",
        component: () => import("@pages/payroll/Payroll.vue"),
      },
      {
        path: "settings",
        name: "settings",
        component: () => import("@pages/settings/Settings.vue"),
      },
      {
        path: "fee-management",
        name: "fee-management",
        component: () => import("@pages/fee-management/FeeManagement.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isDev = process.env.NODE_ENV === "development";
  const isValidURL = to.name !== "not-found"; // or error404
  const isPublic = to.matched.some((route) => route.meta.public);
  const isAuthenticated = store.getters["auth/isAuthenticated"];
  // Set page title
  document.title = to?.meta?.title || "Cebuana Corporate";

  // Check auth
  if (isValidURL && !isPublic && !isAuthenticated && !isDev) {
    next({
      path: "/sign-in",
      query: { redirect: to.fullPath },
    });
  } else {
    if (!store.getters["user/userInfoExists"]) {
      await store.dispatch("user/getUserInfo");
      store.dispatch("org/getAccounts");
      await store.dispatch("org/getOrgDetails");
      await store.dispatch("config/getMetadata");

      if (store.getters["user/isMaker"] || store.getters["user/isChecker"]) {
        store.dispatch("transactions/getBanks");
        store.dispatch("transactions/getSingleTransactions");
      }

      if (store.getters["user/isMaker"]) {
        store.dispatch("favorites/getFavoritesList");
      }
    }

    next();
  }
});

export default router;
