
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import { mapActions } from "vuex";

@Component({
  methods: {
    ...mapActions("auth", ["logout", "stopAuthMonitor"]),
  },
})
export default class Logout extends Vue {
  private readonly logout!: () => void;
  private readonly stopAuthMonitor!: () => void;

  created() {
    this.stopAuthMonitor();
    this.logout();
  }
}
