



































import Vue from "vue";

export default Vue.extend({
  name: "CustomToggleButton",

  props: {
    value: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    type: {
      type: String,
      default: "checkbox",
    },
  },

  computed: {
    getId(): string {
      return this.id;
    },
  },
});
