import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { ProtobufMessage } from "@improbable-eng/grpc-web/dist/typings/message";

import { AccountService as Service } from "@/proto/accounts/all_pb_service";
import * as ServiceProto from "@/proto/accounts/all_pb";

import { OrgAccount } from "@/types";
import AuthUtils from "@/utils/auth-utils";

export default class AccountsService {
  constructor(private baseURL: string, private token: string) {}

  private genericErrorMessage =
    "Connection to the network failed. Please contact our support team support@cebuanalhullier.com.";

  public async getAccounts() {
    const request = new ServiceProto.GetAccountsRequest();

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.GetAccounts, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      throw new Error(`unexpected status code=${status} msg=${statusMessage}`);
    } else if (message === null) {
      throw new Error("received no data");
    } else {
      const decoded = message as ServiceProto.GetAccountsResponse;
      const accounts: OrgAccount[] = decoded.getResultList().map((account) => {
        const orgAccount: OrgAccount = {
          accountName: account.getOwnername(),
          accountNumber: account.getAccountid(),
          availableBalance: parseInt(
            account.getAvailablebalance()?.getNum() as string
          ),
          currentBalance: parseInt(
            account.getCurrentbalance()?.getNum() as string
          ),
          fundsInClearing: parseInt(
            account.getHoldbalance()?.getNum() as string
          ),
          type: "savings",
        };

        return orgAccount;
      });

      return accounts;
    }
  }
}
