







export default {
  name: "ArrowLeft",
};
