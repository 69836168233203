// source: all.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var github_com_openbank_openbank_v1_types_all_pb = require('./../types/all_pb.js');
goog.object.extend(proto, github_com_openbank_openbank_v1_types_all_pb);
goog.exportSymbol('proto.transactions.ApprovePaymentRequest', null, global);
goog.exportSymbol('proto.transactions.ApprovePaymentResponse', null, global);
goog.exportSymbol('proto.transactions.BankAccountInfo', null, global);
goog.exportSymbol('proto.transactions.CreateTransactionRequest', null, global);
goog.exportSymbol('proto.transactions.CreateTransactionResponse', null, global);
goog.exportSymbol('proto.transactions.GetTransactionRequest', null, global);
goog.exportSymbol('proto.transactions.GetTransactionsByAccountRequest', null, global);
goog.exportSymbol('proto.transactions.GetTransactionsByAccountResponse', null, global);
goog.exportSymbol('proto.transactions.GetTransactionsRequest', null, global);
goog.exportSymbol('proto.transactions.GetTransactionsResponse', null, global);
goog.exportSymbol('proto.transactions.ResendTFARequest', null, global);
goog.exportSymbol('proto.transactions.ResendTFAResponse', null, global);
goog.exportSymbol('proto.transactions.Status', null, global);
goog.exportSymbol('proto.transactions.TFARequest', null, global);
goog.exportSymbol('proto.transactions.TFAResponse', null, global);
goog.exportSymbol('proto.transactions.TFAType', null, global);
goog.exportSymbol('proto.transactions.Transaction', null, global);
goog.exportSymbol('proto.transactions.Type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transactions.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transactions.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transactions.Transaction.displayName = 'proto.transactions.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transactions.BankAccountInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transactions.BankAccountInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transactions.BankAccountInfo.displayName = 'proto.transactions.BankAccountInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transactions.GetTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transactions.GetTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transactions.GetTransactionRequest.displayName = 'proto.transactions.GetTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transactions.GetTransactionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transactions.GetTransactionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transactions.GetTransactionsRequest.displayName = 'proto.transactions.GetTransactionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transactions.GetTransactionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.transactions.GetTransactionsResponse.repeatedFields_, null);
};
goog.inherits(proto.transactions.GetTransactionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transactions.GetTransactionsResponse.displayName = 'proto.transactions.GetTransactionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transactions.GetTransactionsByAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transactions.GetTransactionsByAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transactions.GetTransactionsByAccountRequest.displayName = 'proto.transactions.GetTransactionsByAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transactions.GetTransactionsByAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.transactions.GetTransactionsByAccountResponse.repeatedFields_, null);
};
goog.inherits(proto.transactions.GetTransactionsByAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transactions.GetTransactionsByAccountResponse.displayName = 'proto.transactions.GetTransactionsByAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transactions.CreateTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transactions.CreateTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transactions.CreateTransactionRequest.displayName = 'proto.transactions.CreateTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transactions.CreateTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transactions.CreateTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transactions.CreateTransactionResponse.displayName = 'proto.transactions.CreateTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transactions.ApprovePaymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transactions.ApprovePaymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transactions.ApprovePaymentRequest.displayName = 'proto.transactions.ApprovePaymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transactions.ApprovePaymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transactions.ApprovePaymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transactions.ApprovePaymentResponse.displayName = 'proto.transactions.ApprovePaymentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transactions.TFARequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transactions.TFARequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transactions.TFARequest.displayName = 'proto.transactions.TFARequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transactions.TFAResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transactions.TFAResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transactions.TFAResponse.displayName = 'proto.transactions.TFAResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transactions.ResendTFARequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transactions.ResendTFARequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transactions.ResendTFARequest.displayName = 'proto.transactions.ResendTFARequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transactions.ResendTFAResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transactions.ResendTFAResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transactions.ResendTFAResponse.displayName = 'proto.transactions.ResendTFAResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transactions.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.transactions.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transactions.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sourceaccount: (f = msg.getSourceaccount()) && proto.transactions.BankAccountInfo.toObject(includeInstance, f),
    sourceofflineuser: (f = msg.getSourceofflineuser()) && github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo.toObject(includeInstance, f),
    destinationaccount: (f = msg.getDestinationaccount()) && proto.transactions.BankAccountInfo.toObject(includeInstance, f),
    destinationofflineuser: (f = msg.getDestinationofflineuser()) && github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo.toObject(includeInstance, f),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    amount: (f = msg.getAmount()) && github_com_openbank_openbank_v1_types_all_pb.Amount.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 8, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    remarks: jspb.Message.getFieldWithDefault(msg, 10, ""),
    settlementrail: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transactions.Transaction}
 */
proto.transactions.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transactions.Transaction;
  return proto.transactions.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transactions.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transactions.Transaction}
 */
proto.transactions.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    case 2:
      var value = new proto.transactions.BankAccountInfo;
      reader.readMessage(value,proto.transactions.BankAccountInfo.deserializeBinaryFromReader);
      msg.setSourceaccount(value);
      break;
    case 11:
      var value = new github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo;
      reader.readMessage(value,github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo.deserializeBinaryFromReader);
      msg.setSourceofflineuser(value);
      break;
    case 3:
      var value = new proto.transactions.BankAccountInfo;
      reader.readMessage(value,proto.transactions.BankAccountInfo.deserializeBinaryFromReader);
      msg.setDestinationaccount(value);
      break;
    case 12:
      var value = new github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo;
      reader.readMessage(value,github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo.deserializeBinaryFromReader);
      msg.setDestinationofflineuser(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 5:
      var value = /** @type {!proto.transactions.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {!proto.transactions.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = new github_com_openbank_openbank_v1_types_all_pb.Amount;
      reader.readMessage(value,github_com_openbank_openbank_v1_types_all_pb.Amount.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementrail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transactions.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transactions.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transactions.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSourceaccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.transactions.BankAccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getSourceofflineuser();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getDestinationaccount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.transactions.BankAccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getDestinationofflineuser();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      github_com_openbank_openbank_v1_types_all_pb.Amount.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSettlementrail();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string TransactionID = 1;
 * @return {string}
 */
proto.transactions.Transaction.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.transactions.Transaction.prototype.setTransactionid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BankAccountInfo SourceAccount = 2;
 * @return {?proto.transactions.BankAccountInfo}
 */
proto.transactions.Transaction.prototype.getSourceaccount = function() {
  return /** @type{?proto.transactions.BankAccountInfo} */ (
    jspb.Message.getWrapperField(this, proto.transactions.BankAccountInfo, 2));
};


/** @param {?proto.transactions.BankAccountInfo|undefined} value */
proto.transactions.Transaction.prototype.setSourceaccount = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.transactions.Transaction.prototype.clearSourceaccount = function() {
  this.setSourceaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transactions.Transaction.prototype.hasSourceaccount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional types.OfflineUserInfo SourceOfflineUser = 11;
 * @return {?proto.types.OfflineUserInfo}
 */
proto.transactions.Transaction.prototype.getSourceofflineuser = function() {
  return /** @type{?proto.types.OfflineUserInfo} */ (
    jspb.Message.getWrapperField(this, github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo, 11));
};


/** @param {?proto.types.OfflineUserInfo|undefined} value */
proto.transactions.Transaction.prototype.setSourceofflineuser = function(value) {
  jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.transactions.Transaction.prototype.clearSourceofflineuser = function() {
  this.setSourceofflineuser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transactions.Transaction.prototype.hasSourceofflineuser = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional BankAccountInfo DestinationAccount = 3;
 * @return {?proto.transactions.BankAccountInfo}
 */
proto.transactions.Transaction.prototype.getDestinationaccount = function() {
  return /** @type{?proto.transactions.BankAccountInfo} */ (
    jspb.Message.getWrapperField(this, proto.transactions.BankAccountInfo, 3));
};


/** @param {?proto.transactions.BankAccountInfo|undefined} value */
proto.transactions.Transaction.prototype.setDestinationaccount = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.transactions.Transaction.prototype.clearDestinationaccount = function() {
  this.setDestinationaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transactions.Transaction.prototype.hasDestinationaccount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional types.OfflineUserInfo DestinationOfflineUser = 12;
 * @return {?proto.types.OfflineUserInfo}
 */
proto.transactions.Transaction.prototype.getDestinationofflineuser = function() {
  return /** @type{?proto.types.OfflineUserInfo} */ (
    jspb.Message.getWrapperField(this, github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo, 12));
};


/** @param {?proto.types.OfflineUserInfo|undefined} value */
proto.transactions.Transaction.prototype.setDestinationofflineuser = function(value) {
  jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.transactions.Transaction.prototype.clearDestinationofflineuser = function() {
  this.setDestinationofflineuser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transactions.Transaction.prototype.hasDestinationofflineuser = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp Date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.transactions.Transaction.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.transactions.Transaction.prototype.setDate = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.transactions.Transaction.prototype.clearDate = function() {
  this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transactions.Transaction.prototype.hasDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Type Type = 5;
 * @return {!proto.transactions.Type}
 */
proto.transactions.Transaction.prototype.getType = function() {
  return /** @type {!proto.transactions.Type} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.transactions.Type} value */
proto.transactions.Transaction.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional Status Status = 6;
 * @return {!proto.transactions.Status}
 */
proto.transactions.Transaction.prototype.getStatus = function() {
  return /** @type {!proto.transactions.Status} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.transactions.Status} value */
proto.transactions.Transaction.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional types.Amount Amount = 7;
 * @return {?proto.types.Amount}
 */
proto.transactions.Transaction.prototype.getAmount = function() {
  return /** @type{?proto.types.Amount} */ (
    jspb.Message.getWrapperField(this, github_com_openbank_openbank_v1_types_all_pb.Amount, 7));
};


/** @param {?proto.types.Amount|undefined} value */
proto.transactions.Transaction.prototype.setAmount = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.transactions.Transaction.prototype.clearAmount = function() {
  this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transactions.Transaction.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string Description = 8;
 * @return {string}
 */
proto.transactions.Transaction.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.transactions.Transaction.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string UserID = 9;
 * @return {string}
 */
proto.transactions.Transaction.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.transactions.Transaction.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string Remarks = 10;
 * @return {string}
 */
proto.transactions.Transaction.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.transactions.Transaction.prototype.setRemarks = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string SettlementRail = 13;
 * @return {string}
 */
proto.transactions.Transaction.prototype.getSettlementrail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.transactions.Transaction.prototype.setSettlementrail = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transactions.BankAccountInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.transactions.BankAccountInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transactions.BankAccountInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.BankAccountInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bankcode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ownername: jspb.Message.getFieldWithDefault(msg, 3, ""),
    majortype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transactions.BankAccountInfo}
 */
proto.transactions.BankAccountInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transactions.BankAccountInfo;
  return proto.transactions.BankAccountInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transactions.BankAccountInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transactions.BankAccountInfo}
 */
proto.transactions.BankAccountInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankcode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnername(value);
      break;
    case 4:
      var value = /** @type {!proto.types.MajorType} */ (reader.readEnum());
      msg.setMajortype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transactions.BankAccountInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transactions.BankAccountInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transactions.BankAccountInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.BankAccountInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBankcode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwnername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMajortype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string AccountID = 1;
 * @return {string}
 */
proto.transactions.BankAccountInfo.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.transactions.BankAccountInfo.prototype.setAccountid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string BankCode = 2;
 * @return {string}
 */
proto.transactions.BankAccountInfo.prototype.getBankcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.transactions.BankAccountInfo.prototype.setBankcode = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string OwnerName = 3;
 * @return {string}
 */
proto.transactions.BankAccountInfo.prototype.getOwnername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.transactions.BankAccountInfo.prototype.setOwnername = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional types.MajorType MajorType = 4;
 * @return {!proto.types.MajorType}
 */
proto.transactions.BankAccountInfo.prototype.getMajortype = function() {
  return /** @type {!proto.types.MajorType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.types.MajorType} value */
proto.transactions.BankAccountInfo.prototype.setMajortype = function(value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transactions.GetTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transactions.GetTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transactions.GetTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.GetTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transactions.GetTransactionRequest}
 */
proto.transactions.GetTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transactions.GetTransactionRequest;
  return proto.transactions.GetTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transactions.GetTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transactions.GetTransactionRequest}
 */
proto.transactions.GetTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transactions.GetTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transactions.GetTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transactions.GetTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.GetTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string TransactionID = 1;
 * @return {string}
 */
proto.transactions.GetTransactionRequest.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.transactions.GetTransactionRequest.prototype.setTransactionid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transactions.GetTransactionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transactions.GetTransactionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transactions.GetTransactionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.GetTransactionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromdate: (f = msg.getFromdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    todate: (f = msg.getTodate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    transactiontype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 4, ""),
    nextstartingindex: jspb.Message.getFieldWithDefault(msg, 5, ""),
    afterid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pagelimit: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transactions.GetTransactionsRequest}
 */
proto.transactions.GetTransactionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transactions.GetTransactionsRequest;
  return proto.transactions.GetTransactionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transactions.GetTransactionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transactions.GetTransactionsRequest}
 */
proto.transactions.GetTransactionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFromdate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTodate(value);
      break;
    case 3:
      var value = /** @type {!proto.transactions.Type} */ (reader.readEnum());
      msg.setTransactiontype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextstartingindex(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAfterid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagelimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transactions.GetTransactionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transactions.GetTransactionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transactions.GetTransactionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.GetTransactionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTodate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTransactiontype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNextstartingindex();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAfterid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPagelimit();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp FromDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.transactions.GetTransactionsRequest.prototype.getFromdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.transactions.GetTransactionsRequest.prototype.setFromdate = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.transactions.GetTransactionsRequest.prototype.clearFromdate = function() {
  this.setFromdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transactions.GetTransactionsRequest.prototype.hasFromdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp ToDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.transactions.GetTransactionsRequest.prototype.getTodate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.transactions.GetTransactionsRequest.prototype.setTodate = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.transactions.GetTransactionsRequest.prototype.clearTodate = function() {
  this.setTodate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transactions.GetTransactionsRequest.prototype.hasTodate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Type TransactionType = 3;
 * @return {!proto.transactions.Type}
 */
proto.transactions.GetTransactionsRequest.prototype.getTransactiontype = function() {
  return /** @type {!proto.transactions.Type} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.transactions.Type} value */
proto.transactions.GetTransactionsRequest.prototype.setTransactiontype = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string Limit = 4;
 * @return {string}
 */
proto.transactions.GetTransactionsRequest.prototype.getLimit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.transactions.GetTransactionsRequest.prototype.setLimit = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string NextStartingIndex = 5;
 * @return {string}
 */
proto.transactions.GetTransactionsRequest.prototype.getNextstartingindex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.transactions.GetTransactionsRequest.prototype.setNextstartingindex = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string AfterID = 6;
 * @return {string}
 */
proto.transactions.GetTransactionsRequest.prototype.getAfterid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.transactions.GetTransactionsRequest.prototype.setAfterid = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 PageLimit = 7;
 * @return {number}
 */
proto.transactions.GetTransactionsRequest.prototype.getPagelimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.transactions.GetTransactionsRequest.prototype.setPagelimit = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.transactions.GetTransactionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transactions.GetTransactionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transactions.GetTransactionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transactions.GetTransactionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.GetTransactionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.transactions.Transaction.toObject, includeInstance),
    hasmore: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    lastrunningbalance: (f = msg.getLastrunningbalance()) && github_com_openbank_openbank_v1_types_all_pb.Amount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transactions.GetTransactionsResponse}
 */
proto.transactions.GetTransactionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transactions.GetTransactionsResponse;
  return proto.transactions.GetTransactionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transactions.GetTransactionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transactions.GetTransactionsResponse}
 */
proto.transactions.GetTransactionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.transactions.Transaction;
      reader.readMessage(value,proto.transactions.Transaction.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasmore(value);
      break;
    case 3:
      var value = new github_com_openbank_openbank_v1_types_all_pb.Amount;
      reader.readMessage(value,github_com_openbank_openbank_v1_types_all_pb.Amount.deserializeBinaryFromReader);
      msg.setLastrunningbalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transactions.GetTransactionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transactions.GetTransactionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transactions.GetTransactionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.GetTransactionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.transactions.Transaction.serializeBinaryToWriter
    );
  }
  f = message.getHasmore();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getLastrunningbalance();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      github_com_openbank_openbank_v1_types_all_pb.Amount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Transaction Result = 1;
 * @return {!Array<!proto.transactions.Transaction>}
 */
proto.transactions.GetTransactionsResponse.prototype.getResultList = function() {
  return /** @type{!Array<!proto.transactions.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.transactions.Transaction, 1));
};


/** @param {!Array<!proto.transactions.Transaction>} value */
proto.transactions.GetTransactionsResponse.prototype.setResultList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.transactions.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.transactions.Transaction}
 */
proto.transactions.GetTransactionsResponse.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.transactions.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.transactions.GetTransactionsResponse.prototype.clearResultList = function() {
  this.setResultList([]);
};


/**
 * optional bool HasMore = 2;
 * @return {boolean}
 */
proto.transactions.GetTransactionsResponse.prototype.getHasmore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.transactions.GetTransactionsResponse.prototype.setHasmore = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional types.Amount LastRunningBalance = 3;
 * @return {?proto.types.Amount}
 */
proto.transactions.GetTransactionsResponse.prototype.getLastrunningbalance = function() {
  return /** @type{?proto.types.Amount} */ (
    jspb.Message.getWrapperField(this, github_com_openbank_openbank_v1_types_all_pb.Amount, 3));
};


/** @param {?proto.types.Amount|undefined} value */
proto.transactions.GetTransactionsResponse.prototype.setLastrunningbalance = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.transactions.GetTransactionsResponse.prototype.clearLastrunningbalance = function() {
  this.setLastrunningbalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transactions.GetTransactionsResponse.prototype.hasLastrunningbalance = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transactions.GetTransactionsByAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transactions.GetTransactionsByAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transactions.GetTransactionsByAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.GetTransactionsByAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromdate: (f = msg.getFromdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    todate: (f = msg.getTodate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    transactiontype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    afterid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transactions.GetTransactionsByAccountRequest}
 */
proto.transactions.GetTransactionsByAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transactions.GetTransactionsByAccountRequest;
  return proto.transactions.GetTransactionsByAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transactions.GetTransactionsByAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transactions.GetTransactionsByAccountRequest}
 */
proto.transactions.GetTransactionsByAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFromdate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTodate(value);
      break;
    case 4:
      var value = /** @type {!proto.transactions.Type} */ (reader.readEnum());
      msg.setTransactiontype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAfterid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transactions.GetTransactionsByAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transactions.GetTransactionsByAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transactions.GetTransactionsByAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.GetTransactionsByAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTodate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTransactiontype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAfterid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string AccountID = 1;
 * @return {string}
 */
proto.transactions.GetTransactionsByAccountRequest.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.transactions.GetTransactionsByAccountRequest.prototype.setAccountid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp FromDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.transactions.GetTransactionsByAccountRequest.prototype.getFromdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.transactions.GetTransactionsByAccountRequest.prototype.setFromdate = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.transactions.GetTransactionsByAccountRequest.prototype.clearFromdate = function() {
  this.setFromdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transactions.GetTransactionsByAccountRequest.prototype.hasFromdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp ToDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.transactions.GetTransactionsByAccountRequest.prototype.getTodate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.transactions.GetTransactionsByAccountRequest.prototype.setTodate = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.transactions.GetTransactionsByAccountRequest.prototype.clearTodate = function() {
  this.setTodate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transactions.GetTransactionsByAccountRequest.prototype.hasTodate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Type TransactionType = 4;
 * @return {!proto.transactions.Type}
 */
proto.transactions.GetTransactionsByAccountRequest.prototype.getTransactiontype = function() {
  return /** @type {!proto.transactions.Type} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.transactions.Type} value */
proto.transactions.GetTransactionsByAccountRequest.prototype.setTransactiontype = function(value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string AfterID = 5;
 * @return {string}
 */
proto.transactions.GetTransactionsByAccountRequest.prototype.getAfterid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.transactions.GetTransactionsByAccountRequest.prototype.setAfterid = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 Limit = 6;
 * @return {number}
 */
proto.transactions.GetTransactionsByAccountRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.transactions.GetTransactionsByAccountRequest.prototype.setLimit = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.transactions.GetTransactionsByAccountResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transactions.GetTransactionsByAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transactions.GetTransactionsByAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transactions.GetTransactionsByAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.GetTransactionsByAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.transactions.Transaction.toObject, includeInstance),
    lastrunningbalance: (f = msg.getLastrunningbalance()) && github_com_openbank_openbank_v1_types_all_pb.Amount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transactions.GetTransactionsByAccountResponse}
 */
proto.transactions.GetTransactionsByAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transactions.GetTransactionsByAccountResponse;
  return proto.transactions.GetTransactionsByAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transactions.GetTransactionsByAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transactions.GetTransactionsByAccountResponse}
 */
proto.transactions.GetTransactionsByAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.transactions.Transaction;
      reader.readMessage(value,proto.transactions.Transaction.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    case 2:
      var value = new github_com_openbank_openbank_v1_types_all_pb.Amount;
      reader.readMessage(value,github_com_openbank_openbank_v1_types_all_pb.Amount.deserializeBinaryFromReader);
      msg.setLastrunningbalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transactions.GetTransactionsByAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transactions.GetTransactionsByAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transactions.GetTransactionsByAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.GetTransactionsByAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.transactions.Transaction.serializeBinaryToWriter
    );
  }
  f = message.getLastrunningbalance();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      github_com_openbank_openbank_v1_types_all_pb.Amount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Transaction Result = 1;
 * @return {!Array<!proto.transactions.Transaction>}
 */
proto.transactions.GetTransactionsByAccountResponse.prototype.getResultList = function() {
  return /** @type{!Array<!proto.transactions.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.transactions.Transaction, 1));
};


/** @param {!Array<!proto.transactions.Transaction>} value */
proto.transactions.GetTransactionsByAccountResponse.prototype.setResultList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.transactions.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.transactions.Transaction}
 */
proto.transactions.GetTransactionsByAccountResponse.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.transactions.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.transactions.GetTransactionsByAccountResponse.prototype.clearResultList = function() {
  this.setResultList([]);
};


/**
 * optional types.Amount LastRunningBalance = 2;
 * @return {?proto.types.Amount}
 */
proto.transactions.GetTransactionsByAccountResponse.prototype.getLastrunningbalance = function() {
  return /** @type{?proto.types.Amount} */ (
    jspb.Message.getWrapperField(this, github_com_openbank_openbank_v1_types_all_pb.Amount, 2));
};


/** @param {?proto.types.Amount|undefined} value */
proto.transactions.GetTransactionsByAccountResponse.prototype.setLastrunningbalance = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.transactions.GetTransactionsByAccountResponse.prototype.clearLastrunningbalance = function() {
  this.setLastrunningbalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transactions.GetTransactionsByAccountResponse.prototype.hasLastrunningbalance = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transactions.CreateTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transactions.CreateTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transactions.CreateTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.CreateTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceaccountid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sourceofflineuser: (f = msg.getSourceofflineuser()) && github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo.toObject(includeInstance, f),
    destinationaccount: (f = msg.getDestinationaccount()) && proto.transactions.BankAccountInfo.toObject(includeInstance, f),
    destinationofflineuser: (f = msg.getDestinationofflineuser()) && github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo.toObject(includeInstance, f),
    amount: (f = msg.getAmount()) && github_com_openbank_openbank_v1_types_all_pb.Amount.toObject(includeInstance, f),
    remarks: jspb.Message.getFieldWithDefault(msg, 4, ""),
    transactionid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    settlementrail: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transactions.CreateTransactionRequest}
 */
proto.transactions.CreateTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transactions.CreateTransactionRequest;
  return proto.transactions.CreateTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transactions.CreateTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transactions.CreateTransactionRequest}
 */
proto.transactions.CreateTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceaccountid(value);
      break;
    case 5:
      var value = new github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo;
      reader.readMessage(value,github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo.deserializeBinaryFromReader);
      msg.setSourceofflineuser(value);
      break;
    case 2:
      var value = new proto.transactions.BankAccountInfo;
      reader.readMessage(value,proto.transactions.BankAccountInfo.deserializeBinaryFromReader);
      msg.setDestinationaccount(value);
      break;
    case 6:
      var value = new github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo;
      reader.readMessage(value,github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo.deserializeBinaryFromReader);
      msg.setDestinationofflineuser(value);
      break;
    case 3:
      var value = new github_com_openbank_openbank_v1_types_all_pb.Amount;
      reader.readMessage(value,github_com_openbank_openbank_v1_types_all_pb.Amount.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementrail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transactions.CreateTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transactions.CreateTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transactions.CreateTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.CreateTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceaccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSourceofflineuser();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getDestinationaccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.transactions.BankAccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getDestinationofflineuser();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      github_com_openbank_openbank_v1_types_all_pb.Amount.serializeBinaryToWriter
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSettlementrail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string SourceAccountID = 1;
 * @return {string}
 */
proto.transactions.CreateTransactionRequest.prototype.getSourceaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.transactions.CreateTransactionRequest.prototype.setSourceaccountid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional types.OfflineUserInfo SourceOfflineUser = 5;
 * @return {?proto.types.OfflineUserInfo}
 */
proto.transactions.CreateTransactionRequest.prototype.getSourceofflineuser = function() {
  return /** @type{?proto.types.OfflineUserInfo} */ (
    jspb.Message.getWrapperField(this, github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo, 5));
};


/** @param {?proto.types.OfflineUserInfo|undefined} value */
proto.transactions.CreateTransactionRequest.prototype.setSourceofflineuser = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.transactions.CreateTransactionRequest.prototype.clearSourceofflineuser = function() {
  this.setSourceofflineuser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transactions.CreateTransactionRequest.prototype.hasSourceofflineuser = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional BankAccountInfo DestinationAccount = 2;
 * @return {?proto.transactions.BankAccountInfo}
 */
proto.transactions.CreateTransactionRequest.prototype.getDestinationaccount = function() {
  return /** @type{?proto.transactions.BankAccountInfo} */ (
    jspb.Message.getWrapperField(this, proto.transactions.BankAccountInfo, 2));
};


/** @param {?proto.transactions.BankAccountInfo|undefined} value */
proto.transactions.CreateTransactionRequest.prototype.setDestinationaccount = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.transactions.CreateTransactionRequest.prototype.clearDestinationaccount = function() {
  this.setDestinationaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transactions.CreateTransactionRequest.prototype.hasDestinationaccount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional types.OfflineUserInfo DestinationOfflineUser = 6;
 * @return {?proto.types.OfflineUserInfo}
 */
proto.transactions.CreateTransactionRequest.prototype.getDestinationofflineuser = function() {
  return /** @type{?proto.types.OfflineUserInfo} */ (
    jspb.Message.getWrapperField(this, github_com_openbank_openbank_v1_types_all_pb.OfflineUserInfo, 6));
};


/** @param {?proto.types.OfflineUserInfo|undefined} value */
proto.transactions.CreateTransactionRequest.prototype.setDestinationofflineuser = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.transactions.CreateTransactionRequest.prototype.clearDestinationofflineuser = function() {
  this.setDestinationofflineuser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transactions.CreateTransactionRequest.prototype.hasDestinationofflineuser = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional types.Amount Amount = 3;
 * @return {?proto.types.Amount}
 */
proto.transactions.CreateTransactionRequest.prototype.getAmount = function() {
  return /** @type{?proto.types.Amount} */ (
    jspb.Message.getWrapperField(this, github_com_openbank_openbank_v1_types_all_pb.Amount, 3));
};


/** @param {?proto.types.Amount|undefined} value */
proto.transactions.CreateTransactionRequest.prototype.setAmount = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.transactions.CreateTransactionRequest.prototype.clearAmount = function() {
  this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transactions.CreateTransactionRequest.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string Remarks = 4;
 * @return {string}
 */
proto.transactions.CreateTransactionRequest.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.transactions.CreateTransactionRequest.prototype.setRemarks = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string TransactionID = 7;
 * @return {string}
 */
proto.transactions.CreateTransactionRequest.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.transactions.CreateTransactionRequest.prototype.setTransactionid = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string SettlementRail = 8;
 * @return {string}
 */
proto.transactions.CreateTransactionRequest.prototype.getSettlementrail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.transactions.CreateTransactionRequest.prototype.setSettlementrail = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transactions.CreateTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transactions.CreateTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transactions.CreateTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.CreateTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cratedat: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transactions.CreateTransactionResponse}
 */
proto.transactions.CreateTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transactions.CreateTransactionResponse;
  return proto.transactions.CreateTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transactions.CreateTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transactions.CreateTransactionResponse}
 */
proto.transactions.CreateTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCratedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transactions.CreateTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transactions.CreateTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transactions.CreateTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.CreateTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCratedat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string TransactionID = 1;
 * @return {string}
 */
proto.transactions.CreateTransactionResponse.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.transactions.CreateTransactionResponse.prototype.setTransactionid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string CratedAt = 2;
 * @return {string}
 */
proto.transactions.CreateTransactionResponse.prototype.getCratedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.transactions.CreateTransactionResponse.prototype.setCratedat = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transactions.ApprovePaymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transactions.ApprovePaymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transactions.ApprovePaymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.ApprovePaymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    approve: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    tfatype: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transactions.ApprovePaymentRequest}
 */
proto.transactions.ApprovePaymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transactions.ApprovePaymentRequest;
  return proto.transactions.ApprovePaymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transactions.ApprovePaymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transactions.ApprovePaymentRequest}
 */
proto.transactions.ApprovePaymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApprove(value);
      break;
    case 3:
      var value = /** @type {!proto.transactions.TFAType} */ (reader.readEnum());
      msg.setTfatype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transactions.ApprovePaymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transactions.ApprovePaymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transactions.ApprovePaymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.ApprovePaymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApprove();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTfatype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string TransactionID = 1;
 * @return {string}
 */
proto.transactions.ApprovePaymentRequest.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.transactions.ApprovePaymentRequest.prototype.setTransactionid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool Approve = 2;
 * @return {boolean}
 */
proto.transactions.ApprovePaymentRequest.prototype.getApprove = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.transactions.ApprovePaymentRequest.prototype.setApprove = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional TFAType TFAType = 3;
 * @return {!proto.transactions.TFAType}
 */
proto.transactions.ApprovePaymentRequest.prototype.getTfatype = function() {
  return /** @type {!proto.transactions.TFAType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.transactions.TFAType} value */
proto.transactions.ApprovePaymentRequest.prototype.setTfatype = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transactions.ApprovePaymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transactions.ApprovePaymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transactions.ApprovePaymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.ApprovePaymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authorizationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    smscode: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transactions.ApprovePaymentResponse}
 */
proto.transactions.ApprovePaymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transactions.ApprovePaymentResponse;
  return proto.transactions.ApprovePaymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transactions.ApprovePaymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transactions.ApprovePaymentResponse}
 */
proto.transactions.ApprovePaymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorizationid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSmscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transactions.ApprovePaymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transactions.ApprovePaymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transactions.ApprovePaymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.ApprovePaymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthorizationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSmscode();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string AuthorizationID = 1;
 * @return {string}
 */
proto.transactions.ApprovePaymentResponse.prototype.getAuthorizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.transactions.ApprovePaymentResponse.prototype.setAuthorizationid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 SMSCode = 2;
 * @return {number}
 */
proto.transactions.ApprovePaymentResponse.prototype.getSmscode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.transactions.ApprovePaymentResponse.prototype.setSmscode = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transactions.TFARequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transactions.TFARequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transactions.TFARequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.TFARequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    authorizationid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tfatype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transactions.TFARequest}
 */
proto.transactions.TFARequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transactions.TFARequest;
  return proto.transactions.TFARequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transactions.TFARequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transactions.TFARequest}
 */
proto.transactions.TFARequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorizationid(value);
      break;
    case 4:
      var value = /** @type {!proto.transactions.TFAType} */ (reader.readEnum());
      msg.setTfatype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transactions.TFARequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transactions.TFARequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transactions.TFARequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.TFARequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuthorizationid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTfatype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string TransactionID = 1;
 * @return {string}
 */
proto.transactions.TFARequest.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.transactions.TFARequest.prototype.setTransactionid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Code = 2;
 * @return {string}
 */
proto.transactions.TFARequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.transactions.TFARequest.prototype.setCode = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string AuthorizationID = 3;
 * @return {string}
 */
proto.transactions.TFARequest.prototype.getAuthorizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.transactions.TFARequest.prototype.setAuthorizationid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional TFAType TFAType = 4;
 * @return {!proto.transactions.TFAType}
 */
proto.transactions.TFARequest.prototype.getTfatype = function() {
  return /** @type {!proto.transactions.TFAType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.transactions.TFAType} value */
proto.transactions.TFARequest.prototype.setTfatype = function(value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transactions.TFAResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transactions.TFAResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transactions.TFAResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.TFAResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tracenumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transactions.TFAResponse}
 */
proto.transactions.TFAResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transactions.TFAResponse;
  return proto.transactions.TFAResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transactions.TFAResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transactions.TFAResponse}
 */
proto.transactions.TFAResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTracenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transactions.TFAResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transactions.TFAResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transactions.TFAResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.TFAResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTracenumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string TraceNumber = 1;
 * @return {string}
 */
proto.transactions.TFAResponse.prototype.getTracenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.transactions.TFAResponse.prototype.setTracenumber = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transactions.ResendTFARequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transactions.ResendTFARequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transactions.ResendTFARequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.ResendTFARequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tfatype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transactions.ResendTFARequest}
 */
proto.transactions.ResendTFARequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transactions.ResendTFARequest;
  return proto.transactions.ResendTFARequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transactions.ResendTFARequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transactions.ResendTFARequest}
 */
proto.transactions.ResendTFARequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    case 2:
      var value = /** @type {!proto.transactions.TFAType} */ (reader.readEnum());
      msg.setTfatype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transactions.ResendTFARequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transactions.ResendTFARequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transactions.ResendTFARequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.ResendTFARequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTfatype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string TransactionID = 1;
 * @return {string}
 */
proto.transactions.ResendTFARequest.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.transactions.ResendTFARequest.prototype.setTransactionid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TFAType TFAType = 2;
 * @return {!proto.transactions.TFAType}
 */
proto.transactions.ResendTFARequest.prototype.getTfatype = function() {
  return /** @type {!proto.transactions.TFAType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.transactions.TFAType} value */
proto.transactions.ResendTFARequest.prototype.setTfatype = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transactions.ResendTFAResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transactions.ResendTFAResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transactions.ResendTFAResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.ResendTFAResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transactions.ResendTFAResponse}
 */
proto.transactions.ResendTFAResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transactions.ResendTFAResponse;
  return proto.transactions.ResendTFAResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transactions.ResendTFAResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transactions.ResendTFAResponse}
 */
proto.transactions.ResendTFAResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transactions.ResendTFAResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transactions.ResendTFAResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transactions.ResendTFAResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transactions.ResendTFAResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.transactions.Type = {
  UNKNOWNTYPE: 0,
  CREDIT: 1,
  DEBIT: 2
};

/**
 * @enum {number}
 */
proto.transactions.Status = {
  UNKNOWNSTATUS: 0,
  SUCCESS: 1,
  PENDING: 2,
  REJECTED: 3
};

/**
 * @enum {number}
 */
proto.transactions.TFAType = {
  _: 0,
  SMS: 1,
  SAFEKEY: 2
};

goog.object.extend(exports, proto.transactions);
