import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { ProtobufMessage } from "@improbable-eng/grpc-web/dist/typings/message";

import { TransactionService as Service } from "@/proto/transactions/all_pb_service";
import * as ServiceProto from "@/proto/transactions/all_pb";
import { BatchTransactionService as BatchService } from "@/proto/batch/all_pb_service";
import * as BatchServiceProto from "@/proto/batch/all_pb";
import { Amount, OfflineUserInfo, Address } from "@/proto/types/all_pb";

import { Batch, Txn, Source, TxnSourceMap, TxnSource } from "@/types";
import AuthUtils from "@/utils/auth-utils";

export default class TransactionService {
  constructor(private baseURL: string, private token: string) {}

  private genericErrorMessage =
    "Connection to the network failed. Please contact our support team support@cebuanalhullier.com.";

  public async processTFA(code: string, batchID: string) {
    const request = new ServiceProto.TFARequest();

    request.setCode(code);
    request.setTransactionid(batchID);
    request.setTfatype(0);

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.TFA, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      console.error(
        `Error when processing TFA: unexpected status code=${status} msg=${statusMessage}`
      );
      if (status === grpc.Code.InvalidArgument) {
        throw new Error("Please enter a valid code");
      } else {
        throw new Error(this.genericErrorMessage);
      }
    } else {
      const decoded = message as ServiceProto.TFAResponse;
      const result = decoded.toObject();
      return result;
    }
  }

  public async resendTFA(batchID: string) {
    const request = new ServiceProto.ResendTFARequest();

    request.setTransactionid(batchID);
    request.setTfatype(1);

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.ResendTFA, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      console.error(
        `Error when resending transaction otp: unexpected status code=${status} msg=${statusMessage}`
      );
      throw new Error(this.genericErrorMessage);
    } else {
      const decoded = message as ServiceProto.ResendTFAResponse;
      //*TODO fix this return response
      const result = decoded.toObject();
      return result;
    }
  }

  public async processBatch(id: string, reject: boolean) {
    const request = new BatchServiceProto.ProcessBatchRequest();

    request.setBatchid(id);

    if (reject) {
      request.setClose(true);
    }

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(BatchService.ProcessBatch, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      console.error(
        `Error when processing batch: unexpected status code=${status} msg=${statusMessage}`
      );
      throw new Error(this.genericErrorMessage);
    } else {
      const decoded = message as BatchServiceProto.ProcessBatchResponse;
      //*TODO fix this return response
      const result = decoded.toObject();
      return result;
    }
  }

  public async getSingleTransactions() {
    const request = new ServiceProto.GetTransactionsRequest();

    const metadata = {
      ...AuthUtils.getMetadata(this.token),
      "transaction-source": "single",
    };

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.GetTransactions, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: metadata,
      });
    });

    if (status !== grpc.Code.OK) {
      throw new Error(`unexpected status code=${status} msg=${statusMessage}`);
    } else if (message === null) {
      throw new Error("received no data");
    } else {
      const decoded = message as ServiceProto.GetTransactionsResponse;
      return decoded.toObject();
    }
  }

  public async createBatch(payload: Batch) {
    const request = new BatchServiceProto.CreateBatchRequest();

    request.setSourceaccount(payload.source.accountNumber);
    request.setSettlementrail(payload.settlementRail);
    request.setPurpose(payload.remarks);

    const metadata = {
      ...AuthUtils.getMetadata(this.token),
      "transaction-source": payload.txnSource,
    };

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(BatchService.CreateBatch, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: metadata,
      });
    });

    if (status !== grpc.Code.OK) {
      console.error(
        `Error when processing batch: unexpected status code=${status} msg=${statusMessage}`
      );
      throw new Error(this.genericErrorMessage);
    } else {
      const decoded = message as BatchServiceProto.CreateBatchResponse;
      const batchID = decoded.getBatchid();
      const { source, settlementRail } = payload;

      await Promise.all(
        payload.transactions.map((txn) => {
          return this.createTransaction(
            source,
            txn,
            batchID,
            settlementRail,
            payload.txnSource
          );
        })
      );

      return batchID;
    }
  }

  public async getSingleTxns() {
    return this.getBatchList(TxnSourceMap.single, []);
  }

  public async getBatchTxns(batch: Array<string>) {
    return this.getBatchList(TxnSourceMap.batch, batch);
  }

  private async getBatchList(txnSource: TxnSource, batch: Array<string>) {
    const request = new BatchServiceProto.ListBatchRequest();

    if (batch.length) {
      request.setBatchesList(batch);
    }

    const metadata = {
      ...AuthUtils.getMetadata(this.token),
      "transaction-source": txnSource,
    };

    // *TODO: Do proper pagination here
    request.setLimit(1000);
    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(BatchService.ListBatch, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: metadata,
      });
    });

    if (status !== grpc.Code.OK) {
      throw new Error(`unexpected status code=${status} msg=${statusMessage}`);
    } else if (message === null) {
      throw new Error("received no data");
    } else {
      const decoded = message as BatchServiceProto.ListBatchResponse;
      return decoded.toObject();
    }
  }

  public async getAccountTransactions(accountNumber: string) {
    const request = new ServiceProto.GetTransactionsByAccountRequest();

    request.setAccountid(accountNumber);

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.GetTransactionsByAccount, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      throw new Error(`unexpected status code=${status} msg=${statusMessage}`);
    } else if (message === null) {
      throw new Error("received no data");
    } else {
      const decoded = message as ServiceProto.GetTransactionsByAccountResponse;
      return decoded.toObject();
    }
  }

  private generateBankAccountInfo(
    accountID: string,
    bankCode: string,
    accountName: string
  ): ServiceProto.BankAccountInfo {
    const bankAccountInfo = new ServiceProto.BankAccountInfo();
    bankAccountInfo.setAccountid(accountID);
    bankAccountInfo.setBankcode(bankCode);
    bankAccountInfo.setOwnername(accountName);

    return bankAccountInfo;
  }

  private generateAmount(cur: string, num: string): Amount {
    const amount = new Amount();
    amount.setCur(cur);
    amount.setNum(num);

    return amount;
  }

  private generateOfflineUserInfo(
    firstName: string,
    lastName: string,
    address = ""
  ): OfflineUserInfo {
    const userInfo = new OfflineUserInfo();
    const userAddress = new Address();
    userAddress.setStreet(address);

    userInfo.setFirstname(firstName);
    userInfo.setLastname(lastName);
    userInfo.setAddress(userAddress);

    return userInfo;
  }

  public async createTransaction(
    source: Source,
    txn: Txn,
    batchID: string,
    settlementRail: string,
    txnSource: string
  ) {
    const request = new ServiceProto.CreateTransactionRequest();

    request.setSourceaccountid(source.accountNumber);
    request.setSourceofflineuser(
      this.generateOfflineUserInfo(source.firstName, source.lastName)
    );
    request.setDestinationaccount(
      this.generateBankAccountInfo(
        txn.destination.accountNumber,
        txn.destination.bankCode,
        txn.destination.accountName
      )
    );

    request.setDestinationofflineuser(
      this.generateOfflineUserInfo(
        txn.destination.firstName,
        txn.destination.lastName,
        txn.destination.address
      )
    );

    request.setAmount(this.generateAmount(txn.currency, txn.amount));
    request.setSettlementrail(settlementRail);
    request.setTransactionid(batchID);

    const metadata = {
      ...AuthUtils.getMetadata(this.token),
      "transaction-source": txnSource,
    };

    const {
      status,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.CreateTransaction, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: metadata,
      });
    });

    if (status !== grpc.Code.OK) {
      console.error(
        `Error when processing batch: unexpected status code=${status} msg=${statusMessage}`
      );
      throw new Error(this.genericErrorMessage);
    }
    // Comment for now, as no need to return any data
    /* else {
      const decoded = message as ServiceProto.CreateTransactionResponse;
      console.log(decoded.toObject());
      return decoded.toObject();
    } */
  }
}
