











export default {
  name: "IconCheck",
};
