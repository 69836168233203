































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import VModal from "vue-js-modal";
import Vuelidate from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { isEmpty } from "lodash";
import { CustomInput, SvgIcon, Loader } from "@/components";
import { OrgAccount } from "@/types";
import { formatAmount, formatCurrency } from "@/utils/common";

const orgModule = namespace("org");
Vue.use(VModal);
Vue.use(Vuelidate);

interface BillerInfo {
  billingCode: string;
  company: string;
  account: string;
  category: string;
  actions: string;
}

@Component({
  components: {
    Loader,
    SvgIcon,
    CustomInput,
    AccountsDropdown: () =>
      import("@components/accounts-dropdown/AccountsDropdown.vue"),
  },
  validations: {
    sourceAccount: {
      hasChosen: (value) => !isEmpty(value),
    },
    amount: {
      required,
    },
  },
})
export default class PayBillModal extends Vue {
  @Prop()
  private readonly billerInfo!: BillerInfo;

  @Prop()
  private readonly loading!: boolean;

  @Prop()
  private readonly errorMessage!: string;

  @orgModule.State("accounts") accounts!: OrgAccount[];
  sourceAccount = {} as OrgAccount;
  amount = "";

  _hidePayBillsModal() {
    this.$modal.hide("pay-bill-modal");
  }

  _setSourceAccount(payload: OrgAccount) {
    this.sourceAccount = payload;
  }

  get balance() {
    return isEmpty(this.sourceAccount)
      ? 0
      : this.sourceAccount.availableBalance;
  }

  get availableBalance() {
    if (this.sourceAccount.availableBalance) {
      return formatCurrency(this.sourceAccount.availableBalance);
    }

    return false;
  }

  async onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) return;
    const payload: Record<string, any> = {
      billingCode: this.billerInfo.billingCode,
      account: this.billerInfo.account,
      amount: formatAmount(this.amount),
    };

    this.$emit("on-submit", payload);
  }

  _onModalClose() {
    this.sourceAccount = {} as OrgAccount;
    this.amount = "";
    this.$v.$reset();
  }

  get biller() {
    return {
      billingCompany: this.billerInfo.company,
      accountNumber: this.billerInfo.account,
    };
  }
}
