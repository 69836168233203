








import Vue from "vue";
import SvgIcon from "../svg-icon/SvgIcon.vue";

export default Vue.extend({
  name: "Loader",

  components: {
    SvgIcon,
  },

  props: {
    iconClass: {
      type: String,
      default: "",
    },
  },
});
