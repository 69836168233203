// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.46.0/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.46.0/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/icons/bell.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".toasted-custom{top:0!important;width:100%!important;clear:both!important;margin-top:10px!important;position:relative!important;height:auto!important;word-break:normal!important;display:flex!important;align-items:center!important;justify-content:center!important}.toasted-primary-success{border-radius:8px!important;min-height:60px!important;line-height:1.1em!important;background-color:#82c666!important;font-size:22px!important;font-weight:400!important;color:#fff!important;box-shadow:0 5px 10px rgb(0 0 0/12%)!important}.toasted-primary-success:before{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");padding-right:10px}.toasted-primary-fail{border-radius:8px!important;min-height:60px!important;line-height:1.1em!important;background-color:red!important;font-size:22px!important;font-weight:400!important;color:#fff!important;box-shadow:0 5px 10px rgb(0 0 0/12%)!important}.toasted-primary-fail:before{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");padding-right:10px}", ""]);
// Exports
module.exports = exports;
