






































































































import { Vue, Component, Prop } from "vue-property-decorator";

import { OrgAccount } from "@/types";
import { formatCurrency } from "@/utils/common";

@Component({})
export default class AccountCard extends Vue {
  @Prop()
  private readonly account!: OrgAccount;

  get availableBalance() {
    return this._formattedBalance(this.account.availableBalance);
  }

  get currentBalance() {
    return this._formattedBalance(this.account.currentBalance);
  }

  get fundsInClearing() {
    return this._formattedBalance(this.account.fundsInClearing);
  }

  _formattedBalance(amount: number): string {
    return formatCurrency(amount);
  }
}
