








import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as Icons from "./icons";

const recursivelyRemoveFill = (el: any) => {
  if (!el) {
    return;
  }
  el.removeAttribute("fill");
  [].forEach.call(el.children, (child) => {
    // Recursively remove all fill attribute of element and its nested children
    recursivelyRemoveFill(child);
  });
};

@Component({
  name: "svg-icon",
})
export default class Icon extends Vue {
  @Prop({ type: String, default: null }) name!: string;
  /**
   * hasFill: This property is to indicate whether should keep the default fill value of SVG or not
   * Default to true. Can set to false when need to customise the icons (e.g: color; background color)
   */
  @Prop({ type: Boolean, default: true }) hasFill!: boolean;

  @Prop({ type: String, default: "w-5 h-5" }) size!: string;

  get iconComponent() {
    return this.name && (Icons as any)[this.name];
  }

  _formatSvg() {
    if (this.$el.nodeName === "svg") {
      const svgElement = this.$el;
      if (!this.hasFill) {
        recursivelyRemoveFill(svgElement);
      }
    }
  }

  @Watch("iconComponent", { immediate: true })
  onIconChanged() {
    this.$nextTick(() => this._formatSvg());
  }
}
