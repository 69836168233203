




















export default {
  name: "IconTrash",
};
