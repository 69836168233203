
















































































import Vue from "vue";
import { SvgIcon } from "@components";

export default Vue.extend({
  name: "TablePagination",

  components: {
    SvgIcon,
  },

  props: {
    total: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },

  data() {
    return {
      currentPage: 1,
      inputPageNumber: 1,
      prevPage: 0,
      currentPerPage: 10,
    };
  },

  watch: {
    perPage: {
      immediate: true,
      handler(val: string, oldVal: string) {
        this.currentPerPage = this.perPage ? this.perPage : 10;
        if (oldVal) {
          //* only emit if this isn't first initialization
          this.$emit("per-page-changed", {
            currentPage: this.currentPage,
            prevPage: this.prevPage,
          });
        }
        this.changePage(1, false);
      },
    },
    currentPage: {
      immediate: true,
      handler(val: number) {
        if (val !== this.inputPageNumber) {
          this.inputPageNumber = val;
        }
      },
    },
  },

  computed: {
    pagesCount(): number {
      // *TODO render pagination properly, overflowing when too many
      const quotient = Math.floor(this.total / this.currentPerPage);
      const remainder = this.total % this.currentPerPage;

      const count = remainder === 0 ? quotient : quotient + 1;

      return count > 10 ? 10 : count;
    },

    nextIsPossible(): boolean {
      return this.currentPage < this.pagesCount;
    },

    prevIsPossible(): boolean {
      return this.currentPage > 1;
    },
  },

  methods: {
    // Go to next page
    gotoNextPage() {
      if (this.nextIsPossible) {
        this.prevPage = this.currentPage;
        ++this.currentPage;
        this.pageChanged();
      }
    },

    // Go to previous page
    gotoPreviousPage() {
      if (this.prevIsPossible) {
        this.prevPage = this.currentPage;
        --this.currentPage;
        this.pageChanged();
      }
    },

    gotoCustomPage() {
      const input = this.$refs.inputPageNumber as any;
      const value = parseInt(input.value);

      //! invalid number
      if (Number.isNaN(value) || value > this.pagesCount || value < 1) {
        input.value = this.currentPage;
        return false;
      }

      //* valid number
      // input.value = value;
      this.changePage(value);
    },

    // Change current page
    changePage(pageNumber: number, emit = true) {
      if (
        pageNumber > 0 &&
        this.total > this.currentPerPage * (pageNumber - 1)
      ) {
        this.prevPage = this.currentPage;
        this.currentPage = pageNumber;
        if (emit) this.pageChanged();
      }
    },

    pageChanged() {
      this.$emit("page-changed", {
        currentPage: this.currentPage,
        prevPage: this.prevPage,
      });
    },
  },
});
