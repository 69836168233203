import { ActionContext } from "vuex";
import { RootState } from "@/store";
import { TransactionState } from "./index";
import { Transactions, Banks, Billing } from "@/services";
import { Bank } from "@/proto/banks/all_pb";
import { Batch, PayBill } from "@/types";

export async function processTFA(
  { rootState }: ActionContext<TransactionState, RootState>,
  payload: any
) {
  const { auth, config } = rootState;

  await new Transactions(config.apiURL, auth.user.accessToken).processTFA(
    payload.code,
    payload.batchID
  );
}

export async function resendTFA(
  { rootState }: ActionContext<TransactionState, RootState>,
  batchID: string
) {
  const { auth, config } = rootState;

  await new Transactions(config.apiURL, auth.user.accessToken).resendTFA(
    batchID
  );
}

export async function processBatch(
  { rootState }: ActionContext<TransactionState, RootState>,
  payload: any
) {
  const { auth, config } = rootState;

  await new Transactions(config.apiURL, auth.user.accessToken).processBatch(
    payload.batchID,
    payload.reject
  );
}

export async function getSingleTransactions({
  commit,
  rootState,
}: ActionContext<TransactionState, RootState>) {
  const { auth, config } = rootState;

  commit("setLoading", true);
  commit("setError", "");

  try {
    const result = await new Transactions(
      config.apiURL,
      auth.user.accessToken
    ).getSingleTransactions();

    result.resultList.reverse();

    commit("setTransactions", result.resultList);
  } catch (error) {
    commit("setError", error.message);
    console.error("Error when getting single transactions: ", error.message);
  } finally {
    commit("setLoading", false);
  }
}

export async function getBillers({
  rootState,
}: ActionContext<TransactionState, RootState>) {
  const { auth, config } = rootState;

  const result = await new Billing(
    config.apiURL,
    auth.user.accessToken
  ).getBillers();

  return result;
}

export async function getBillInputs(
  { rootState }: ActionContext<TransactionState, RootState>,
  billerCode: string
) {
  const { auth, config } = rootState;

  const result = await new Billing(
    config.apiURL,
    auth.user.accessToken
  ).getBillInputs(billerCode);

  return result;
}

export async function payBill(
  { rootState }: ActionContext<TransactionState, RootState>,
  payload: PayBill
) {
  const { auth, config } = rootState;

  const result = await new Billing(
    config.apiURL,
    auth.user.accessToken
  ).payBill(payload);

  return result;
}

export async function getBatchTxns(
  { rootState }: ActionContext<TransactionState, RootState>,
  batch: Array<string>
) {
  const { auth, config } = rootState;

  const result = await new Transactions(
    config.apiURL,
    auth.user.accessToken
  ).getBatchTxns(batch);

  // Show latest first
  result.batchList.reverse();

  return result;
}

export async function getSingleTxns({
  rootState,
}: ActionContext<TransactionState, RootState>) {
  const { auth, config } = rootState;

  const result = await new Transactions(
    config.apiURL,
    auth.user.accessToken
  ).getSingleTxns();

  // Show latest first
  result.batchList.reverse();

  return result;
}

export async function getAccountTransactions(
  { rootState }: ActionContext<TransactionState, RootState>,
  accountNumber: string
) {
  const { auth, config } = rootState;

  const txns = await new Transactions(
    config.apiURL,
    auth.user.accessToken
  ).getAccountTransactions(accountNumber);

  return txns;
}

export async function createBatch(
  { rootState }: ActionContext<TransactionState, RootState>,
  payload: Batch
) {
  const { auth, config } = rootState;

  await new Transactions(config.apiURL, auth.user.accessToken).createBatch(
    payload
  );
}

export async function getBanks({
  commit,
  rootState,
}: ActionContext<TransactionState, RootState>) {
  const { auth, config } = rootState;

  const banksMap = await new Banks(
    config.apiURL,
    auth.user.accessToken
  ).getBanks();

  const pesonetBanks: Array<Bank.AsObject> = banksMap
    .filter((bank: [string, Bank.AsObject]) => {
      const bankData = bank[1];
      return !!bankData.rails?.pesonet;
    })
    .map((bank: [string, Bank.AsObject]) => bank[1]);

  const instapayBanks: Array<Bank.AsObject> = banksMap
    .filter((bank: [string, Bank.AsObject]) => {
      const bankData = bank[1];
      return !!bankData.rails?.instapay;
    })
    .map((bank: [string, Bank.AsObject]) => bank[1]);

  commit("config/setPesonetBanks", pesonetBanks, { root: true });
  commit("config/setInstapayBanks", instapayBanks, { root: true });
  commit("config/setAllBanks", banksMap, { root: true });
}
