<template>
  <div>
    <slot name="childComponent"></slot>
  </div>
</template>
<script>
export default {
  name: "MakeDuplicate",
};
</script>
