import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { ProtobufMessage } from "@improbable-eng/grpc-web/dist/typings/message";

import { BankService as Service } from "@/proto/banks/all_pb_service";
import * as ServiceProto from "@/proto/banks/all_pb";

import AuthUtils from "@/utils/auth-utils";

export default class BankService {
  constructor(private baseURL: string, private token: string) {}

  private genericErrorMessage =
    "Connection to the network failed. Please contact our support team support@cebuanalhullier.com.";

  public async getBanks(): Promise<
    Array<[string, ServiceProto.Bank.AsObject]>
  > {
    const request = new ServiceProto.GetBanksRequest();

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.GetBanks, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      throw new Error(`unexpected status code=${status} msg=${statusMessage}`);
    } else if (message === null) {
      throw new Error("received no data");
    } else {
      const decoded = message as ServiceProto.GetBanksResponse;
      const { banksMap } = decoded.toObject();
      return banksMap;
    }
  }
}
