





import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class TabItem extends Vue {
  @Prop({ type: String, required: true }) name!: string;
  @Prop({ type: Boolean, default: false }) selected!: boolean;

  isActive = false;

  get href() {
    return "#" + this.name.toLowerCase().replace(/ /g, "-");
  }

  mounted() {
    this.isActive = this.selected;
  }
}
