import { RoleType } from "@/proto/profile/all_pb";

export const MARITAL_STATUS = ["Single", "Married", "Separated", "Widowed"];

export const EDUCATION = [
  "Elementary",
  "High School",
  "Technical Vocational",
  "Bachelor's Degree/College Degree",
  "Master's Degree",
  "Doctorate Degree",
];

export const INDUSTRY = [
  "Airline/Maritime Employee",
  "Art/Entertainment Media/Sports Professional",
  "Civil/Government Employee",
  "Hotel/Restaurant/Leisure Services Employee",
  "IT and Tech Professional",
  "Laborer-Oil/Gas/Mining/Forestry",
  "Law Enforcement/Military Professional",
  "Medical and Health Care Professional",
  "Corporate Professional",
  "Professional Service Practitioner",
  "Retail Sales",
  "Sales/Insurance/Real Estate Professional",
  "Science/Research Professional",
  "Self-Employed",
];

export const POSITION = [
  "Entry Level",
  "Mid-Level/Supervisory/Management",
  "Senior Level/Executive",
];

export const SIDEBAR_LINKS = [
  {
    iconName: "AccountOverview",
    groupName: "Account Overview",
    links: [
      {
        label: "Account Summary",
        to: "/accounts",
        allowedUsers: [RoleType.CHECKER, RoleType.MAKER],
      },
      {
        label: "Statement",
        to: "/statement",
        allowedUsers: [RoleType.CHECKER],
      },
      {
        label: "Manage Batches",
        to: "/batches",
        allowedUsers: [RoleType.CHECKER],
      },
    ],
  },
  {
    iconName: "PayTransfer",
    groupName: "Pay & Transfer",
    links: [
      {
        label: "Fund Transfer",
        to: "/fund-transfer",
        allowedUsers: [RoleType.MAKER],
      },
      {
        label: "Bills Payment",
        to: "/bills-payment",
        allowedUsers: [RoleType.MAKER],
      },
    ],
  },
  {
    iconName: "Collection",
    groupName: "Collection & Disburse",
    links: [
      {
        label: "Batch Debit",
        to: "/batch-debit",
        allowedUsers: [RoleType.MAKER],
      },
      {
        label: "Batch Credit",
        to: "/batch-credit",
        allowedUsers: [RoleType.MAKER],
      },
    ],
  },
  {
    iconName: "Favorites",
    groupName: "Favorites",
    links: [
      {
        label: "Payees",
        to: "/favorites/payees",
        allowedUsers: [RoleType.MAKER],
      },
      {
        label: "Billers",
        to: "/billers",
        allowedUsers: [RoleType.MAKER],
      },
    ],
  },
  {
    iconName: "Request",
    groupName: "Requests",
    links: [
      {
        label: "Apply as Merchant",
        to: "/merchant-registration",
        allowedUsers: [RoleType.ACCOUNTADMINISTRATOR],
      },
    ],
  },
  {
    iconName: "Settings",
    groupName: "Settings",
    links: [
      {
        label: "User Management",
        to: "/users",
        allowedUsers: [
          RoleType.BANKADMINISTRATOR,
          RoleType.ACCOUNTADMINISTRATOR,
        ],
      },
      {
        label: "Fee Management",
        to: "/fee-management",
        allowedUsers: [RoleType.BANKADMINISTRATOR],
      },
    ],
  },
];

export const FILE_MANAGEMENT_COLUMNS = [
  {
    field: "name",
    label: "FILE NAME",
    sortable: true,
  },
  {
    field: "file",
    label: "FILE NO",
    sortable: true,
  },
  {
    field: "date",
    label: "Date",
    sortable: true,
  },
  {
    field: "category",
    label: "CATEGORY",
    sortable: true,
  },
  {
    field: "actions",
    label: "ACTIONS",
    sortable: false,
  },
];
export const ANNOUNCEMENT_COLUMNS = [
  {
    field: "code",
    label: "Code",
    sortable: true,
  },
  {
    field: "announcement",
    label: "Announcement",
    sortable: true,
  },
  {
    field: "startDate",
    label: "Start Date",
    sortable: true,
  },
  {
    field: "startEnd",
    label: "Start End",
    sortable: true,
  },
  {
    field: "status",
    label: "Status",
    sortable: true,
  },
  {
    field: "actions",
    label: "Actions",
    sortable: false,
  },
];
