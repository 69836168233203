// source: all.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.metadata.Amount', null, global);
goog.exportSymbol('proto.metadata.Bank', null, global);
goog.exportSymbol('proto.metadata.Biller', null, global);
goog.exportSymbol('proto.metadata.Cities', null, global);
goog.exportSymbol('proto.metadata.City', null, global);
goog.exportSymbol('proto.metadata.Country', null, global);
goog.exportSymbol('proto.metadata.DateRange', null, global);
goog.exportSymbol('proto.metadata.EFT', null, global);
goog.exportSymbol('proto.metadata.Fee', null, global);
goog.exportSymbol('proto.metadata.Fees', null, global);
goog.exportSymbol('proto.metadata.GetMetadataRequest', null, global);
goog.exportSymbol('proto.metadata.IDType', null, global);
goog.exportSymbol('proto.metadata.Insurance', null, global);
goog.exportSymbol('proto.metadata.InsuranceCategory', null, global);
goog.exportSymbol('proto.metadata.Location', null, global);
goog.exportSymbol('proto.metadata.Maintenance', null, global);
goog.exportSymbol('proto.metadata.Metadata', null, global);
goog.exportSymbol('proto.metadata.Province', null, global);
goog.exportSymbol('proto.metadata.Provinces', null, global);
goog.exportSymbol('proto.metadata.RemittancePartner', null, global);
goog.exportSymbol('proto.metadata.TCVersion', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.GetMetadataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metadata.GetMetadataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.GetMetadataRequest.displayName = 'proto.metadata.GetMetadataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.Metadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.metadata.Metadata.repeatedFields_, null);
};
goog.inherits(proto.metadata.Metadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.Metadata.displayName = 'proto.metadata.Metadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.TCVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metadata.TCVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.TCVersion.displayName = 'proto.metadata.TCVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.DateRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metadata.DateRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.DateRange.displayName = 'proto.metadata.DateRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.Maintenance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metadata.Maintenance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.Maintenance.displayName = 'proto.metadata.Maintenance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metadata.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.Location.displayName = 'proto.metadata.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.Country = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metadata.Country, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.Country.displayName = 'proto.metadata.Country';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.Province = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metadata.Province, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.Province.displayName = 'proto.metadata.Province';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.Provinces = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.metadata.Provinces.repeatedFields_, null);
};
goog.inherits(proto.metadata.Provinces, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.Provinces.displayName = 'proto.metadata.Provinces';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.City = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metadata.City, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.City.displayName = 'proto.metadata.City';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.Cities = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.metadata.Cities.repeatedFields_, null);
};
goog.inherits(proto.metadata.Cities, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.Cities.displayName = 'proto.metadata.Cities';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.Amount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metadata.Amount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.Amount.displayName = 'proto.metadata.Amount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.Fees = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.metadata.Fees.repeatedFields_, null);
};
goog.inherits(proto.metadata.Fees, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.Fees.displayName = 'proto.metadata.Fees';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.Fee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metadata.Fee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.Fee.displayName = 'proto.metadata.Fee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.Bank = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metadata.Bank, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.Bank.displayName = 'proto.metadata.Bank';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.Biller = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.metadata.Biller.repeatedFields_, null);
};
goog.inherits(proto.metadata.Biller, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.Biller.displayName = 'proto.metadata.Biller';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.Insurance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metadata.Insurance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.Insurance.displayName = 'proto.metadata.Insurance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.InsuranceCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.metadata.InsuranceCategory.repeatedFields_, null);
};
goog.inherits(proto.metadata.InsuranceCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.InsuranceCategory.displayName = 'proto.metadata.InsuranceCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.RemittancePartner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metadata.RemittancePartner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.RemittancePartner.displayName = 'proto.metadata.RemittancePartner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.IDType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metadata.IDType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.IDType.displayName = 'proto.metadata.IDType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.metadata.EFT = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.metadata.EFT, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.metadata.EFT.displayName = 'proto.metadata.EFT';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.GetMetadataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.GetMetadataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.GetMetadataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.GetMetadataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.GetMetadataRequest}
 */
proto.metadata.GetMetadataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.GetMetadataRequest;
  return proto.metadata.GetMetadataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.GetMetadataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.GetMetadataRequest}
 */
proto.metadata.GetMetadataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.GetMetadataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.GetMetadataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.GetMetadataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.GetMetadataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.metadata.Metadata.repeatedFields_ = [5,6,8,9,10,12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.Metadata.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.Metadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.Metadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Metadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    feesMap: (f = msg.getFeesMap()) ? f.toObject(includeInstance, proto.metadata.Fees.toObject) : [],
    bankcodesMap: (f = msg.getBankcodesMap()) ? f.toObject(includeInstance, proto.metadata.Bank.toObject) : [],
    billercodesMap: (f = msg.getBillercodesMap()) ? f.toObject(includeInstance, proto.metadata.Biller.toObject) : [],
    location: (f = msg.getLocation()) && proto.metadata.Location.toObject(includeInstance, f),
    loantypesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    maintenancewindowsList: jspb.Message.toObjectList(msg.getMaintenancewindowsList(),
    proto.metadata.Maintenance.toObject, includeInstance),
    tcversion: (f = msg.getTcversion()) && proto.metadata.TCVersion.toObject(includeInstance, f),
    nationalitiesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    insurancecategoriesList: jspb.Message.toObjectList(msg.getInsurancecategoriesList(),
    proto.metadata.InsuranceCategory.toObject, includeInstance),
    remittancepartnersList: jspb.Message.toObjectList(msg.getRemittancepartnersList(),
    proto.metadata.RemittancePartner.toObject, includeInstance),
    eft: (f = msg.getEft()) && proto.metadata.EFT.toObject(includeInstance, f),
    idtypesList: jspb.Message.toObjectList(msg.getIdtypesList(),
    proto.metadata.IDType.toObject, includeInstance),
    relationshipsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.Metadata}
 */
proto.metadata.Metadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.Metadata;
  return proto.metadata.Metadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.Metadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.Metadata}
 */
proto.metadata.Metadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getFeesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.metadata.Fees.deserializeBinaryFromReader, "", new proto.metadata.Fees());
         });
      break;
    case 2:
      var value = msg.getBankcodesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.metadata.Bank.deserializeBinaryFromReader, "", new proto.metadata.Bank());
         });
      break;
    case 3:
      var value = msg.getBillercodesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.metadata.Biller.deserializeBinaryFromReader, "", new proto.metadata.Biller());
         });
      break;
    case 4:
      var value = new proto.metadata.Location;
      reader.readMessage(value,proto.metadata.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addLoantypes(value);
      break;
    case 6:
      var value = new proto.metadata.Maintenance;
      reader.readMessage(value,proto.metadata.Maintenance.deserializeBinaryFromReader);
      msg.addMaintenancewindows(value);
      break;
    case 7:
      var value = new proto.metadata.TCVersion;
      reader.readMessage(value,proto.metadata.TCVersion.deserializeBinaryFromReader);
      msg.setTcversion(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addNationalities(value);
      break;
    case 9:
      var value = new proto.metadata.InsuranceCategory;
      reader.readMessage(value,proto.metadata.InsuranceCategory.deserializeBinaryFromReader);
      msg.addInsurancecategories(value);
      break;
    case 10:
      var value = new proto.metadata.RemittancePartner;
      reader.readMessage(value,proto.metadata.RemittancePartner.deserializeBinaryFromReader);
      msg.addRemittancepartners(value);
      break;
    case 11:
      var value = new proto.metadata.EFT;
      reader.readMessage(value,proto.metadata.EFT.deserializeBinaryFromReader);
      msg.setEft(value);
      break;
    case 12:
      var value = new proto.metadata.IDType;
      reader.readMessage(value,proto.metadata.IDType.deserializeBinaryFromReader);
      msg.addIdtypes(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addRelationships(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.Metadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.Metadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.Metadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Metadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.metadata.Fees.serializeBinaryToWriter);
  }
  f = message.getBankcodesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.metadata.Bank.serializeBinaryToWriter);
  }
  f = message.getBillercodesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.metadata.Biller.serializeBinaryToWriter);
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.metadata.Location.serializeBinaryToWriter
    );
  }
  f = message.getLoantypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getMaintenancewindowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.metadata.Maintenance.serializeBinaryToWriter
    );
  }
  f = message.getTcversion();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.metadata.TCVersion.serializeBinaryToWriter
    );
  }
  f = message.getNationalitiesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getInsurancecategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.metadata.InsuranceCategory.serializeBinaryToWriter
    );
  }
  f = message.getRemittancepartnersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.metadata.RemittancePartner.serializeBinaryToWriter
    );
  }
  f = message.getEft();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.metadata.EFT.serializeBinaryToWriter
    );
  }
  f = message.getIdtypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.metadata.IDType.serializeBinaryToWriter
    );
  }
  f = message.getRelationshipsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
};


/**
 * map<string, Fees> Fees = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.metadata.Fees>}
 */
proto.metadata.Metadata.prototype.getFeesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.metadata.Fees>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.metadata.Fees));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.clearFeesMap = function() {
  this.getFeesMap().clear();
  return this;};


/**
 * map<string, Bank> BankCodes = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.metadata.Bank>}
 */
proto.metadata.Metadata.prototype.getBankcodesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.metadata.Bank>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.metadata.Bank));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.clearBankcodesMap = function() {
  this.getBankcodesMap().clear();
  return this;};


/**
 * map<string, Biller> BillerCodes = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.metadata.Biller>}
 */
proto.metadata.Metadata.prototype.getBillercodesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.metadata.Biller>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.metadata.Biller));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.clearBillercodesMap = function() {
  this.getBillercodesMap().clear();
  return this;};


/**
 * optional Location Location = 4;
 * @return {?proto.metadata.Location}
 */
proto.metadata.Metadata.prototype.getLocation = function() {
  return /** @type{?proto.metadata.Location} */ (
    jspb.Message.getWrapperField(this, proto.metadata.Location, 4));
};


/**
 * @param {?proto.metadata.Location|undefined} value
 * @return {!proto.metadata.Metadata} returns this
*/
proto.metadata.Metadata.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.Metadata.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated string LoanTypes = 5;
 * @return {!Array<string>}
 */
proto.metadata.Metadata.prototype.getLoantypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.setLoantypesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.addLoantypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.clearLoantypesList = function() {
  return this.setLoantypesList([]);
};


/**
 * repeated Maintenance MaintenanceWindows = 6;
 * @return {!Array<!proto.metadata.Maintenance>}
 */
proto.metadata.Metadata.prototype.getMaintenancewindowsList = function() {
  return /** @type{!Array<!proto.metadata.Maintenance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.metadata.Maintenance, 6));
};


/**
 * @param {!Array<!proto.metadata.Maintenance>} value
 * @return {!proto.metadata.Metadata} returns this
*/
proto.metadata.Metadata.prototype.setMaintenancewindowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.metadata.Maintenance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.metadata.Maintenance}
 */
proto.metadata.Metadata.prototype.addMaintenancewindows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.metadata.Maintenance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.clearMaintenancewindowsList = function() {
  return this.setMaintenancewindowsList([]);
};


/**
 * optional TCVersion TCVersion = 7;
 * @return {?proto.metadata.TCVersion}
 */
proto.metadata.Metadata.prototype.getTcversion = function() {
  return /** @type{?proto.metadata.TCVersion} */ (
    jspb.Message.getWrapperField(this, proto.metadata.TCVersion, 7));
};


/**
 * @param {?proto.metadata.TCVersion|undefined} value
 * @return {!proto.metadata.Metadata} returns this
*/
proto.metadata.Metadata.prototype.setTcversion = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.clearTcversion = function() {
  return this.setTcversion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.Metadata.prototype.hasTcversion = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated string Nationalities = 8;
 * @return {!Array<string>}
 */
proto.metadata.Metadata.prototype.getNationalitiesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.setNationalitiesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.addNationalities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.clearNationalitiesList = function() {
  return this.setNationalitiesList([]);
};


/**
 * repeated InsuranceCategory InsuranceCategories = 9;
 * @return {!Array<!proto.metadata.InsuranceCategory>}
 */
proto.metadata.Metadata.prototype.getInsurancecategoriesList = function() {
  return /** @type{!Array<!proto.metadata.InsuranceCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.metadata.InsuranceCategory, 9));
};


/**
 * @param {!Array<!proto.metadata.InsuranceCategory>} value
 * @return {!proto.metadata.Metadata} returns this
*/
proto.metadata.Metadata.prototype.setInsurancecategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.metadata.InsuranceCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.metadata.InsuranceCategory}
 */
proto.metadata.Metadata.prototype.addInsurancecategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.metadata.InsuranceCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.clearInsurancecategoriesList = function() {
  return this.setInsurancecategoriesList([]);
};


/**
 * repeated RemittancePartner RemittancePartners = 10;
 * @return {!Array<!proto.metadata.RemittancePartner>}
 */
proto.metadata.Metadata.prototype.getRemittancepartnersList = function() {
  return /** @type{!Array<!proto.metadata.RemittancePartner>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.metadata.RemittancePartner, 10));
};


/**
 * @param {!Array<!proto.metadata.RemittancePartner>} value
 * @return {!proto.metadata.Metadata} returns this
*/
proto.metadata.Metadata.prototype.setRemittancepartnersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.metadata.RemittancePartner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.metadata.RemittancePartner}
 */
proto.metadata.Metadata.prototype.addRemittancepartners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.metadata.RemittancePartner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.clearRemittancepartnersList = function() {
  return this.setRemittancepartnersList([]);
};


/**
 * optional EFT EFT = 11;
 * @return {?proto.metadata.EFT}
 */
proto.metadata.Metadata.prototype.getEft = function() {
  return /** @type{?proto.metadata.EFT} */ (
    jspb.Message.getWrapperField(this, proto.metadata.EFT, 11));
};


/**
 * @param {?proto.metadata.EFT|undefined} value
 * @return {!proto.metadata.Metadata} returns this
*/
proto.metadata.Metadata.prototype.setEft = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.clearEft = function() {
  return this.setEft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.Metadata.prototype.hasEft = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated IDType IDTypes = 12;
 * @return {!Array<!proto.metadata.IDType>}
 */
proto.metadata.Metadata.prototype.getIdtypesList = function() {
  return /** @type{!Array<!proto.metadata.IDType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.metadata.IDType, 12));
};


/**
 * @param {!Array<!proto.metadata.IDType>} value
 * @return {!proto.metadata.Metadata} returns this
*/
proto.metadata.Metadata.prototype.setIdtypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.metadata.IDType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.metadata.IDType}
 */
proto.metadata.Metadata.prototype.addIdtypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.metadata.IDType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.clearIdtypesList = function() {
  return this.setIdtypesList([]);
};


/**
 * repeated string Relationships = 13;
 * @return {!Array<string>}
 */
proto.metadata.Metadata.prototype.getRelationshipsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.setRelationshipsList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.addRelationships = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.metadata.Metadata} returns this
 */
proto.metadata.Metadata.prototype.clearRelationshipsList = function() {
  return this.setRelationshipsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.TCVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.TCVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.TCVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.TCVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    publishdate: (f = msg.getPublishdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    version: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.TCVersion}
 */
proto.metadata.TCVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.TCVersion;
  return proto.metadata.TCVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.TCVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.TCVersion}
 */
proto.metadata.TCVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPublishdate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.TCVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.TCVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.TCVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.TCVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublishdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp publishDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.metadata.TCVersion.prototype.getPublishdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.metadata.TCVersion} returns this
*/
proto.metadata.TCVersion.prototype.setPublishdate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.TCVersion} returns this
 */
proto.metadata.TCVersion.prototype.clearPublishdate = function() {
  return this.setPublishdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.TCVersion.prototype.hasPublishdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string version = 2;
 * @return {string}
 */
proto.metadata.TCVersion.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.TCVersion} returns this
 */
proto.metadata.TCVersion.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.DateRange.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.DateRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.DateRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.DateRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.DateRange}
 */
proto.metadata.DateRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.DateRange;
  return proto.metadata.DateRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.DateRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.DateRange}
 */
proto.metadata.DateRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.DateRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.DateRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.DateRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.DateRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp Start = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.metadata.DateRange.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.metadata.DateRange} returns this
*/
proto.metadata.DateRange.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.DateRange} returns this
 */
proto.metadata.DateRange.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.DateRange.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp End = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.metadata.DateRange.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.metadata.DateRange} returns this
*/
proto.metadata.DateRange.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.DateRange} returns this
 */
proto.metadata.DateRange.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.DateRange.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.Maintenance.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.Maintenance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.Maintenance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Maintenance.toObject = function(includeInstance, msg) {
  var f, obj = {
    announcewindow: (f = msg.getAnnouncewindow()) && proto.metadata.DateRange.toObject(includeInstance, f),
    unavailablewindow: (f = msg.getUnavailablewindow()) && proto.metadata.DateRange.toObject(includeInstance, f),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.Maintenance}
 */
proto.metadata.Maintenance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.Maintenance;
  return proto.metadata.Maintenance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.Maintenance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.Maintenance}
 */
proto.metadata.Maintenance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.metadata.DateRange;
      reader.readMessage(value,proto.metadata.DateRange.deserializeBinaryFromReader);
      msg.setAnnouncewindow(value);
      break;
    case 2:
      var value = new proto.metadata.DateRange;
      reader.readMessage(value,proto.metadata.DateRange.deserializeBinaryFromReader);
      msg.setUnavailablewindow(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.Maintenance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.Maintenance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.Maintenance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Maintenance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnouncewindow();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.metadata.DateRange.serializeBinaryToWriter
    );
  }
  f = message.getUnavailablewindow();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.metadata.DateRange.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional DateRange AnnounceWindow = 1;
 * @return {?proto.metadata.DateRange}
 */
proto.metadata.Maintenance.prototype.getAnnouncewindow = function() {
  return /** @type{?proto.metadata.DateRange} */ (
    jspb.Message.getWrapperField(this, proto.metadata.DateRange, 1));
};


/**
 * @param {?proto.metadata.DateRange|undefined} value
 * @return {!proto.metadata.Maintenance} returns this
*/
proto.metadata.Maintenance.prototype.setAnnouncewindow = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.Maintenance} returns this
 */
proto.metadata.Maintenance.prototype.clearAnnouncewindow = function() {
  return this.setAnnouncewindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.Maintenance.prototype.hasAnnouncewindow = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DateRange UnavailableWindow = 2;
 * @return {?proto.metadata.DateRange}
 */
proto.metadata.Maintenance.prototype.getUnavailablewindow = function() {
  return /** @type{?proto.metadata.DateRange} */ (
    jspb.Message.getWrapperField(this, proto.metadata.DateRange, 2));
};


/**
 * @param {?proto.metadata.DateRange|undefined} value
 * @return {!proto.metadata.Maintenance} returns this
*/
proto.metadata.Maintenance.prototype.setUnavailablewindow = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.Maintenance} returns this
 */
proto.metadata.Maintenance.prototype.clearUnavailablewindow = function() {
  return this.setUnavailablewindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.Maintenance.prototype.hasUnavailablewindow = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string Message = 3;
 * @return {string}
 */
proto.metadata.Maintenance.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.Maintenance} returns this
 */
proto.metadata.Maintenance.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    countriesMap: (f = msg.getCountriesMap()) ? f.toObject(includeInstance, proto.metadata.Country.toObject) : [],
    provincesMap: (f = msg.getProvincesMap()) ? f.toObject(includeInstance, proto.metadata.Provinces.toObject) : [],
    citiesMap: (f = msg.getCitiesMap()) ? f.toObject(includeInstance, proto.metadata.Cities.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.Location}
 */
proto.metadata.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.Location;
  return proto.metadata.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.Location}
 */
proto.metadata.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getCountriesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.metadata.Country.deserializeBinaryFromReader, 0, new proto.metadata.Country());
         });
      break;
    case 2:
      var value = msg.getProvincesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.metadata.Provinces.deserializeBinaryFromReader, 0, new proto.metadata.Provinces());
         });
      break;
    case 3:
      var value = msg.getCitiesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.metadata.Cities.deserializeBinaryFromReader, 0, new proto.metadata.Cities());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountriesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.metadata.Country.serializeBinaryToWriter);
  }
  f = message.getProvincesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.metadata.Provinces.serializeBinaryToWriter);
  }
  f = message.getCitiesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.metadata.Cities.serializeBinaryToWriter);
  }
};


/**
 * map<int32, Country> Countries = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.metadata.Country>}
 */
proto.metadata.Location.prototype.getCountriesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.metadata.Country>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.metadata.Country));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.metadata.Location} returns this
 */
proto.metadata.Location.prototype.clearCountriesMap = function() {
  this.getCountriesMap().clear();
  return this;};


/**
 * map<int32, Provinces> Provinces = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.metadata.Provinces>}
 */
proto.metadata.Location.prototype.getProvincesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.metadata.Provinces>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.metadata.Provinces));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.metadata.Location} returns this
 */
proto.metadata.Location.prototype.clearProvincesMap = function() {
  this.getProvincesMap().clear();
  return this;};


/**
 * map<int32, Cities> Cities = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.metadata.Cities>}
 */
proto.metadata.Location.prototype.getCitiesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.metadata.Cities>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.metadata.Cities));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.metadata.Location} returns this
 */
proto.metadata.Location.prototype.clearCitiesMap = function() {
  this.getCitiesMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.Country.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.Country.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.Country} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Country.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.Country}
 */
proto.metadata.Country.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.Country;
  return proto.metadata.Country.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.Country} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.Country}
 */
proto.metadata.Country.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.Country.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.Country.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.Country} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Country.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 ID = 1;
 * @return {number}
 */
proto.metadata.Country.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.metadata.Country} returns this
 */
proto.metadata.Country.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.metadata.Country.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.Country} returns this
 */
proto.metadata.Country.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.Province.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.Province.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.Province} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Province.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    countryid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.Province}
 */
proto.metadata.Province.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.Province;
  return proto.metadata.Province.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.Province} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.Province}
 */
proto.metadata.Province.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountryid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.Province.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.Province.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.Province} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Province.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountryid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 ID = 1;
 * @return {number}
 */
proto.metadata.Province.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.metadata.Province} returns this
 */
proto.metadata.Province.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.metadata.Province.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.Province} returns this
 */
proto.metadata.Province.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 CountryID = 3;
 * @return {number}
 */
proto.metadata.Province.prototype.getCountryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.metadata.Province} returns this
 */
proto.metadata.Province.prototype.setCountryid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.metadata.Provinces.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.Provinces.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.Provinces.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.Provinces} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Provinces.toObject = function(includeInstance, msg) {
  var f, obj = {
    provincesList: jspb.Message.toObjectList(msg.getProvincesList(),
    proto.metadata.Province.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.Provinces}
 */
proto.metadata.Provinces.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.Provinces;
  return proto.metadata.Provinces.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.Provinces} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.Provinces}
 */
proto.metadata.Provinces.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.metadata.Province;
      reader.readMessage(value,proto.metadata.Province.deserializeBinaryFromReader);
      msg.addProvinces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.Provinces.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.Provinces.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.Provinces} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Provinces.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvincesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.metadata.Province.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Province Provinces = 1;
 * @return {!Array<!proto.metadata.Province>}
 */
proto.metadata.Provinces.prototype.getProvincesList = function() {
  return /** @type{!Array<!proto.metadata.Province>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.metadata.Province, 1));
};


/**
 * @param {!Array<!proto.metadata.Province>} value
 * @return {!proto.metadata.Provinces} returns this
*/
proto.metadata.Provinces.prototype.setProvincesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.metadata.Province=} opt_value
 * @param {number=} opt_index
 * @return {!proto.metadata.Province}
 */
proto.metadata.Provinces.prototype.addProvinces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.metadata.Province, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.metadata.Provinces} returns this
 */
proto.metadata.Provinces.prototype.clearProvincesList = function() {
  return this.setProvincesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.City.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.City.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.City} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.City.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    provinceid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.City}
 */
proto.metadata.City.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.City;
  return proto.metadata.City.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.City} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.City}
 */
proto.metadata.City.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProvinceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.City.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.City.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.City} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.City.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProvinceid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 ID = 1;
 * @return {number}
 */
proto.metadata.City.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.metadata.City} returns this
 */
proto.metadata.City.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.metadata.City.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.City} returns this
 */
proto.metadata.City.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 ProvinceID = 3;
 * @return {number}
 */
proto.metadata.City.prototype.getProvinceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.metadata.City} returns this
 */
proto.metadata.City.prototype.setProvinceid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.metadata.Cities.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.Cities.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.Cities.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.Cities} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Cities.toObject = function(includeInstance, msg) {
  var f, obj = {
    citiesList: jspb.Message.toObjectList(msg.getCitiesList(),
    proto.metadata.City.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.Cities}
 */
proto.metadata.Cities.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.Cities;
  return proto.metadata.Cities.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.Cities} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.Cities}
 */
proto.metadata.Cities.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.metadata.City;
      reader.readMessage(value,proto.metadata.City.deserializeBinaryFromReader);
      msg.addCities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.Cities.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.Cities.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.Cities} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Cities.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.metadata.City.serializeBinaryToWriter
    );
  }
};


/**
 * repeated City Cities = 1;
 * @return {!Array<!proto.metadata.City>}
 */
proto.metadata.Cities.prototype.getCitiesList = function() {
  return /** @type{!Array<!proto.metadata.City>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.metadata.City, 1));
};


/**
 * @param {!Array<!proto.metadata.City>} value
 * @return {!proto.metadata.Cities} returns this
*/
proto.metadata.Cities.prototype.setCitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.metadata.City=} opt_value
 * @param {number=} opt_index
 * @return {!proto.metadata.City}
 */
proto.metadata.Cities.prototype.addCities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.metadata.City, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.metadata.Cities} returns this
 */
proto.metadata.Cities.prototype.clearCitiesList = function() {
  return this.setCitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.Amount.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.Amount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.Amount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Amount.toObject = function(includeInstance, msg) {
  var f, obj = {
    amt: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cur: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.Amount}
 */
proto.metadata.Amount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.Amount;
  return proto.metadata.Amount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.Amount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.Amount}
 */
proto.metadata.Amount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCur(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.Amount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.Amount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.Amount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Amount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCur();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Amt = 1;
 * @return {string}
 */
proto.metadata.Amount.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.Amount} returns this
 */
proto.metadata.Amount.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Cur = 2;
 * @return {string}
 */
proto.metadata.Amount.prototype.getCur = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.Amount} returns this
 */
proto.metadata.Amount.prototype.setCur = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.metadata.Fees.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.Fees.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.Fees.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.Fees} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Fees.toObject = function(includeInstance, msg) {
  var f, obj = {
    feesList: jspb.Message.toObjectList(msg.getFeesList(),
    proto.metadata.Fee.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.Fees}
 */
proto.metadata.Fees.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.Fees;
  return proto.metadata.Fees.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.Fees} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.Fees}
 */
proto.metadata.Fees.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.metadata.Fee;
      reader.readMessage(value,proto.metadata.Fee.deserializeBinaryFromReader);
      msg.addFees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.Fees.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.Fees.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.Fees} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Fees.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.metadata.Fee.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Fee Fees = 1;
 * @return {!Array<!proto.metadata.Fee>}
 */
proto.metadata.Fees.prototype.getFeesList = function() {
  return /** @type{!Array<!proto.metadata.Fee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.metadata.Fee, 1));
};


/**
 * @param {!Array<!proto.metadata.Fee>} value
 * @return {!proto.metadata.Fees} returns this
*/
proto.metadata.Fees.prototype.setFeesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.metadata.Fee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.metadata.Fee}
 */
proto.metadata.Fees.prototype.addFees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.metadata.Fee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.metadata.Fees} returns this
 */
proto.metadata.Fees.prototype.clearFeesList = function() {
  return this.setFeesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.Fee.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.Fee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.Fee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Fee.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && proto.metadata.Amount.toObject(includeInstance, f),
    minamt: (f = msg.getMinamt()) && proto.metadata.Amount.toObject(includeInstance, f),
    maxamt: (f = msg.getMaxamt()) && proto.metadata.Amount.toObject(includeInstance, f),
    rate: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.Fee}
 */
proto.metadata.Fee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.Fee;
  return proto.metadata.Fee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.Fee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.Fee}
 */
proto.metadata.Fee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.metadata.Amount;
      reader.readMessage(value,proto.metadata.Amount.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 2:
      var value = new proto.metadata.Amount;
      reader.readMessage(value,proto.metadata.Amount.deserializeBinaryFromReader);
      msg.setMinamt(value);
      break;
    case 3:
      var value = new proto.metadata.Amount;
      reader.readMessage(value,proto.metadata.Amount.deserializeBinaryFromReader);
      msg.setMaxamt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.Fee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.Fee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.Fee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Fee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.metadata.Amount.serializeBinaryToWriter
    );
  }
  f = message.getMinamt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.metadata.Amount.serializeBinaryToWriter
    );
  }
  f = message.getMaxamt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.metadata.Amount.serializeBinaryToWriter
    );
  }
  f = message.getRate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional Amount Base = 1;
 * @return {?proto.metadata.Amount}
 */
proto.metadata.Fee.prototype.getBase = function() {
  return /** @type{?proto.metadata.Amount} */ (
    jspb.Message.getWrapperField(this, proto.metadata.Amount, 1));
};


/**
 * @param {?proto.metadata.Amount|undefined} value
 * @return {!proto.metadata.Fee} returns this
*/
proto.metadata.Fee.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.Fee} returns this
 */
proto.metadata.Fee.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.Fee.prototype.hasBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Amount MinAmt = 2;
 * @return {?proto.metadata.Amount}
 */
proto.metadata.Fee.prototype.getMinamt = function() {
  return /** @type{?proto.metadata.Amount} */ (
    jspb.Message.getWrapperField(this, proto.metadata.Amount, 2));
};


/**
 * @param {?proto.metadata.Amount|undefined} value
 * @return {!proto.metadata.Fee} returns this
*/
proto.metadata.Fee.prototype.setMinamt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.Fee} returns this
 */
proto.metadata.Fee.prototype.clearMinamt = function() {
  return this.setMinamt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.Fee.prototype.hasMinamt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Amount MaxAmt = 3;
 * @return {?proto.metadata.Amount}
 */
proto.metadata.Fee.prototype.getMaxamt = function() {
  return /** @type{?proto.metadata.Amount} */ (
    jspb.Message.getWrapperField(this, proto.metadata.Amount, 3));
};


/**
 * @param {?proto.metadata.Amount|undefined} value
 * @return {!proto.metadata.Fee} returns this
*/
proto.metadata.Fee.prototype.setMaxamt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.Fee} returns this
 */
proto.metadata.Fee.prototype.clearMaxamt = function() {
  return this.setMaxamt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.Fee.prototype.hasMaxamt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string Rate = 4;
 * @return {string}
 */
proto.metadata.Fee.prototype.getRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.Fee} returns this
 */
proto.metadata.Fee.prototype.setRate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.Bank.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.Bank.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.Bank} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Bank.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    maxtransfer: (f = msg.getMaxtransfer()) && proto.metadata.Amount.toObject(includeInstance, f),
    logo: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.Bank}
 */
proto.metadata.Bank.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.Bank;
  return proto.metadata.Bank.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.Bank} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.Bank}
 */
proto.metadata.Bank.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 4:
      var value = new proto.metadata.Amount;
      reader.readMessage(value,proto.metadata.Amount.deserializeBinaryFromReader);
      msg.setMaxtransfer(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.Bank.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.Bank.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.Bank} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Bank.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getMaxtransfer();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.metadata.Amount.serializeBinaryToWriter
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string Code = 1;
 * @return {string}
 */
proto.metadata.Bank.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.Bank} returns this
 */
proto.metadata.Bank.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.metadata.Bank.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.Bank} returns this
 */
proto.metadata.Bank.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool Enabled = 3;
 * @return {boolean}
 */
proto.metadata.Bank.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.metadata.Bank} returns this
 */
proto.metadata.Bank.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional Amount MaxTransfer = 4;
 * @return {?proto.metadata.Amount}
 */
proto.metadata.Bank.prototype.getMaxtransfer = function() {
  return /** @type{?proto.metadata.Amount} */ (
    jspb.Message.getWrapperField(this, proto.metadata.Amount, 4));
};


/**
 * @param {?proto.metadata.Amount|undefined} value
 * @return {!proto.metadata.Bank} returns this
*/
proto.metadata.Bank.prototype.setMaxtransfer = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.Bank} returns this
 */
proto.metadata.Bank.prototype.clearMaxtransfer = function() {
  return this.setMaxtransfer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.Bank.prototype.hasMaxtransfer = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string Logo = 5;
 * @return {string}
 */
proto.metadata.Bank.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.Bank} returns this
 */
proto.metadata.Bank.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.metadata.Biller.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.Biller.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.Biller.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.Biller} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Biller.toObject = function(includeInstance, msg) {
  var f, obj = {
    billercode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    billername: jspb.Message.getFieldWithDefault(msg, 2, ""),
    billerdescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fee: (f = msg.getFee()) && proto.metadata.Amount.toObject(includeInstance, f),
    categoryList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.Biller}
 */
proto.metadata.Biller.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.Biller;
  return proto.metadata.Biller.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.Biller} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.Biller}
 */
proto.metadata.Biller.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillercode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillerdescription(value);
      break;
    case 4:
      var value = new proto.metadata.Amount;
      reader.readMessage(value,proto.metadata.Amount.deserializeBinaryFromReader);
      msg.setFee(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.Biller.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.Biller.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.Biller} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Biller.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillercode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBillername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBillerdescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFee();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.metadata.Amount.serializeBinaryToWriter
    );
  }
  f = message.getCategoryList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string BillerCode = 1;
 * @return {string}
 */
proto.metadata.Biller.prototype.getBillercode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.Biller} returns this
 */
proto.metadata.Biller.prototype.setBillercode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string BillerName = 2;
 * @return {string}
 */
proto.metadata.Biller.prototype.getBillername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.Biller} returns this
 */
proto.metadata.Biller.prototype.setBillername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string BillerDescription = 3;
 * @return {string}
 */
proto.metadata.Biller.prototype.getBillerdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.Biller} returns this
 */
proto.metadata.Biller.prototype.setBillerdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Amount Fee = 4;
 * @return {?proto.metadata.Amount}
 */
proto.metadata.Biller.prototype.getFee = function() {
  return /** @type{?proto.metadata.Amount} */ (
    jspb.Message.getWrapperField(this, proto.metadata.Amount, 4));
};


/**
 * @param {?proto.metadata.Amount|undefined} value
 * @return {!proto.metadata.Biller} returns this
*/
proto.metadata.Biller.prototype.setFee = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.Biller} returns this
 */
proto.metadata.Biller.prototype.clearFee = function() {
  return this.setFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.Biller.prototype.hasFee = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated string Category = 5;
 * @return {!Array<string>}
 */
proto.metadata.Biller.prototype.getCategoryList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.metadata.Biller} returns this
 */
proto.metadata.Biller.prototype.setCategoryList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.metadata.Biller} returns this
 */
proto.metadata.Biller.prototype.addCategory = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.metadata.Biller} returns this
 */
proto.metadata.Biller.prototype.clearCategoryList = function() {
  return this.setCategoryList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.Insurance.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.Insurance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.Insurance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Insurance.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sellingprice: (f = msg.getSellingprice()) && proto.metadata.Amount.toObject(includeInstance, f),
    limitperyear: jspb.Message.getFieldWithDefault(msg, 4, 0),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    code: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.Insurance}
 */
proto.metadata.Insurance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.Insurance;
  return proto.metadata.Insurance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.Insurance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.Insurance}
 */
proto.metadata.Insurance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.metadata.Amount;
      reader.readMessage(value,proto.metadata.Amount.deserializeBinaryFromReader);
      msg.setSellingprice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimitperyear(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.Insurance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.Insurance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.Insurance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.Insurance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSellingprice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.metadata.Amount.serializeBinaryToWriter
    );
  }
  f = message.getLimitperyear();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 Id = 1;
 * @return {number}
 */
proto.metadata.Insurance.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.metadata.Insurance} returns this
 */
proto.metadata.Insurance.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.metadata.Insurance.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.Insurance} returns this
 */
proto.metadata.Insurance.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Amount SellingPrice = 3;
 * @return {?proto.metadata.Amount}
 */
proto.metadata.Insurance.prototype.getSellingprice = function() {
  return /** @type{?proto.metadata.Amount} */ (
    jspb.Message.getWrapperField(this, proto.metadata.Amount, 3));
};


/**
 * @param {?proto.metadata.Amount|undefined} value
 * @return {!proto.metadata.Insurance} returns this
*/
proto.metadata.Insurance.prototype.setSellingprice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.Insurance} returns this
 */
proto.metadata.Insurance.prototype.clearSellingprice = function() {
  return this.setSellingprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.Insurance.prototype.hasSellingprice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 LimitPerYear = 4;
 * @return {number}
 */
proto.metadata.Insurance.prototype.getLimitperyear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.metadata.Insurance} returns this
 */
proto.metadata.Insurance.prototype.setLimitperyear = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string Description = 5;
 * @return {string}
 */
proto.metadata.Insurance.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.Insurance} returns this
 */
proto.metadata.Insurance.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Code = 6;
 * @return {string}
 */
proto.metadata.Insurance.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.Insurance} returns this
 */
proto.metadata.Insurance.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.metadata.InsuranceCategory.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.InsuranceCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.InsuranceCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.InsuranceCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.InsuranceCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.metadata.Insurance.toObject, includeInstance),
    icondata: msg.getIcondata_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.InsuranceCategory}
 */
proto.metadata.InsuranceCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.InsuranceCategory;
  return proto.metadata.InsuranceCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.InsuranceCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.InsuranceCategory}
 */
proto.metadata.InsuranceCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.metadata.Insurance;
      reader.readMessage(value,proto.metadata.Insurance.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setIcondata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.InsuranceCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.InsuranceCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.InsuranceCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.InsuranceCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.metadata.Insurance.serializeBinaryToWriter
    );
  }
  f = message.getIcondata_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional int32 Id = 1;
 * @return {number}
 */
proto.metadata.InsuranceCategory.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.metadata.InsuranceCategory} returns this
 */
proto.metadata.InsuranceCategory.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.metadata.InsuranceCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.InsuranceCategory} returns this
 */
proto.metadata.InsuranceCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Insurance Products = 3;
 * @return {!Array<!proto.metadata.Insurance>}
 */
proto.metadata.InsuranceCategory.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.metadata.Insurance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.metadata.Insurance, 3));
};


/**
 * @param {!Array<!proto.metadata.Insurance>} value
 * @return {!proto.metadata.InsuranceCategory} returns this
*/
proto.metadata.InsuranceCategory.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.metadata.Insurance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.metadata.Insurance}
 */
proto.metadata.InsuranceCategory.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.metadata.Insurance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.metadata.InsuranceCategory} returns this
 */
proto.metadata.InsuranceCategory.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * optional bytes IconData = 4;
 * @return {!(string|Uint8Array)}
 */
proto.metadata.InsuranceCategory.prototype.getIcondata = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes IconData = 4;
 * This is a type-conversion wrapper around `getIcondata()`
 * @return {string}
 */
proto.metadata.InsuranceCategory.prototype.getIcondata_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getIcondata()));
};


/**
 * optional bytes IconData = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getIcondata()`
 * @return {!Uint8Array}
 */
proto.metadata.InsuranceCategory.prototype.getIcondata_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getIcondata()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.metadata.InsuranceCategory} returns this
 */
proto.metadata.InsuranceCategory.prototype.setIcondata = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.RemittancePartner.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.RemittancePartner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.RemittancePartner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.RemittancePartner.toObject = function(includeInstance, msg) {
  var f, obj = {
    partnerid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    logolandscape: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tcurl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    minimumage: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.RemittancePartner}
 */
proto.metadata.RemittancePartner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.RemittancePartner;
  return proto.metadata.RemittancePartner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.RemittancePartner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.RemittancePartner}
 */
proto.metadata.RemittancePartner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogolandscape(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTcurl(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinimumage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.RemittancePartner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.RemittancePartner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.RemittancePartner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.RemittancePartner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartnerid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLogolandscape();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTcurl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMinimumage();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string PartnerID = 1;
 * @return {string}
 */
proto.metadata.RemittancePartner.prototype.getPartnerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.RemittancePartner} returns this
 */
proto.metadata.RemittancePartner.prototype.setPartnerid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.metadata.RemittancePartner.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.RemittancePartner} returns this
 */
proto.metadata.RemittancePartner.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Logo = 3;
 * @return {string}
 */
proto.metadata.RemittancePartner.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.RemittancePartner} returns this
 */
proto.metadata.RemittancePartner.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string LogoLandscape = 4;
 * @return {string}
 */
proto.metadata.RemittancePartner.prototype.getLogolandscape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.RemittancePartner} returns this
 */
proto.metadata.RemittancePartner.prototype.setLogolandscape = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string TCUrl = 5;
 * @return {string}
 */
proto.metadata.RemittancePartner.prototype.getTcurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.RemittancePartner} returns this
 */
proto.metadata.RemittancePartner.prototype.setTcurl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 MinimumAge = 6;
 * @return {number}
 */
proto.metadata.RemittancePartner.prototype.getMinimumage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.metadata.RemittancePartner} returns this
 */
proto.metadata.RemittancePartner.prototype.setMinimumage = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.IDType.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.IDType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.IDType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.IDType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    expirydaterequired: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    issuedaterequired: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.IDType}
 */
proto.metadata.IDType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.IDType;
  return proto.metadata.IDType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.IDType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.IDType}
 */
proto.metadata.IDType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpirydaterequired(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIssuedaterequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.IDType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.IDType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.IDType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.IDType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpirydaterequired();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIssuedaterequired();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.metadata.IDType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.IDType} returns this
 */
proto.metadata.IDType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Value = 2;
 * @return {string}
 */
proto.metadata.IDType.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.metadata.IDType} returns this
 */
proto.metadata.IDType.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool ExpiryDateRequired = 3;
 * @return {boolean}
 */
proto.metadata.IDType.prototype.getExpirydaterequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.metadata.IDType} returns this
 */
proto.metadata.IDType.prototype.setExpirydaterequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool IssueDateRequired = 4;
 * @return {boolean}
 */
proto.metadata.IDType.prototype.getIssuedaterequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.metadata.IDType} returns this
 */
proto.metadata.IDType.prototype.setIssuedaterequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.metadata.EFT.prototype.toObject = function(opt_includeInstance) {
  return proto.metadata.EFT.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.metadata.EFT} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.EFT.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxamountpertxn: (f = msg.getMaxamountpertxn()) && proto.metadata.Amount.toObject(includeInstance, f),
    maxtxnamountperday: (f = msg.getMaxtxnamountperday()) && proto.metadata.Amount.toObject(includeInstance, f),
    maxtxnamountpermonth: (f = msg.getMaxtxnamountpermonth()) && proto.metadata.Amount.toObject(includeInstance, f),
    maxsuccessfultxnperday: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minamountpertxn: (f = msg.getMinamountpertxn()) && proto.metadata.Amount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.metadata.EFT}
 */
proto.metadata.EFT.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.metadata.EFT;
  return proto.metadata.EFT.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.metadata.EFT} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.metadata.EFT}
 */
proto.metadata.EFT.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.metadata.Amount;
      reader.readMessage(value,proto.metadata.Amount.deserializeBinaryFromReader);
      msg.setMaxamountpertxn(value);
      break;
    case 2:
      var value = new proto.metadata.Amount;
      reader.readMessage(value,proto.metadata.Amount.deserializeBinaryFromReader);
      msg.setMaxtxnamountperday(value);
      break;
    case 3:
      var value = new proto.metadata.Amount;
      reader.readMessage(value,proto.metadata.Amount.deserializeBinaryFromReader);
      msg.setMaxtxnamountpermonth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxsuccessfultxnperday(value);
      break;
    case 5:
      var value = new proto.metadata.Amount;
      reader.readMessage(value,proto.metadata.Amount.deserializeBinaryFromReader);
      msg.setMinamountpertxn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.metadata.EFT.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.metadata.EFT.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.metadata.EFT} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.metadata.EFT.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxamountpertxn();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.metadata.Amount.serializeBinaryToWriter
    );
  }
  f = message.getMaxtxnamountperday();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.metadata.Amount.serializeBinaryToWriter
    );
  }
  f = message.getMaxtxnamountpermonth();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.metadata.Amount.serializeBinaryToWriter
    );
  }
  f = message.getMaxsuccessfultxnperday();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMinamountpertxn();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.metadata.Amount.serializeBinaryToWriter
    );
  }
};


/**
 * optional Amount MaxAmountPerTxn = 1;
 * @return {?proto.metadata.Amount}
 */
proto.metadata.EFT.prototype.getMaxamountpertxn = function() {
  return /** @type{?proto.metadata.Amount} */ (
    jspb.Message.getWrapperField(this, proto.metadata.Amount, 1));
};


/**
 * @param {?proto.metadata.Amount|undefined} value
 * @return {!proto.metadata.EFT} returns this
*/
proto.metadata.EFT.prototype.setMaxamountpertxn = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.EFT} returns this
 */
proto.metadata.EFT.prototype.clearMaxamountpertxn = function() {
  return this.setMaxamountpertxn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.EFT.prototype.hasMaxamountpertxn = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Amount MaxTxnAmountPerDay = 2;
 * @return {?proto.metadata.Amount}
 */
proto.metadata.EFT.prototype.getMaxtxnamountperday = function() {
  return /** @type{?proto.metadata.Amount} */ (
    jspb.Message.getWrapperField(this, proto.metadata.Amount, 2));
};


/**
 * @param {?proto.metadata.Amount|undefined} value
 * @return {!proto.metadata.EFT} returns this
*/
proto.metadata.EFT.prototype.setMaxtxnamountperday = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.EFT} returns this
 */
proto.metadata.EFT.prototype.clearMaxtxnamountperday = function() {
  return this.setMaxtxnamountperday(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.EFT.prototype.hasMaxtxnamountperday = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Amount MaxTxnAmountPerMonth = 3;
 * @return {?proto.metadata.Amount}
 */
proto.metadata.EFT.prototype.getMaxtxnamountpermonth = function() {
  return /** @type{?proto.metadata.Amount} */ (
    jspb.Message.getWrapperField(this, proto.metadata.Amount, 3));
};


/**
 * @param {?proto.metadata.Amount|undefined} value
 * @return {!proto.metadata.EFT} returns this
*/
proto.metadata.EFT.prototype.setMaxtxnamountpermonth = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.EFT} returns this
 */
proto.metadata.EFT.prototype.clearMaxtxnamountpermonth = function() {
  return this.setMaxtxnamountpermonth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.EFT.prototype.hasMaxtxnamountpermonth = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 MaxSuccessfulTxnPerDay = 4;
 * @return {number}
 */
proto.metadata.EFT.prototype.getMaxsuccessfultxnperday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.metadata.EFT} returns this
 */
proto.metadata.EFT.prototype.setMaxsuccessfultxnperday = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Amount MinAmountPerTxn = 5;
 * @return {?proto.metadata.Amount}
 */
proto.metadata.EFT.prototype.getMinamountpertxn = function() {
  return /** @type{?proto.metadata.Amount} */ (
    jspb.Message.getWrapperField(this, proto.metadata.Amount, 5));
};


/**
 * @param {?proto.metadata.Amount|undefined} value
 * @return {!proto.metadata.EFT} returns this
*/
proto.metadata.EFT.prototype.setMinamountpertxn = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.metadata.EFT} returns this
 */
proto.metadata.EFT.prototype.clearMinamountpertxn = function() {
  return this.setMinamountpertxn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.metadata.EFT.prototype.hasMinamountpertxn = function() {
  return jspb.Message.getField(this, 5) != null;
};


goog.object.extend(exports, proto.metadata);
