

















































































import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import { FilterInputValue, FilterInputOption, FilterType } from "../../types";

import FilterDate from "../../filter-date/FilterDate.vue";

import Datepicker from "vuejs-datepicker";

import SvgIcon from "../../../../components/svg-icon/SvgIcon.vue";
import Dropdown from "../../../dropdown/Dropdown.vue";

interface State {
  rules: any;
}

@Component({
  components: {
    Datepicker,
    SvgIcon,
    Dropdown,
    FilterDate,
  },
})
export default class FilterInput extends Vue {
  @Prop()
  private readonly name!: string;

  @Prop()
  private readonly type!: FilterType;

  @Prop({ default: () => [] })
  private readonly options!: Array<FilterInputOption>;

  @Prop({ default: null })
  private readonly value!: FilterInputValue;

  @Prop({ default: null })
  private readonly min?: number | null;

  @Prop({ default: null })
  private readonly max?: number | null;

  @Prop({ default: "vertical" })
  private readonly dateRangePosition!: "vertical" | "horizontal";

  private state: State = {
    rules: {
      dateRange: {
        disabled: {
          start: {
            from: null,
            to: null,
          },
          end: {
            from: null,
            to: null,
          },
        },
      },
      date: {
        filterType: "isInLast",
      },
    },
  };

  onChangeDateFilterType() {
    console.log("changed");
  }

  onChange(value: any) {
    const val =
      this.type === "checkbox" ? this._getCheckedValues(value) : value;

    this.$emit("input", val);
  }

  _getCheckedValues(value: any) {
    if (!Array.isArray(this.value)) {
      return [value];
    }

    if (this.value.includes(value)) {
      return this.value.filter((x) => x !== value);
    } else {
      return this.value.concat(value);
    }
  }

  _getInitialValue(type: FilterType): FilterInputValue {
    switch (type) {
      case "text":
      case "radio":
        return "";
      case "checkbox":
        return [];
      case "number":
        return null;
      case "date":
        return null;
      default:
        return null;
    }
  }

  /**
   * Used to determine if checkbox is checked
   * based on the provided array of values
   */
  _isCheckboxChecked(value: string) {
    if (!this.value || !Array.isArray(this.value)) {
      return false;
    }

    return (this.value as Array<string>).includes(value);
  }

  /**
   * Used to determine if radio button is selected.
   * This also handles issue with numerical values since
   * radio button converts values to string when selected
   */
  _isRadioSelected(value: any) {
    /**
     * Interpolate to handle potential
     * null, undefined, and numerical values
     */
    return this.value === `${value}`;
  }

  /**
   * Since the value of the filter is dynamic, we need
   * to ensure that there is a default fallback value
   * based on the provided type
   */
  get filterValue() {
    return this.value || this._getInitialValue(this.type);
  }
}
