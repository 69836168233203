import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { ProtobufMessage } from "@improbable-eng/grpc-web/dist/typings/message";

import { BillsPayment as Service } from "@/proto/billing/all_pb_service";
import * as ServiceProto from "@/proto/billing/all_pb";

import { Biller, PayBill } from "@/types";
import AuthUtils from "@/utils/auth-utils";

export default class Billing {
  constructor(private baseURL: string, private token: string) {}

  private genericErrorMessage =
    "Connection to the network failed. Please contact our support team support@cebuanalhullier.com.";

  public async getBillers() {
    const request = new ServiceProto.GetBillersRequest();

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.GetBillers, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      throw new Error(`unexpected status code=${status} msg=${statusMessage}`);
    } else if (message === null) {
      throw new Error("received no data");
    } else {
      const decoded = message as ServiceProto.GetBillersResponse;
      const billers = decoded.toObject();
      const billercodesMap = billers.billercodesMap;

      return billercodesMap.map(
        (biller): Biller => {
          return {
            label: biller[1].billername,
            refCode: biller[1].billercode,
          };
        }
      );
    }
  }

  public async payBill(payload: PayBill) {
    const request = new ServiceProto.PayBillRequest();

    const amount = new ServiceProto.Amount();
    amount.setAmt(payload.amount);
    amount.setCur("PHP");

    const fullName = new ServiceProto.FullName();
    fullName.setFirstname("Kim Lian");
    fullName.setMiddlename("");
    fullName.setLastname("Lopez");

    request.setBillercode(payload.billerCode);
    request.setAccountno(payload.accountNo);
    request.setAmount(amount);
    request.setRegisteredname(fullName);

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.PayBill, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      console.error(
        `Error when paying bill: unexpected status code=${status} msg=${statusMessage}`
      );
      throw new Error(this.genericErrorMessage);
    } else if (message === null) {
      throw new Error("received no data");
    } else {
      const decoded = message as ServiceProto.PayBillResponse;
      return decoded.toObject();
    }
  }

  public async getBillInputs(billerCode: string) {
    const request = new ServiceProto.GetBillInputsRequest();
    request.setBillercode(billerCode);
    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.GetBillInputs, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      throw new Error(`unexpected status code=${status} msg=${statusMessage}`);
    } else if (message === null) {
      throw new Error("received no data");
    } else {
      const decoded = message as ServiceProto.GetBillInputsResponse;
      const billInputs = decoded.toObject();
      return billInputs;
    }
  }
}
