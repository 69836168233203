import Vue from "vue";
import { GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";
import { Bank } from "@/proto/banks/all_pb";
import * as actions from "./action";

export interface ConfigState {
  environment: string;
  profileURL: string;
  apiURL: string;
  banks: {
    pesonet: Array<Bank.AsObject>;
    instapay: Array<Bank.AsObject>;
    all: Array<[string, Bank.AsObject]>;
  };
  locations: {
    cities: Array<any>;
    provinces: Array<any>;
  };
}

const getInitialState = (): ConfigState => {
  return {
    environment: "",
    profileURL: "",
    apiURL: "",
    banks: {
      pesonet: [],
      instapay: [],
      all: [],
    },
    locations: {
      cities: [],
      provinces: [],
    },
  };
};

const initialState: ConfigState = getInitialState();

const getters: GetterTree<ConfigState, RootState> = {
  pesonetBanks: (state: ConfigState): Array<Bank.AsObject> => {
    return state.banks.pesonet;
  },
  instapayBanks: (state: ConfigState): Array<Bank.AsObject> => {
    return state.banks.instapay;
  },
  allBanks: (state: ConfigState): Array<[string, Bank.AsObject]> => {
    return state.banks.all;
  },
  cities: (state: ConfigState): Array<any> => {
    return state.locations.cities;
  },
  provinces: (state: ConfigState): Array<any> => {
    return state.locations.provinces;
  },
};

const mutations: MutationTree<ConfigState> = {
  setConfig(state: ConfigState, config: any) {
    Vue.set(state, "environment", config["environment"]);
    Vue.set(state, "profileURL", config["profileUrl"]);
    Vue.set(state, "apiURL", config["apiUrl"]);
  },
  setPesonetBanks(state: ConfigState, bankList: Array<Bank.AsObject>) {
    state.banks.pesonet = bankList;
  },
  setInstapayBanks(state: ConfigState, bankList: Array<Bank.AsObject>) {
    state.banks.instapay = bankList;
  },
  setAllBanks(state: ConfigState, bankList: Array<[string, Bank.AsObject]>) {
    state.banks.all = bankList;
  },
  setCities(state: ConfigState, cities: Array<any>) {
    state.locations.cities = cities;
  },
  setProvinces(state: ConfigState, provinces: Array<any>) {
    state.locations.provinces = provinces;
  },
};

const config: Module<ConfigState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default config;
