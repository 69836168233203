









export default {
  name: "IconUnblock",
};
