import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { ProtobufMessage } from "@improbable-eng/grpc-web/dist/typings/message";

import { FavoriteService as Service } from "@/proto/favorites/all_pb_service";
import * as ServiceProto from "@/proto/favorites/all_pb";

import { Favorite } from "@/types";

import AuthUtils from "@/utils/auth-utils";

export default class Favorites {
  constructor(private baseURL: string, private token: string) {}

  private genericErrorMessage =
    "Connection to the network failed. Please contact our support team support@cebuanalhullier.com.";

  public async addFavorite(fav: Favorite) {
    const request = new ServiceProto.AddFavoriteRequest();

    request.setBillingcode(fav.billingCode);
    request.setRefcode(fav.refCode);
    request.setFavtype(fav.type);
    request.setCategory(fav.category as string);

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.AddFavorite, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      console.error(
        `Error when fetching favorites: unexpected status code=${status} msg=${statusMessage}`
      );
      throw new Error(this.genericErrorMessage);
    } else if (message === null) {
      console.error(
        `No data when fetching favorites: unexpected status code=${status} msg=${statusMessage}`
      );
      throw new Error(this.genericErrorMessage);
    } else {
      const decoded = message as ServiceProto.AddFavoriteResponse;
      return decoded.toObject();
    }
  }

  public async getFavoritesList() {
    const request = new ServiceProto.GetFavoritesRequest();

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.GetFavorites, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      console.error(
        `Error when fetching favorites: unexpected status code=${status} msg=${statusMessage}`
      );
      throw new Error(this.genericErrorMessage);
    } else if (message === null) {
      console.error(
        `No data when fetching favorites: unexpected status code=${status} msg=${statusMessage}`
      );
      throw new Error(this.genericErrorMessage);
    } else {
      const decoded = message as ServiceProto.GetFavoritesResponse;
      return decoded.toObject();
    }
  }
}
