import { Module, MutationTree } from "vuex";

import { RootState } from "@/store";
import * as actions from "./action";

export interface TransactionState {
  loading: boolean;
  error: string;
  transactions: Array<any>;
}

const getInitialState = (): TransactionState => {
  return {
    loading: true,
    error: "",
    transactions: [],
  };
};

const initialState: TransactionState = getInitialState();

const mutations: MutationTree<TransactionState> = {
  setLoading(state: TransactionState, loading: boolean) {
    state.loading = loading;
  },
  setTransactions(state: TransactionState, payload: Array<any>) {
    state.transactions = payload;
  },
  setError(state: TransactionState, error: string) {
    state.error = error;
  },
};

const transactions: Module<TransactionState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};

export default transactions;
