import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { ProtobufMessage } from "@improbable-eng/grpc-web/dist/typings/message";

import { MetadataService as Service } from "@/proto/metadata/all_pb_service";
import * as ServiceProto from "@/proto/metadata/all_pb";
import { FeeService } from "@/proto/fee/all_pb_service";
import * as FeeServiceProto from "@/proto/fee/all_pb";

// import { FeeList, Services, FeeType, FeeStatus, FeeHandler } from "@/types";
import AuthUtils from "@/utils/auth-utils";

export default class Metadata {
  constructor(private baseURL: string, private token: string) {}

  private genericErrorMessage =
    "Connection to the network failed. Please contact our support team support@cebuanalhullier.com.";

  public async getMetadata() {
    const request = new ServiceProto.GetMetadataRequest();

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(Service.GetMetadata, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      throw new Error(`unexpected status code=${status} msg=${statusMessage}`);
    } else if (message === null) {
      throw new Error("received no data");
    } else {
      const decoded = message as ServiceProto.Metadata;
      const metadata = decoded.toObject();
      return metadata;
    }
  }

  public async getFeeList() {
    const request = new FeeServiceProto.GetFeesRequest();

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(FeeService.GetFees, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      throw new Error(`unexpected status code=${status} msg=${statusMessage}`);
    } else if (message === null) {
      throw new Error("received no data");
    } else {
      const decoded = message as FeeServiceProto.GetFeesResponse;
      const { feesList } = decoded.toObject();
      return feesList;
    }
  }

  public async updateFee(f: FeeServiceProto.Fee.AsObject) {
    const request = new FeeServiceProto.UpdateFeeRequest();
    const fee = new FeeServiceProto.Fee();

    fee.setService(f.service);
    fee.setName(f.name);
    fee.setFeetype(f.feetype);
    fee.setResponsible(f.responsible);
    fee.setStatus(f.status);
    fee.setCost(f.cost);

    request.setFee(fee);

    const {
      status,
      message,
      statusMessage,
    }: UnaryOutput<ProtobufMessage> = await new Promise((resolve) => {
      grpc.unary(FeeService.UpdateFee, {
        request,
        host: this.baseURL,
        onEnd: resolve,
        metadata: AuthUtils.getMetadata(this.token),
      });
    });

    if (status !== grpc.Code.OK) {
      throw new Error(`unexpected status code=${status} msg=${statusMessage}`);
    } else if (message === null) {
      throw new Error("received no data");
    }
  }
}
