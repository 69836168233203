import { GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";
import * as actions from "./action";
import utils from "@/utils/auth-utils";

export interface AuthState {
  loading: boolean;
  error: string;
  redirectUrl: string;
  loginParams: Record<string, string>;
  user: {
    accessToken: string;
    refreshToken: string;
    expiryTime: number;
    orgId?: string;
  };
  /* This is the Interval ID the checks the auth status of user */
  monitorId: number;
}

const getInitialState = (): AuthState => {
  return {
    loading: false,
    error: "",
    redirectUrl: "",
    monitorId: 0,
    user: {
      accessToken: "",
      expiryTime: 0,
      orgId: "",
      refreshToken: "",
    },
    loginParams: {},
  };
};

const initialState: AuthState = getInitialState();

const getters: GetterTree<AuthState, RootState> = {
  isTokenExpired: (state: AuthState): boolean => {
    return utils.isTokenExpired(state.user.expiryTime);
  },
  isAuthenticated: (state: AuthState): boolean => {
    const isTokenExpired = utils.isTokenExpired(state.user.expiryTime);
    return state.user.accessToken.trim().length > 0 && !isTokenExpired;
  },
  accessToken: (state: AuthState): string => {
    return state.user.accessToken;
  },
};

const mutations: MutationTree<AuthState> = {
  setRedirectUrl(state: AuthState, redirectUrl: string) {
    state.redirectUrl = redirectUrl;
  },
  setUser(state: AuthState, user: AuthState["user"]) {
    state.user = user;
  },
  setOrgId(state: AuthState, orgId: string) {
    state.user.orgId = orgId;
  },
  setMonitorId(state: AuthState, monitorId: number) {
    state.monitorId = monitorId;
  },
  setLoginParams(state: AuthState, params: Record<string, string>) {
    state.loginParams = params;
  },
  resetState(state: AuthState) {
    Object.assign(state, getInitialState());
  },
};

const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default auth;
