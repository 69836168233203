

























import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { SvgIcon, Loader } from "@components";
import { Batch } from "@/proto/batch/all_pb";

const transactionsModule = namespace("transactions");

interface ProcessBatchPayload {
  batchID: string;
  reject: boolean;
}

@Component({
  components: {
    SvgIcon,
    Loader,
  },
})
export default class RejectBatchModal extends Vue {
  @Prop()
  batch!: Batch.AsObject;

  @transactionsModule.Action("processBatch") processBatch!: (
    payload: ProcessBatchPayload
  ) => void;

  loading = false;
  error = "";

  async confirmBatchReject() {
    this.loading = true;

    try {
      const payload: ProcessBatchPayload = {
        batchID: this.batch.id,
        reject: true,
      };
      await this.processBatch(payload);
      this.$emit("on-confirm-reject");
    } catch (error) {
      const err = error as Error;
      this.error = err.message;
    } finally {
      this.loading = false;
    }
  }
}
