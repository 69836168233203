// source: all.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var github_com_openbank_openbank_v1_types_all_pb = require('./../types/all_pb.js');
goog.object.extend(proto, github_com_openbank_openbank_v1_types_all_pb);
goog.exportSymbol('proto.accounts.Account', null, global);
goog.exportSymbol('proto.accounts.AccountRole', null, global);
goog.exportSymbol('proto.accounts.CheckAccountRequest', null, global);
goog.exportSymbol('proto.accounts.CheckAccountResponse', null, global);
goog.exportSymbol('proto.accounts.CreateAccountRequest', null, global);
goog.exportSymbol('proto.accounts.CreateAccountResponse', null, global);
goog.exportSymbol('proto.accounts.DebitTransaction', null, global);
goog.exportSymbol('proto.accounts.DeleteAccountRequest', null, global);
goog.exportSymbol('proto.accounts.EntityType', null, global);
goog.exportSymbol('proto.accounts.GetAccountRequest', null, global);
goog.exportSymbol('proto.accounts.GetAccountStatusRequest', null, global);
goog.exportSymbol('proto.accounts.GetAccountStatusResponse', null, global);
goog.exportSymbol('proto.accounts.GetAccountsRequest', null, global);
goog.exportSymbol('proto.accounts.GetAccountsResponse', null, global);
goog.exportSymbol('proto.accounts.UpdateAccountRequest', null, global);
goog.exportSymbol('proto.accounts.UpdateAccountStatusRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accounts.Account = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accounts.Account, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accounts.Account.displayName = 'proto.accounts.Account';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accounts.GetAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accounts.GetAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accounts.GetAccountRequest.displayName = 'proto.accounts.GetAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accounts.GetAccountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accounts.GetAccountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accounts.GetAccountsRequest.displayName = 'proto.accounts.GetAccountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accounts.GetAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accounts.GetAccountsResponse.repeatedFields_, null);
};
goog.inherits(proto.accounts.GetAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accounts.GetAccountsResponse.displayName = 'proto.accounts.GetAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accounts.AccountRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accounts.AccountRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accounts.AccountRole.displayName = 'proto.accounts.AccountRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accounts.DebitTransaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accounts.DebitTransaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accounts.DebitTransaction.displayName = 'proto.accounts.DebitTransaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accounts.CreateAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accounts.CreateAccountRequest.repeatedFields_, null);
};
goog.inherits(proto.accounts.CreateAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accounts.CreateAccountRequest.displayName = 'proto.accounts.CreateAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accounts.CreateAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accounts.CreateAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accounts.CreateAccountResponse.displayName = 'proto.accounts.CreateAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accounts.UpdateAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accounts.UpdateAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accounts.UpdateAccountRequest.displayName = 'proto.accounts.UpdateAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accounts.UpdateAccountStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accounts.UpdateAccountStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accounts.UpdateAccountStatusRequest.displayName = 'proto.accounts.UpdateAccountStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accounts.GetAccountStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accounts.GetAccountStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accounts.GetAccountStatusRequest.displayName = 'proto.accounts.GetAccountStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accounts.GetAccountStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accounts.GetAccountStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accounts.GetAccountStatusResponse.displayName = 'proto.accounts.GetAccountStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accounts.DeleteAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accounts.DeleteAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accounts.DeleteAccountRequest.displayName = 'proto.accounts.DeleteAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accounts.CheckAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accounts.CheckAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accounts.CheckAccountRequest.displayName = 'proto.accounts.CheckAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accounts.CheckAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accounts.CheckAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accounts.CheckAccountResponse.displayName = 'proto.accounts.CheckAccountResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accounts.Account.prototype.toObject = function(opt_includeInstance) {
  return proto.accounts.Account.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accounts.Account} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.Account.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 2, ""),
    branchname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accruedinterestatmaturitydate: (f = msg.getAccruedinterestatmaturitydate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    amountdue: (f = msg.getAmountdue()) && github_com_openbank_openbank_v1_types_all_pb.Amount.toObject(includeInstance, f),
    availablebalance: (f = msg.getAvailablebalance()) && github_com_openbank_openbank_v1_types_all_pb.Amount.toObject(includeInstance, f),
    availablecreditlimit: jspb.Message.getFieldWithDefault(msg, 8, ""),
    checkinginterestrate: jspb.Message.getFieldWithDefault(msg, 9, ""),
    contractdate: (f = msg.getContractdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    creditlimit: jspb.Message.getFieldWithDefault(msg, 11, ""),
    currentaccruedinterest: jspb.Message.getFieldWithDefault(msg, 12, ""),
    currentbalance: (f = msg.getCurrentbalance()) && github_com_openbank_openbank_v1_types_all_pb.Amount.toObject(includeInstance, f),
    currentterm: jspb.Message.getFieldWithDefault(msg, 14, ""),
    duedate: (f = msg.getDuedate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    interestrate: jspb.Message.getFieldWithDefault(msg, 16, ""),
    majortype: jspb.Message.getFieldWithDefault(msg, 17, 0),
    majorcategory: jspb.Message.getFieldWithDefault(msg, 18, 0),
    maturitydate: (f = msg.getMaturitydate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    nextpaymentduedate: (f = msg.getNextpaymentduedate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    ownername: jspb.Message.getFieldWithDefault(msg, 21, ""),
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    holdbalance: (f = msg.getHoldbalance()) && github_com_openbank_openbank_v1_types_all_pb.Amount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accounts.Account}
 */
proto.accounts.Account.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accounts.Account;
  return proto.accounts.Account.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accounts.Account} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accounts.Account}
 */
proto.accounts.Account.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAccruedinterestatmaturitydate(value);
      break;
    case 6:
      var value = new github_com_openbank_openbank_v1_types_all_pb.Amount;
      reader.readMessage(value,github_com_openbank_openbank_v1_types_all_pb.Amount.deserializeBinaryFromReader);
      msg.setAmountdue(value);
      break;
    case 7:
      var value = new github_com_openbank_openbank_v1_types_all_pb.Amount;
      reader.readMessage(value,github_com_openbank_openbank_v1_types_all_pb.Amount.deserializeBinaryFromReader);
      msg.setAvailablebalance(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailablecreditlimit(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckinginterestrate(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setContractdate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditlimit(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentaccruedinterest(value);
      break;
    case 13:
      var value = new github_com_openbank_openbank_v1_types_all_pb.Amount;
      reader.readMessage(value,github_com_openbank_openbank_v1_types_all_pb.Amount.deserializeBinaryFromReader);
      msg.setCurrentbalance(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentterm(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDuedate(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterestrate(value);
      break;
    case 17:
      var value = /** @type {!proto.types.MajorType} */ (reader.readEnum());
      msg.setMajortype(value);
      break;
    case 18:
      var value = /** @type {!proto.types.MajorCategory} */ (reader.readEnum());
      msg.setMajorcategory(value);
      break;
    case 19:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMaturitydate(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setNextpaymentduedate(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnername(value);
      break;
    case 22:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 23:
      var value = new github_com_openbank_openbank_v1_types_all_pb.Amount;
      reader.readMessage(value,github_com_openbank_openbank_v1_types_all_pb.Amount.deserializeBinaryFromReader);
      msg.setHoldbalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accounts.Account.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accounts.Account.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accounts.Account} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.Account.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBranchname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccruedinterestatmaturitydate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAmountdue();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      github_com_openbank_openbank_v1_types_all_pb.Amount.serializeBinaryToWriter
    );
  }
  f = message.getAvailablebalance();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      github_com_openbank_openbank_v1_types_all_pb.Amount.serializeBinaryToWriter
    );
  }
  f = message.getAvailablecreditlimit();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCheckinginterestrate();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getContractdate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreditlimit();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCurrentaccruedinterest();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCurrentbalance();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      github_com_openbank_openbank_v1_types_all_pb.Amount.serializeBinaryToWriter
    );
  }
  f = message.getCurrentterm();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDuedate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInterestrate();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getMajortype();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getMajorcategory();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getMaturitydate();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNextpaymentduedate();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOwnername();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getHoldbalance();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      github_com_openbank_openbank_v1_types_all_pb.Amount.serializeBinaryToWriter
    );
  }
};


/**
 * optional string AccountID = 1;
 * @return {string}
 */
proto.accounts.Account.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Branch = 2;
 * @return {string}
 */
proto.accounts.Account.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string BranchName = 3;
 * @return {string}
 */
proto.accounts.Account.prototype.getBranchname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.setBranchname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Status = 4;
 * @return {string}
 */
proto.accounts.Account.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp AccruedInterestAtMaturityDate = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.accounts.Account.prototype.getAccruedinterestatmaturitydate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.accounts.Account} returns this
*/
proto.accounts.Account.prototype.setAccruedinterestatmaturitydate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.clearAccruedinterestatmaturitydate = function() {
  return this.setAccruedinterestatmaturitydate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accounts.Account.prototype.hasAccruedinterestatmaturitydate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional types.Amount AmountDue = 6;
 * @return {?proto.types.Amount}
 */
proto.accounts.Account.prototype.getAmountdue = function() {
  return /** @type{?proto.types.Amount} */ (
    jspb.Message.getWrapperField(this, github_com_openbank_openbank_v1_types_all_pb.Amount, 6));
};


/**
 * @param {?proto.types.Amount|undefined} value
 * @return {!proto.accounts.Account} returns this
*/
proto.accounts.Account.prototype.setAmountdue = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.clearAmountdue = function() {
  return this.setAmountdue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accounts.Account.prototype.hasAmountdue = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional types.Amount AvailableBalance = 7;
 * @return {?proto.types.Amount}
 */
proto.accounts.Account.prototype.getAvailablebalance = function() {
  return /** @type{?proto.types.Amount} */ (
    jspb.Message.getWrapperField(this, github_com_openbank_openbank_v1_types_all_pb.Amount, 7));
};


/**
 * @param {?proto.types.Amount|undefined} value
 * @return {!proto.accounts.Account} returns this
*/
proto.accounts.Account.prototype.setAvailablebalance = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.clearAvailablebalance = function() {
  return this.setAvailablebalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accounts.Account.prototype.hasAvailablebalance = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string AvailableCreditLimit = 8;
 * @return {string}
 */
proto.accounts.Account.prototype.getAvailablecreditlimit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.setAvailablecreditlimit = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string CheckingInterestRate = 9;
 * @return {string}
 */
proto.accounts.Account.prototype.getCheckinginterestrate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.setCheckinginterestrate = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp ContractDate = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.accounts.Account.prototype.getContractdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.accounts.Account} returns this
*/
proto.accounts.Account.prototype.setContractdate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.clearContractdate = function() {
  return this.setContractdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accounts.Account.prototype.hasContractdate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string CreditLimit = 11;
 * @return {string}
 */
proto.accounts.Account.prototype.getCreditlimit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.setCreditlimit = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string CurrentAccruedInterest = 12;
 * @return {string}
 */
proto.accounts.Account.prototype.getCurrentaccruedinterest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.setCurrentaccruedinterest = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional types.Amount CurrentBalance = 13;
 * @return {?proto.types.Amount}
 */
proto.accounts.Account.prototype.getCurrentbalance = function() {
  return /** @type{?proto.types.Amount} */ (
    jspb.Message.getWrapperField(this, github_com_openbank_openbank_v1_types_all_pb.Amount, 13));
};


/**
 * @param {?proto.types.Amount|undefined} value
 * @return {!proto.accounts.Account} returns this
*/
proto.accounts.Account.prototype.setCurrentbalance = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.clearCurrentbalance = function() {
  return this.setCurrentbalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accounts.Account.prototype.hasCurrentbalance = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string CurrentTerm = 14;
 * @return {string}
 */
proto.accounts.Account.prototype.getCurrentterm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.setCurrentterm = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional google.protobuf.Timestamp DueDate = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.accounts.Account.prototype.getDuedate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.accounts.Account} returns this
*/
proto.accounts.Account.prototype.setDuedate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.clearDuedate = function() {
  return this.setDuedate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accounts.Account.prototype.hasDuedate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string InterestRate = 16;
 * @return {string}
 */
proto.accounts.Account.prototype.getInterestrate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.setInterestrate = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional types.MajorType MajorType = 17;
 * @return {!proto.types.MajorType}
 */
proto.accounts.Account.prototype.getMajortype = function() {
  return /** @type {!proto.types.MajorType} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.types.MajorType} value
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.setMajortype = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional types.MajorCategory MajorCategory = 18;
 * @return {!proto.types.MajorCategory}
 */
proto.accounts.Account.prototype.getMajorcategory = function() {
  return /** @type {!proto.types.MajorCategory} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.types.MajorCategory} value
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.setMajorcategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional google.protobuf.Timestamp MaturityDate = 19;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.accounts.Account.prototype.getMaturitydate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 19));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.accounts.Account} returns this
*/
proto.accounts.Account.prototype.setMaturitydate = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.clearMaturitydate = function() {
  return this.setMaturitydate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accounts.Account.prototype.hasMaturitydate = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.Timestamp NextPaymentDueDate = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.accounts.Account.prototype.getNextpaymentduedate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.accounts.Account} returns this
*/
proto.accounts.Account.prototype.setNextpaymentduedate = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.clearNextpaymentduedate = function() {
  return this.setNextpaymentduedate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accounts.Account.prototype.hasNextpaymentduedate = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string OwnerName = 21;
 * @return {string}
 */
proto.accounts.Account.prototype.getOwnername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.setOwnername = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional google.protobuf.Timestamp StartDate = 22;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.accounts.Account.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 22));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.accounts.Account} returns this
*/
proto.accounts.Account.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accounts.Account.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional types.Amount HoldBalance = 23;
 * @return {?proto.types.Amount}
 */
proto.accounts.Account.prototype.getHoldbalance = function() {
  return /** @type{?proto.types.Amount} */ (
    jspb.Message.getWrapperField(this, github_com_openbank_openbank_v1_types_all_pb.Amount, 23));
};


/**
 * @param {?proto.types.Amount|undefined} value
 * @return {!proto.accounts.Account} returns this
*/
proto.accounts.Account.prototype.setHoldbalance = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accounts.Account} returns this
 */
proto.accounts.Account.prototype.clearHoldbalance = function() {
  return this.setHoldbalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accounts.Account.prototype.hasHoldbalance = function() {
  return jspb.Message.getField(this, 23) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accounts.GetAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accounts.GetAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accounts.GetAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.GetAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accounts.GetAccountRequest}
 */
proto.accounts.GetAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accounts.GetAccountRequest;
  return proto.accounts.GetAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accounts.GetAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accounts.GetAccountRequest}
 */
proto.accounts.GetAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accounts.GetAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accounts.GetAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accounts.GetAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.GetAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string AccountID = 1;
 * @return {string}
 */
proto.accounts.GetAccountRequest.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.GetAccountRequest} returns this
 */
proto.accounts.GetAccountRequest.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accounts.GetAccountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accounts.GetAccountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accounts.GetAccountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.GetAccountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    nextstartingindex: jspb.Message.getFieldWithDefault(msg, 1, ""),
    afterid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accounts.GetAccountsRequest}
 */
proto.accounts.GetAccountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accounts.GetAccountsRequest;
  return proto.accounts.GetAccountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accounts.GetAccountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accounts.GetAccountsRequest}
 */
proto.accounts.GetAccountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextstartingindex(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAfterid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accounts.GetAccountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accounts.GetAccountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accounts.GetAccountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.GetAccountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNextstartingindex();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAfterid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string NextStartingIndex = 1;
 * @return {string}
 */
proto.accounts.GetAccountsRequest.prototype.getNextstartingindex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.GetAccountsRequest} returns this
 */
proto.accounts.GetAccountsRequest.prototype.setNextstartingindex = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string AfterID = 2;
 * @return {string}
 */
proto.accounts.GetAccountsRequest.prototype.getAfterid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.GetAccountsRequest} returns this
 */
proto.accounts.GetAccountsRequest.prototype.setAfterid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 Limit = 3;
 * @return {number}
 */
proto.accounts.GetAccountsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.accounts.GetAccountsRequest} returns this
 */
proto.accounts.GetAccountsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accounts.GetAccountsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accounts.GetAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accounts.GetAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accounts.GetAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.GetAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.accounts.Account.toObject, includeInstance),
    hasmore: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accounts.GetAccountsResponse}
 */
proto.accounts.GetAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accounts.GetAccountsResponse;
  return proto.accounts.GetAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accounts.GetAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accounts.GetAccountsResponse}
 */
proto.accounts.GetAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accounts.Account;
      reader.readMessage(value,proto.accounts.Account.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasmore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accounts.GetAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accounts.GetAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accounts.GetAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.GetAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accounts.Account.serializeBinaryToWriter
    );
  }
  f = message.getHasmore();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated Account Result = 1;
 * @return {!Array<!proto.accounts.Account>}
 */
proto.accounts.GetAccountsResponse.prototype.getResultList = function() {
  return /** @type{!Array<!proto.accounts.Account>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accounts.Account, 1));
};


/**
 * @param {!Array<!proto.accounts.Account>} value
 * @return {!proto.accounts.GetAccountsResponse} returns this
*/
proto.accounts.GetAccountsResponse.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accounts.Account=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accounts.Account}
 */
proto.accounts.GetAccountsResponse.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accounts.Account, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accounts.GetAccountsResponse} returns this
 */
proto.accounts.GetAccountsResponse.prototype.clearResultList = function() {
  return this.setResultList([]);
};


/**
 * optional bool HasMore = 2;
 * @return {boolean}
 */
proto.accounts.GetAccountsResponse.prototype.getHasmore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accounts.GetAccountsResponse} returns this
 */
proto.accounts.GetAccountsResponse.prototype.setHasmore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accounts.AccountRole.prototype.toObject = function(opt_includeInstance) {
  return proto.accounts.AccountRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accounts.AccountRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.AccountRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    entitynumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entitytype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    role: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accounts.AccountRole}
 */
proto.accounts.AccountRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accounts.AccountRole;
  return proto.accounts.AccountRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accounts.AccountRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accounts.AccountRole}
 */
proto.accounts.AccountRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntitynumber(value);
      break;
    case 2:
      var value = /** @type {!proto.accounts.EntityType} */ (reader.readEnum());
      msg.setEntitytype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accounts.AccountRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accounts.AccountRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accounts.AccountRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.AccountRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntitynumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntitytype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string EntityNumber = 1;
 * @return {string}
 */
proto.accounts.AccountRole.prototype.getEntitynumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.AccountRole} returns this
 */
proto.accounts.AccountRole.prototype.setEntitynumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EntityType EntityType = 2;
 * @return {!proto.accounts.EntityType}
 */
proto.accounts.AccountRole.prototype.getEntitytype = function() {
  return /** @type {!proto.accounts.EntityType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.accounts.EntityType} value
 * @return {!proto.accounts.AccountRole} returns this
 */
proto.accounts.AccountRole.prototype.setEntitytype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string Role = 3;
 * @return {string}
 */
proto.accounts.AccountRole.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.AccountRole} returns this
 */
proto.accounts.AccountRole.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accounts.DebitTransaction.prototype.toObject = function(opt_includeInstance) {
  return proto.accounts.DebitTransaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accounts.DebitTransaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.DebitTransaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFieldWithDefault(msg, 1, ""),
    debitaccount: jspb.Message.getFieldWithDefault(msg, 2, ""),
    exchangerate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isfx: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accounts.DebitTransaction}
 */
proto.accounts.DebitTransaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accounts.DebitTransaction;
  return proto.accounts.DebitTransaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accounts.DebitTransaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accounts.DebitTransaction}
 */
proto.accounts.DebitTransaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebitaccount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExchangerate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsfx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accounts.DebitTransaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accounts.DebitTransaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accounts.DebitTransaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.DebitTransaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDebitaccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExchangerate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsfx();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string Amount = 1;
 * @return {string}
 */
proto.accounts.DebitTransaction.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.DebitTransaction} returns this
 */
proto.accounts.DebitTransaction.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string DebitAccount = 2;
 * @return {string}
 */
proto.accounts.DebitTransaction.prototype.getDebitaccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.DebitTransaction} returns this
 */
proto.accounts.DebitTransaction.prototype.setDebitaccount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ExchangeRate = 3;
 * @return {string}
 */
proto.accounts.DebitTransaction.prototype.getExchangerate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.DebitTransaction} returns this
 */
proto.accounts.DebitTransaction.prototype.setExchangerate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool IsFx = 4;
 * @return {boolean}
 */
proto.accounts.DebitTransaction.prototype.getIsfx = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accounts.DebitTransaction} returns this
 */
proto.accounts.DebitTransaction.prototype.setIsfx = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accounts.CreateAccountRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accounts.CreateAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accounts.CreateAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accounts.CreateAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.CreateAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountrolesList: jspb.Message.toObjectList(msg.getAccountrolesList(),
    proto.accounts.AccountRole.toObject, includeInstance),
    branch: jspb.Message.getFieldWithDefault(msg, 4, ""),
    customer: jspb.Message.getFieldWithDefault(msg, 5, ""),
    debittransaction: (f = msg.getDebittransaction()) && proto.accounts.DebitTransaction.toObject(includeInstance, f),
    interestrate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    majortype: jspb.Message.getFieldWithDefault(msg, 8, 0),
    maturitydate: (f = msg.getMaturitydate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    minor: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accounts.CreateAccountRequest}
 */
proto.accounts.CreateAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accounts.CreateAccountRequest;
  return proto.accounts.CreateAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accounts.CreateAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accounts.CreateAccountRequest}
 */
proto.accounts.CreateAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new proto.accounts.AccountRole;
      reader.readMessage(value,proto.accounts.AccountRole.deserializeBinaryFromReader);
      msg.addAccountroles(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomer(value);
      break;
    case 6:
      var value = new proto.accounts.DebitTransaction;
      reader.readMessage(value,proto.accounts.DebitTransaction.deserializeBinaryFromReader);
      msg.setDebittransaction(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterestrate(value);
      break;
    case 8:
      var value = /** @type {!proto.types.MajorType} */ (reader.readEnum());
      msg.setMajortype(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMaturitydate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMinor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accounts.CreateAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accounts.CreateAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accounts.CreateAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.CreateAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountrolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.accounts.AccountRole.serializeBinaryToWriter
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCustomer();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDebittransaction();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.accounts.DebitTransaction.serializeBinaryToWriter
    );
  }
  f = message.getInterestrate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMajortype();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getMaturitydate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMinor();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string AccountID = 1;
 * @return {string}
 */
proto.accounts.CreateAccountRequest.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.CreateAccountRequest} returns this
 */
proto.accounts.CreateAccountRequest.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Description = 2;
 * @return {string}
 */
proto.accounts.CreateAccountRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.CreateAccountRequest} returns this
 */
proto.accounts.CreateAccountRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated AccountRole AccountRoles = 3;
 * @return {!Array<!proto.accounts.AccountRole>}
 */
proto.accounts.CreateAccountRequest.prototype.getAccountrolesList = function() {
  return /** @type{!Array<!proto.accounts.AccountRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accounts.AccountRole, 3));
};


/**
 * @param {!Array<!proto.accounts.AccountRole>} value
 * @return {!proto.accounts.CreateAccountRequest} returns this
*/
proto.accounts.CreateAccountRequest.prototype.setAccountrolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.accounts.AccountRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accounts.AccountRole}
 */
proto.accounts.CreateAccountRequest.prototype.addAccountroles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.accounts.AccountRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accounts.CreateAccountRequest} returns this
 */
proto.accounts.CreateAccountRequest.prototype.clearAccountrolesList = function() {
  return this.setAccountrolesList([]);
};


/**
 * optional string Branch = 4;
 * @return {string}
 */
proto.accounts.CreateAccountRequest.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.CreateAccountRequest} returns this
 */
proto.accounts.CreateAccountRequest.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Customer = 5;
 * @return {string}
 */
proto.accounts.CreateAccountRequest.prototype.getCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.CreateAccountRequest} returns this
 */
proto.accounts.CreateAccountRequest.prototype.setCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional DebitTransaction DebitTransaction = 6;
 * @return {?proto.accounts.DebitTransaction}
 */
proto.accounts.CreateAccountRequest.prototype.getDebittransaction = function() {
  return /** @type{?proto.accounts.DebitTransaction} */ (
    jspb.Message.getWrapperField(this, proto.accounts.DebitTransaction, 6));
};


/**
 * @param {?proto.accounts.DebitTransaction|undefined} value
 * @return {!proto.accounts.CreateAccountRequest} returns this
*/
proto.accounts.CreateAccountRequest.prototype.setDebittransaction = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accounts.CreateAccountRequest} returns this
 */
proto.accounts.CreateAccountRequest.prototype.clearDebittransaction = function() {
  return this.setDebittransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accounts.CreateAccountRequest.prototype.hasDebittransaction = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string InterestRate = 7;
 * @return {string}
 */
proto.accounts.CreateAccountRequest.prototype.getInterestrate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.CreateAccountRequest} returns this
 */
proto.accounts.CreateAccountRequest.prototype.setInterestrate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional types.MajorType MajorType = 8;
 * @return {!proto.types.MajorType}
 */
proto.accounts.CreateAccountRequest.prototype.getMajortype = function() {
  return /** @type {!proto.types.MajorType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.types.MajorType} value
 * @return {!proto.accounts.CreateAccountRequest} returns this
 */
proto.accounts.CreateAccountRequest.prototype.setMajortype = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp MaturityDate = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.accounts.CreateAccountRequest.prototype.getMaturitydate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.accounts.CreateAccountRequest} returns this
*/
proto.accounts.CreateAccountRequest.prototype.setMaturitydate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accounts.CreateAccountRequest} returns this
 */
proto.accounts.CreateAccountRequest.prototype.clearMaturitydate = function() {
  return this.setMaturitydate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accounts.CreateAccountRequest.prototype.hasMaturitydate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string Minor = 10;
 * @return {string}
 */
proto.accounts.CreateAccountRequest.prototype.getMinor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.CreateAccountRequest} returns this
 */
proto.accounts.CreateAccountRequest.prototype.setMinor = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accounts.CreateAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accounts.CreateAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accounts.CreateAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.CreateAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    credittransactionnumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    debittransactionnumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    majortype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minor: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accounts.CreateAccountResponse}
 */
proto.accounts.CreateAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accounts.CreateAccountResponse;
  return proto.accounts.CreateAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accounts.CreateAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accounts.CreateAccountResponse}
 */
proto.accounts.CreateAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCredittransactionnumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebittransactionnumber(value);
      break;
    case 4:
      var value = /** @type {!proto.types.MajorType} */ (reader.readEnum());
      msg.setMajortype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMinor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accounts.CreateAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accounts.CreateAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accounts.CreateAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.CreateAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCredittransactionnumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDebittransactionnumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMajortype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMinor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string AccountID = 1;
 * @return {string}
 */
proto.accounts.CreateAccountResponse.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.CreateAccountResponse} returns this
 */
proto.accounts.CreateAccountResponse.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string CreditTransactionNumber = 2;
 * @return {string}
 */
proto.accounts.CreateAccountResponse.prototype.getCredittransactionnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.CreateAccountResponse} returns this
 */
proto.accounts.CreateAccountResponse.prototype.setCredittransactionnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string DebitTransactionNumber = 3;
 * @return {string}
 */
proto.accounts.CreateAccountResponse.prototype.getDebittransactionnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.CreateAccountResponse} returns this
 */
proto.accounts.CreateAccountResponse.prototype.setDebittransactionnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional types.MajorType MajorType = 4;
 * @return {!proto.types.MajorType}
 */
proto.accounts.CreateAccountResponse.prototype.getMajortype = function() {
  return /** @type {!proto.types.MajorType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.MajorType} value
 * @return {!proto.accounts.CreateAccountResponse} returns this
 */
proto.accounts.CreateAccountResponse.prototype.setMajortype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string Minor = 5;
 * @return {string}
 */
proto.accounts.CreateAccountResponse.prototype.getMinor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.CreateAccountResponse} returns this
 */
proto.accounts.CreateAccountResponse.prototype.setMinor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accounts.UpdateAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accounts.UpdateAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accounts.UpdateAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.UpdateAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accounts.UpdateAccountRequest}
 */
proto.accounts.UpdateAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accounts.UpdateAccountRequest;
  return proto.accounts.UpdateAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accounts.UpdateAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accounts.UpdateAccountRequest}
 */
proto.accounts.UpdateAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accounts.UpdateAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accounts.UpdateAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accounts.UpdateAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.UpdateAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string AccountID = 1;
 * @return {string}
 */
proto.accounts.UpdateAccountRequest.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.UpdateAccountRequest} returns this
 */
proto.accounts.UpdateAccountRequest.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Description = 2;
 * @return {string}
 */
proto.accounts.UpdateAccountRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.UpdateAccountRequest} returns this
 */
proto.accounts.UpdateAccountRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accounts.UpdateAccountStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accounts.UpdateAccountStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accounts.UpdateAccountStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.UpdateAccountStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accounts.UpdateAccountStatusRequest}
 */
proto.accounts.UpdateAccountStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accounts.UpdateAccountStatusRequest;
  return proto.accounts.UpdateAccountStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accounts.UpdateAccountStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accounts.UpdateAccountStatusRequest}
 */
proto.accounts.UpdateAccountStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accounts.UpdateAccountStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accounts.UpdateAccountStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accounts.UpdateAccountStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.UpdateAccountStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string AccountID = 1;
 * @return {string}
 */
proto.accounts.UpdateAccountStatusRequest.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.UpdateAccountStatusRequest} returns this
 */
proto.accounts.UpdateAccountStatusRequest.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.accounts.UpdateAccountStatusRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.UpdateAccountStatusRequest} returns this
 */
proto.accounts.UpdateAccountStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accounts.GetAccountStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accounts.GetAccountStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accounts.GetAccountStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.GetAccountStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accounts.GetAccountStatusRequest}
 */
proto.accounts.GetAccountStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accounts.GetAccountStatusRequest;
  return proto.accounts.GetAccountStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accounts.GetAccountStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accounts.GetAccountStatusRequest}
 */
proto.accounts.GetAccountStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accounts.GetAccountStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accounts.GetAccountStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accounts.GetAccountStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.GetAccountStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string AccountID = 1;
 * @return {string}
 */
proto.accounts.GetAccountStatusRequest.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.GetAccountStatusRequest} returns this
 */
proto.accounts.GetAccountStatusRequest.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accounts.GetAccountStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accounts.GetAccountStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accounts.GetAccountStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.GetAccountStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    effectivedate: (f = msg.getEffectivedate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    statusdescription: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accounts.GetAccountStatusResponse}
 */
proto.accounts.GetAccountStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accounts.GetAccountStatusResponse;
  return proto.accounts.GetAccountStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accounts.GetAccountStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accounts.GetAccountStatusResponse}
 */
proto.accounts.GetAccountStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEffectivedate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusdescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accounts.GetAccountStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accounts.GetAccountStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accounts.GetAccountStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.GetAccountStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEffectivedate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatusdescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string AccountID = 1;
 * @return {string}
 */
proto.accounts.GetAccountStatusResponse.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.GetAccountStatusResponse} returns this
 */
proto.accounts.GetAccountStatusResponse.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp EffectiveDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.accounts.GetAccountStatusResponse.prototype.getEffectivedate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.accounts.GetAccountStatusResponse} returns this
*/
proto.accounts.GetAccountStatusResponse.prototype.setEffectivedate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accounts.GetAccountStatusResponse} returns this
 */
proto.accounts.GetAccountStatusResponse.prototype.clearEffectivedate = function() {
  return this.setEffectivedate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accounts.GetAccountStatusResponse.prototype.hasEffectivedate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string Status = 3;
 * @return {string}
 */
proto.accounts.GetAccountStatusResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.GetAccountStatusResponse} returns this
 */
proto.accounts.GetAccountStatusResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string StatusDescription = 4;
 * @return {string}
 */
proto.accounts.GetAccountStatusResponse.prototype.getStatusdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.GetAccountStatusResponse} returns this
 */
proto.accounts.GetAccountStatusResponse.prototype.setStatusdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accounts.DeleteAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accounts.DeleteAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accounts.DeleteAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.DeleteAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accounts.DeleteAccountRequest}
 */
proto.accounts.DeleteAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accounts.DeleteAccountRequest;
  return proto.accounts.DeleteAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accounts.DeleteAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accounts.DeleteAccountRequest}
 */
proto.accounts.DeleteAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accounts.DeleteAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accounts.DeleteAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accounts.DeleteAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.DeleteAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string AccountID = 1;
 * @return {string}
 */
proto.accounts.DeleteAccountRequest.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.DeleteAccountRequest} returns this
 */
proto.accounts.DeleteAccountRequest.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accounts.CheckAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accounts.CheckAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accounts.CheckAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.CheckAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accounts.CheckAccountRequest}
 */
proto.accounts.CheckAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accounts.CheckAccountRequest;
  return proto.accounts.CheckAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accounts.CheckAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accounts.CheckAccountRequest}
 */
proto.accounts.CheckAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accounts.CheckAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accounts.CheckAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accounts.CheckAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.CheckAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string AccountID = 1;
 * @return {string}
 */
proto.accounts.CheckAccountRequest.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.CheckAccountRequest} returns this
 */
proto.accounts.CheckAccountRequest.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accounts.CheckAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accounts.CheckAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accounts.CheckAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.CheckAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bankcode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    majortype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accounts.CheckAccountResponse}
 */
proto.accounts.CheckAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accounts.CheckAccountResponse;
  return proto.accounts.CheckAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accounts.CheckAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accounts.CheckAccountResponse}
 */
proto.accounts.CheckAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankcode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountname(value);
      break;
    case 4:
      var value = /** @type {!proto.types.MajorType} */ (reader.readEnum());
      msg.setMajortype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accounts.CheckAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accounts.CheckAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accounts.CheckAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accounts.CheckAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBankcode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMajortype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string AccountID = 1;
 * @return {string}
 */
proto.accounts.CheckAccountResponse.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.CheckAccountResponse} returns this
 */
proto.accounts.CheckAccountResponse.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string BankCode = 2;
 * @return {string}
 */
proto.accounts.CheckAccountResponse.prototype.getBankcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.CheckAccountResponse} returns this
 */
proto.accounts.CheckAccountResponse.prototype.setBankcode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string AccountName = 3;
 * @return {string}
 */
proto.accounts.CheckAccountResponse.prototype.getAccountname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accounts.CheckAccountResponse} returns this
 */
proto.accounts.CheckAccountResponse.prototype.setAccountname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional types.MajorType MajorType = 4;
 * @return {!proto.types.MajorType}
 */
proto.accounts.CheckAccountResponse.prototype.getMajortype = function() {
  return /** @type {!proto.types.MajorType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.MajorType} value
 * @return {!proto.accounts.CheckAccountResponse} returns this
 */
proto.accounts.CheckAccountResponse.prototype.setMajortype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.accounts.EntityType = {
  UNKNOWNENTITYTYPE: 0,
  PERS: 1,
  ORG: 2
};

goog.object.extend(exports, proto.accounts);
