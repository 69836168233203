import { ActionContext } from "vuex";
import { User } from "oidc-client";
import { AuthState } from "./index";
import { RootState } from "@/store";
import Auth from "@/services/auth";
import AuthUtils from "@/utils/auth-utils";

export async function login({ state }: ActionContext<AuthState, RootState>) {
  const { loginParams } = state;
  const auth = new Auth();
  await auth.login(loginParams);
}

export async function logout({ commit }: ActionContext<AuthState, RootState>) {
  const auth = new Auth();
  commit("resetState");
  AuthUtils.clearData();

  await auth.logout();
}

export async function exchangeCode({
  commit,
}: ActionContext<AuthState, RootState>) {
  const auth = new Auth();

  const commitUser = (user: User) => {
    const data: AuthState["user"] = {
      accessToken: user["access_token"] || "",
      refreshToken: user["refresh_token"] || "",
      expiryTime: user["expires_at"] * 1000 || 0,
    };

    commit("setUser", data);
  };

  const user = await auth.exchangeCode(commitUser);
  commitUser(user);
}

export async function beginAuthMonitor({
  commit,
}: ActionContext<AuthState, RootState>) {
  const auth = new Auth();

  const onSessionInvalid = () => {
    commit("resetState");
    AuthUtils.clearData();
    window.location.reload();
  };

  const monitorId = await auth.monitorAuthUser(onSessionInvalid);

  commit("setMonitorId", monitorId);
}

export async function stopAuthMonitor({
  commit,
  rootState,
}: ActionContext<AuthState, RootState>) {
  clearInterval(rootState.auth.monitorId);

  commit("setMonitorId", 0);
}
