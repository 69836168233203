import { ActionContext } from "vuex";
import { RootState } from "@/store";
import { FavoriteState } from "./index";
import { Favorites as FavoriteService } from "@/services";
import { Favorite } from "@/types";

export async function addFavorite(
  { rootState }: ActionContext<FavoriteState, RootState>,
  favorite: Favorite
) {
  const { auth, config } = rootState;

  const result = await new FavoriteService(
    config.apiURL,
    auth.user.accessToken
  ).addFavorite(favorite);

  return result;
}

export async function getFavoritesList({
  commit,
  rootState,
}: ActionContext<FavoriteState, RootState>) {
  const { auth, config } = rootState;

  commit("setLoading", true);

  try {
    const result = await new FavoriteService(
      config.apiURL,
      auth.user.accessToken
    ).getFavoritesList();

    commit("setFavList", result.favoritesList);
  } catch (error) {
    const err = error as Error;
    commit("setError", err.message);
  } finally {
    commit("setLoading", false);
  }
}
