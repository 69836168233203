// source: all.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var github_com_openbank_openbank_v1_transaction_all_pb = require('./../transactions/all_pb.js');
goog.object.extend(proto, github_com_openbank_openbank_v1_transaction_all_pb);
goog.exportSymbol('proto.batch.Batch', null, global);
goog.exportSymbol('proto.batch.CreateBatchRequest', null, global);
goog.exportSymbol('proto.batch.CreateBatchResponse', null, global);
goog.exportSymbol('proto.batch.ListBatchRequest', null, global);
goog.exportSymbol('proto.batch.ListBatchResponse', null, global);
goog.exportSymbol('proto.batch.MoveTransactionRequest', null, global);
goog.exportSymbol('proto.batch.MoveTransactionResponse', null, global);
goog.exportSymbol('proto.batch.ProcessBatchRequest', null, global);
goog.exportSymbol('proto.batch.ProcessBatchResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.batch.CreateBatchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.batch.CreateBatchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.batch.CreateBatchRequest.displayName = 'proto.batch.CreateBatchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.batch.CreateBatchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.batch.CreateBatchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.batch.CreateBatchResponse.displayName = 'proto.batch.CreateBatchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.batch.ProcessBatchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.batch.ProcessBatchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.batch.ProcessBatchRequest.displayName = 'proto.batch.ProcessBatchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.batch.ProcessBatchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.batch.ProcessBatchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.batch.ProcessBatchResponse.displayName = 'proto.batch.ProcessBatchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.batch.ListBatchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.batch.ListBatchRequest.repeatedFields_, null);
};
goog.inherits(proto.batch.ListBatchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.batch.ListBatchRequest.displayName = 'proto.batch.ListBatchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.batch.ListBatchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.batch.ListBatchResponse.repeatedFields_, null);
};
goog.inherits(proto.batch.ListBatchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.batch.ListBatchResponse.displayName = 'proto.batch.ListBatchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.batch.Batch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.batch.Batch.repeatedFields_, null);
};
goog.inherits(proto.batch.Batch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.batch.Batch.displayName = 'proto.batch.Batch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.batch.MoveTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.batch.MoveTransactionRequest.repeatedFields_, null);
};
goog.inherits(proto.batch.MoveTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.batch.MoveTransactionRequest.displayName = 'proto.batch.MoveTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.batch.MoveTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.batch.MoveTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.batch.MoveTransactionResponse.displayName = 'proto.batch.MoveTransactionResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.batch.CreateBatchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.batch.CreateBatchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.batch.CreateBatchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.CreateBatchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    settlementrail: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sourceaccount: jspb.Message.getFieldWithDefault(msg, 2, ""),
    purpose: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountingcode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deadline: (f = msg.getDeadline()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.batch.CreateBatchRequest}
 */
proto.batch.CreateBatchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.batch.CreateBatchRequest;
  return proto.batch.CreateBatchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.batch.CreateBatchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.batch.CreateBatchRequest}
 */
proto.batch.CreateBatchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementrail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceaccount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurpose(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountingcode(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeadline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.batch.CreateBatchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.batch.CreateBatchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.batch.CreateBatchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.CreateBatchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettlementrail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSourceaccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPurpose();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountingcode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeadline();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string SettlementRail = 1;
 * @return {string}
 */
proto.batch.CreateBatchRequest.prototype.getSettlementrail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.CreateBatchRequest} returns this
 */
proto.batch.CreateBatchRequest.prototype.setSettlementrail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string SourceAccount = 2;
 * @return {string}
 */
proto.batch.CreateBatchRequest.prototype.getSourceaccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.CreateBatchRequest} returns this
 */
proto.batch.CreateBatchRequest.prototype.setSourceaccount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Purpose = 3;
 * @return {string}
 */
proto.batch.CreateBatchRequest.prototype.getPurpose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.CreateBatchRequest} returns this
 */
proto.batch.CreateBatchRequest.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string AccountingCode = 4;
 * @return {string}
 */
proto.batch.CreateBatchRequest.prototype.getAccountingcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.CreateBatchRequest} returns this
 */
proto.batch.CreateBatchRequest.prototype.setAccountingcode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp Deadline = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.batch.CreateBatchRequest.prototype.getDeadline = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.batch.CreateBatchRequest} returns this
*/
proto.batch.CreateBatchRequest.prototype.setDeadline = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.batch.CreateBatchRequest} returns this
 */
proto.batch.CreateBatchRequest.prototype.clearDeadline = function() {
  return this.setDeadline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.batch.CreateBatchRequest.prototype.hasDeadline = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.batch.CreateBatchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.batch.CreateBatchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.batch.CreateBatchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.CreateBatchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.batch.CreateBatchResponse}
 */
proto.batch.CreateBatchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.batch.CreateBatchResponse;
  return proto.batch.CreateBatchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.batch.CreateBatchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.batch.CreateBatchResponse}
 */
proto.batch.CreateBatchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.batch.CreateBatchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.batch.CreateBatchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.batch.CreateBatchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.CreateBatchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string BatchID = 1;
 * @return {string}
 */
proto.batch.CreateBatchResponse.prototype.getBatchid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.CreateBatchResponse} returns this
 */
proto.batch.CreateBatchResponse.prototype.setBatchid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.batch.ProcessBatchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.batch.ProcessBatchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.batch.ProcessBatchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.ProcessBatchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    settlementrail: jspb.Message.getFieldWithDefault(msg, 1, ""),
    batchid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comments: jspb.Message.getFieldWithDefault(msg, 3, ""),
    close: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.batch.ProcessBatchRequest}
 */
proto.batch.ProcessBatchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.batch.ProcessBatchRequest;
  return proto.batch.ProcessBatchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.batch.ProcessBatchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.batch.ProcessBatchRequest}
 */
proto.batch.ProcessBatchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementrail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComments(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.batch.ProcessBatchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.batch.ProcessBatchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.batch.ProcessBatchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.ProcessBatchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettlementrail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBatchid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComments();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClose();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string SettlementRail = 1;
 * @return {string}
 */
proto.batch.ProcessBatchRequest.prototype.getSettlementrail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.ProcessBatchRequest} returns this
 */
proto.batch.ProcessBatchRequest.prototype.setSettlementrail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string BatchID = 2;
 * @return {string}
 */
proto.batch.ProcessBatchRequest.prototype.getBatchid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.ProcessBatchRequest} returns this
 */
proto.batch.ProcessBatchRequest.prototype.setBatchid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Comments = 3;
 * @return {string}
 */
proto.batch.ProcessBatchRequest.prototype.getComments = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.ProcessBatchRequest} returns this
 */
proto.batch.ProcessBatchRequest.prototype.setComments = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool Close = 4;
 * @return {boolean}
 */
proto.batch.ProcessBatchRequest.prototype.getClose = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.batch.ProcessBatchRequest} returns this
 */
proto.batch.ProcessBatchRequest.prototype.setClose = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.batch.ProcessBatchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.batch.ProcessBatchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.batch.ProcessBatchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.ProcessBatchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    processed: (f = msg.getProcessed()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.batch.ProcessBatchResponse}
 */
proto.batch.ProcessBatchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.batch.ProcessBatchResponse;
  return proto.batch.ProcessBatchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.batch.ProcessBatchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.batch.ProcessBatchResponse}
 */
proto.batch.ProcessBatchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setProcessed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.batch.ProcessBatchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.batch.ProcessBatchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.batch.ProcessBatchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.ProcessBatchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProcessed();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string BatchID = 1;
 * @return {string}
 */
proto.batch.ProcessBatchResponse.prototype.getBatchid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.ProcessBatchResponse} returns this
 */
proto.batch.ProcessBatchResponse.prototype.setBatchid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp Processed = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.batch.ProcessBatchResponse.prototype.getProcessed = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.batch.ProcessBatchResponse} returns this
*/
proto.batch.ProcessBatchResponse.prototype.setProcessed = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.batch.ProcessBatchResponse} returns this
 */
proto.batch.ProcessBatchResponse.prototype.clearProcessed = function() {
  return this.setProcessed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.batch.ProcessBatchResponse.prototype.hasProcessed = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.batch.ListBatchRequest.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.batch.ListBatchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.batch.ListBatchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.batch.ListBatchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.ListBatchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    after: (f = msg.getAfter()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    before: (f = msg.getBefore()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    start: jspb.Message.getFieldWithDefault(msg, 3, ""),
    end: jspb.Message.getFieldWithDefault(msg, 4, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 6, 0),
    creator: jspb.Message.getFieldWithDefault(msg, 7, ""),
    processer: jspb.Message.getFieldWithDefault(msg, 8, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, ""),
    batchesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.batch.ListBatchRequest}
 */
proto.batch.ListBatchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.batch.ListBatchRequest;
  return proto.batch.ListBatchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.batch.ListBatchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.batch.ListBatchRequest}
 */
proto.batch.ListBatchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAfter(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBefore(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnd(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreator(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcesser(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addBatches(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.batch.ListBatchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.batch.ListBatchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.batch.ListBatchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.ListBatchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAfter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBefore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEnd();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCreator();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProcesser();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBatchesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp After = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.batch.ListBatchRequest.prototype.getAfter = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.batch.ListBatchRequest} returns this
*/
proto.batch.ListBatchRequest.prototype.setAfter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.batch.ListBatchRequest} returns this
 */
proto.batch.ListBatchRequest.prototype.clearAfter = function() {
  return this.setAfter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.batch.ListBatchRequest.prototype.hasAfter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp Before = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.batch.ListBatchRequest.prototype.getBefore = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.batch.ListBatchRequest} returns this
*/
proto.batch.ListBatchRequest.prototype.setBefore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.batch.ListBatchRequest} returns this
 */
proto.batch.ListBatchRequest.prototype.clearBefore = function() {
  return this.setBefore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.batch.ListBatchRequest.prototype.hasBefore = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string Start = 3;
 * @return {string}
 */
proto.batch.ListBatchRequest.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.ListBatchRequest} returns this
 */
proto.batch.ListBatchRequest.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string End = 4;
 * @return {string}
 */
proto.batch.ListBatchRequest.prototype.getEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.ListBatchRequest} returns this
 */
proto.batch.ListBatchRequest.prototype.setEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 Limit = 5;
 * @return {number}
 */
proto.batch.ListBatchRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.batch.ListBatchRequest} returns this
 */
proto.batch.ListBatchRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 Offset = 6;
 * @return {number}
 */
proto.batch.ListBatchRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.batch.ListBatchRequest} returns this
 */
proto.batch.ListBatchRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string Creator = 7;
 * @return {string}
 */
proto.batch.ListBatchRequest.prototype.getCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.ListBatchRequest} returns this
 */
proto.batch.ListBatchRequest.prototype.setCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string Processer = 8;
 * @return {string}
 */
proto.batch.ListBatchRequest.prototype.getProcesser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.ListBatchRequest} returns this
 */
proto.batch.ListBatchRequest.prototype.setProcesser = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string Status = 9;
 * @return {string}
 */
proto.batch.ListBatchRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.ListBatchRequest} returns this
 */
proto.batch.ListBatchRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated string Batches = 10;
 * @return {!Array<string>}
 */
proto.batch.ListBatchRequest.prototype.getBatchesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.batch.ListBatchRequest} returns this
 */
proto.batch.ListBatchRequest.prototype.setBatchesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.batch.ListBatchRequest} returns this
 */
proto.batch.ListBatchRequest.prototype.addBatches = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.batch.ListBatchRequest} returns this
 */
proto.batch.ListBatchRequest.prototype.clearBatchesList = function() {
  return this.setBatchesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.batch.ListBatchResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.batch.ListBatchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.batch.ListBatchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.batch.ListBatchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.ListBatchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0),
    next: jspb.Message.getFieldWithDefault(msg, 2, 0),
    batchList: jspb.Message.toObjectList(msg.getBatchList(),
    proto.batch.Batch.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.batch.ListBatchResponse}
 */
proto.batch.ListBatchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.batch.ListBatchResponse;
  return proto.batch.ListBatchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.batch.ListBatchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.batch.ListBatchResponse}
 */
proto.batch.ListBatchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNext(value);
      break;
    case 3:
      var value = new proto.batch.Batch;
      reader.readMessage(value,proto.batch.Batch.deserializeBinaryFromReader);
      msg.addBatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.batch.ListBatchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.batch.ListBatchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.batch.ListBatchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.ListBatchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNext();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getBatchList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.batch.Batch.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 Count = 1;
 * @return {number}
 */
proto.batch.ListBatchResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.batch.ListBatchResponse} returns this
 */
proto.batch.ListBatchResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 Next = 2;
 * @return {number}
 */
proto.batch.ListBatchResponse.prototype.getNext = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.batch.ListBatchResponse} returns this
 */
proto.batch.ListBatchResponse.prototype.setNext = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Batch Batch = 3;
 * @return {!Array<!proto.batch.Batch>}
 */
proto.batch.ListBatchResponse.prototype.getBatchList = function() {
  return /** @type{!Array<!proto.batch.Batch>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.batch.Batch, 3));
};


/**
 * @param {!Array<!proto.batch.Batch>} value
 * @return {!proto.batch.ListBatchResponse} returns this
*/
proto.batch.ListBatchResponse.prototype.setBatchList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.batch.Batch=} opt_value
 * @param {number=} opt_index
 * @return {!proto.batch.Batch}
 */
proto.batch.ListBatchResponse.prototype.addBatch = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.batch.Batch, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.batch.ListBatchResponse} returns this
 */
proto.batch.ListBatchResponse.prototype.clearBatchList = function() {
  return this.setBatchList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.batch.Batch.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.batch.Batch.prototype.toObject = function(opt_includeInstance) {
  return proto.batch.Batch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.batch.Batch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.Batch.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    settlementrail: jspb.Message.getFieldWithDefault(msg, 3, ""),
    purpose: jspb.Message.getFieldWithDefault(msg, 4, ""),
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    github_com_openbank_openbank_v1_transaction_all_pb.Transaction.toObject, includeInstance),
    creator: jspb.Message.getFieldWithDefault(msg, 6, ""),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    processer: jspb.Message.getFieldWithDefault(msg, 8, ""),
    processed: (f = msg.getProcessed()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.batch.Batch}
 */
proto.batch.Batch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.batch.Batch;
  return proto.batch.Batch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.batch.Batch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.batch.Batch}
 */
proto.batch.Batch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementrail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurpose(value);
      break;
    case 5:
      var value = new github_com_openbank_openbank_v1_transaction_all_pb.Transaction;
      reader.readMessage(value,github_com_openbank_openbank_v1_transaction_all_pb.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreator(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcesser(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setProcessed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.batch.Batch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.batch.Batch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.batch.Batch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.Batch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSettlementrail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPurpose();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      github_com_openbank_openbank_v1_transaction_all_pb.Transaction.serializeBinaryToWriter
    );
  }
  f = message.getCreator();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProcesser();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProcessed();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.batch.Batch.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.Batch} returns this
 */
proto.batch.Batch.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.batch.Batch.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.Batch} returns this
 */
proto.batch.Batch.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string SettlementRail = 3;
 * @return {string}
 */
proto.batch.Batch.prototype.getSettlementrail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.Batch} returns this
 */
proto.batch.Batch.prototype.setSettlementrail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Purpose = 4;
 * @return {string}
 */
proto.batch.Batch.prototype.getPurpose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.Batch} returns this
 */
proto.batch.Batch.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated transactions.Transaction Transactions = 5;
 * @return {!Array<!proto.transactions.Transaction>}
 */
proto.batch.Batch.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.transactions.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, github_com_openbank_openbank_v1_transaction_all_pb.Transaction, 5));
};


/**
 * @param {!Array<!proto.transactions.Transaction>} value
 * @return {!proto.batch.Batch} returns this
*/
proto.batch.Batch.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.transactions.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.transactions.Transaction}
 */
proto.batch.Batch.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.transactions.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.batch.Batch} returns this
 */
proto.batch.Batch.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional string Creator = 6;
 * @return {string}
 */
proto.batch.Batch.prototype.getCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.Batch} returns this
 */
proto.batch.Batch.prototype.setCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp Created = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.batch.Batch.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.batch.Batch} returns this
*/
proto.batch.Batch.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.batch.Batch} returns this
 */
proto.batch.Batch.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.batch.Batch.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string Processer = 8;
 * @return {string}
 */
proto.batch.Batch.prototype.getProcesser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.Batch} returns this
 */
proto.batch.Batch.prototype.setProcesser = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp Processed = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.batch.Batch.prototype.getProcessed = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.batch.Batch} returns this
*/
proto.batch.Batch.prototype.setProcessed = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.batch.Batch} returns this
 */
proto.batch.Batch.prototype.clearProcessed = function() {
  return this.setProcessed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.batch.Batch.prototype.hasProcessed = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.batch.MoveTransactionRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.batch.MoveTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.batch.MoveTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.batch.MoveTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.MoveTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    destinationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionidList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.batch.MoveTransactionRequest}
 */
proto.batch.MoveTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.batch.MoveTransactionRequest;
  return proto.batch.MoveTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.batch.MoveTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.batch.MoveTransactionRequest}
 */
proto.batch.MoveTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addTransactionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.batch.MoveTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.batch.MoveTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.batch.MoveTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.MoveTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDestinationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionidList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string DestinationID = 1;
 * @return {string}
 */
proto.batch.MoveTransactionRequest.prototype.getDestinationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.batch.MoveTransactionRequest} returns this
 */
proto.batch.MoveTransactionRequest.prototype.setDestinationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string TransactionID = 2;
 * @return {!Array<string>}
 */
proto.batch.MoveTransactionRequest.prototype.getTransactionidList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.batch.MoveTransactionRequest} returns this
 */
proto.batch.MoveTransactionRequest.prototype.setTransactionidList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.batch.MoveTransactionRequest} returns this
 */
proto.batch.MoveTransactionRequest.prototype.addTransactionid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.batch.MoveTransactionRequest} returns this
 */
proto.batch.MoveTransactionRequest.prototype.clearTransactionidList = function() {
  return this.setTransactionidList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.batch.MoveTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.batch.MoveTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.batch.MoveTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.MoveTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    processed: (f = msg.getProcessed()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.batch.MoveTransactionResponse}
 */
proto.batch.MoveTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.batch.MoveTransactionResponse;
  return proto.batch.MoveTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.batch.MoveTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.batch.MoveTransactionResponse}
 */
proto.batch.MoveTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setProcessed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.batch.MoveTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.batch.MoveTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.batch.MoveTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.batch.MoveTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessed();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp Processed = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.batch.MoveTransactionResponse.prototype.getProcessed = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.batch.MoveTransactionResponse} returns this
*/
proto.batch.MoveTransactionResponse.prototype.setProcessed = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.batch.MoveTransactionResponse} returns this
 */
proto.batch.MoveTransactionResponse.prototype.clearProcessed = function() {
  return this.setProcessed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.batch.MoveTransactionResponse.prototype.hasProcessed = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.batch);
