








































































import { Vue, Component, Prop } from "vue-property-decorator";
import VModal from "vue-js-modal";

import { SvgIcon, Loader } from "@components";

Vue.use(VModal);

@Component({
  components: {
    SvgIcon,
    Loader,
  },
})
export default class OTPModal extends Vue {
  @Prop({ type: String, default: "Verify Action" }) modalTitle!: string;
  @Prop({ type: String, default: "" }) externalError!: string;

  loading = false;
  code = [...Array(6).keys()] as Array<any>;
  error = "";

  created() {
    const code = [...this.code];
    this.code = code.map(() => {
      return {
        value: "",
      };
    });
  }

  mounted() {
    this._setFocus(0);
  }

  get generateCode(): string {
    return this.code.map((val) => val.value).join("");
  }

  async onSubmit() {
    if (!this._validateCode()) {
      this.error = "Please enter a valid code";
      return;
    }

    this.loading = true;

    await this.$emit("on-submit", this.generateCode);

    this.loading = false;
  }

  _onModalClose() {
    this.$emit("on-modal-close");
  }

  _validateCode() {
    const hasEmpty = this.code.find((val) => val.value === "");
    return !hasEmpty;
  }

  _onKeyup(index: number, event: any) {
    const key = event.keyCode || event.charCode;

    if (key == 8) {
      this._setFocus(index - 1);
    } else {
      this.error = "";
      this._setFocus(index + 1);
    }
  }

  _setFocus(index: number) {
    if (index < this.code.length && index >= 0) {
      const input = this.$refs[`code-${index}`] as any;
      input[0].focus();
    }
  }
}
