





















import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class ProcessedBatchModal extends Vue {
  @Prop({ default: false })
  private isSingleTxn!: boolean;
}
