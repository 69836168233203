// package: metadata
// file: brank.as/ecebuana/gunk/metadata/all.proto

var brank_as_ecebuana_gunk_metadata_all_pb = require("./all_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var MetadataService = (function () {
  function MetadataService() {}
  MetadataService.serviceName = "metadata.MetadataService";
  return MetadataService;
}());

MetadataService.GetMetadata = {
  methodName: "GetMetadata",
  service: MetadataService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_ecebuana_gunk_metadata_all_pb.GetMetadataRequest,
  responseType: brank_as_ecebuana_gunk_metadata_all_pb.Metadata
};

exports.MetadataService = MetadataService;

function MetadataServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

MetadataServiceClient.prototype.getMetadata = function getMetadata(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MetadataService.GetMetadata, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.MetadataServiceClient = MetadataServiceClient;

