// package: profile
// file: brank.as/cebuana-corporate/gunk/v1/profile/all.proto

var brank_as_cebuana_corporate_gunk_v1_profile_all_pb = require("./all_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ProfileService = (function () {
  function ProfileService() {}
  ProfileService.serviceName = "profile.ProfileService";
  return ProfileService;
}());

ProfileService.CreateProfile = {
  methodName: "CreateProfile",
  service: ProfileService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.CreateProfileRequest,
  responseType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.CreateProfileResponse
};

ProfileService.UpdateProfile = {
  methodName: "UpdateProfile",
  service: ProfileService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.UpdateProfileRequest,
  responseType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.UpdateProfileResponse
};

ProfileService.GetProfile = {
  methodName: "GetProfile",
  service: ProfileService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.GetProfileRequest,
  responseType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.GetProfileResponse
};

ProfileService.GetProfiles = {
  methodName: "GetProfiles",
  service: ProfileService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.GetProfilesRequest,
  responseType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.GetProfilesResponse
};

ProfileService.BlockProfile = {
  methodName: "BlockProfile",
  service: ProfileService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.BlockProfileRequest,
  responseType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.BlockProfileResponse
};

ProfileService.UnblockProfile = {
  methodName: "UnblockProfile",
  service: ProfileService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.UnblockProfileRequest,
  responseType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.UnblockProfileResponse
};

ProfileService.DeleteProfile = {
  methodName: "DeleteProfile",
  service: ProfileService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.DeleteProfileRequest,
  responseType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.DeleteProfileResponse
};

ProfileService.ChangePassword = {
  methodName: "ChangePassword",
  service: ProfileService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.ChangePasswordRequest,
  responseType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.ChangePasswordResponse
};

ProfileService.Approve = {
  methodName: "Approve",
  service: ProfileService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.ApproveRequest,
  responseType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.ApproveResponse
};

ProfileService.SendInvite = {
  methodName: "SendInvite",
  service: ProfileService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.SendInviteRequest,
  responseType: brank_as_cebuana_corporate_gunk_v1_profile_all_pb.SendInviteResponse
};

exports.ProfileService = ProfileService;

function ProfileServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ProfileServiceClient.prototype.createProfile = function createProfile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProfileService.CreateProfile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProfileServiceClient.prototype.updateProfile = function updateProfile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProfileService.UpdateProfile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProfileServiceClient.prototype.getProfile = function getProfile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProfileService.GetProfile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProfileServiceClient.prototype.getProfiles = function getProfiles(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProfileService.GetProfiles, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProfileServiceClient.prototype.blockProfile = function blockProfile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProfileService.BlockProfile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProfileServiceClient.prototype.unblockProfile = function unblockProfile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProfileService.UnblockProfile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProfileServiceClient.prototype.deleteProfile = function deleteProfile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProfileService.DeleteProfile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProfileServiceClient.prototype.changePassword = function changePassword(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProfileService.ChangePassword, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProfileServiceClient.prototype.approve = function approve(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProfileService.Approve, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProfileServiceClient.prototype.sendInvite = function sendInvite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProfileService.SendInvite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ProfileServiceClient = ProfileServiceClient;

