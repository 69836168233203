




























export default {
  name: "Users",
};
