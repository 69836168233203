import { Module, MutationTree, GetterTree } from "vuex";

import { RootState } from "@/store";
import * as actions from "./action";
import { Favorite, FavoriteType } from "@/proto/favorites/all_pb";

export interface FavoriteState {
  loading: boolean;
  error: string;
  list: Array<Favorite.AsObject>;
}

const getInitialState = (): FavoriteState => {
  return {
    loading: false,
    error: "",
    list: [],
  };
};

const initialState: FavoriteState = getInitialState();

const getters: GetterTree<FavoriteState, RootState> = {
  favoriteBillers: (state: FavoriteState): Array<Favorite.AsObject> => {
    return state.list.filter((favorite) => {
      return favorite.favtype === FavoriteType.BILLER;
    });
  },
  favoritePayees: (state: FavoriteState): Array<Favorite.AsObject> => {
    return state.list.filter((favorite) => {
      return favorite.favtype === FavoriteType.PAYEE;
    });
  },
};

const mutations: MutationTree<FavoriteState> = {
  setLoading(state: FavoriteState, loading: boolean) {
    state.loading = loading;
  },
  setError(state: FavoriteState, error: string) {
    state.error = error;
  },
  setFavList(state: FavoriteState, payload: Array<Favorite.AsObject>) {
    state.list = payload;
  },
};

const favorites: Module<FavoriteState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default favorites;
