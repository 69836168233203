













export default {
  name: "IconFilter",
};
