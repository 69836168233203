





















































import { Prop, Vue, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import { FilterInputValue, DateRange } from "../types";

import Datepicker from "vuejs-datepicker";

import SvgIcon from "../../../components/svg-icon/SvgIcon.vue";
import Input from "../../../components/custom-input/CustomInput.vue";
import Dropdown from "../../../components/dropdown/Dropdown.vue";

interface State {
  filterType: string;
  rules: any;
}

@Component({
  components: {
    Datepicker,
    SvgIcon,
    Dropdown,
    Input,
  },
})
export default class FilterDate extends Vue {
  @Prop({ default: null })
  private readonly value!: FilterInputValue;

  @Prop({ default: "vertical" })
  private readonly dateRangePosition!: "vertical" | "horizontal";

  private state: State = {
    filterType: "last",
    rules: {
      last: {
        value: "",
        interval: "days",
        intervalOptions: [
          {
            label: "days",
            value: "days",
          },
          {
            label: "months",
            value: "months",
          },
        ],
      },
      equal: {
        value: "",
      },
      dateRange: {
        disabled: {
          start: {
            from: null,
            to: null,
          },
          end: {
            from: null,
            to: null,
          },
        },
      },
    },
  };

  get dateFilterTypes() {
    return [
      {
        label: "is in the last",
        value: "last",
      },
      {
        label: "is equal to",
        value: "equal",
      },
    ];
  }

  onChangeDateFilterType(value: any) {
    this.state.filterType = value;
  }

  onChange(value: any) {
    this.$emit("on-change", {
      type: this.state.filterType,
      ...value,
    });
  }

  onChangeRuleValue(value: any) {
    if (this.state.filterType === "last") {
      this.onChange({
        value: value,
        interval: this.state.rules.last.interval,
      });
    } else if (this.state.filterType === "equal") {
      this.onChange({
        value: value,
      });
    }
  }

  onChangeDateRange(type: "start" | "end", value: Date) {
    const { start, end } = this.value as DateRange;

    const range = {
      start,
      end,
    };

    range[type] = value;

    this.$emit("input", range);
  }

  onRuleLastIntervalChange(value: any) {
    this.state.rules.last.interval = value;
    this.onChangeRuleValue(this.state.rules.last.value);
  }

  @Watch("state.rules.last.value")
  onRuleLastChange(value: number) {
    if (value) {
      this.onChangeRuleValue(this.state.rules.last.value);
    }
  }

  /**
   * Watch selected Start and End date to ensure
   * that the user will only be able to select valid
   * dates
   */

  @Watch("value.start")
  onStartDateChange(value: Date | null) {
    if (value) {
      this.state.rules.dateRange.disabled.end.to = value;
    } else {
      this.state.rules.dateRange.disabled.end = {
        from: new Date(),
        to: null,
      };
    }
  }

  @Watch("value.end")
  onEndDateChange(value: Date | null) {
    if (value) {
      this.state.rules.dateRange.disabled.start.from = value;
    } else {
      this.state.rules.dateRange.disabled.start = {
        from: new Date(),
        to: null,
      };
    }
  }
}
