// package: transactions
// file: github.com/openbank/openbank/v1/transaction/all.proto

var github_com_openbank_openbank_v1_transaction_all_pb = require("./all_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var TransactionService = (function () {
  function TransactionService() {}
  TransactionService.serviceName = "transactions.TransactionService";
  return TransactionService;
}());

TransactionService.GetTransaction = {
  methodName: "GetTransaction",
  service: TransactionService,
  requestStream: false,
  responseStream: false,
  requestType: github_com_openbank_openbank_v1_transaction_all_pb.GetTransactionRequest,
  responseType: github_com_openbank_openbank_v1_transaction_all_pb.Transaction
};

TransactionService.GetTransactions = {
  methodName: "GetTransactions",
  service: TransactionService,
  requestStream: false,
  responseStream: false,
  requestType: github_com_openbank_openbank_v1_transaction_all_pb.GetTransactionsRequest,
  responseType: github_com_openbank_openbank_v1_transaction_all_pb.GetTransactionsResponse
};

TransactionService.GetTransactionsByAccount = {
  methodName: "GetTransactionsByAccount",
  service: TransactionService,
  requestStream: false,
  responseStream: false,
  requestType: github_com_openbank_openbank_v1_transaction_all_pb.GetTransactionsByAccountRequest,
  responseType: github_com_openbank_openbank_v1_transaction_all_pb.GetTransactionsByAccountResponse
};

TransactionService.CreateTransaction = {
  methodName: "CreateTransaction",
  service: TransactionService,
  requestStream: false,
  responseStream: false,
  requestType: github_com_openbank_openbank_v1_transaction_all_pb.CreateTransactionRequest,
  responseType: github_com_openbank_openbank_v1_transaction_all_pb.CreateTransactionResponse
};

TransactionService.ApprovePayment = {
  methodName: "ApprovePayment",
  service: TransactionService,
  requestStream: false,
  responseStream: false,
  requestType: github_com_openbank_openbank_v1_transaction_all_pb.ApprovePaymentRequest,
  responseType: github_com_openbank_openbank_v1_transaction_all_pb.ApprovePaymentResponse
};

TransactionService.TFA = {
  methodName: "TFA",
  service: TransactionService,
  requestStream: false,
  responseStream: false,
  requestType: github_com_openbank_openbank_v1_transaction_all_pb.TFARequest,
  responseType: github_com_openbank_openbank_v1_transaction_all_pb.TFAResponse
};

TransactionService.ResendTFA = {
  methodName: "ResendTFA",
  service: TransactionService,
  requestStream: false,
  responseStream: false,
  requestType: github_com_openbank_openbank_v1_transaction_all_pb.ResendTFARequest,
  responseType: github_com_openbank_openbank_v1_transaction_all_pb.ResendTFAResponse
};

exports.TransactionService = TransactionService;

function TransactionServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

TransactionServiceClient.prototype.getTransaction = function getTransaction(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TransactionService.GetTransaction, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TransactionServiceClient.prototype.getTransactions = function getTransactions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TransactionService.GetTransactions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TransactionServiceClient.prototype.getTransactionsByAccount = function getTransactionsByAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TransactionService.GetTransactionsByAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TransactionServiceClient.prototype.createTransaction = function createTransaction(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TransactionService.CreateTransaction, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TransactionServiceClient.prototype.approvePayment = function approvePayment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TransactionService.ApprovePayment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TransactionServiceClient.prototype.tFA = function tFA(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TransactionService.TFA, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TransactionServiceClient.prototype.resendTFA = function resendTFA(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TransactionService.ResendTFA, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.TransactionServiceClient = TransactionServiceClient;

