// package: favorite
// file: brank.as/cebuana-corporate/gunk/bank/v1/favorite/all.proto

var brank_as_cebuana_corporate_gunk_bank_v1_favorite_all_pb = require("./all_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var FavoriteService = (function () {
  function FavoriteService() {}
  FavoriteService.serviceName = "favorite.FavoriteService";
  return FavoriteService;
}());

FavoriteService.GetFavorites = {
  methodName: "GetFavorites",
  service: FavoriteService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_bank_v1_favorite_all_pb.GetFavoritesRequest,
  responseType: brank_as_cebuana_corporate_gunk_bank_v1_favorite_all_pb.GetFavoritesResponse
};

FavoriteService.AddFavorite = {
  methodName: "AddFavorite",
  service: FavoriteService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_cebuana_corporate_gunk_bank_v1_favorite_all_pb.AddFavoriteRequest,
  responseType: brank_as_cebuana_corporate_gunk_bank_v1_favorite_all_pb.AddFavoriteResponse
};

exports.FavoriteService = FavoriteService;

function FavoriteServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

FavoriteServiceClient.prototype.getFavorites = function getFavorites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(FavoriteService.GetFavorites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

FavoriteServiceClient.prototype.addFavorite = function addFavorite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(FavoriteService.AddFavorite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.FavoriteServiceClient = FavoriteServiceClient;

