


















































































import { Vue, Component, Prop } from "vue-property-decorator";
import VTooltip from "v-tooltip";
import { SvgIcon } from "@components";

Vue.use(VTooltip);

@Component({
  components: {
    SvgIcon,
  },
})
export default class CustomInput extends Vue {
  @Prop({ default: "" })
  private readonly label!: string;

  @Prop({ default: "" })
  private readonly placeholder!: string;

  @Prop({ default: "" })
  private readonly value!: string | number;

  @Prop({ default: false })
  private readonly error!: boolean;

  @Prop({ default: false })
  private readonly id!: string;

  @Prop({ default: "text" })
  private readonly type!: string;

  @Prop()
  private readonly icon!: string;

  @Prop({ default: "" })
  private readonly iconClass!: string;

  @Prop({ default: false })
  private readonly iconHover!: boolean;

  @Prop({ default: false })
  private readonly iconClick!: boolean;

  @Prop({ default: "" })
  private readonly color!: string;

  @Prop({ default: false })
  private readonly readonly!: boolean;

  @Prop({ default: false })
  private readonly pageWidth!: boolean;

  @Prop({ default: "md" })
  private readonly size!: string;

  @Prop({ default: "" })
  private readonly validation!: "alpha" | "alphaNum";

  get inputID(): string {
    return this.id
      ? this.id
      : `custom-input-${this.label.replace(/[^\w-]+/g, "-").toLowerCase()}`;
  }

  get bgColor(): string {
    return this.color ? `bg-${this.color}` : "bg-grey-400";
  }

  onInput(event: KeyboardEvent) {
    const value = (event.target as any).value;
    this.$emit("input", value);
  }

  isKeyAllowed(e: KeyboardEvent) {
    if (e.ctrlKey) {
      return true;
    }

    const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];

    if (allowedKeys.includes(e.key)) {
      return true;
    }

    return false;
  }

  isNumber(value: string) {
    const numberRegex = /^\d+$/;
    return numberRegex.test(value);
  }

  onKeydown(event: KeyboardEvent) {
    if (this.type === "number") {
      const isNumber = this.isNumber(event.key);
      const isKeyAllowed = this.isKeyAllowed(event);
      if (!isNumber && !isKeyAllowed) {
        event.preventDefault();
      }
    }

    if (this.validation) {
      if (this.validation === "alpha") {
        const regex = /^[a-zA-Z ]*$/;
        if (!regex.test(event.key)) {
          event.preventDefault();
        }
      }
    }

    this.$emit("keydown", event);
  }

  onIconHover() {
    console.log("hellohover");
  }

  onIconClick() {
    if (this.iconClick) {
      console.log("helloclick");
    }
  }
}
