










export default {
  name: "IconInvalid",
};
