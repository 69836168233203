import * as Oidc from "oidc-client";
import APP_CONFIG from "@/config";
import { User } from "oidc-client";

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.NONE;

export default class Auth {
  private static DOMAIN =
    window.location.protocol + "//" + window.location.host;

  private static getUserManager = () => {
    const data: any = {
      userStore: new Oidc.WebStorageStateStore({}),
      authority: APP_CONFIG.authUrl,
      scope: APP_CONFIG.scope,
      automaticSilentRenew: true,
      revokeAccessTokenOnSignout: true,
    };

    /**
     * This is workaround to bypass @typscript-eslint/camelcase rule
     * OIDC client requires some parameters in underscore convention
     */
    data["client_id"] = APP_CONFIG.authClientId;
    data["redirect_uri"] = Auth.DOMAIN + "/oidc-callback";
    data["post_logout_redirect_uri"] =
      Auth.DOMAIN + "/sign-in?logout_success=1";
    data["response_type"] = "code";

    return new Oidc.UserManager(data);
  };

  public login = (query = {}) => {
    return Auth.getUserManager().signinRedirect({
      extraQueryParams: query,
    });
  };

  public logout = () => {
    return Auth.getUserManager().signoutRedirect({
      extraQueryParams: {
        logout_success: "1",
      },
    });
  };

  public frontChannelLogout = async () => {
    const params = new URLSearchParams(window.location.search);
    let authUrl = APP_CONFIG.authUrl;
    authUrl += authUrl.endsWith("/") ? "" : "/";

    if (params.get("iss") !== authUrl) {
      return;
    }

    const mgr = Auth.getUserManager();
    const user = await mgr.getUser();

    if (user && params.get("sid") !== user.profile.sid) {
      return;
    }

    await mgr.revokeAccessToken();
    await mgr.removeUser();
  };

  public monitorAuthUser = async (onSessionInvalid: () => void) => {
    const mgr = Auth.getUserManager();
    await mgr.getUser();

    const intervalId = window.setInterval(async () => {
      try {
        const user = await mgr.getUser();

        if (!user) {
          onSessionInvalid();
        }
      } catch (err) {
        onSessionInvalid();
      }
    }, 30000); // 30 seconds

    return intervalId;
  };

  public exchangeCode = (onAddUserLoaded: (user: User) => void) => {
    const mgr = Auth.getUserManager();

    mgr.events.addUserLoaded(onAddUserLoaded);

    return mgr.signinRedirectCallback();
  };
}
