// source: all.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.favorite.AddFavoriteRequest', null, global);
goog.exportSymbol('proto.favorite.AddFavoriteResponse', null, global);
goog.exportSymbol('proto.favorite.Favorite', null, global);
goog.exportSymbol('proto.favorite.FavoriteType', null, global);
goog.exportSymbol('proto.favorite.GetFavoritesRequest', null, global);
goog.exportSymbol('proto.favorite.GetFavoritesResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.favorite.GetFavoritesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.favorite.GetFavoritesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.favorite.GetFavoritesRequest.displayName = 'proto.favorite.GetFavoritesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.favorite.Favorite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.favorite.Favorite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.favorite.Favorite.displayName = 'proto.favorite.Favorite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.favorite.GetFavoritesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.favorite.GetFavoritesResponse.repeatedFields_, null);
};
goog.inherits(proto.favorite.GetFavoritesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.favorite.GetFavoritesResponse.displayName = 'proto.favorite.GetFavoritesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.favorite.AddFavoriteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.favorite.AddFavoriteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.favorite.AddFavoriteRequest.displayName = 'proto.favorite.AddFavoriteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.favorite.AddFavoriteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.favorite.AddFavoriteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.favorite.AddFavoriteResponse.displayName = 'proto.favorite.AddFavoriteResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.favorite.GetFavoritesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.favorite.GetFavoritesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.favorite.GetFavoritesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.favorite.GetFavoritesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.favorite.GetFavoritesRequest}
 */
proto.favorite.GetFavoritesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.favorite.GetFavoritesRequest;
  return proto.favorite.GetFavoritesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.favorite.GetFavoritesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.favorite.GetFavoritesRequest}
 */
proto.favorite.GetFavoritesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.favorite.GetFavoritesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.favorite.GetFavoritesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.favorite.GetFavoritesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.favorite.GetFavoritesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string UserID = 1;
 * @return {string}
 */
proto.favorite.GetFavoritesRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.favorite.GetFavoritesRequest} returns this
 */
proto.favorite.GetFavoritesRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.favorite.Favorite.prototype.toObject = function(opt_includeInstance) {
  return proto.favorite.Favorite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.favorite.Favorite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.favorite.Favorite.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    txnsource: jspb.Message.getFieldWithDefault(msg, 2, ""),
    refcode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    favtype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    category: jspb.Message.getFieldWithDefault(msg, 5, ""),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    billingcode: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.favorite.Favorite}
 */
proto.favorite.Favorite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.favorite.Favorite;
  return proto.favorite.Favorite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.favorite.Favorite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.favorite.Favorite}
 */
proto.favorite.Favorite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxnsource(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefcode(value);
      break;
    case 4:
      var value = /** @type {!proto.favorite.FavoriteType} */ (reader.readEnum());
      msg.setFavtype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.favorite.Favorite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.favorite.Favorite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.favorite.Favorite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.favorite.Favorite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTxnsource();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRefcode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFavtype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBillingcode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.favorite.Favorite.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.favorite.Favorite} returns this
 */
proto.favorite.Favorite.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string TxnSource = 2;
 * @return {string}
 */
proto.favorite.Favorite.prototype.getTxnsource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.favorite.Favorite} returns this
 */
proto.favorite.Favorite.prototype.setTxnsource = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string RefCode = 3;
 * @return {string}
 */
proto.favorite.Favorite.prototype.getRefcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.favorite.Favorite} returns this
 */
proto.favorite.Favorite.prototype.setRefcode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional FavoriteType FavType = 4;
 * @return {!proto.favorite.FavoriteType}
 */
proto.favorite.Favorite.prototype.getFavtype = function() {
  return /** @type {!proto.favorite.FavoriteType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.favorite.FavoriteType} value
 * @return {!proto.favorite.Favorite} returns this
 */
proto.favorite.Favorite.prototype.setFavtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string Category = 5;
 * @return {string}
 */
proto.favorite.Favorite.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.favorite.Favorite} returns this
 */
proto.favorite.Favorite.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp Created = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.favorite.Favorite.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.favorite.Favorite} returns this
*/
proto.favorite.Favorite.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.favorite.Favorite} returns this
 */
proto.favorite.Favorite.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.favorite.Favorite.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string BillingCode = 7;
 * @return {string}
 */
proto.favorite.Favorite.prototype.getBillingcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.favorite.Favorite} returns this
 */
proto.favorite.Favorite.prototype.setBillingcode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.favorite.GetFavoritesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.favorite.GetFavoritesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.favorite.GetFavoritesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.favorite.GetFavoritesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.favorite.GetFavoritesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    favoritesList: jspb.Message.toObjectList(msg.getFavoritesList(),
    proto.favorite.Favorite.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.favorite.GetFavoritesResponse}
 */
proto.favorite.GetFavoritesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.favorite.GetFavoritesResponse;
  return proto.favorite.GetFavoritesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.favorite.GetFavoritesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.favorite.GetFavoritesResponse}
 */
proto.favorite.GetFavoritesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.favorite.Favorite;
      reader.readMessage(value,proto.favorite.Favorite.deserializeBinaryFromReader);
      msg.addFavorites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.favorite.GetFavoritesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.favorite.GetFavoritesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.favorite.GetFavoritesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.favorite.GetFavoritesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFavoritesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.favorite.Favorite.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Favorite Favorites = 1;
 * @return {!Array<!proto.favorite.Favorite>}
 */
proto.favorite.GetFavoritesResponse.prototype.getFavoritesList = function() {
  return /** @type{!Array<!proto.favorite.Favorite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.favorite.Favorite, 1));
};


/**
 * @param {!Array<!proto.favorite.Favorite>} value
 * @return {!proto.favorite.GetFavoritesResponse} returns this
*/
proto.favorite.GetFavoritesResponse.prototype.setFavoritesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.favorite.Favorite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.favorite.Favorite}
 */
proto.favorite.GetFavoritesResponse.prototype.addFavorites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.favorite.Favorite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.favorite.GetFavoritesResponse} returns this
 */
proto.favorite.GetFavoritesResponse.prototype.clearFavoritesList = function() {
  return this.setFavoritesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.favorite.AddFavoriteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.favorite.AddFavoriteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.favorite.AddFavoriteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.favorite.AddFavoriteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    txnsource: jspb.Message.getFieldWithDefault(msg, 1, ""),
    refcode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    favtype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    category: jspb.Message.getFieldWithDefault(msg, 4, ""),
    billingcode: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.favorite.AddFavoriteRequest}
 */
proto.favorite.AddFavoriteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.favorite.AddFavoriteRequest;
  return proto.favorite.AddFavoriteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.favorite.AddFavoriteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.favorite.AddFavoriteRequest}
 */
proto.favorite.AddFavoriteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxnsource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefcode(value);
      break;
    case 3:
      var value = /** @type {!proto.favorite.FavoriteType} */ (reader.readEnum());
      msg.setFavtype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.favorite.AddFavoriteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.favorite.AddFavoriteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.favorite.AddFavoriteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.favorite.AddFavoriteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTxnsource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRefcode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFavtype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBillingcode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string TxnSource = 1;
 * @return {string}
 */
proto.favorite.AddFavoriteRequest.prototype.getTxnsource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.favorite.AddFavoriteRequest} returns this
 */
proto.favorite.AddFavoriteRequest.prototype.setTxnsource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string RefCode = 2;
 * @return {string}
 */
proto.favorite.AddFavoriteRequest.prototype.getRefcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.favorite.AddFavoriteRequest} returns this
 */
proto.favorite.AddFavoriteRequest.prototype.setRefcode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional FavoriteType FavType = 3;
 * @return {!proto.favorite.FavoriteType}
 */
proto.favorite.AddFavoriteRequest.prototype.getFavtype = function() {
  return /** @type {!proto.favorite.FavoriteType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.favorite.FavoriteType} value
 * @return {!proto.favorite.AddFavoriteRequest} returns this
 */
proto.favorite.AddFavoriteRequest.prototype.setFavtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string Category = 4;
 * @return {string}
 */
proto.favorite.AddFavoriteRequest.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.favorite.AddFavoriteRequest} returns this
 */
proto.favorite.AddFavoriteRequest.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string BillingCode = 5;
 * @return {string}
 */
proto.favorite.AddFavoriteRequest.prototype.getBillingcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.favorite.AddFavoriteRequest} returns this
 */
proto.favorite.AddFavoriteRequest.prototype.setBillingcode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.favorite.AddFavoriteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.favorite.AddFavoriteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.favorite.AddFavoriteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.favorite.AddFavoriteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.favorite.AddFavoriteResponse}
 */
proto.favorite.AddFavoriteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.favorite.AddFavoriteResponse;
  return proto.favorite.AddFavoriteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.favorite.AddFavoriteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.favorite.AddFavoriteResponse}
 */
proto.favorite.AddFavoriteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.favorite.AddFavoriteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.favorite.AddFavoriteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.favorite.AddFavoriteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.favorite.AddFavoriteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.favorite.AddFavoriteResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.favorite.AddFavoriteResponse} returns this
 */
proto.favorite.AddFavoriteResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp Created = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.favorite.AddFavoriteResponse.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.favorite.AddFavoriteResponse} returns this
*/
proto.favorite.AddFavoriteResponse.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.favorite.AddFavoriteResponse} returns this
 */
proto.favorite.AddFavoriteResponse.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.favorite.AddFavoriteResponse.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.favorite.FavoriteType = {
  UNKNOWNENTITYTYPE: 0,
  BILLER: 1,
  PAYEE: 2
};

goog.object.extend(exports, proto.favorite);
