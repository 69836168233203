// source: all.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.billspayment.Amount', null, global);
goog.exportSymbol('proto.billspayment.Beneficiary', null, global);
goog.exportSymbol('proto.billspayment.Biller', null, global);
goog.exportSymbol('proto.billspayment.Date', null, global);
goog.exportSymbol('proto.billspayment.FullName', null, global);
goog.exportSymbol('proto.billspayment.GetBillInputsRequest', null, global);
goog.exportSymbol('proto.billspayment.GetBillInputsResponse', null, global);
goog.exportSymbol('proto.billspayment.GetBillersRequest', null, global);
goog.exportSymbol('proto.billspayment.GetBillersResponse', null, global);
goog.exportSymbol('proto.billspayment.GetProductsRequest', null, global);
goog.exportSymbol('proto.billspayment.GetProductsResponse', null, global);
goog.exportSymbol('proto.billspayment.InputField', null, global);
goog.exportSymbol('proto.billspayment.InputType', null, global);
goog.exportSymbol('proto.billspayment.PayBillRequest', null, global);
goog.exportSymbol('proto.billspayment.PayBillResponse', null, global);
goog.exportSymbol('proto.billspayment.PaymentStatusRequest', null, global);
goog.exportSymbol('proto.billspayment.PaymentStatusResponse', null, global);
goog.exportSymbol('proto.billspayment.ProcessingStatus', null, global);
goog.exportSymbol('proto.billspayment.Product', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billspayment.GetProductsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billspayment.GetProductsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billspayment.GetProductsRequest.displayName = 'proto.billspayment.GetProductsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billspayment.GetProductsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billspayment.GetProductsResponse.repeatedFields_, null);
};
goog.inherits(proto.billspayment.GetProductsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billspayment.GetProductsResponse.displayName = 'proto.billspayment.GetProductsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billspayment.Amount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billspayment.Amount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billspayment.Amount.displayName = 'proto.billspayment.Amount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billspayment.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billspayment.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billspayment.Product.displayName = 'proto.billspayment.Product';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billspayment.GetBillInputsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billspayment.GetBillInputsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billspayment.GetBillInputsRequest.displayName = 'proto.billspayment.GetBillInputsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billspayment.GetBillInputsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billspayment.GetBillInputsResponse.repeatedFields_, null);
};
goog.inherits(proto.billspayment.GetBillInputsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billspayment.GetBillInputsResponse.displayName = 'proto.billspayment.GetBillInputsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billspayment.Date = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billspayment.Date, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billspayment.Date.displayName = 'proto.billspayment.Date';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billspayment.InputField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billspayment.InputField.repeatedFields_, null);
};
goog.inherits(proto.billspayment.InputField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billspayment.InputField.displayName = 'proto.billspayment.InputField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billspayment.FullName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billspayment.FullName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billspayment.FullName.displayName = 'proto.billspayment.FullName';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billspayment.Beneficiary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billspayment.Beneficiary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billspayment.Beneficiary.displayName = 'proto.billspayment.Beneficiary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billspayment.PayBillRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billspayment.PayBillRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billspayment.PayBillRequest.displayName = 'proto.billspayment.PayBillRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billspayment.PayBillResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billspayment.PayBillResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billspayment.PayBillResponse.displayName = 'proto.billspayment.PayBillResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billspayment.PaymentStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billspayment.PaymentStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billspayment.PaymentStatusRequest.displayName = 'proto.billspayment.PaymentStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billspayment.PaymentStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billspayment.PaymentStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billspayment.PaymentStatusResponse.displayName = 'proto.billspayment.PaymentStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billspayment.Biller = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billspayment.Biller.repeatedFields_, null);
};
goog.inherits(proto.billspayment.Biller, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billspayment.Biller.displayName = 'proto.billspayment.Biller';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billspayment.GetBillersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billspayment.GetBillersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billspayment.GetBillersRequest.displayName = 'proto.billspayment.GetBillersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billspayment.GetBillersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billspayment.GetBillersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billspayment.GetBillersResponse.displayName = 'proto.billspayment.GetBillersResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billspayment.GetProductsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billspayment.GetProductsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billspayment.GetProductsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.GetProductsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    billercode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    useraccountno: jspb.Message.getFieldWithDefault(msg, 2, ""),
    referenceno: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billspayment.GetProductsRequest}
 */
proto.billspayment.GetProductsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billspayment.GetProductsRequest;
  return proto.billspayment.GetProductsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billspayment.GetProductsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billspayment.GetProductsRequest}
 */
proto.billspayment.GetProductsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillercode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUseraccountno(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceno(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billspayment.GetProductsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billspayment.GetProductsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billspayment.GetProductsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.GetProductsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillercode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUseraccountno();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReferenceno();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string BillerCode = 1;
 * @return {string}
 */
proto.billspayment.GetProductsRequest.prototype.getBillercode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.GetProductsRequest} returns this
 */
proto.billspayment.GetProductsRequest.prototype.setBillercode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string UserAccountNo = 2;
 * @return {string}
 */
proto.billspayment.GetProductsRequest.prototype.getUseraccountno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.GetProductsRequest} returns this
 */
proto.billspayment.GetProductsRequest.prototype.setUseraccountno = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ReferenceNo = 3;
 * @return {string}
 */
proto.billspayment.GetProductsRequest.prototype.getReferenceno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.GetProductsRequest} returns this
 */
proto.billspayment.GetProductsRequest.prototype.setReferenceno = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billspayment.GetProductsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billspayment.GetProductsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billspayment.GetProductsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billspayment.GetProductsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.GetProductsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    billercode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.billspayment.Product.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billspayment.GetProductsResponse}
 */
proto.billspayment.GetProductsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billspayment.GetProductsResponse;
  return proto.billspayment.GetProductsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billspayment.GetProductsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billspayment.GetProductsResponse}
 */
proto.billspayment.GetProductsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillercode(value);
      break;
    case 2:
      var value = new proto.billspayment.Product;
      reader.readMessage(value,proto.billspayment.Product.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billspayment.GetProductsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billspayment.GetProductsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billspayment.GetProductsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.GetProductsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillercode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.billspayment.Product.serializeBinaryToWriter
    );
  }
};


/**
 * optional string BillerCode = 1;
 * @return {string}
 */
proto.billspayment.GetProductsResponse.prototype.getBillercode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.GetProductsResponse} returns this
 */
proto.billspayment.GetProductsResponse.prototype.setBillercode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Product Products = 2;
 * @return {!Array<!proto.billspayment.Product>}
 */
proto.billspayment.GetProductsResponse.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.billspayment.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billspayment.Product, 2));
};


/**
 * @param {!Array<!proto.billspayment.Product>} value
 * @return {!proto.billspayment.GetProductsResponse} returns this
*/
proto.billspayment.GetProductsResponse.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.billspayment.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billspayment.Product}
 */
proto.billspayment.GetProductsResponse.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.billspayment.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billspayment.GetProductsResponse} returns this
 */
proto.billspayment.GetProductsResponse.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billspayment.Amount.prototype.toObject = function(opt_includeInstance) {
  return proto.billspayment.Amount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billspayment.Amount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.Amount.toObject = function(includeInstance, msg) {
  var f, obj = {
    amt: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cur: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billspayment.Amount}
 */
proto.billspayment.Amount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billspayment.Amount;
  return proto.billspayment.Amount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billspayment.Amount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billspayment.Amount}
 */
proto.billspayment.Amount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCur(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billspayment.Amount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billspayment.Amount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billspayment.Amount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.Amount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCur();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Amt = 1;
 * @return {string}
 */
proto.billspayment.Amount.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.Amount} returns this
 */
proto.billspayment.Amount.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Cur = 2;
 * @return {string}
 */
proto.billspayment.Amount.prototype.getCur = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.Amount} returns this
 */
proto.billspayment.Amount.prototype.setCur = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billspayment.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.billspayment.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billspayment.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    productcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productdescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
    minamt: (f = msg.getMinamt()) && proto.billspayment.Amount.toObject(includeInstance, f),
    maxamt: (f = msg.getMaxamt()) && proto.billspayment.Amount.toObject(includeInstance, f),
    svcfee: (f = msg.getSvcfee()) && proto.billspayment.Amount.toObject(includeInstance, f),
    referenceno: jspb.Message.getFieldWithDefault(msg, 6, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 8, ""),
    itemdescription: jspb.Message.getFieldWithDefault(msg, 9, ""),
    principalamount: (f = msg.getPrincipalamount()) && proto.billspayment.Amount.toObject(includeInstance, f),
    loandate: (f = msg.getLoandate()) && proto.billspayment.Date.toObject(includeInstance, f),
    maturitydate: (f = msg.getMaturitydate()) && proto.billspayment.Date.toObject(includeInstance, f),
    expirydate: (f = msg.getExpirydate()) && proto.billspayment.Date.toObject(includeInstance, f),
    advanceinterest: (f = msg.getAdvanceinterest()) && proto.billspayment.Amount.toObject(includeInstance, f),
    interestamount: (f = msg.getInterestamount()) && proto.billspayment.Amount.toObject(includeInstance, f),
    liquidateddamages: (f = msg.getLiquidateddamages()) && proto.billspayment.Amount.toObject(includeInstance, f),
    mailingcharge: (f = msg.getMailingcharge()) && proto.billspayment.Amount.toObject(includeInstance, f),
    storagefee: (f = msg.getStoragefee()) && proto.billspayment.Amount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billspayment.Product}
 */
proto.billspayment.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billspayment.Product;
  return proto.billspayment.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billspayment.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billspayment.Product}
 */
proto.billspayment.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductdescription(value);
      break;
    case 3:
      var value = new proto.billspayment.Amount;
      reader.readMessage(value,proto.billspayment.Amount.deserializeBinaryFromReader);
      msg.setMinamt(value);
      break;
    case 4:
      var value = new proto.billspayment.Amount;
      reader.readMessage(value,proto.billspayment.Amount.deserializeBinaryFromReader);
      msg.setMaxamt(value);
      break;
    case 5:
      var value = new proto.billspayment.Amount;
      reader.readMessage(value,proto.billspayment.Amount.deserializeBinaryFromReader);
      msg.setSvcfee(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceno(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemdescription(value);
      break;
    case 10:
      var value = new proto.billspayment.Amount;
      reader.readMessage(value,proto.billspayment.Amount.deserializeBinaryFromReader);
      msg.setPrincipalamount(value);
      break;
    case 11:
      var value = new proto.billspayment.Date;
      reader.readMessage(value,proto.billspayment.Date.deserializeBinaryFromReader);
      msg.setLoandate(value);
      break;
    case 12:
      var value = new proto.billspayment.Date;
      reader.readMessage(value,proto.billspayment.Date.deserializeBinaryFromReader);
      msg.setMaturitydate(value);
      break;
    case 13:
      var value = new proto.billspayment.Date;
      reader.readMessage(value,proto.billspayment.Date.deserializeBinaryFromReader);
      msg.setExpirydate(value);
      break;
    case 14:
      var value = new proto.billspayment.Amount;
      reader.readMessage(value,proto.billspayment.Amount.deserializeBinaryFromReader);
      msg.setAdvanceinterest(value);
      break;
    case 15:
      var value = new proto.billspayment.Amount;
      reader.readMessage(value,proto.billspayment.Amount.deserializeBinaryFromReader);
      msg.setInterestamount(value);
      break;
    case 16:
      var value = new proto.billspayment.Amount;
      reader.readMessage(value,proto.billspayment.Amount.deserializeBinaryFromReader);
      msg.setLiquidateddamages(value);
      break;
    case 17:
      var value = new proto.billspayment.Amount;
      reader.readMessage(value,proto.billspayment.Amount.deserializeBinaryFromReader);
      msg.setMailingcharge(value);
      break;
    case 18:
      var value = new proto.billspayment.Amount;
      reader.readMessage(value,proto.billspayment.Amount.deserializeBinaryFromReader);
      msg.setStoragefee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billspayment.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billspayment.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billspayment.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductdescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMinamt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.billspayment.Amount.serializeBinaryToWriter
    );
  }
  f = message.getMaxamt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.billspayment.Amount.serializeBinaryToWriter
    );
  }
  f = message.getSvcfee();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.billspayment.Amount.serializeBinaryToWriter
    );
  }
  f = message.getReferenceno();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getItemdescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPrincipalamount();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.billspayment.Amount.serializeBinaryToWriter
    );
  }
  f = message.getLoandate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.billspayment.Date.serializeBinaryToWriter
    );
  }
  f = message.getMaturitydate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.billspayment.Date.serializeBinaryToWriter
    );
  }
  f = message.getExpirydate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.billspayment.Date.serializeBinaryToWriter
    );
  }
  f = message.getAdvanceinterest();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.billspayment.Amount.serializeBinaryToWriter
    );
  }
  f = message.getInterestamount();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.billspayment.Amount.serializeBinaryToWriter
    );
  }
  f = message.getLiquidateddamages();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.billspayment.Amount.serializeBinaryToWriter
    );
  }
  f = message.getMailingcharge();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.billspayment.Amount.serializeBinaryToWriter
    );
  }
  f = message.getStoragefee();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.billspayment.Amount.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ProductCode = 1;
 * @return {string}
 */
proto.billspayment.Product.prototype.getProductcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.setProductcode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ProductDescription = 2;
 * @return {string}
 */
proto.billspayment.Product.prototype.getProductdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.setProductdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Amount MinAmt = 3;
 * @return {?proto.billspayment.Amount}
 */
proto.billspayment.Product.prototype.getMinamt = function() {
  return /** @type{?proto.billspayment.Amount} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.Amount, 3));
};


/**
 * @param {?proto.billspayment.Amount|undefined} value
 * @return {!proto.billspayment.Product} returns this
*/
proto.billspayment.Product.prototype.setMinamt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.clearMinamt = function() {
  return this.setMinamt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.Product.prototype.hasMinamt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Amount MaxAmt = 4;
 * @return {?proto.billspayment.Amount}
 */
proto.billspayment.Product.prototype.getMaxamt = function() {
  return /** @type{?proto.billspayment.Amount} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.Amount, 4));
};


/**
 * @param {?proto.billspayment.Amount|undefined} value
 * @return {!proto.billspayment.Product} returns this
*/
proto.billspayment.Product.prototype.setMaxamt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.clearMaxamt = function() {
  return this.setMaxamt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.Product.prototype.hasMaxamt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Amount SvcFee = 5;
 * @return {?proto.billspayment.Amount}
 */
proto.billspayment.Product.prototype.getSvcfee = function() {
  return /** @type{?proto.billspayment.Amount} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.Amount, 5));
};


/**
 * @param {?proto.billspayment.Amount|undefined} value
 * @return {!proto.billspayment.Product} returns this
*/
proto.billspayment.Product.prototype.setSvcfee = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.clearSvcfee = function() {
  return this.setSvcfee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.Product.prototype.hasSvcfee = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string ReferenceNo = 6;
 * @return {string}
 */
proto.billspayment.Product.prototype.getReferenceno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.setReferenceno = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string FirstName = 7;
 * @return {string}
 */
proto.billspayment.Product.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string LastName = 8;
 * @return {string}
 */
proto.billspayment.Product.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string ItemDescription = 9;
 * @return {string}
 */
proto.billspayment.Product.prototype.getItemdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.setItemdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional Amount PrincipalAmount = 10;
 * @return {?proto.billspayment.Amount}
 */
proto.billspayment.Product.prototype.getPrincipalamount = function() {
  return /** @type{?proto.billspayment.Amount} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.Amount, 10));
};


/**
 * @param {?proto.billspayment.Amount|undefined} value
 * @return {!proto.billspayment.Product} returns this
*/
proto.billspayment.Product.prototype.setPrincipalamount = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.clearPrincipalamount = function() {
  return this.setPrincipalamount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.Product.prototype.hasPrincipalamount = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Date LoanDate = 11;
 * @return {?proto.billspayment.Date}
 */
proto.billspayment.Product.prototype.getLoandate = function() {
  return /** @type{?proto.billspayment.Date} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.Date, 11));
};


/**
 * @param {?proto.billspayment.Date|undefined} value
 * @return {!proto.billspayment.Product} returns this
*/
proto.billspayment.Product.prototype.setLoandate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.clearLoandate = function() {
  return this.setLoandate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.Product.prototype.hasLoandate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Date MaturityDate = 12;
 * @return {?proto.billspayment.Date}
 */
proto.billspayment.Product.prototype.getMaturitydate = function() {
  return /** @type{?proto.billspayment.Date} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.Date, 12));
};


/**
 * @param {?proto.billspayment.Date|undefined} value
 * @return {!proto.billspayment.Product} returns this
*/
proto.billspayment.Product.prototype.setMaturitydate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.clearMaturitydate = function() {
  return this.setMaturitydate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.Product.prototype.hasMaturitydate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Date ExpiryDate = 13;
 * @return {?proto.billspayment.Date}
 */
proto.billspayment.Product.prototype.getExpirydate = function() {
  return /** @type{?proto.billspayment.Date} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.Date, 13));
};


/**
 * @param {?proto.billspayment.Date|undefined} value
 * @return {!proto.billspayment.Product} returns this
*/
proto.billspayment.Product.prototype.setExpirydate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.clearExpirydate = function() {
  return this.setExpirydate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.Product.prototype.hasExpirydate = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Amount AdvanceInterest = 14;
 * @return {?proto.billspayment.Amount}
 */
proto.billspayment.Product.prototype.getAdvanceinterest = function() {
  return /** @type{?proto.billspayment.Amount} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.Amount, 14));
};


/**
 * @param {?proto.billspayment.Amount|undefined} value
 * @return {!proto.billspayment.Product} returns this
*/
proto.billspayment.Product.prototype.setAdvanceinterest = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.clearAdvanceinterest = function() {
  return this.setAdvanceinterest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.Product.prototype.hasAdvanceinterest = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Amount InterestAmount = 15;
 * @return {?proto.billspayment.Amount}
 */
proto.billspayment.Product.prototype.getInterestamount = function() {
  return /** @type{?proto.billspayment.Amount} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.Amount, 15));
};


/**
 * @param {?proto.billspayment.Amount|undefined} value
 * @return {!proto.billspayment.Product} returns this
*/
proto.billspayment.Product.prototype.setInterestamount = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.clearInterestamount = function() {
  return this.setInterestamount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.Product.prototype.hasInterestamount = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional Amount LiquidatedDamages = 16;
 * @return {?proto.billspayment.Amount}
 */
proto.billspayment.Product.prototype.getLiquidateddamages = function() {
  return /** @type{?proto.billspayment.Amount} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.Amount, 16));
};


/**
 * @param {?proto.billspayment.Amount|undefined} value
 * @return {!proto.billspayment.Product} returns this
*/
proto.billspayment.Product.prototype.setLiquidateddamages = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.clearLiquidateddamages = function() {
  return this.setLiquidateddamages(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.Product.prototype.hasLiquidateddamages = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Amount MailingCharge = 17;
 * @return {?proto.billspayment.Amount}
 */
proto.billspayment.Product.prototype.getMailingcharge = function() {
  return /** @type{?proto.billspayment.Amount} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.Amount, 17));
};


/**
 * @param {?proto.billspayment.Amount|undefined} value
 * @return {!proto.billspayment.Product} returns this
*/
proto.billspayment.Product.prototype.setMailingcharge = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.clearMailingcharge = function() {
  return this.setMailingcharge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.Product.prototype.hasMailingcharge = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Amount StorageFee = 18;
 * @return {?proto.billspayment.Amount}
 */
proto.billspayment.Product.prototype.getStoragefee = function() {
  return /** @type{?proto.billspayment.Amount} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.Amount, 18));
};


/**
 * @param {?proto.billspayment.Amount|undefined} value
 * @return {!proto.billspayment.Product} returns this
*/
proto.billspayment.Product.prototype.setStoragefee = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.Product} returns this
 */
proto.billspayment.Product.prototype.clearStoragefee = function() {
  return this.setStoragefee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.Product.prototype.hasStoragefee = function() {
  return jspb.Message.getField(this, 18) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billspayment.GetBillInputsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billspayment.GetBillInputsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billspayment.GetBillInputsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.GetBillInputsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    billercode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    insurancecategory: jspb.Message.getFieldWithDefault(msg, 2, 0),
    insuranceid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billspayment.GetBillInputsRequest}
 */
proto.billspayment.GetBillInputsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billspayment.GetBillInputsRequest;
  return proto.billspayment.GetBillInputsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billspayment.GetBillInputsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billspayment.GetBillInputsRequest}
 */
proto.billspayment.GetBillInputsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillercode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInsurancecategory(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInsuranceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billspayment.GetBillInputsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billspayment.GetBillInputsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billspayment.GetBillInputsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.GetBillInputsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillercode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInsurancecategory();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getInsuranceid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string BillerCode = 1;
 * @return {string}
 */
proto.billspayment.GetBillInputsRequest.prototype.getBillercode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.GetBillInputsRequest} returns this
 */
proto.billspayment.GetBillInputsRequest.prototype.setBillercode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 InsuranceCategory = 2;
 * @return {number}
 */
proto.billspayment.GetBillInputsRequest.prototype.getInsurancecategory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billspayment.GetBillInputsRequest} returns this
 */
proto.billspayment.GetBillInputsRequest.prototype.setInsurancecategory = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 InsuranceID = 3;
 * @return {number}
 */
proto.billspayment.GetBillInputsRequest.prototype.getInsuranceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billspayment.GetBillInputsRequest} returns this
 */
proto.billspayment.GetBillInputsRequest.prototype.setInsuranceid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billspayment.GetBillInputsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billspayment.GetBillInputsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billspayment.GetBillInputsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billspayment.GetBillInputsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.GetBillInputsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    billercode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inputsList: jspb.Message.toObjectList(msg.getInputsList(),
    proto.billspayment.InputField.toObject, includeInstance),
    insurancecategory: jspb.Message.getFieldWithDefault(msg, 3, 0),
    insuranceid: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billspayment.GetBillInputsResponse}
 */
proto.billspayment.GetBillInputsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billspayment.GetBillInputsResponse;
  return proto.billspayment.GetBillInputsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billspayment.GetBillInputsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billspayment.GetBillInputsResponse}
 */
proto.billspayment.GetBillInputsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillercode(value);
      break;
    case 2:
      var value = new proto.billspayment.InputField;
      reader.readMessage(value,proto.billspayment.InputField.deserializeBinaryFromReader);
      msg.addInputs(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInsurancecategory(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInsuranceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billspayment.GetBillInputsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billspayment.GetBillInputsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billspayment.GetBillInputsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.GetBillInputsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillercode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInputsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.billspayment.InputField.serializeBinaryToWriter
    );
  }
  f = message.getInsurancecategory();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getInsuranceid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string BillerCode = 1;
 * @return {string}
 */
proto.billspayment.GetBillInputsResponse.prototype.getBillercode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.GetBillInputsResponse} returns this
 */
proto.billspayment.GetBillInputsResponse.prototype.setBillercode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated InputField Inputs = 2;
 * @return {!Array<!proto.billspayment.InputField>}
 */
proto.billspayment.GetBillInputsResponse.prototype.getInputsList = function() {
  return /** @type{!Array<!proto.billspayment.InputField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billspayment.InputField, 2));
};


/**
 * @param {!Array<!proto.billspayment.InputField>} value
 * @return {!proto.billspayment.GetBillInputsResponse} returns this
*/
proto.billspayment.GetBillInputsResponse.prototype.setInputsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.billspayment.InputField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billspayment.InputField}
 */
proto.billspayment.GetBillInputsResponse.prototype.addInputs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.billspayment.InputField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billspayment.GetBillInputsResponse} returns this
 */
proto.billspayment.GetBillInputsResponse.prototype.clearInputsList = function() {
  return this.setInputsList([]);
};


/**
 * optional int32 InsuranceCategory = 3;
 * @return {number}
 */
proto.billspayment.GetBillInputsResponse.prototype.getInsurancecategory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billspayment.GetBillInputsResponse} returns this
 */
proto.billspayment.GetBillInputsResponse.prototype.setInsurancecategory = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 InsuranceID = 4;
 * @return {number}
 */
proto.billspayment.GetBillInputsResponse.prototype.getInsuranceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billspayment.GetBillInputsResponse} returns this
 */
proto.billspayment.GetBillInputsResponse.prototype.setInsuranceid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billspayment.Date.prototype.toObject = function(opt_includeInstance) {
  return proto.billspayment.Date.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billspayment.Date} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.Date.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, ""),
    month: jspb.Message.getFieldWithDefault(msg, 2, ""),
    day: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billspayment.Date}
 */
proto.billspayment.Date.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billspayment.Date;
  return proto.billspayment.Date.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billspayment.Date} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billspayment.Date}
 */
proto.billspayment.Date.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billspayment.Date.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billspayment.Date.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billspayment.Date} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.Date.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDay();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string Year = 1;
 * @return {string}
 */
proto.billspayment.Date.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.Date} returns this
 */
proto.billspayment.Date.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Month = 2;
 * @return {string}
 */
proto.billspayment.Date.prototype.getMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.Date} returns this
 */
proto.billspayment.Date.prototype.setMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Day = 3;
 * @return {string}
 */
proto.billspayment.Date.prototype.getDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.Date} returns this
 */
proto.billspayment.Date.prototype.setDay = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billspayment.InputField.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billspayment.InputField.prototype.toObject = function(opt_includeInstance) {
  return proto.billspayment.InputField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billspayment.InputField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.InputField.toObject = function(includeInstance, msg) {
  var f, obj = {
    inputtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    inputid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    required: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    regex: jspb.Message.getFieldWithDefault(msg, 6, ""),
    format: jspb.Message.getFieldWithDefault(msg, 7, ""),
    itemsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    mindate: (f = msg.getMindate()) && proto.billspayment.Date.toObject(includeInstance, f),
    overdue: jspb.Message.getFieldWithDefault(msg, 10, ""),
    validationnote: jspb.Message.getFieldWithDefault(msg, 11, ""),
    allowdecimal: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billspayment.InputField}
 */
proto.billspayment.InputField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billspayment.InputField;
  return proto.billspayment.InputField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billspayment.InputField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billspayment.InputField}
 */
proto.billspayment.InputField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.billspayment.InputType} */ (reader.readEnum());
      msg.setInputtype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInputid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegex(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addItems(value);
      break;
    case 9:
      var value = new proto.billspayment.Date;
      reader.readMessage(value,proto.billspayment.Date.deserializeBinaryFromReader);
      msg.setMindate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverdue(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationnote(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowdecimal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billspayment.InputField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billspayment.InputField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billspayment.InputField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.InputField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInputtype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getInputid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRegex();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getMindate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.billspayment.Date.serializeBinaryToWriter
    );
  }
  f = message.getOverdue();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getValidationnote();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAllowdecimal();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional InputType InputType = 1;
 * @return {!proto.billspayment.InputType}
 */
proto.billspayment.InputField.prototype.getInputtype = function() {
  return /** @type {!proto.billspayment.InputType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.billspayment.InputType} value
 * @return {!proto.billspayment.InputField} returns this
 */
proto.billspayment.InputField.prototype.setInputtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string InputID = 2;
 * @return {string}
 */
proto.billspayment.InputField.prototype.getInputid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.InputField} returns this
 */
proto.billspayment.InputField.prototype.setInputid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Label = 3;
 * @return {string}
 */
proto.billspayment.InputField.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.InputField} returns this
 */
proto.billspayment.InputField.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool Required = 4;
 * @return {boolean}
 */
proto.billspayment.InputField.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billspayment.InputField} returns this
 */
proto.billspayment.InputField.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string Description = 5;
 * @return {string}
 */
proto.billspayment.InputField.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.InputField} returns this
 */
proto.billspayment.InputField.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Regex = 6;
 * @return {string}
 */
proto.billspayment.InputField.prototype.getRegex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.InputField} returns this
 */
proto.billspayment.InputField.prototype.setRegex = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Format = 7;
 * @return {string}
 */
proto.billspayment.InputField.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.InputField} returns this
 */
proto.billspayment.InputField.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string Items = 8;
 * @return {!Array<string>}
 */
proto.billspayment.InputField.prototype.getItemsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.billspayment.InputField} returns this
 */
proto.billspayment.InputField.prototype.setItemsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.billspayment.InputField} returns this
 */
proto.billspayment.InputField.prototype.addItems = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billspayment.InputField} returns this
 */
proto.billspayment.InputField.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional Date MinDate = 9;
 * @return {?proto.billspayment.Date}
 */
proto.billspayment.InputField.prototype.getMindate = function() {
  return /** @type{?proto.billspayment.Date} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.Date, 9));
};


/**
 * @param {?proto.billspayment.Date|undefined} value
 * @return {!proto.billspayment.InputField} returns this
*/
proto.billspayment.InputField.prototype.setMindate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.InputField} returns this
 */
proto.billspayment.InputField.prototype.clearMindate = function() {
  return this.setMindate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.InputField.prototype.hasMindate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string Overdue = 10;
 * @return {string}
 */
proto.billspayment.InputField.prototype.getOverdue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.InputField} returns this
 */
proto.billspayment.InputField.prototype.setOverdue = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string ValidationNote = 11;
 * @return {string}
 */
proto.billspayment.InputField.prototype.getValidationnote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.InputField} returns this
 */
proto.billspayment.InputField.prototype.setValidationnote = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool AllowDecimal = 12;
 * @return {boolean}
 */
proto.billspayment.InputField.prototype.getAllowdecimal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billspayment.InputField} returns this
 */
proto.billspayment.InputField.prototype.setAllowdecimal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billspayment.FullName.prototype.toObject = function(opt_includeInstance) {
  return proto.billspayment.FullName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billspayment.FullName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.FullName.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    middlename: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billspayment.FullName}
 */
proto.billspayment.FullName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billspayment.FullName;
  return proto.billspayment.FullName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billspayment.FullName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billspayment.FullName}
 */
proto.billspayment.FullName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billspayment.FullName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billspayment.FullName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billspayment.FullName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.FullName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMiddlename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string FirstName = 1;
 * @return {string}
 */
proto.billspayment.FullName.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.FullName} returns this
 */
proto.billspayment.FullName.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string LastName = 2;
 * @return {string}
 */
proto.billspayment.FullName.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.FullName} returns this
 */
proto.billspayment.FullName.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string MiddleName = 3;
 * @return {string}
 */
proto.billspayment.FullName.prototype.getMiddlename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.FullName} returns this
 */
proto.billspayment.FullName.prototype.setMiddlename = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billspayment.Beneficiary.prototype.toObject = function(opt_includeInstance) {
  return proto.billspayment.Beneficiary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billspayment.Beneficiary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.Beneficiary.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && proto.billspayment.FullName.toObject(includeInstance, f),
    relationship: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billspayment.Beneficiary}
 */
proto.billspayment.Beneficiary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billspayment.Beneficiary;
  return proto.billspayment.Beneficiary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billspayment.Beneficiary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billspayment.Beneficiary}
 */
proto.billspayment.Beneficiary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billspayment.FullName;
      reader.readMessage(value,proto.billspayment.FullName.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelationship(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billspayment.Beneficiary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billspayment.Beneficiary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billspayment.Beneficiary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.Beneficiary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.billspayment.FullName.serializeBinaryToWriter
    );
  }
  f = message.getRelationship();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional FullName Name = 1;
 * @return {?proto.billspayment.FullName}
 */
proto.billspayment.Beneficiary.prototype.getName = function() {
  return /** @type{?proto.billspayment.FullName} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.FullName, 1));
};


/**
 * @param {?proto.billspayment.FullName|undefined} value
 * @return {!proto.billspayment.Beneficiary} returns this
*/
proto.billspayment.Beneficiary.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.Beneficiary} returns this
 */
proto.billspayment.Beneficiary.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.Beneficiary.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Relationship = 2;
 * @return {string}
 */
proto.billspayment.Beneficiary.prototype.getRelationship = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.Beneficiary} returns this
 */
proto.billspayment.Beneficiary.prototype.setRelationship = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billspayment.PayBillRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billspayment.PayBillRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billspayment.PayBillRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.PayBillRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    billercode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountno: jspb.Message.getFieldWithDefault(msg, 2, ""),
    invoiceref: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: (f = msg.getAmount()) && proto.billspayment.Amount.toObject(includeInstance, f),
    registeredname: (f = msg.getRegisteredname()) && proto.billspayment.FullName.toObject(includeInstance, f),
    fieldsMap: (f = msg.getFieldsMap()) ? f.toObject(includeInstance, undefined) : [],
    insurancecategory: jspb.Message.getFieldWithDefault(msg, 7, 0),
    insuranceid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    beneficiaryinfo: (f = msg.getBeneficiaryinfo()) && proto.billspayment.Beneficiary.toObject(includeInstance, f),
    redeempoints: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billspayment.PayBillRequest}
 */
proto.billspayment.PayBillRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billspayment.PayBillRequest;
  return proto.billspayment.PayBillRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billspayment.PayBillRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billspayment.PayBillRequest}
 */
proto.billspayment.PayBillRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillercode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountno(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceref(value);
      break;
    case 4:
      var value = new proto.billspayment.Amount;
      reader.readMessage(value,proto.billspayment.Amount.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 5:
      var value = new proto.billspayment.FullName;
      reader.readMessage(value,proto.billspayment.FullName.deserializeBinaryFromReader);
      msg.setRegisteredname(value);
      break;
    case 6:
      var value = msg.getFieldsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInsurancecategory(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInsuranceid(value);
      break;
    case 9:
      var value = new proto.billspayment.Beneficiary;
      reader.readMessage(value,proto.billspayment.Beneficiary.deserializeBinaryFromReader);
      msg.setBeneficiaryinfo(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRedeempoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billspayment.PayBillRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billspayment.PayBillRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billspayment.PayBillRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.PayBillRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillercode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountno();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInvoiceref();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.billspayment.Amount.serializeBinaryToWriter
    );
  }
  f = message.getRegisteredname();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.billspayment.FullName.serializeBinaryToWriter
    );
  }
  f = message.getFieldsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getInsurancecategory();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getInsuranceid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getBeneficiaryinfo();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.billspayment.Beneficiary.serializeBinaryToWriter
    );
  }
  f = message.getRedeempoints();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional string BillerCode = 1;
 * @return {string}
 */
proto.billspayment.PayBillRequest.prototype.getBillercode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.PayBillRequest} returns this
 */
proto.billspayment.PayBillRequest.prototype.setBillercode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string AccountNo = 2;
 * @return {string}
 */
proto.billspayment.PayBillRequest.prototype.getAccountno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.PayBillRequest} returns this
 */
proto.billspayment.PayBillRequest.prototype.setAccountno = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string InvoiceRef = 3;
 * @return {string}
 */
proto.billspayment.PayBillRequest.prototype.getInvoiceref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.PayBillRequest} returns this
 */
proto.billspayment.PayBillRequest.prototype.setInvoiceref = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Amount Amount = 4;
 * @return {?proto.billspayment.Amount}
 */
proto.billspayment.PayBillRequest.prototype.getAmount = function() {
  return /** @type{?proto.billspayment.Amount} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.Amount, 4));
};


/**
 * @param {?proto.billspayment.Amount|undefined} value
 * @return {!proto.billspayment.PayBillRequest} returns this
*/
proto.billspayment.PayBillRequest.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.PayBillRequest} returns this
 */
proto.billspayment.PayBillRequest.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.PayBillRequest.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FullName RegisteredName = 5;
 * @return {?proto.billspayment.FullName}
 */
proto.billspayment.PayBillRequest.prototype.getRegisteredname = function() {
  return /** @type{?proto.billspayment.FullName} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.FullName, 5));
};


/**
 * @param {?proto.billspayment.FullName|undefined} value
 * @return {!proto.billspayment.PayBillRequest} returns this
*/
proto.billspayment.PayBillRequest.prototype.setRegisteredname = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.PayBillRequest} returns this
 */
proto.billspayment.PayBillRequest.prototype.clearRegisteredname = function() {
  return this.setRegisteredname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.PayBillRequest.prototype.hasRegisteredname = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * map<string, string> Fields = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.billspayment.PayBillRequest.prototype.getFieldsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.billspayment.PayBillRequest} returns this
 */
proto.billspayment.PayBillRequest.prototype.clearFieldsMap = function() {
  this.getFieldsMap().clear();
  return this;};


/**
 * optional int32 InsuranceCategory = 7;
 * @return {number}
 */
proto.billspayment.PayBillRequest.prototype.getInsurancecategory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.billspayment.PayBillRequest} returns this
 */
proto.billspayment.PayBillRequest.prototype.setInsurancecategory = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 InsuranceID = 8;
 * @return {number}
 */
proto.billspayment.PayBillRequest.prototype.getInsuranceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.billspayment.PayBillRequest} returns this
 */
proto.billspayment.PayBillRequest.prototype.setInsuranceid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional Beneficiary BeneficiaryInfo = 9;
 * @return {?proto.billspayment.Beneficiary}
 */
proto.billspayment.PayBillRequest.prototype.getBeneficiaryinfo = function() {
  return /** @type{?proto.billspayment.Beneficiary} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.Beneficiary, 9));
};


/**
 * @param {?proto.billspayment.Beneficiary|undefined} value
 * @return {!proto.billspayment.PayBillRequest} returns this
*/
proto.billspayment.PayBillRequest.prototype.setBeneficiaryinfo = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.PayBillRequest} returns this
 */
proto.billspayment.PayBillRequest.prototype.clearBeneficiaryinfo = function() {
  return this.setBeneficiaryinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.PayBillRequest.prototype.hasBeneficiaryinfo = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int32 RedeemPoints = 10;
 * @return {number}
 */
proto.billspayment.PayBillRequest.prototype.getRedeempoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.billspayment.PayBillRequest} returns this
 */
proto.billspayment.PayBillRequest.prototype.setRedeempoints = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billspayment.PayBillResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billspayment.PayBillResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billspayment.PayBillResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.PayBillResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    billercode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    message: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billspayment.PayBillResponse}
 */
proto.billspayment.PayBillResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billspayment.PayBillResponse;
  return proto.billspayment.PayBillResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billspayment.PayBillResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billspayment.PayBillResponse}
 */
proto.billspayment.PayBillResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillercode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    case 3:
      var value = /** @type {!proto.billspayment.ProcessingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billspayment.PayBillResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billspayment.PayBillResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billspayment.PayBillResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.PayBillResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillercode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string BillerCode = 1;
 * @return {string}
 */
proto.billspayment.PayBillResponse.prototype.getBillercode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.PayBillResponse} returns this
 */
proto.billspayment.PayBillResponse.prototype.setBillercode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string TransactionID = 2;
 * @return {string}
 */
proto.billspayment.PayBillResponse.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.PayBillResponse} returns this
 */
proto.billspayment.PayBillResponse.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ProcessingStatus Status = 3;
 * @return {!proto.billspayment.ProcessingStatus}
 */
proto.billspayment.PayBillResponse.prototype.getStatus = function() {
  return /** @type {!proto.billspayment.ProcessingStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.billspayment.ProcessingStatus} value
 * @return {!proto.billspayment.PayBillResponse} returns this
 */
proto.billspayment.PayBillResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string Message = 4;
 * @return {string}
 */
proto.billspayment.PayBillResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.PayBillResponse} returns this
 */
proto.billspayment.PayBillResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billspayment.PaymentStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billspayment.PaymentStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billspayment.PaymentStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.PaymentStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billspayment.PaymentStatusRequest}
 */
proto.billspayment.PaymentStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billspayment.PaymentStatusRequest;
  return proto.billspayment.PaymentStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billspayment.PaymentStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billspayment.PaymentStatusRequest}
 */
proto.billspayment.PaymentStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billspayment.PaymentStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billspayment.PaymentStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billspayment.PaymentStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.PaymentStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string TransactionID = 1;
 * @return {string}
 */
proto.billspayment.PaymentStatusRequest.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.PaymentStatusRequest} returns this
 */
proto.billspayment.PaymentStatusRequest.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billspayment.PaymentStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billspayment.PaymentStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billspayment.PaymentStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.PaymentStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billspayment.PaymentStatusResponse}
 */
proto.billspayment.PaymentStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billspayment.PaymentStatusResponse;
  return proto.billspayment.PaymentStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billspayment.PaymentStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billspayment.PaymentStatusResponse}
 */
proto.billspayment.PaymentStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    case 2:
      var value = /** @type {!proto.billspayment.ProcessingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billspayment.PaymentStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billspayment.PaymentStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billspayment.PaymentStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.PaymentStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string TransactionID = 1;
 * @return {string}
 */
proto.billspayment.PaymentStatusResponse.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.PaymentStatusResponse} returns this
 */
proto.billspayment.PaymentStatusResponse.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ProcessingStatus Status = 2;
 * @return {!proto.billspayment.ProcessingStatus}
 */
proto.billspayment.PaymentStatusResponse.prototype.getStatus = function() {
  return /** @type {!proto.billspayment.ProcessingStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.billspayment.ProcessingStatus} value
 * @return {!proto.billspayment.PaymentStatusResponse} returns this
 */
proto.billspayment.PaymentStatusResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billspayment.Biller.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billspayment.Biller.prototype.toObject = function(opt_includeInstance) {
  return proto.billspayment.Biller.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billspayment.Biller} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.Biller.toObject = function(includeInstance, msg) {
  var f, obj = {
    billercode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    billername: jspb.Message.getFieldWithDefault(msg, 2, ""),
    billerdescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fee: (f = msg.getFee()) && proto.billspayment.Amount.toObject(includeInstance, f),
    categoryList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billspayment.Biller}
 */
proto.billspayment.Biller.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billspayment.Biller;
  return proto.billspayment.Biller.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billspayment.Biller} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billspayment.Biller}
 */
proto.billspayment.Biller.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillercode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillerdescription(value);
      break;
    case 4:
      var value = new proto.billspayment.Amount;
      reader.readMessage(value,proto.billspayment.Amount.deserializeBinaryFromReader);
      msg.setFee(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billspayment.Biller.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billspayment.Biller.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billspayment.Biller} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.Biller.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillercode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBillername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBillerdescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFee();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.billspayment.Amount.serializeBinaryToWriter
    );
  }
  f = message.getCategoryList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string BillerCode = 1;
 * @return {string}
 */
proto.billspayment.Biller.prototype.getBillercode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.Biller} returns this
 */
proto.billspayment.Biller.prototype.setBillercode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string BillerName = 2;
 * @return {string}
 */
proto.billspayment.Biller.prototype.getBillername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.Biller} returns this
 */
proto.billspayment.Biller.prototype.setBillername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string BillerDescription = 3;
 * @return {string}
 */
proto.billspayment.Biller.prototype.getBillerdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billspayment.Biller} returns this
 */
proto.billspayment.Biller.prototype.setBillerdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Amount Fee = 4;
 * @return {?proto.billspayment.Amount}
 */
proto.billspayment.Biller.prototype.getFee = function() {
  return /** @type{?proto.billspayment.Amount} */ (
    jspb.Message.getWrapperField(this, proto.billspayment.Amount, 4));
};


/**
 * @param {?proto.billspayment.Amount|undefined} value
 * @return {!proto.billspayment.Biller} returns this
*/
proto.billspayment.Biller.prototype.setFee = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billspayment.Biller} returns this
 */
proto.billspayment.Biller.prototype.clearFee = function() {
  return this.setFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billspayment.Biller.prototype.hasFee = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated string Category = 5;
 * @return {!Array<string>}
 */
proto.billspayment.Biller.prototype.getCategoryList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.billspayment.Biller} returns this
 */
proto.billspayment.Biller.prototype.setCategoryList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.billspayment.Biller} returns this
 */
proto.billspayment.Biller.prototype.addCategory = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billspayment.Biller} returns this
 */
proto.billspayment.Biller.prototype.clearCategoryList = function() {
  return this.setCategoryList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billspayment.GetBillersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billspayment.GetBillersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billspayment.GetBillersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.GetBillersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billspayment.GetBillersRequest}
 */
proto.billspayment.GetBillersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billspayment.GetBillersRequest;
  return proto.billspayment.GetBillersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billspayment.GetBillersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billspayment.GetBillersRequest}
 */
proto.billspayment.GetBillersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billspayment.GetBillersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billspayment.GetBillersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billspayment.GetBillersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.GetBillersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billspayment.GetBillersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billspayment.GetBillersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billspayment.GetBillersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.GetBillersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    billercodesMap: (f = msg.getBillercodesMap()) ? f.toObject(includeInstance, proto.billspayment.Biller.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billspayment.GetBillersResponse}
 */
proto.billspayment.GetBillersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billspayment.GetBillersResponse;
  return proto.billspayment.GetBillersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billspayment.GetBillersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billspayment.GetBillersResponse}
 */
proto.billspayment.GetBillersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getBillercodesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.billspayment.Biller.deserializeBinaryFromReader, "", new proto.billspayment.Biller());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billspayment.GetBillersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billspayment.GetBillersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billspayment.GetBillersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billspayment.GetBillersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillercodesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.billspayment.Biller.serializeBinaryToWriter);
  }
};


/**
 * map<string, Biller> BillerCodes = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.billspayment.Biller>}
 */
proto.billspayment.GetBillersResponse.prototype.getBillercodesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.billspayment.Biller>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.billspayment.Biller));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.billspayment.GetBillersResponse} returns this
 */
proto.billspayment.GetBillersResponse.prototype.clearBillercodesMap = function() {
  this.getBillercodesMap().clear();
  return this;};


/**
 * @enum {number}
 */
proto.billspayment.InputType = {
  STRING: 0,
  INTEGER: 1,
  DATE: 2,
  EMAIL: 3,
  PHONE: 4,
  OPTION: 5,
  RADIO: 6,
  CHECKBOX: 7,
  NOTE: 8
};

/**
 * @enum {number}
 */
proto.billspayment.ProcessingStatus = {
  UNKNOWN: 0,
  SUCCESS: 1,
  CONFIRMCODE: 2,
  PROCESSING: 3,
  ERROR: 4
};

goog.object.extend(exports, proto.billspayment);
