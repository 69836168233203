














export default {
  name: "IconInfo",
};
