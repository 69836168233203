
















export default {
  name: "SearchSolid",
};
