// package: bank
// file: brank.as/ecebuana/gunk/bank/all.proto

var brank_as_ecebuana_gunk_bank_all_pb = require("./all_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var BankService = (function () {
  function BankService() {}
  BankService.serviceName = "bank.BankService";
  return BankService;
}());

BankService.GetBanks = {
  methodName: "GetBanks",
  service: BankService,
  requestStream: false,
  responseStream: false,
  requestType: brank_as_ecebuana_gunk_bank_all_pb.GetBanksRequest,
  responseType: brank_as_ecebuana_gunk_bank_all_pb.GetBanksResponse
};

exports.BankService = BankService;

function BankServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

BankServiceClient.prototype.getBanks = function getBanks(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BankService.GetBanks, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.BankServiceClient = BankServiceClient;

