








































































import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import { mixin as clickaway } from "vue-clickaway";
import { FilterItem, FilterValues } from "../types";

import FilterOptionsItem from "../components/filter-options-item/FilterOptionsItem.vue";
import SvgIcon from "../../svg-icon/SvgIcon.vue";

interface State {
  active: boolean;
}

@Component({
  mixins: [clickaway],
  components: {
    FilterOptionsItem,
    SvgIcon,
  },
})
export default class FilterDropdown extends Vue {
  @Prop({ default: "Filter" })
  private readonly text!: string;

  @Prop({ default: () => [] })
  private readonly options!: Array<FilterItem>;

  @Prop({ default: null })
  private readonly value!: FilterValues;

  private state: State = {
    active: false,
  };

  get filterCount() {
    let count = 0;

    for (const filterProperty in this.value) {
      if (this.value[filterProperty] !== null) {
        count++;
      }
    }

    return count;
  }

  onCancel() {
    this._hideSelection();
    this.$emit("cancel");
  }

  onClearItem(name: string) {
    this.$emit("clear-item", name);
  }

  onSubmit() {
    this.state.active = false;

    this.$emit("submit", this.value);
  }

  onToggle() {
    this.state.active = !this.state.active;
  }

  onInput(data: any) {
    const filters = {
      ...this.value,
      [data.name]: data.value,
    };
    this.$emit("input", filters);
  }

  _hideSelection() {
    this.state.active = false;
  }

  _getValue(key: string) {
    return !this.value ? null : this.value[key];
  }
}
