var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full"},[(_vm.mainLabel)?_c('div',{staticClass:"\n      block\n      lg:hidden\n      mb-2\n      font-medium\n      text-blue-900 text-opacity-70 text-sm\n    "},[_vm._v(" "+_vm._s(_vm.mainLabel)+" ")]):_vm._e(),_c('div',{staticClass:"inline-flex flex-col w-full shadow border rounded-md",class:{
      'ring-2 border-blue-500 ring-blue-450 ring-opacity-30':
        _vm.showMenu && !_vm.error,
      'border-orange': _vm.error,
      'border-grey-400': !_vm.showMenu && !_vm.error,
    }},[_c('div',_vm._b({staticClass:"inline-flex items-center w-full rounded-md",class:_vm.bgColor},'div',Object.assign({}, _vm.$attrs),false),[(_vm.mainLabel)?_c('div',{staticClass:"\n          flex-shrink-0\n          font-medium\n          text-blue-900 text-opacity-70 text-sm\n          hidden\n          px-6\n          lg:block\n        ",class:_vm.pageWidth && 'w-2/12'},[_vm._v(" "+_vm._s(_vm.mainLabel)+" ")]):_vm._e(),_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.onClickAway),expression:"onClickAway"}],staticClass:"flex-grow relative text-left align-middle"},[_c('div',{class:{ 'pointer-events-none': _vm.disabled },on:{"click":_vm.toggleMenu}},[_c('div',{staticClass:"overflow-hidden pr-4 cursor-pointer",class:{
              'px-4': !_vm.mainLabel,
              'py-2': _vm.size === 'sm',
              'py-4': _vm.size === 'md',
            }},[_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"text-sm font-semibold truncate mx-4 w-60",attrs:{"title":_vm.currentLabel}},[_vm._v(" "+_vm._s(_vm.currentLabel)+" ")]),_c('svg-icon',{staticClass:"w-4 h-4 right-2 top-4 align-middle",attrs:{"name":"ChevronDown"}})],1)])]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMenu),expression:"showMenu"}],staticClass:"\n            absolute\n            z-50\n            min-w-full\n            max-h-40\n            overflow-auto\n            list-none\n            bg-white\n            shadow-md\n            w-auto\n            rounded-bl rounded-br\n          "},_vm._l((_vm.menuOptions),function(ref,idx){
            var value = ref.value;
            var label = ref.label;
return _c('li',{key:idx,staticClass:"\n              overflow-hidden\n              w-full\n              cursor-pointer\n              hover:bg-grey\n              p-4\n              leading-3\n              text-sm\n              whitespace-no-wrap\n            ",attrs:{"id":'option' + idx},on:{"click":function($event){return _vm.onSelectOption({ value: value, label: label })}}},[_c('span',{class:{
                uppercase: _vm.uppercaseValues,
              }},[_vm._v(_vm._s(label))])])}),0)])]),(_vm.error)?_vm._t("input-error"):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }