








export default {
  name: "DownloadSolid",
};
