// source: all.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.types.Address', null, global);
goog.exportSymbol('proto.types.Amount', null, global);
goog.exportSymbol('proto.types.BankCode', null, global);
goog.exportSymbol('proto.types.CardAccessStatus', null, global);
goog.exportSymbol('proto.types.CardStatus', null, global);
goog.exportSymbol('proto.types.CreditRating', null, global);
goog.exportSymbol('proto.types.Location', null, global);
goog.exportSymbol('proto.types.MajorCategory', null, global);
goog.exportSymbol('proto.types.MajorType', null, global);
goog.exportSymbol('proto.types.OfflineUserInfo', null, global);
goog.exportSymbol('proto.types.Profile', null, global);
goog.exportSymbol('proto.types.ProfileAccountInfo', null, global);
goog.exportSymbol('proto.types.SortDirection', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Amount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.Amount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Amount.displayName = 'proto.types.Amount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Address.displayName = 'proto.types.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Location.displayName = 'proto.types.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.OfflineUserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.OfflineUserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.OfflineUserInfo.displayName = 'proto.types.OfflineUserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.ProfileAccountInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.ProfileAccountInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.ProfileAccountInfo.displayName = 'proto.types.ProfileAccountInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.CreditRating = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.types.CreditRating, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.CreditRating.displayName = 'proto.types.CreditRating';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Profile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.Profile.repeatedFields_, null);
};
goog.inherits(proto.types.Profile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Profile.displayName = 'proto.types.Profile';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Amount.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Amount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Amount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Amount.toObject = function(includeInstance, msg) {
  var f, obj = {
    cur: jspb.Message.getFieldWithDefault(msg, 1, ""),
    num: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Amount}
 */
proto.types.Amount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Amount;
  return proto.types.Amount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Amount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Amount}
 */
proto.types.Amount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCur(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Amount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Amount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Amount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Amount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCur();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNum();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Cur = 1;
 * @return {string}
 */
proto.types.Amount.prototype.getCur = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.types.Amount.prototype.setCur = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Num = 2;
 * @return {string}
 */
proto.types.Amount.prototype.getNum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.types.Amount.prototype.setNum = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    countryname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cityname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, ""),
    street: jspb.Message.getFieldWithDefault(msg, 4, ""),
    postalcode: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Address}
 */
proto.types.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Address;
  return proto.types.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Address}
 */
proto.types.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountryname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCityname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPostalcode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string CountryName = 1;
 * @return {string}
 */
proto.types.Address.prototype.getCountryname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.types.Address.prototype.setCountryname = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string CityName = 2;
 * @return {string}
 */
proto.types.Address.prototype.getCityname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.types.Address.prototype.setCityname = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string State = 3;
 * @return {string}
 */
proto.types.Address.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.types.Address.prototype.setState = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Street = 4;
 * @return {string}
 */
proto.types.Address.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.types.Address.prototype.setStreet = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string PostalCode = 5;
 * @return {string}
 */
proto.types.Address.prototype.getPostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.types.Address.prototype.setPostalcode = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Location}
 */
proto.types.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Location;
  return proto.types.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Location}
 */
proto.types.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double Latitude = 1;
 * @return {number}
 */
proto.types.Location.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.types.Location.prototype.setLatitude = function(value) {
  jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double Longitude = 2;
 * @return {number}
 */
proto.types.Location.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.types.Location.prototype.setLongitude = function(value) {
  jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.OfflineUserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.types.OfflineUserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.OfflineUserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.OfflineUserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    middlename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    mobileno: jspb.Message.getFieldWithDefault(msg, 5, ""),
    location: (f = msg.getLocation()) && proto.types.Location.toObject(includeInstance, f),
    address: (f = msg.getAddress()) && proto.types.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.OfflineUserInfo}
 */
proto.types.OfflineUserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.OfflineUserInfo;
  return proto.types.OfflineUserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.OfflineUserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.OfflineUserInfo}
 */
proto.types.OfflineUserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlename(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobileno(value);
      break;
    case 6:
      var value = new proto.types.Location;
      reader.readMessage(value,proto.types.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 7:
      var value = new proto.types.Address;
      reader.readMessage(value,proto.types.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.OfflineUserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.OfflineUserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.OfflineUserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.OfflineUserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMiddlename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMobileno();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.types.Location.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.types.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional string UserID = 1;
 * @return {string}
 */
proto.types.OfflineUserInfo.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.types.OfflineUserInfo.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string FirstName = 2;
 * @return {string}
 */
proto.types.OfflineUserInfo.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.types.OfflineUserInfo.prototype.setFirstname = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string MiddleName = 3;
 * @return {string}
 */
proto.types.OfflineUserInfo.prototype.getMiddlename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.types.OfflineUserInfo.prototype.setMiddlename = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string LastName = 4;
 * @return {string}
 */
proto.types.OfflineUserInfo.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.types.OfflineUserInfo.prototype.setLastname = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string MobileNo = 5;
 * @return {string}
 */
proto.types.OfflineUserInfo.prototype.getMobileno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.types.OfflineUserInfo.prototype.setMobileno = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Location Location = 6;
 * @return {?proto.types.Location}
 */
proto.types.OfflineUserInfo.prototype.getLocation = function() {
  return /** @type{?proto.types.Location} */ (
    jspb.Message.getWrapperField(this, proto.types.Location, 6));
};


/** @param {?proto.types.Location|undefined} value */
proto.types.OfflineUserInfo.prototype.setLocation = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.types.OfflineUserInfo.prototype.clearLocation = function() {
  this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.OfflineUserInfo.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Address Address = 7;
 * @return {?proto.types.Address}
 */
proto.types.OfflineUserInfo.prototype.getAddress = function() {
  return /** @type{?proto.types.Address} */ (
    jspb.Message.getWrapperField(this, proto.types.Address, 7));
};


/** @param {?proto.types.Address|undefined} value */
proto.types.OfflineUserInfo.prototype.setAddress = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.types.OfflineUserInfo.prototype.clearAddress = function() {
  this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.OfflineUserInfo.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.ProfileAccountInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.types.ProfileAccountInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.ProfileAccountInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ProfileAccountInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    bankcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profileid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.ProfileAccountInfo}
 */
proto.types.ProfileAccountInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.ProfileAccountInfo;
  return proto.types.ProfileAccountInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.ProfileAccountInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.ProfileAccountInfo}
 */
proto.types.ProfileAccountInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.ProfileAccountInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.ProfileAccountInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.ProfileAccountInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ProfileAccountInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string BankCode = 1;
 * @return {string}
 */
proto.types.ProfileAccountInfo.prototype.getBankcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.types.ProfileAccountInfo.prototype.setBankcode = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string AccountID = 2;
 * @return {string}
 */
proto.types.ProfileAccountInfo.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.types.ProfileAccountInfo.prototype.setAccountid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ProfileID = 3;
 * @return {string}
 */
proto.types.ProfileAccountInfo.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.types.ProfileAccountInfo.prototype.setProfileid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.CreditRating.prototype.toObject = function(opt_includeInstance) {
  return proto.types.CreditRating.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.CreditRating} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.CreditRating.toObject = function(includeInstance, msg) {
  var f, obj = {
    rating: jspb.Message.getFieldWithDefault(msg, 1, ""),
    source: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.CreditRating}
 */
proto.types.CreditRating.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.CreditRating;
  return proto.types.CreditRating.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.CreditRating} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.CreditRating}
 */
proto.types.CreditRating.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRating(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.CreditRating.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.CreditRating.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.CreditRating} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.CreditRating.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRating();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Rating = 1;
 * @return {string}
 */
proto.types.CreditRating.prototype.getRating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.types.CreditRating.prototype.setRating = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Source = 2;
 * @return {string}
 */
proto.types.CreditRating.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.types.CreditRating.prototype.setSource = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.Profile.repeatedFields_ = [19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Profile.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Profile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Profile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Profile.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fullname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    birthdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    language: jspb.Message.getFieldWithDefault(msg, 5, ""),
    country: jspb.Message.getFieldWithDefault(msg, 6, ""),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    emailvefified: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    mobile: jspb.Message.getFieldWithDefault(msg, 9, ""),
    photo: jspb.Message.getFieldWithDefault(msg, 10, ""),
    title: jspb.Message.getFieldWithDefault(msg, 11, ""),
    permanentaddress: (f = msg.getPermanentaddress()) && proto.types.Address.toObject(includeInstance, f),
    contactaddress: (f = msg.getContactaddress()) && proto.types.Address.toObject(includeInstance, f),
    profilenumber: jspb.Message.getFieldWithDefault(msg, 14, ""),
    faceimageurl: jspb.Message.getFieldWithDefault(msg, 15, ""),
    faceimagedate: jspb.Message.getFieldWithDefault(msg, 16, ""),
    relationshipstatus: jspb.Message.getFieldWithDefault(msg, 17, ""),
    dependents: jspb.Message.getFieldWithDefault(msg, 18, 0),
    dobofdependentsList: jspb.Message.toObjectList(msg.getDobofdependentsList(),
    google_protobuf_timestamp_pb.Timestamp.toObject, includeInstance),
    creditrating: (f = msg.getCreditrating()) && proto.types.CreditRating.toObject(includeInstance, f),
    creditlimit: (f = msg.getCreditlimit()) && proto.types.Amount.toObject(includeInstance, f),
    highesteducationattained: jspb.Message.getFieldWithDefault(msg, 22, ""),
    employmentstatus: jspb.Message.getFieldWithDefault(msg, 23, ""),
    kycstatus: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    branchid: jspb.Message.getFieldWithDefault(msg, 25, ""),
    namesuffix: jspb.Message.getFieldWithDefault(msg, 26, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 27, ""),
    middlename: jspb.Message.getFieldWithDefault(msg, 28, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 29, ""),
    contactnumber: jspb.Message.getFieldWithDefault(msg, 30, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Profile}
 */
proto.types.Profile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Profile;
  return proto.types.Profile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Profile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Profile}
 */
proto.types.Profile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthdate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmailvefified(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoto(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 12:
      var value = new proto.types.Address;
      reader.readMessage(value,proto.types.Address.deserializeBinaryFromReader);
      msg.setPermanentaddress(value);
      break;
    case 13:
      var value = new proto.types.Address;
      reader.readMessage(value,proto.types.Address.deserializeBinaryFromReader);
      msg.setContactaddress(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilenumber(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaceimageurl(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaceimagedate(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelationshipstatus(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDependents(value);
      break;
    case 19:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.addDobofdependents(value);
      break;
    case 20:
      var value = new proto.types.CreditRating;
      reader.readMessage(value,proto.types.CreditRating.deserializeBinaryFromReader);
      msg.setCreditrating(value);
      break;
    case 21:
      var value = new proto.types.Amount;
      reader.readMessage(value,proto.types.Amount.deserializeBinaryFromReader);
      msg.setCreditlimit(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setHighesteducationattained(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmploymentstatus(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKycstatus(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchid(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamesuffix(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlename(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Profile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Profile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Profile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Profile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFullname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBirthdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEmailvefified();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPhoto();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPermanentaddress();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.types.Address.serializeBinaryToWriter
    );
  }
  f = message.getContactaddress();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.types.Address.serializeBinaryToWriter
    );
  }
  f = message.getProfilenumber();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getFaceimageurl();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getFaceimagedate();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getRelationshipstatus();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getDependents();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getDobofdependentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreditrating();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.types.CreditRating.serializeBinaryToWriter
    );
  }
  f = message.getCreditlimit();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.types.Amount.serializeBinaryToWriter
    );
  }
  f = message.getHighesteducationattained();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getEmploymentstatus();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getKycstatus();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getBranchid();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getNamesuffix();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getMiddlename();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getContactnumber();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
};


/**
 * optional string ProfileID = 1;
 * @return {string}
 */
proto.types.Profile.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setProfileid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string FullName = 2;
 * @return {string}
 */
proto.types.Profile.prototype.getFullname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setFullname = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string UserName = 3;
 * @return {string}
 */
proto.types.Profile.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setUsername = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string BirthDate = 4;
 * @return {string}
 */
proto.types.Profile.prototype.getBirthdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setBirthdate = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Language = 5;
 * @return {string}
 */
proto.types.Profile.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setLanguage = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Country = 6;
 * @return {string}
 */
proto.types.Profile.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setCountry = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Email = 7;
 * @return {string}
 */
proto.types.Profile.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool EmailVefified = 8;
 * @return {boolean}
 */
proto.types.Profile.prototype.getEmailvefified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.types.Profile.prototype.setEmailvefified = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string Mobile = 9;
 * @return {string}
 */
proto.types.Profile.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setMobile = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string Photo = 10;
 * @return {string}
 */
proto.types.Profile.prototype.getPhoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setPhoto = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string Title = 11;
 * @return {string}
 */
proto.types.Profile.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional Address PermanentAddress = 12;
 * @return {?proto.types.Address}
 */
proto.types.Profile.prototype.getPermanentaddress = function() {
  return /** @type{?proto.types.Address} */ (
    jspb.Message.getWrapperField(this, proto.types.Address, 12));
};


/** @param {?proto.types.Address|undefined} value */
proto.types.Profile.prototype.setPermanentaddress = function(value) {
  jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.types.Profile.prototype.clearPermanentaddress = function() {
  this.setPermanentaddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Profile.prototype.hasPermanentaddress = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Address ContactAddress = 13;
 * @return {?proto.types.Address}
 */
proto.types.Profile.prototype.getContactaddress = function() {
  return /** @type{?proto.types.Address} */ (
    jspb.Message.getWrapperField(this, proto.types.Address, 13));
};


/** @param {?proto.types.Address|undefined} value */
proto.types.Profile.prototype.setContactaddress = function(value) {
  jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.types.Profile.prototype.clearContactaddress = function() {
  this.setContactaddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Profile.prototype.hasContactaddress = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string ProfileNumber = 14;
 * @return {string}
 */
proto.types.Profile.prototype.getProfilenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setProfilenumber = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string FaceImageUrl = 15;
 * @return {string}
 */
proto.types.Profile.prototype.getFaceimageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setFaceimageurl = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string FaceImageDate = 16;
 * @return {string}
 */
proto.types.Profile.prototype.getFaceimagedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setFaceimagedate = function(value) {
  jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string RelationshipStatus = 17;
 * @return {string}
 */
proto.types.Profile.prototype.getRelationshipstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setRelationshipstatus = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int32 Dependents = 18;
 * @return {number}
 */
proto.types.Profile.prototype.getDependents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {number} value */
proto.types.Profile.prototype.setDependents = function(value) {
  jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * repeated google.protobuf.Timestamp DobOfDependents = 19;
 * @return {!Array<!proto.google.protobuf.Timestamp>}
 */
proto.types.Profile.prototype.getDobofdependentsList = function() {
  return /** @type{!Array<!proto.google.protobuf.Timestamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 19));
};


/** @param {!Array<!proto.google.protobuf.Timestamp>} value */
proto.types.Profile.prototype.setDobofdependentsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.google.protobuf.Timestamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Timestamp}
 */
proto.types.Profile.prototype.addDobofdependents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.google.protobuf.Timestamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.types.Profile.prototype.clearDobofdependentsList = function() {
  this.setDobofdependentsList([]);
};


/**
 * optional CreditRating CreditRating = 20;
 * @return {?proto.types.CreditRating}
 */
proto.types.Profile.prototype.getCreditrating = function() {
  return /** @type{?proto.types.CreditRating} */ (
    jspb.Message.getWrapperField(this, proto.types.CreditRating, 20));
};


/** @param {?proto.types.CreditRating|undefined} value */
proto.types.Profile.prototype.setCreditrating = function(value) {
  jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.types.Profile.prototype.clearCreditrating = function() {
  this.setCreditrating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Profile.prototype.hasCreditrating = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional Amount CreditLimit = 21;
 * @return {?proto.types.Amount}
 */
proto.types.Profile.prototype.getCreditlimit = function() {
  return /** @type{?proto.types.Amount} */ (
    jspb.Message.getWrapperField(this, proto.types.Amount, 21));
};


/** @param {?proto.types.Amount|undefined} value */
proto.types.Profile.prototype.setCreditlimit = function(value) {
  jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.types.Profile.prototype.clearCreditlimit = function() {
  this.setCreditlimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.Profile.prototype.hasCreditlimit = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string HighestEducationAttained = 22;
 * @return {string}
 */
proto.types.Profile.prototype.getHighesteducationattained = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setHighesteducationattained = function(value) {
  jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string EmploymentStatus = 23;
 * @return {string}
 */
proto.types.Profile.prototype.getEmploymentstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setEmploymentstatus = function(value) {
  jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional bool KycStatus = 24;
 * @return {boolean}
 */
proto.types.Profile.prototype.getKycstatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/** @param {boolean} value */
proto.types.Profile.prototype.setKycstatus = function(value) {
  jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional string BranchID = 25;
 * @return {string}
 */
proto.types.Profile.prototype.getBranchid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setBranchid = function(value) {
  jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string NameSuffix = 26;
 * @return {string}
 */
proto.types.Profile.prototype.getNamesuffix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setNamesuffix = function(value) {
  jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string FirstName = 27;
 * @return {string}
 */
proto.types.Profile.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setFirstname = function(value) {
  jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string MiddleName = 28;
 * @return {string}
 */
proto.types.Profile.prototype.getMiddlename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setMiddlename = function(value) {
  jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string LastName = 29;
 * @return {string}
 */
proto.types.Profile.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setLastname = function(value) {
  jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string ContactNumber = 30;
 * @return {string}
 */
proto.types.Profile.prototype.getContactnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/** @param {string} value */
proto.types.Profile.prototype.setContactnumber = function(value) {
  jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * @enum {number}
 */
proto.types.MajorType = {
  UNKNOWNMAJORTYPE: 0,
  CHECKING: 1,
  SAVING: 2,
  TIMEDEPOSIT: 3,
  COMMERCIALLOAN: 4,
  MORTGAGELOAN: 5,
  CONSUMERLOAN: 6
};

/**
 * @enum {number}
 */
proto.types.MajorCategory = {
  UNKNOWNMAJORCATEGORY: 0,
  DEP: 1,
  LOAN: 2
};

/**
 * @enum {number}
 */
proto.types.BankCode = {
  UNKNOWNBANKCODE: 0,
  MANDIRI: 1,
  BCA: 2,
  BNI: 3,
  BRI: 4,
  DUMMYBANK: 5,
  BDO: 6,
  BPI: 7,
  ACEH: 8,
  AGRIS: 9,
  AGRONIAGA: 10,
  AMARINDONESIA: 11,
  ANDARA: 12,
  ANTARDAERAH: 13,
  ANZINDONESIA: 14,
  ARTHAGRAHA: 15,
  ARTOS: 16,
  BANGKOKINDONESIA: 17,
  BARCLAYSINDONESIA: 18,
  BCASYARIAH: 19,
  BI: 20,
  BISNISINTERNASIONAL: 21,
  BNISYARIAH: 22,
  BRISYARIAH: 23,
  BTPN: 24,
  BTPNSYARIAH: 25,
  BUKOPIN: 26,
  BUKOPINSYARIAH: 27,
  BUMIARTA: 28,
  CAPITALINDONESIA: 29,
  CENTRATAMANASIONAL: 30,
  CTBCINDONESIA: 31,
  DANAMON: 32,
  DANPAC: 33,
  DINARINDONESIA: 34,
  DKI: 35,
  DKISYARIAH: 36,
  EKONOMIRAHARJA: 37,
  FAMA: 38,
  GANESHA: 39,
  HARDA: 40,
  ICBCINDONESIA: 41,
  INAPERDANA: 42,
  INDEXSELINDO: 43,
  JABAR: 44,
  JABARSYARIAH: 45,
  JASAJAKARTA: 46,
  JATIM: 47,
  JATIMSYARIAH: 48,
  KEBHANA: 49,
  KESEJAHTERAANEKONOMI: 50,
  MANDIRISYARIAH: 51,
  MASPIONINDONESIA: 52,
  MAYAPADA: 53,
  MAYBANK: 54,
  MAYBANKSYARIAHINDONESIA: 55,
  MAYORA: 56,
  MEGA: 57,
  MEGASYARIAH: 58,
  MESTIKADHARMA: 59,
  METROEXPRESS: 60,
  MITRANIAGA: 61,
  MIZUHOINDONESIA: 62,
  MNCINTERNATIONAL: 63,
  MUAMALATINDONESIA: 64,
  MULTIARTA: 65,
  MUTIARA: 66,
  NUSANTARAPARAHYANGAN: 67,
  OCBCNISP: 68,
  OCBCNISPSYARIAH: 69,
  BANKOFAMERICAINDONESIA: 70,
  BANKOFCHINAINDONESIA: 71,
  BANKOFINDIAINDONESIA: 72,
  PIKKO: 73,
  PRIMAMASTER: 74,
  PUNDIINDONESIA: 75,
  QNBINDONESIA: 76,
  ROYALINDONESIA: 77,
  SAHABATSAMPOERNA: 78,
  SBIINDONESIA: 79,
  SINARHARAPAN: 80,
  SINARMAS: 81,
  BTN: 82,
  BTNSYARIAH: 83,
  UOBINDONESIA: 84,
  VICTORIA: 85,
  VICTORIASYARIAH: 86,
  WINDUKENTJANA: 87,
  WOORISAUDARAINDONESIA: 88,
  YUDHABAKTI: 89,
  BNPPARIBASINDONESIA: 90,
  BPDACEHSYARIAH: 91,
  BPDBALI: 92,
  BPDBENGKULU: 93,
  BPDJAMBI: 94,
  BPDJAWATENGAH: 95,
  BPDKALIMANTANBARAT: 96,
  BPDKALIMANTANBARATSYARIAH: 97,
  BPDKALIMANTANTENGAH: 98,
  BPDKALIMANTANTIMUR: 99,
  BPDKALIMANTANTIMURSYARIAH: 100,
  BPDKALIMANTANSELATAN: 101,
  BPDKALIMANTANSELATANSYARIAH: 102,
  BPDLAMPUNG: 103,
  BPDMALUKU: 104,
  BPDNTB: 105,
  BPDNTT: 106,
  BPDPAPUA: 107,
  BPDRIAUKEPRI: 108,
  BPDSULAWESISELATANBARAT: 109,
  BPDSULAWESITENGAH: 110,
  BPDSULAWESITENGGARA: 111,
  BPDSULAWESIUTARA: 112,
  BPDSUMATERABARAT: 113,
  BPDSUMATERABARATSYARIAH: 114,
  BPDSUMATERASELATAN: 115,
  BPDSUMATERASELATANBABEL: 116,
  BPDSUMATERASELATANSYARIAH: 117,
  BPDSUMATERASELATANUUS: 118,
  BPDSUMATERAUTARA: 119,
  BPDSUMATERAUTARASYARIAH: 120,
  BPDYOGYAKARTA: 121,
  BPDYOGYAKARTASYARIAH: 122,
  CIMBNIAGA: 123,
  CIMBNIAGASYARIAH: 124,
  CITIBANKINDONESIA: 125,
  COMMONWEALTHINDONESIA: 126,
  DANAMONSYARIAH: 127,
  DBSINDONESIA: 128,
  DEUTSCHEAGINDONESIA: 129,
  NOBUINDONESIA: 130,
  PANIN: 131,
  PANINSYARIAH: 132,
  RABOINDONESIA: 133,
  RESONAPERDANIA: 134,
  STANDARDCHARTEREDINDONESIA: 135,
  SUMITOMOMITSUIINDONESIA: 136,
  BANKOFTOKYOINDONESIA: 137,
  ROYALBANKOFSCOTLANDINDONESIA: 138,
  PERMATA: 139,
  PERMATASYARIAH: 140,
  SINARMASSYARIAH: 141,
  MANDIRITASPEN: 142,
  BANTEN: 143,
  BEI: 144,
  CHASEINDONESIA: 145,
  CCBINDONESIA: 146,
  JTRUSTINDONESIA: 147,
  TRANSFERWISE: 148
};

/**
 * @enum {number}
 */
proto.types.CardStatus = {
  UNKNOWNCARDSTATUS: 0,
  LOCK: 1,
  UNLOCK: 2,
  ACTIVE: 3
};

/**
 * @enum {number}
 */
proto.types.CardAccessStatus = {
  UNKNOWNCARDACCESSSTATUS: 0,
  OFTEN: 1,
  RARE: 2
};

/**
 * @enum {number}
 */
proto.types.SortDirection = {
  UNKNOWNSORTDIRECTION: 0,
  ASC: 1,
  DESC: 2
};

goog.object.extend(exports, proto.types);
