import Vue from "vue";
import Vuex, { Store } from "vuex";
import VuexPersist from "vuex-persist";
import authUtils from "@/utils/auth-utils";

Vue.use(Vuex);

import {
  auth,
  user,
  config,
  org,
  favorites,
  transactions,
  AuthState,
  UserState,
  ConfigState,
  OrgState,
  TransactionState,
  FavoriteState,
} from "@/store/modules";

export interface RootState {
  auth: AuthState;
  user: UserState;
  config: ConfigState;
  org: OrgState;
  transactions: TransactionState;
  favorites: FavoriteState;
}

const storage = new VuexPersist({
  key: "client.cebcorp.dashboard",
  storage: window.sessionStorage,
  reducer: (state: RootState) => ({
    auth: state.auth,
  }),
});

const store: Store<RootState> = new Vuex.Store<RootState>({
  modules: {
    auth,
    user,
    config,
    org,
    transactions,
    favorites,
  },
  plugins: [storage.plugin],
  actions: {
    checkError(context, err) {
      if (err instanceof Error) {
        const error: Error = err;
        if (error.message.includes("code=16 msg=unauthorized")) {
          authUtils.clearData();
          window.location.href = "/";
        }
      }
    },
  },
});

export default store;
