





















































































import { Vue, Component, Prop } from "vue-property-decorator";
import VModal from "vue-js-modal";

import { SvgIcon } from "@/components";
import { Transaction } from "@/proto/transactions/all_pb";
import { unixToDateString, formatCurrency } from "@/utils/common";

Vue.use(VModal);

@Component({
  components: {
    SvgIcon,
  },
})
export default class TransactionDetailsModal extends Vue {
  @Prop()
  private readonly transaction!: Transaction.AsObject;

  _showTransactionDetailsModal() {
    this.$modal.show("transaction-details-modal");
  }

  _hideTransactionDetailsModal() {
    this.$modal.hide("transaction-details-modal");
  }

  get txn() {
    return {
      destinationAccountName:
        this.transaction?.destinationaccount?.ownername || "N/A",
      destinationAccountID:
        this.transaction?.destinationaccount?.accountid || "N/A",
      sourceaccount: this.transaction?.sourceaccount?.accountid,
      settlementrail: this.transaction?.settlementrail,
      date: unixToDateString(this.transaction?.date?.seconds),
      ref: this.transaction?.transactionid,
      remarks: this.transaction?.remarks,
    };
  }

  get txnAmount() {
    const amount = parseFloat(this.transaction?.amount?.num as string);
    return formatCurrency(amount / 100);
  }
}
